import useFetchImportedData from "./fetchImportData.hook";
import { importTabs } from "./excelDataTabs";
import GridTable from "atoms/GridTable/GridTable";
import withNotification from "components/Hoc/withNotification";
import GridTableScrollable from "atoms/GridTable/GridScrollable";

export const editField: string = "inEdit";

export const enum MigrationStatus {
  None = 0,
  ValidationError = 1,
  Failed = 2,
  Completed = 3,
}
export const MigrationStatusDescription = new Map<number, string>([
  [MigrationStatus.None, 'None'],
  [MigrationStatus.ValidationError, 'Validation Error'],
  [MigrationStatus.Failed, 'Failed'],
  [MigrationStatus.Completed, 'Completed'],
]);
const TabData = (props: any) => {
  const {
    data,
    pageLoading,
    handlePagination,
    rowRender,
    itemChange,
    columns,
    sortOrder,
    filters
  } = useFetchImportedData(importTabs.CLIENTINFO, props);

  return (
    <>
      {filters?.showAllRecords ? (
        <GridTableScrollable
          columns={columns}
          data={data?.data}
          loading={pageLoading}
          resizable={true}
          rowRender={rowRender}
          onItemChange={itemChange}
          editField={editField}
        />
      ) : (
        <GridTable
          title=""
          total={data?.total}
          columns={columns}
          data={data?.data}
          // key={"test"}
          handlePagination={handlePagination}
          pageSize={sortOrder ? data?.total : 10}
          rowRender={rowRender}
          onItemChange={itemChange}
          editField={editField}
          loading={pageLoading}
          // key={data?.pageNumber === 0 ? "refresh" : "notRefresh"}
          resizable={true}
        />
      )}
      {/* <a data-for='client-validation-tooltip' data-tip='Lovely colors!'>ㅇㅅㅇ</a>
      <ReactTooltip id={"client-validation-tooltip"} place="top" type="dark" effect="float" html={true}/> */}
    </>
  );
};

export default withNotification(TabData);

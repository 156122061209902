import { Button } from "@progress/kendo-react-buttons";
import SignaturePad from "components/signaturePad";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";

const WaiverAgreement = (props: any) => {
  let service = new TenantService();
  //base64 encode signature
  const [signature, setSignature] = useState("");
  const [signatureText, setSignatureText] = useState<any>("");
  const [agreement, setAgreement] = useState<any>("");
  const [signatureFont, setSignatureFont] = useState<any>("");
  const [signatureType, setSignatureType] = useState<any>("");
  const [visibleSignPad, setVisibleSignPad] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    setLoading(true);
    const userMemberId = props?.UserMemberId;
    const data = await service?.GetWaiverAgreement(userMemberId);
    if (data) {
      setAgreement(data);
    }
    setLoading(false);
  };

  const handleSave = async () => {
    const req = {
      UserMemberId: props?.UserMemberId,
      Signature: signature,
      TermsAndConditionAccepted: true,
    };
    setBtnLoading(true);
    const savewaiverSign = await service?.SaveWaiverAgreement(req);
    setBtnLoading(false);
    if(savewaiverSign?.ResponseCode===100){
        props?.handleBack(true)
    }else{
        const errorMsg = savewaiverSign.ErrorMessages[0];
        props?.handleNotificationMessage(errorMsg, "error");
    }

  };

  //to saving signature
  const generatedImage = (
    imageString: string,
    text?: string,
    font?: string,
    Type?: string
  ) => {
    setSignature(imageString);
    setSignatureText(text);
    setSignatureFont(font);
    setSignatureType(Type || "");
  };

  //handle signature pad popup
  const handleSignaturePad = () => {
    setVisibleSignPad(true);
  };

  if (Loading) {
    return <BuzopsLoader type={"list"} />;
  }
  return (
    <>
      {/* <h5 className="mb-3 bz_fs_1_5 d-flex">
        <div className="pr-1">
          <button
            className="btn btn-primary bz-back-btn btn-sm"
            onClick={() => props?.handleBack()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
        </div>
        &nbsp;
        <div>
          {"Waiver Agreement"}
          <br />
          <small className="text-muted">{`Accept & Sign the Terms and Conditions of a waiver`}</small>
        </div>
      </h5> */}
      <div
        className="agreement-view"
        id={"agreement-view"}
        dangerouslySetInnerHTML={{
          __html: agreement,
        }}
      />
      <div className="">
        <label>{`Signature:`}</label>
        <div
          className={
            signatureType === "type" ? `buz-signature-type` : `buz-signature`
          }
          onClick={() => {
            handleSignaturePad();
          }}
          style={{ display: "flex", justifyContent: "left" }}
        >
          {signature !== "" ? (
            signatureText && signatureFont ? (
              <span
                style={{
                  fontFamily: signatureFont,
                  alignSelf: "center",
                  fontSize: signatureType === "type" ? `25px` : `36px`,
                  overflow: "visible",
                  padding: signatureType === "type" ? `2px` : `0px`,
                }}
              >
                {signatureText}
              </span>
            ) : (
              <img
                src={signature}
                placeholder={"Please Sign"}
                width={"100%"}
                height={"100%"}
                onClick={() => {
                  handleSignaturePad();
                }}
              ></img>
            )
          ) : (
            <div className="buz-signature-pad">
              <img src="https://uatchargehub.buzops.com/Assets/Images/sign_here.jpg" />
            </div>
          )}
        </div>
        <Button disabled={btnLoading} onClick={() => props?.handleBack()}>
          Cancel
        </Button>
        <BuzopsButton
          loading={btnLoading}
          disabled={btnLoading}
          label={"Save"}
          onClick={() => handleSave()}
        />
      </div>
      {visibleSignPad && (
        <SignaturePad
          staffPage={props?.staffPage}
          generatedImage={(imageString, text, font, typeVal) =>
            generatedImage(imageString, text, font, typeVal)
          }
          key={visibleSignPad.toString()}
          onClose={() => setVisibleSignPad(false)}
        />
      )}
    </>
  );
};

export default WaiverAgreement;

import { Dialog } from "@progress/kendo-react-dialogs";
import React from "react";

interface IDialogPopup {
  className: string;
  height: number;
  onClose: any;
  title: string;
}

const DialogPopup: React.FunctionComponent<IDialogPopup> = (props) => {
  return (
    <Dialog
      className={props.className}
      height={props.height}
      onClose={props.onClose}
      title={props.title}
    >
      {props.children}
    </Dialog>
  );
};

export default DialogPopup;

import React, { useEffect, useState } from 'react'
import { useFetchClassDetails } from './useFetchClassDetails';
import { ClassService } from 'services/class/index.service';
import moment from 'moment';
import EditSession from 'components/session/EditSession';
import BuzopsLoader from 'generic-components/BuzopsLoader';
import withNotification from 'components/Hoc/withNotification';

const ManageAppointments = (props: any) => {
  const {classDetails} = useFetchClassDetails(props);
  const [managebtnLoading, setManagebtnLoading] = useState(true);
  const [classSessions, setClassSessions] = useState<any[]>([]);
  useEffect(() => {
    if(classDetails){
      fetchAllSessions();
    }
  }, [classDetails]);
  const fetchAllSessions = async (showMessage = false) => {
    if(showMessage){
      props.handleNotificationMessage(`Selected Appointments successfully Updated`, "success");
    }
    const service = new ClassService();
    setManagebtnLoading(true);
    let req : any = {
      ClassId: classDetails?.ClassId,
      SortDirection: "ascending",
      FromDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      ToDate: moment().add(1, "year").format("YYYY-MM-DD") + "T00:00:00",
      IncludeInActive: false,
      SortBy: "ClassSessionStartDate",
    };
    if(props?.match?.params?.SessionId){
      req = {
        ...req,
        ClassSessionId: props?.match?.params?.SessionId
      }
      
    }
    const result = await service.filterClassSchedule(req);
    setClassSessions(result?.Items);
    setManagebtnLoading(false);
  };
  if(managebtnLoading){
    return <BuzopsLoader />
  }
  return (
    <div>
      <EditSession
          classValue={classDetails}
          classSessions={classSessions}
          // classSessions={[selectedItem]}
          editType={props?.match?.params?.SessionId ? "single" : "bulk"}
          // refreshSessions={() => fetchAllSessions()}
          {...props}
          handleWindow={(bool = false) => fetchAllSessions(bool)}
          key={managebtnLoading.toString()}
        />
    </div>
  )
}

export default withNotification(ManageAppointments)
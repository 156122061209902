import About from "components/about/About";
import Auth from "components/authcomponent/Auth";
import { useState } from "react";

const AffiliateLogin = (props: any) => {
  const [loginStatus, setLoginStatus] = useState(true);
  const handleCloseLogin = () => {
    setLoginStatus(false);
  };
  return (
    <>
      <About />
      <Auth
        handleCloseLogin={() => handleCloseLogin()}
        showComponent={loginStatus}
        key={loginStatus.toString()}
        loginPage={"affiliate"}
      />
    </>
  );
};

export default AffiliateLogin;

import { Switch } from "@progress/kendo-react-inputs";
import withNotification from "components/Hoc/withNotification";
import ManageTaxesView from "components/ManageTaxes/ManageTaxes.view";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { TfiAngleRight } from "react-icons/tfi";
import useSettings from "./Settings.hook";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";

const Settings = (props: any) => {
  const {
    loading,
    uuid,
    handleEnableTax,
    hasTaxEnabled,
    toggleLoading,
    handleTax,
    handleClose,
    page,
  } = useSettings(props);
  if (loading) {
    return <BuzopsLoader type="list" />;
  }

  if (page?.tax) {
    return <ManageTaxesView uuid={uuid} handleClose={handleClose} />;
  }

  return (
    <>
      <div className="p-4">
        <h6 className="bz-article-headline pb-4">Settings</h6>
        <div className="row" style={{ marginBottom: "1rem" }}>
          <div className="bz-taxes-card">
            <div className={`bz-card card`}>
              <div className="card-body p-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="pr-3">
                    <div className="bz-list-profile-lead">Surcharges</div>
                    <div className="bz-list-profile-caption">
                      Surcharges are applicable for the club if we enable the
                      surcharge
                    </div>
                  </div>
                  <div className="d-flex align-items-center pr-3">
                    {toggleLoading ? (
                      <span>
                        <i className={"btnloading fa fa-spinner fa-spin"}></i>
                      </span>
                    ) : (
                      <BuzopsTooltip
                      html={
                        <span title={hasTaxEnabled ?"Enabled":"Disabled"} className="mr-1">

                          <Switch
                          disabled={toggleLoading}
                          checked={hasTaxEnabled}
                          onChange={(e: any) => handleEnableTax(e.value)}
                        />
                        </span>
                      }
                    />
                    )}
                  </div>
                  <div className="align-self-center">
                    <span
                      className="d-inline-flex bz-taxpanel-arrow-icon"
                      onClick={() => handleTax()}
                    >
                      <TfiAngleRight size={"14px"} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default withNotification(Settings);

import BuzopsLoader from "generic-components/BuzopsLoader";
import { useEffect, useState } from "react";
import { ReportsService } from "services/reports/index.service";
import ReportsCommonFilters from "./reportsCommonFilters";
import ReportsSection from "./ReportsSection";
import ReportConstants from "./Utilities/constants";

const Reports = (props: any) => {
  const service = new ReportsService();
  const uuid = props.match?.params?.uuid;
  const [loading, setLoading] = useState(true);

  async function fetchUserSession() {
    setLoading(true);
    if (uuid) {
      const result = await service.getTenantDetails(uuid);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchUserSession();
  }, []);

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <div>
      <ReportsCommonFilters
        {...props}
        history={props.history}
        showReports={false}
        showDate={false}
        location={props.location}
        {...props}
      />
      <div className="reports-landing-main">
        <div className="row">
          <div className="col-md-6">
            <ReportsSection
              uuid={uuid}
              reports={ReportConstants.subscribers}
              heading="Subscribers"
            />
          </div>
          <div className="col-md-6">
            <ReportsSection
              uuid={uuid}
              reports={ReportConstants.sales}
              heading="Sales"
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <ReportsSection
                uuid={uuid}
                reports={ReportConstants.attendance}
                heading="Attendance"
              />
            </div>
            <div className="col-md-6">
              <ReportsSection
                uuid={uuid}
                reports={ReportConstants.revenue}
                heading="Revenue"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <ReportsSection
                uuid={uuid}
                reports={ReportConstants.payroll}
                heading="Payroll"
              />
            </div>
            <div className="col-md-6">
              <ReportsSection
                uuid={uuid}
                reports={ReportConstants.payments}
                heading="Payments"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;

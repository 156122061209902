import { useEffect, useState, useRef } from "react";
import { CheckInService } from "services/checkin/index.service";
import moment from "moment";
import {
  CustomFieldsTypeOptions,
  checkinModeEnum,
  checkinTypeEnum,
  checkinTypeStringEnum,
} from "utils/form-utils";
import { SecuritySystemType } from "utils/constants";
import { ClassService } from "services/class/index.service";
import { ManageCustomFieldsService } from "services/managecustomfields/index.service";
import { DATA_ITEM_KEY, FORM_DATA_INDEX } from "components/ManageCustomFields/MangeCustomFields.hook";
import { guid } from "@progress/kendo-react-common";
import { TenantService } from "services/tenant/index.service";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

const initialDataState = {
  skip: 0,
  take: 10,
};

const useCheckinsReportHook = (props: any) => {
  const tenService = new TenantService();
  const checkIns = new CheckInService();
  const [page, setPage] = useState(initialDataState);

  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [securitySystemType, setSecuritySystemType] = useState(undefined);

  const [customFields, setCustomFields] = useState<any>([]);
  const [customOrigFields, setCustomOrigFields] = useState<any>([]);
  const [planData, setPlanData] = useState<any>([]);
  const [planOrigData, setPlanOrigData] = useState<any>([]);
  
  const [membershipField, setMembershipField] = useState<any>([]);
  const [selectedCustomField, setSelectedCustomField] = useState<any>([]);
  const [disable, setDisable] = useState(false);
  const [selectedCheckInTypeOption, setSelectedCheckInTypeOption] =
    useState<any>({
      id: 1,
      text: `All Check-Ins`,
    });

  const [clientsList, setClientsList] = useState<any>([]);

  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment()
      .startOf("isoWeek")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [dateFilter, setDateFilter] = useState("This Week");
  const [dataList, setDataList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    checkInsData: [],
  });
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const service = new ThirdPartyConfigService();


  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async() => {
    setLoading(true);
    await fetchUserSession();
  }
  const fetchUserSession = async () => {
    setLoading(true);
    await fetchSecurityConfig();
    await fetchCustomFields();
    await fetchPlanList();

    setLoading(false);
  };

  const fetchSecurityConfig = async () => {
    setLoading(true);
    const result = await service.getSecuritySystemConfigs();
    setSecuritySystemType(result.data.SecuritySystemType);
    setLoading(false);
  };
  const _export = useRef<any>(null);
  const _grid = useRef<any>();

  const excelExport = async () => {
    if (_export.current !== null) {
      const data = await fetchCheckIns(0, 100000, true);
      _export.current.save(data, _grid?.current.columns);
    }
  };

  const filterRequired = async () => {
    setMembershipField([]);
    setSelectedCustomField([]);
    setDisable((prev) => !prev);
    if(disable){
      setPage(initialDataState);
      fetchCheckIns(0, 10, false, false);
    }
    
  }
  const searchAdditionalFilters = async () => {
    setPage(initialDataState);
    fetchCheckIns(0);
    setSearchClicked(true);
  }
  const fetchCustomFields = async () => {
    const service = new ManageCustomFieldsService();
    const data = await service.getCustomFields();
    const result = data?.map((dataItem: any, idx: any) => ({
        ...dataItem,
        [FORM_DATA_INDEX]: idx,
        [DATA_ITEM_KEY]: guid(),
        FieldType: CustomFieldsTypeOptions?.filter(
            (i: any) => i?.Id === dataItem?.FieldType
        )?.[0],
    }));
    setCustomFields(result.filter((val: any) => val.CanShow));
    setCustomOrigFields(result.filter((val: any) => val.CanShow));
};
const fetchPlanList = async () => {
  let req: any = {};
    const res = await tenService.AvailablePlansToSubscribe(req);
    setPlanData(res);
    setPlanOrigData(res);
}
  const getCheckinType = (type: checkinTypeEnum) => {
    switch (type) {
      case checkinTypeEnum.Location:
        return checkinTypeStringEnum.Location;
      case checkinTypeEnum.OneOnOne:
        return checkinTypeStringEnum.OneOnOne;
      case checkinTypeEnum.Group:
        return checkinTypeStringEnum.Group;
    }
  };

  const fetchCheckIns = async (
    pageNumber: number,
    pageSize: number = 10,
    forExcel = false,
    reset = true
  ) => {
    if (pageNumber === 0) {
      setLoading(true);
    }

    let req: any = {
      FilterFields: [],
      PageNumber: pageNumber,
      PageSize: pageSize,
      FromDate: fromDateToDate.from,
      ToDate: fromDateToDate.to,
      SortBy: "Ocurred",
      SortDirection: "desc",
      CheckinType: selectedCheckInTypeOption?.id,
      UserId: null,
      ...(props.securitySystemType ===
        SecuritySystemType.ProximityReaderSystem && {
        AccessPoint: props?.configuredEntries?.filter(
          (entry: any) => entry?.UsedForSiteAccess === true
        )?.[0]?.Id,
      })
    };

    if (selectedCheckInTypeOption?.id === 1) {
      delete req["CheckinType"];
    }
    if (selectedClient.type === "select") {
      const UserId = selectedClient?.value?.UserId || null;
      if (UserId) {
        req = { ...req, UserId };
      }
    }
   
    if(membershipField.length > 0 && reset){
      
      const mappedMembershipData = membershipField?.map((item: any) => {
        return item.PlanManagementId;
      })
      req = { ...req, MembershipPlanList: mappedMembershipData}
    }

    if(selectedCustomField.length > 0 && reset){
      
      const mappedCustomFieldData = selectedCustomField?.map((item: any) => {
        return item.FieldName;
      })
      req = { ...req, AdditionalFieldsToIncludeList: mappedCustomFieldData}
    }

    const url =
      securitySystemType === SecuritySystemType.ScannerSystem
        ? `SimpleAccess/Checkins/AdvancedSearch`
        : `HardwareAccess/Checkins/AdvancedSearch`;
    const result = await checkIns.getCheckins(url, req);
    if (pageNumber === 0) {
      setLoading(false);
    }
    if (result?.ResponseCode === 100) {
      const total = result.Item?.TotalItems;
      const data = result?.Item?.Result?.map((row: any) => {
        return {
          ...row,
          Type: getCheckinType(row?.Type),
          CheckinMode: checkinModeEnum[row?.CheckinMode],
          Details: `${row?.EntityName || ""}  ${row?.ScheduledStartTime || ""}`,
          TypeOfMember: row?.MemberType === 0 ? "Primary" : "Secondary",
          DateOfBirth: row?.DateOfBirth ? moment(`${row?.DateOfBirth}`).format('MM/DD/YYYY') : ""
        };
      });

      forExcel === false &&
        setDataList({
          pageNumber: pageNumber,
          total: total,
          checkInsData: data || [],
        });
      return data;
    } else {
      props?.handleNotificationMessage("Invalid date range", "error");
    }
    return [];
  };

  const pageChange = async (event: any) => {
    setPage({
      ...event.page,
    });
    await fetchCheckIns(event.page.skip / 10);
  };

  async function autoCompleteClients(str: string) {
    const req = {
      Where: {
        SearchKeyword: str,
        IsGloablSearch: true,
      },
    };
    setClientSearchLoading(true);
    const clientservice = new ClassService();
    const result = await clientservice.autoCompleteClients(req);
    setClientSearchLoading(false);
    setClientsList(result);
  }

  const handleClientSelecton = (e: any) => {
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
      }
      return true;
    }
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      autoCompleteClients(e.value);
    }
    if (e.syntheticEvent?.type === "click") {
      setSelectedClient({
        ...selectedClient,
        type: "select",
        value: e.value,
      });
    }
  };

  const handleMembershipChange = (event:any) => {
    if(event.value.length === 0){
      setSelectedCustomField([]);
      setSearchClicked(false);
    }else {
      if(event.value.length === 1) {
        setSelectedCustomField(customFields);
      }
    }
    setMembershipField(event.value)
    
  }
  const handleCustomFieldChange = (event:any) => {
    setSelectedCustomField(event.value)
  }

  const membershipFilterData = (filter: any) => {
    const data = planOrigData.slice();
    return filterBy(data, filter);
  };
  const membershipFilter = (e: MultiSelectFilterChangeEvent) => {
    const filteredData = membershipFilterData(e.filter);
    setPlanData(filteredData);
  };

  const customFieldFilterData = (filter: any) => {
    const data = customOrigFields.slice();
    return filterBy(data, filter);
  };
  const customFieldFilter = (e: MultiSelectFilterChangeEvent) => {
    const filteredData = customFieldFilterData(e.filter);
    setCustomFields(filteredData);
  };
  
  useEffect(() => {
    if(securitySystemType){
      setPage(initialDataState);
      fetchCheckIns(0);
    }
  }, [selectedCheckInTypeOption, selectedClient, fromDateToDate, securitySystemType]);

  return {
    loading,
    processing,
    setProcessing,
    selectedCheckInTypeOption,
    setSelectedCheckInTypeOption,
    dateFilter,
    setFromDateToDate,
    excelExport,
    dataList,
    _export,
    clientsList,
    selectedClient,
    clientSearchLoading,
    handleClientSelecton,
    handleMembershipChange,
    handleCustomFieldChange,
    filterRequired,
    searchAdditionalFilters,
    membershipField,
    selectedCustomField,
    setDateFilter,
    pageChange,
    page,
    _grid,
    customFields,
    planData,
    disable,
    customFieldFilter,
    membershipFilter,
    customOrigFields,
    planOrigData,
    searchClicked
  };
};

export default useCheckinsReportHook;

import React from "react";
import usePromotions from "./Promotions.hook";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { ListView } from "@progress/kendo-react-listview";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { OfferDurationTypeEnum, RewardTypeList } from "utils/form-utils";
import { DropDownButton } from "@progress/kendo-react-buttons";
import withNotification from "components/Hoc/withNotification";
import { CurrencyFormat } from "utils";
import { SecondTierCommissionType } from "utils/form-utils";
import { Switch } from "@progress/kendo-react-inputs";
import { Pager } from "@progress/kendo-react-data-tools";
import { DropDownList } from "@progress/kendo-react-dropdowns";
export const MetricsToolTip = (data: any) => {
  const tooltipData = data?.KeyMetrics;
  if (tooltipData?.NetRevenueFromPurchases === 0) {
    return <div>{"No Revenue Generated"}</div>;
  }
  return (
    <table className="table table-sm table-borderless">
      <tbody>
        <tr className="border-bottom-1 p-0" style={{ width: "100%", borderBottom: '1px solid white' }}>
          <td>{"Gross revenue"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.RevenueFromPurchases)}
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Refunds"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.PurchaseRefunds)}
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Returns"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.PurchaseReturns)}
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Chargebacks"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.Chargebacks)}
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Voids"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.PurchaseVoids)}
          </td>
        </tr>
        <tr className="border-top-1 p-0" style={{ width: "100%", borderTop: '1px solid white' }}>
          <td>{"Net Revenue"}</td>
          <td className="text-center">:</td>
          <td className="text-right">
            {CurrencyFormat(tooltipData?.NetRevenueFromPurchases)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const OfferTooltip = (props: any) => {
  const tooltipData = props?.data;
  return (
    <table className="table table-sm table-borderless">
      <tbody>
        <tr className="border-bottom-1 p-0" style={{ width: "100%", borderBottom: '1px solid white' }}>
          <td>{"Offer Details"}</td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Coupon Name"}</td>
          <td className="text-left">
            <div style={{display:'flex', justifyContent:'space-between', gap:'2px'}}>
<span>:</span> <span style={{flexGrow:'1'}}> {tooltipData?.Description}  </span>
            </div>
            
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Duration Type"}</td>
          <td className="text-left">
          <div style={{display:'flex', justifyContent:'space-between', gap:'2px'}}>
<span>:</span> <span style={{flexGrow:'1'}}>  {`${OfferDurationTypeEnum[tooltipData?.DurationType]}`}  </span>
            </div>
             
          </td>
        </tr>
        <tr className="border-0 p-0" style={{ width: "100%" }}>
          <td>{"Offer Value"}</td>
          <td className="text-left">

          <div style={{display:'flex', justifyContent:'space-between', gap:'2px'}}>
<span>:</span> <span style={{flexGrow:'1'}}>   {`${tooltipData?.Type === 1
              ? CurrencyFormat(tooltipData?.Amount)
              : `${tooltipData?.Amount} %`
              }`} </span>
            </div>
            
          </td>
        </tr>
      </tbody>
    </table>
  );
};
const Promotions = (props: any) => {
  const {
    handleView,
    rewardTypes,
    handleCreateForm,
    handleSearchPromotion,
    searchKeyWord,
    promotions,
    loading,
    handleToggleStatus,
    showInactive,
    page,
    handlePageChange,
    totalItems,
    promotionsType,
    promotionTypeFilter
  } = usePromotions(props);
  const { skip, take } = page;

  const SettingBtns = (dataItem: any) => {
    const addItems = [
      {
        text: "View",
        key: "view",
        disable: false,
      },
      {
        text: "Edit",
        key: "edit",
        disable: dataItem?.IsActive ? false : true,
      },
    ];

    return (
      <BuzopsTooltip
        html={
          <div title="Settings">
            <DropDownButton
              text={
                <>
                  <i className={`fas fa-cogs`}></i>
                </>
              }
              onItemClick={(e: any) => handleView(e?.item?.key, dataItem)}
              items={addItems?.filter((i: any) => !i?.disable)}
              primary={false}
              className="bz-icon-btn"
            />
          </div>
        }
      />
    );
  };

  const NewMyItemRender = (props: any) => {
    let item = props.dataItem;

    return (
      <>
        <div className="" key={item?.PromotionId}>
          <div className="p-3 align-middle buz-online-list-view pe-sm-0 promotions-list-view ">
            <div className="section-bg">
              <div className="section-1 align-items-center" style={{ gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: '10px' }} className="d-blockmanagepromotions">
                  <h6 style={{ flexGrow: '1' }}>{item?.Name}   </h6>
                  <span className={!item?.IsActive ? "badge badge-status-InActive" : 'badge badge-success'} style={{ minWidth: '60px', padding: '5px 0', }}>
                    {item?.IsActive ? 'Active' : 'In-Active'}</span>
                </div>

                <div className="payout-settings">
                  {/* <div>
                  Payout terms:{" "}
                  <span className="buz-font-600">{`Monthly/Net-${item?.PaymentTermDays}`}</span>
                </div> */}
                  <div>
                    <span className="payment-setting-icon">
                      {/* <i className={`fas fa-cogs`}></i> */}
                      {SettingBtns(item)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-2 adminpromotions">
              <div className="count-items">
                <div className="child-count-item" style={{ minWidth: '110px' }}>
                  <div className="value-item">
                    <span className="">
                      <BuzopsTooltip
                        html={
                          <a
                            title="Net Revenue from Purchases"
                            style={{
                              borderBottom: "1px dotted #d9d9d9",
                              cursor: "pointer",
                            }}
                          >
                            {CurrencyFormat(
                              item?.KeyMetrics?.NetRevenueFromPurchases || 0
                            )}
                          </a>
                        }
                        content={
                          <MetricsToolTip KeyMetrics={item?.KeyMetrics} />
                        }
                        position={"right"}
                      />
                    </span>
                  </div>
                  <div className="heading-item">Revenue</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      {item?.KeyMetrics?.LeadsGenerated || 0}
                    </span>
                  </div>
                  <div className="heading-item">Prospects</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      {item?.KeyMetrics?.SubscriptionsRedeemed || 0}
                    </span>
                  </div>
                  <div className="heading-item">Subscriptions Redeemed</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      {item?.KeyMetrics?.SubscriptionsCancelled || 0}
                    </span>
                  </div>
                  <div className="heading-item">Subscriptions Cancelled</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    {item?.ValidForAnySubscription
                      ? "All"
                      : item?.PromotionSubscriptions?.length}
                  </div>
                  <div className="heading-item">Tagged Subscriptions</div>
                </div>

                <div className="child-count-item">
                  <div className="value-item">
                    {item?.ValidForAnyAffiliate
                      ? "All"
                      : item?.PromotionAffiliates?.length}
                  </div>
                  <div className="heading-item">Tagged Affiliates</div>
                </div>
              </div>
              <div className="rewards-section">
                <>
                  {item?.PromotionRewards?.map((reward: any) => {
                    const TypeValue = RewardTypeList?.filter(
                      (i: any) => i?.id === reward?.RewardCreditTypeId
                    )[0]?.text;

                    const RewardTypeValue = rewardTypes?.filter(
                      (i: any) => i?.RewardTypeId === reward?.RewardType
                    )[0]?.RewardTypeName;

                    const commissionSource = SecondTierCommissionType?.filter(
                      (i: any) => i?.id === reward?.CommissionSource
                    )[0]?.text;

                    let descriptionValue = "";
                    if (reward?.RewardCreditTypeId === 1) {
                      descriptionValue =
                        reward?.CommissionType === 1
                          ? `${CurrencyFormat(
                            reward?.RewardAmount
                          )} ${RewardTypeValue}`
                          : `${reward?.RewardAmount}%  ${RewardTypeValue}`;
                    } else if (reward?.RewardCreditTypeId === 2) {
                      descriptionValue = `${CurrencyFormat(
                        reward?.RewardAmount
                      )} ${RewardTypeValue}`;
                    } else if (reward?.RewardCreditTypeId === 3) {
                      descriptionValue = `${reward?.RewardAmount}%  ${commissionSource}`;
                    }
                    return (
                      <>
                        <div className="d-flex justify-content-between mobile">
                          <div className="d-flex mobilesub" style={{ width: '100%' }}>

                            <div className="d-flex mainrewardspayout">
                              {/* <span className="highlight">{description}</span> */}
                              <span className="rewardspayout">
                                {TypeValue}
                                {/* <BiCheck color="green" size={"25px"} style={{paddingBottom:'0.35rem'}} /> */}
                              </span>
                              <BuzopsTooltip
                                html={
                                  <span className="buz-font-400" style={{
                                    borderBottom: "1px dotted #d9d9d9",
                                    cursor: "pointer",
                                  }} title={`Reward Name :  ${reward?.RewardName}`}>
                                    <span className="rewardsclon">:</span>  {descriptionValue}
                                  </span>
                                }
                                position='bottom'
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {item?.PromotionType !== 2 && <div className="d-flex justify-content-between mobile">
                    <div className="d-flex mobilesub" style={{ width: '100%' }}>

                      <div className="d-flex mainrewardspayout">
                        <span className="rewardspayout">{"Offer"} </span>
                        <BuzopsTooltip
                          html={
                            <span title="Offer" style={{
                              borderBottom: "1px dotted #d9d9d9",
                              cursor: "pointer",
                            }} className="buz-font-400"> <span className="rewardsclon">:</span> {item.CouponName}</span>
                          }
                          content={
                            <OfferTooltip data={item?.Offer} />
                          }
                          position={"bottom"}
                        />
                      </div>
                    </div>
                  </div>}
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="promotion-promoters">
      <div className="d-flex justify-content-center flex-column">
        <div className="bz_fs_1_5 jw-100">
          <div className="row d-flex justify-content-between p-3" >
            <div className=" col-lg-6 col-md-12">
              <div className="d-flex managempromotions">

                <h6 className="bz_fs_1_5 affiliatemainheading mb-2">Manage Promotions  <small className="pl-4 includeactive" style={{ fontSize: '14px' }} >
                  Include Inactive :{" "}
                  <Switch
                    checked={showInactive}
                    onChange={(e) => handleToggleStatus(e.value)}

                  />
                </small>  </h6> </div>

              <p className="text-muted desaffiliates">

                Enhance your campaign's visibility with our 'Promotion' feature, designed to boost engagement and attract a wider audience</p>
            </div>

            <div className=" col-lg-6 col-md-12">

              <div className="row align-items-center" >

                <div className="col-lg-12 col-md-12 text-right adminaffialteheader">
                  <div className="row d-flex justify-content-end">
                  <div className="col-md-4 col-sm-4 col-12 col-lg-4 col-xl-3 addpromotions">
                  <div className="form-group bz-packages-search bzo-search-service mb-0 justify-content-end float-right searchinput">
                  <DropDownList
                        id="promotion-select"
                        data={promotionsType}
                        textField="text"
                        dataItemKey="value"
                        value={promotionTypeFilter}
                        onChange={(e) => handleSearchPromotion(e.target.value, "PromotionType")}
                      />
                      </div>
                    </div>
                    
                    <div className="col-md-5 col-sm-5 col-12 col-lg-5 col-xl-4 "> <div className="form-group bz-packages-search bzo-search-service mb-0 justify-content-end searchinput">
                      <span className="fa fa-search form-control-feedback"></span>
                    
                      <input
                        type={"search"}
                        className="form-control"
                        placeholder={"Search Promotion"}
                        onChange={(e) => handleSearchPromotion(e.target.value,"Search")}
                        value={searchKeyWord}
                        // style={{ width: 'auto' }}
                      />
                    </div> </div>
                    <div className="col-md-3 col-sm-3 col-12 col-lg-3 col-xl-3 addpromotions">  <Button primary onClick={() => handleCreateForm()}>
                      <em className="mdi mdi-plus"></em> Add Promotion
                    </Button></div> </div>
                </div>
              </div>
            </div>



          </div>
        </div>
        <div className="px-3">
        </div>
        <div className="container1">
          <div className="bz-packages-action d-flex justify-content-start pl-3">

          </div>
          {loading ? (
            <BuzopsLoader type={"list"} />
          ) : (
            <>
              {promotions?.length === 0 ? (
                <>
                  <div className="bz-norecords-available">
                    {"No Promotions Available"}
                  </div>
                </>
              ) : (
                <div className="mt-2 px-3">
                  <ListView
                    data={promotions || []}
                    item={NewMyItemRender}
                    style={{
                      width: "100%",
                    }}
                    className="bz-promotions-list-view"
                  />
                  <Pager
                    className="k-listview-pager-chargeback"
                    skip={skip}
                    responsive={true}
                    // size={'small'}

                    take={take}
                    onPageChange={handlePageChange}
                    total={totalItems}
                    pageSizes={[5, 10, 20, 50]}

                  />
                </div>
              )}
            </>
          )}
        </div>
      </div >
    </div >
  );
};

export default withNotification(Promotions);

import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AffiliateSignup from "./AffiliateSignup";
import './index.scss'
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { UserAffiliateStatusEnum } from "utils/form-utils";
import { RemoveLocalStore } from "utils/storage";
import { setUserConfiguration } from "redux-slices/userSlice";
import { useDispatch } from "react-redux";
import BuzopsButton from "generic-components/BuzopsButton";

const AffiliateRegistration = () => {
  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const dispatch=useDispatch()
  const searchUrl=search?.toLowerCase()
  const query: any = new URLSearchParams(searchUrl);
  const [promoter, setpromoter] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const service=new AffiliateProgramService()

  useEffect(() => {
    fetchPromoterDetails();
  }, []);


  const handleRedirectToLogin=()=>{
    history.push("/affiliatelogin");
  }

  const fetchPromoterDetails = async () => {
    setLoading(true);
    dispatch(
      setUserConfiguration({
        loggedIn: false,
        affiliateDetails: null,
      })
    );
    RemoveLocalStore('AffiliateDetails')
    const promoterData = await service.getActivationDetails(query?.get("id"),query?.get('isaccepted'));
    setLoading(false);
    if (promoterData?.ResponseCode === 100) {
      const responseData=promoterData?.Item
      const status=responseData?.Status
      switch (status) {
        case UserAffiliateStatusEnum.Active:
          history.push("/affiliatelogin");
          break;
        case UserAffiliateStatusEnum.Invited:
          setpromoter(promoterData?.Item);
          break;
        case UserAffiliateStatusEnum.Declined:
          setErrorMessage(`Request Declined by the User`);
          break;
        case UserAffiliateStatusEnum.InActive:
          setErrorMessage(`Current User is In-Active`);
          break;
        default:
          break;
      }
    } else {
      setErrorMessage(promoterData?.ErrorMessages[0]);
    }
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (promoter) {
    return (
      <div className="base-container">
        <div className="content-login">
          <AffiliateSignup promoter={promoter} />
        </div>
      </div>
    );
  }
  return (
    <div className="">
      <div id="bz-notfound">
        <div className="bz-notfound">
          <div className="bz-notfound-404"></div>
          <h1>404</h1>
          <h2 style={{textTransform:'none',fontSize:'1.5rem'}}>{errorMessage}</h2>
          {/* <h6>Please check the URL address</h6> */}
          <BuzopsButton style={{textTransform:'none'}} label={'Click here to login page'} onClick={()=>handleRedirectToLogin()} />
        </div>
      </div>
    </div>
  );
};

export default AffiliateRegistration;

import SecureLS from "secure-ls";

export const LocalStore = (key: string, params: any) => {
  var ls = new SecureLS();
  ls.set(key, params);
};

export const GetLocalStore = (key: string) => {
  var ls = new SecureLS();
  return ls.get(key);
};

export const RemoveLocalStore = (key: string) => {
  var ls = new SecureLS();
  ls.remove(key);
};

export const RemoveAllLocalStore = () => {
  var ls = new SecureLS();
  ls.removeAll();
};

export const setWithExpiry = (key: string, ttl: number) => {
  const now = new Date();
  const expiryTime = now.getTime() + ttl;
  LocalStore(key, expiryTime);
};

export const getWithExpiry = (key: string) => {
  const itemStr = GetLocalStore(key);
  if (!itemStr) {
    return null;
  }
  const item = parseInt(itemStr);
  const now = new Date();

  if (now.getTime() > item) {
    RemoveLocalStore(key);
    return null;
  }
  return item;
};

export const RemoveAllExceptTenant=()=>{
  RemoveLocalStore('userDetails')
  RemoveLocalStore('emailId')
  RemoveLocalStore('userId')
  RemoveLocalStore('UserMemberId')
  RemoveLocalStore('CurrentApplicationType')
  // RemoveLocalStore('tenantUserSession')
  RemoveLocalStore('provider')
  RemoveLocalStore('AffiliateDetails')
}

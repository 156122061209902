import { Slide } from "@progress/kendo-react-animation";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { PaymentItemRender, PaymentValueRender } from "components/Payment/PaymentRenderItems";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import React from "react";
import { useHistory } from "react-router-dom";
import { checkIfUserCanAddBankAccount, getFullImageUrl } from "utils";
import { CreditsErrorMessage } from "utils/form-utils";


const items = [
  {
    iconClass: "fa fa-credit-card pr-1",
    text: "Add credit card",
    key: "addCard",
    visible: true,
  },
  {
    iconClass: "fa fa-university pr-1",
    text: "Add Bank Account",
    key: "addBank",
    visible: checkIfUserCanAddBankAccount(),
  },
];
const renderTree = (item: any) => {
  return (
    <>
      <Grid data={item.RelatedDeclinedTransactions}>
        <GridColumn field="FullName" title="Client Name" />
        <GridColumn field="TransactionDate" title="Transaction Date" />
        <GridColumn
          field="PaymentMadeForDescription"
          title="Payment Made For"
        />
        <GridColumn field="ServiceName" title="Service Name" />
        <GridColumn field="Amount" title="Amount" />
        <GridColumn field="StatusDescription" title="Status" />
        <GridColumn field="TransactionId" title="Transaction Id" />
      </Grid>
    </>
  );
};
const DeclinedItem = (props: any) => {
  const history= useHistory()
  console.log(props,'props')
  console.log(props.match?.params?.uuid,'props.match?.params?.uuid')
  const { declinedData, setDeclinedTransactions, handleCheckData } = props;
  const [expandedItem, setExpandItem] = React.useState(null);
  const [showPaymentDialog, setShowPaymentDialog] = React.useState(false);
  const [addNewItem, setAddNewItem] = React.useState<any>(null);
  const [usermemberId, setUsermemberid] = React.useState<any>(null);
  let item = props.dataItem;
  let collpaseStatus = false;
  if (expandedItem === props.index) {
    collpaseStatus = true;
  }

  const handleExpand = (event: any, item: any, index: any) => {
    if (expandedItem !== null) {
      setExpandItem(null);
    } else {
      setExpandItem(index);
    }
  };

  const onCardOrBankAdd = (e: any, memberId: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
    setUsermemberid(memberId);
  };

  const handleChangeSavedCard = (val: any, userId: any, index: number) => {
    const declinedPaymentsTemp = [...declinedData];
    if(val?.CardTypeId === 8 && declinedPaymentsTemp[index]?.TotalAmount > val?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    declinedPaymentsTemp[index] = {
      ...declinedPaymentsTemp[index],
      PaymentGatewayPaymentProfileId: val?.PaymentGatewayPaymentProfileId,
      PaymentProfileID: val?.PaymentProfileID,
      CustomerProfileID: val?.CustomerProfileID,
      BankAccountType: val?.BankAccountType,
      CardTypeId: val?.CardTypeId,
      Credit: val?.Credit,
    };
    setDeclinedTransactions(declinedPaymentsTemp);
  };
  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
    setUsermemberid(null);
  };
  const handleSuccessClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
    setUsermemberid(null);
    props?.handleRefreshData()
  };

  const handleRedirection=(dataItem:any)=> {
    const data = {
      FullName: `${dataItem?.ParentName}`,
      MainMemberId:dataItem?.ParentUserMemberId,
    };
    window.parent.postMessage(data, "*");
  }

  const PaymethodDialog = () => {
    return (
        <Dialog
          className="bz-addCard-dialog"
          title={`${checkIfUserCanAddBankAccount() ? "Add Bank or Card" : "Add Card"
            }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={usermemberId}
          />
        </Dialog>
    );
  };
  const selectedPaymentProfile=item?.PaymentProfiles?.filter(
    (i: any) =>
      i?.PaymentGatewayPaymentProfileId ===
      item?.PaymentGatewayPaymentProfileId
  )?.[0]

  return (
    <div >
      <div className="row p-3 align-middle manage-declines">
        <div className={"manage-declines-sections"}>

          <div className="section-1" style={{ width: '30%' }}>
            <div className="d-flex flex-direction-column align-items-center">
              <div className="bz-provider-img" style={{ paddingRight: '10px' }}>
                <img width={'50px'} src={getFullImageUrl(item?.Photo) || "/images/user.png"} alt="" />
              </div>
              <div className="section-1-items">
                <h6 className="bz-service-name">
                <span>
                  <span
                    onClick={() => handleRedirection(item)}
                    className="btn btn-link"
                  >
                    {item?.ParentName}
                  </span>
                </span>
                </h6>
                <span className="bz-total-sessions">
                  (Total Amount: {item.TotalAmount})
                </span>
              </div>
            </div>
          </div>
          <div className="section-2" style={{ width: '30%' }}>
            <div className="bz-price-section d-flex">
              <DropDownList
                id={`paymentProfile${item?.ParentUserMemberId}`}
                name={`paymentProfile${item?.ParentUserMemberId}`}
                data={item?.PaymentProfiles}
                textField="MaskedCCNumber"
                style={{ width: "100%" }}
                dataItemKey="PaymentProfileID"
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                itemRender={PaymentItemRender}
                onChange={(e) => {
                  handleChangeSavedCard(
                    e.value,
                    item?.ParentUserMemberId,
                    props.index
                  );
                }}
              />
              <div className="addNewBtn">
                <DropDownButton
                  text={<i className="fas fa-plus"></i>}
                  onItemClick={(e: any) =>
                    onCardOrBankAdd(e, item?.ParentUserMemberId)
                  }
                  items={items.filter((i: any) => i?.visible === true)}
                  look="flat"
                  className="btn btn-link add-new-btn height35"
                />
              </div>
            </div>
          </div>
          <div className="section-3" style={{ width: '30%', textAlign: 'center' }}>
            <span>
              <span style={{ display: 'inline-block', paddingRight: '15px' }}>Charge</span>
              <Switch
                onChange={(e: SwitchChangeEvent) =>
                  handleCheckData(e.value, props.index)
                }

                checked={item?.IsNeedToCharge}
              />
            </span>
          </div>
          <div className="section-4 bz-cursor-pointer" style={{ width: '10%', textAlign: 'right' }}>
            <span onClick={(e) => handleExpand(e, item, props.index)}>
              <i
                style={{ fontSize: "25px" }}
                className={
                  collpaseStatus ? `fa fa-angle-up` : `fa fa-angle-down`
                }
              ></i>
            </span>
          </div>
        </div>
        {collpaseStatus && <Slide transitionEnterDuration={800} transitionExitDuration={1000}><div><br />{renderTree(item)}</div></Slide>}
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </div>
  );
};

export default DeclinedItem

import { CommonServiceUtils } from "services/common-service-utils";


export class MigrationService extends CommonServiceUtils {

    async getClientInfoForExcel(requestId: number, pageNumber: number, pageSize: number, showErrorRecords: boolean = false, filters?: any): Promise<any> {

        const req={
            MigrationRequestId: requestId,
            PageSize: pageSize,
            PageNumber: pageNumber,
            FilterOnlyErrorRecords: showErrorRecords
        };
        const getClientInfo = await this.postData(
          `${this.apiUrlCreator(`Migration/GetClients`)}`,{...req, ...filters}
        );
        return getClientInfo;
    }

    async downloadClientInfo(requestId: number, showErrorRecords: boolean = false, filters?: any): Promise<any> {

      const req={
          MigrationRequestId: requestId,
          FilterOnlyErrorRecords: showErrorRecords
      };
      const getClientInfo = await this.postData(
        `${this.apiUrlCreator(`Migration/Download`)}`,{...req, ...filters}, {
          responseType: 'blob'
        }
      );
      console.log(getClientInfo);
      
      return getClientInfo;
  }

    async getMigrationHistory(pageSize: number, pageNumber: number, requestId?: number): Promise<any> {
        const getHistory: any = await this.postData(
          `${this.apiUrlCreator(`Migration/GetHistory`)}`, {
            PageSize: pageSize,
            PageNumber: pageNumber,
            MigrationRequestId: requestId
          }
        );
        if (getHistory?.ResponseCode === 100) {
            return getHistory;
          } else {
            return {};
          }
    }

    async getMigrationDetailHistory(pageSize: number, pageNumber: number, requestId?: number, filters?: any): Promise<any> {
      const getHistory: any = await this.postData(
        `${this.apiUrlCreator(`Migration/GetSubmittedHistory`)}`, {...{
          PageSize: pageSize,
          PageNumber: pageNumber,
          MigrationRequestId: requestId
        }, ...filters}
      );
      if (getHistory?.ResponseCode === 100) {
          return getHistory;
        } else {
          return {};
        }
  }

    async deleteMigrationHistory(requestId: number): Promise<any> {
        const deletedResp: any = await this.postData(
          `${this.apiUrlCreator(`Migration/Delete?migrationRequestId=${requestId}`)}`
        );
        console.log(deletedResp);
        if (deletedResp?.ResponseCode === 100) {
            return deletedResp;
          } else {
            return null;
          }
    }

    async saveMigrationDraft(payload: any): Promise<any> {
        const saveData: any = await this.postData(
          `${this.apiUrlCreator(`Migration/SaveAsDraft`)}`, payload
        );
        if (saveData?.ResponseCode === 100) {
            return saveData;
          } else {
            return null;
          }
    }

    async submitMigration(payload: any): Promise<any> {
        const submitData: any = await this.postData(
          `${this.apiUrlCreator(`Migration/Submit`)}`, payload
        );
        if (submitData?.ResponseCode === 100) {
            return submitData;
          } else {
            return null;
          }
    }

    async deleteUserMemberFromMigration(userMemberId: number): Promise<any> {
      const deletedResp: any = await this.postData(
        `${this.apiUrlCreator(`Migration/DeleteUserMember?userMemberId=${userMemberId}`)}`
      );
      console.log(deletedResp);
      if (deletedResp?.ResponseCode === 100) {
          return deletedResp;
        } else {
          return null;
        }
    }

    async getPrimaryMemberDetailsForRequest(req: any): Promise<any> {
      const resp: any = await this.postData(
        `${this.apiUrlCreator(`Migration/GetPrimaryMembers`)}`, req
      );
      console.log(resp);
      if (resp?.ResponseCode === 100) {
          return resp;
        } else {
          return null;
        }
    }


}
import React, { useEffect, useState } from "react";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { TenantService } from "services/tenant/index.service";
import PayNow from "components/Transactions/PayNow";
import withNotification from "components/Hoc/withNotification";
import { CheckinMode } from "./Utils/constants";

export type checkinTransactionProps = {
  userMemberID: string;
  userMemberStatus: string;
  entityId: string;
  checkinMode: number;
  filterType: string;
};
const Transactions = (props: any) => {
  const {
    userMemberID,
    entityId,
    checkinMode,
    filterType,
    onPaymentUpdate,
    userDetails
  } = props;
  const staffPage = checkinMode === CheckinMode.ByStaff;
  const [tableData, setTableData] = useState<any>({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchPaymentTransactions();
  }, []);

  const fetchPaymentTransactions = async () => {
    setloading(true);
    let condition = {
      EntityId: entityId,
      FilterType: filterType,
      UserMemberId: userMemberID,
    };
    const req = {
      FilterFields: [],
      PageNumber: 0,
      PageSize: 100,
      SortBy: "TransactionDate",
      SortDirection: "desc",
      Where: condition,
    };
    const transactiondata = new TenantService();
    const result = await transactiondata.getTransactions(req);
    setloading(false);
    if (result) {
      setTableData(
        result?.Items?.find((val: any) => val.IsPaynowAvailable) || {}
      );
    }
  };

  const handleCloseViewDetail = (status: boolean = false) => {
    if(status){
      fetchPaymentTransactions();
    }
    onPaymentUpdate(status);
  };
  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }
  if (tableData?.PaymentGatewayTransactionHistoryId) {
    return (
      <>
      <PayNow
        canAddCardOrBank={true}
        staffPage={staffPage}
        transachisid={tableData?.PaymentGatewayTransactionHistoryId} //PaymentGatewayTransactionHistoryId
        TransactionDate={tableData?.TransactionDate}
        transid={tableData?.TransactionId}
        UserMemberId={userDetails?.ParentId || userMemberID}
        handleCloseViewDetail={(status: boolean) => handleCloseViewDetail(status)}
      />
      </>
    );
  }

  return (
    <div>
      <div className="bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card">
        No transactions pending for payment.
      </div>
    </div>
  );
};

export default withNotification(Transactions);

import { ClassService } from "services/class/index.service";
import React, {useState, useEffect} from 'react';
import { SelectedLocationType } from "../ClassTypes";

const defaultAttributes = {
    ScheduleMode: 0,
    ScheduleModeOption: 0,
    InviteePhoneNumber: "",
    TraineePhoneNumber: "",
    OtherAddress: "",
    OtherMeeting:""
  };
export const useFetchClassDetails = (props: any) => {
    const [classDetails, setClassDetails] = useState<any>(null);
    const [Attributes, setAttributes] = useState<SelectedLocationType>(defaultAttributes);
      
    const service = new ClassService();
    useEffect(() => {
        fetchClass();
    }, []);
    const fetchClass = async () => {
        const res = await service.getClass((props?.classId || props?.match?.params?.groupId));
        setClassDetails(res);
        setAttributes(res?.Attributes)
    };

    return {
        classDetails,Attributes,setAttributes
    }
}
import { CommonServiceUtils } from "services/common-service-utils";
import { GetLocalStore, LocalStore, RemoveAllLocalStore, RemoveLocalStore } from "utils/storage";

  
export class PipeDriveService extends CommonServiceUtils {
  async getTenantDetails(tenant: string): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenantUserSession", {...getToken?.Item?.UserSession, DomainName: getToken.Item.DomainName, StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: getToken.Item.ChargehubSquarePaymentProfile,Configuration: getToken.Item.Configuration});
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      return getToken;
    }
  }

  async getPipeDriveConfiguration(): Promise<any> {
    let response = null;
    const getPipeDriveConfiguration: any =  await this.getData(this.apiUrlCreator(`CRM/GetPipedriveConfiguration`));
    if (getPipeDriveConfiguration?.ResponseCode === 100) {
      response = getPipeDriveConfiguration.Item;
    }
    return response;
  }
  async getPipeLines(): Promise<any> {
    let response = [];
    const getPipeLines: any =  await this.getData(this.apiUrlCreator(`CRM/GetPipelines`));
    if (getPipeLines?.ResponseCode === 100) {
      response = getPipeLines.Item.data;
    }
    return response;
  }
  async getServiceStatus(): Promise<any> {
    let response = null;
    const getServiceStatus: any =  await this.getData(this.apiUrlCreator(`CRM/GetServiceStatus`));
    if (getServiceStatus?.ResponseCode === 100) {
      response = getServiceStatus.Item;
    }
    return response;
  }
  async getStages(req:any): Promise<any> {
    let response = [];
    const getStages: any =  await this.postData(this.apiUrlCreator(`CRM/GetStages`),req);
    if (getStages?.ResponseCode === 100) {
      response = getStages.Item.data;
    }
    return response;
  }
  async updatePipedriveConfiguration(req:any): Promise<any> {
    const updatePipedriveConfiguration: any =  await this.postData(this.apiUrlCreator(`CRM/UpdatePipedriveConfiguration`),req);
    return updatePipedriveConfiguration;
  }
  async unLinkPipedriveConfiguration(): Promise<any> {
    const unLinkPipedriveConfiguration: any =  await this.postData(this.apiUrlCreator(`CRM/UnlinkPipedrive`),{});
    return unLinkPipedriveConfiguration;
  }

}
import withNotification from "components/Hoc/withNotification";
import Service from "components/service/Service";
import { useExternalItem } from "./useExternalItem";
const OneonOne = (props: any) => {
    const { servicesData = [], isServiceLoading = false } = useExternalItem(props, 'oneonone')
    return (
        <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
            <div className="col-12 col-xl-10">
                {!props?.shortCodeValue &&  <h5 className="bzo-primary-heading">{`One-on-One`}</h5>}
                <Service
                    servicesData={servicesData}
                    isServiceLoading={isServiceLoading}
                    serviceShortName={props?.shortCodeValue}
                    ShortCodeStatus={props?.ShortCodeStatus}
                />
            </div>
        </div>
    );
};

export default withNotification(OneonOne);

import { Button } from "@progress/kendo-react-buttons";
import withNotification from "components/Hoc/withNotification";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { BiEdit, BiHistory } from "react-icons/bi";
import useManageTaxes from "./ManageTaxes.hook";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import BuzopsButton from "generic-components/BuzopsButton";
import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import { Label, Error } from "@progress/kendo-react-labels";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import TaxHistory from "./TaxHistory";
import { SurchargeNameValidator, taxPercentageValidator } from "validators/validator";
import BuzopsLongNameHandler from "generic-components/BuzopsLongNameHandler";

const ManageTaxes = (props: any) => {
  const {
    loading,
    modify,
    taxes,
    handleTaxDialog,
    showAddDialog,
    handleChange,
    tax,
    btnLoading,
    handleSaveTax,
    handleEdit,
    handleUpdateTax,
    handleModifyDisable,
    toggleDisableDialog,
    showDisableTaxDialog,
    showHistory,
    handleHistory,
    toggleLoading,
    taxHistory
  } = useManageTaxes(props);
  const TaxForm = () => {
    return (
      <Form
        render={(RenderForm: any) => (
          <>
            <FormElement style={{ width: "100%" }}>
              <div className="row mt-0">
                <div className="col-md-12">
                  <FieldWrapper className="mt-0">
                    <Label>
                      Surcharge Name<span className="required-asterisk">*</span>
                    </Label>
                    <Input
                      id={"TaxName"}
                      name={"TaxName"}
                      autoComplete="off"
                      style={{ width: "100%" }}
                      placeholder="Enter Surcharge Name"
                      onChange={(e) => {
                        handleChange(e.value, "TaxName");
                      }}
                      disabled={tax?.TaxId ? true : false}
                      value={tax?.TaxName}
                    />
                    {modify?.TaxName && (
                      <Error>{SurchargeNameValidator(tax?.TaxName)}</Error>
                    )}
                  </FieldWrapper>
                </div>
                <div className="col-md-12">
                  <FieldWrapper>
                    <Label>
                      Percentage<span className="required-asterisk">*</span>
                    </Label>
                    <NumericTextBox
                      id={"Percentage"}
                      name={"Percentage"}
                      width={"100%"}
                      format={"#.##\\%"}
                      placeholder="Enter Percentage"
                      onChange={(e) => {
                        handleChange(e.value, "Percentage");
                      }}
                      value={tax?.Percentage}
                    />
                    {modify?.Percentage && (
                      <Error>{taxPercentageValidator(tax?.Percentage)}</Error>
                    )}
                  </FieldWrapper>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-4">
                  <Button
                    disabled={btnLoading}
                    className={"ml-1"}
                    onClick={() => handleTaxDialog()}
                  >
                    Cancel
                  </Button>
                  <BuzopsButton
                    primary
                    label={!tax?.TaxId ? "Create" : "Update"}
                    disable={
                      btnLoading ||
                      SurchargeNameValidator(tax?.TaxName) ||
                      tax?.Percentage === null ||
                      tax?.Percentage <= 0 ||
                      !tax?.TaxName || !modify?.Percentage
                    }
                    loading={btnLoading}
                    onClick={() =>
                      !tax?.TaxId ? handleSaveTax() : handleUpdateTax()
                    }
                  />
                </div>
              </div>
            </FormElement>
          </>
        )}
      />
    );
  };
  const renderDiableTaxDialog = () => {
    return (
      <Dialog onClose={() => toggleDisableDialog()}>
        <p className={"dialogContainer"}>
          {`Are you sure you want to ${
            !tax?.IsActive ? "Enable" : "Disable"
          } the Surcharge?`}
        </p>
        <DialogActionsBar>
          <Button onClick={() => toggleDisableDialog()}>{"No,keep it."}</Button>

          <BuzopsButton
            primary
            label={"Yes"}
            disable={btnLoading}
            loading={btnLoading}
            onClick={() => handleModifyDisable()}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };
  const AddTaxDialog = () => {
    return (
      <>
        <Dialog
          title={!tax?.TaxId ? "Add Surcharge" : "Edit Surcharge"}
          onClose={() => handleTaxDialog()}
          style={{ zIndex: 9999 }}
          width={"100%%"}
          className="bz-book-Appoinment-dialog bz-add-tax-dialog"
        >
          {TaxForm()}
        </Dialog>
      </>
    );
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }

  return (
    <>
      <div className="d-flex justify-content-center flex-column p-4">
        {showHistory ? (
          <TaxHistory handleClose={handleHistory} tax={tax} taxHistory={taxHistory} />
        ) : (
          <>
            <div className="mb-3 bz_fs_1_5 d-flex flex-column flex-sm-row justify-content-between">
              <h6 className="bz-article-headline">
                {" "}
                <button
                  className="btn btn-primary bz-back-btn btn-sm"
                  onClick={() => props?.handleClose()}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>{" "}
                &nbsp;Manage Surcharges
              </h6>
             <div className="d-flex justify-content-end mt-3 mt-sm-0">
             <Button
                primary
                onClick={() => handleTaxDialog()}
                style={{ width: "9.1rem" }}
                className="mr-0"
              >
                Add New Surcharge
              </Button>
             </div>
            </div>
            <div className="manage-tax-container row">
              {taxes?.length > 0 ? (<>
                {taxes?.map((i: any) => {
                return (
                  <>
                   <div className="col-sm-6 col-lg-4 col-xl-3">
                   <div className={`card bz-card tax-card-item mb-4 ${i?.IsActive ?"":"tax-disabled"}`}>
                      <div className="card-body d-flex justify-content-between align-items-center flex-row p-3">
                        <div className="d-flex flex-column justify-content-center h-100">
                          <span style={{ color: "#717171", fontSize: "11px" }}>
                            Surcharge Name
                          </span>
                          <div className="m-0">
                            <BuzopsLongNameHandler name={i?.TaxName} maxLength={22} className="taxName-tooltip"/>
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-end h-100" style={{width:'7.2rem'}}>
                          <span className="badge bzo-status-success-dark bz-radius-1 ml-0 mr-1">
                            {i?.Percentage?.toFixed(2)}%
                          </span>
                          <BuzopsTooltip
                            html={
                              <button
                                onClick={() => handleEdit(i)}
                                className="btn btn-icon mr-1"
                                title="Edit"
                                disabled={!i?.IsActive}
                              >
                                <BiEdit color="#008db9" />
                              </button>
                            }
                          />
                                <BuzopsTooltip
                            html={
                              <span title={i?.IsActive ?"Enabled":"Disabled"} className="mr-1">

                                <Switch
                                disabled={toggleLoading}
                                checked={i?.IsActive}
                                onChange={(e: any) => toggleDisableDialog(i)}
                              />
                              </span>
                            }
                          />
                         
                                 <BuzopsTooltip
                            html={
                              <button
                                onClick={() => handleHistory(i)}
                                className="btn btn-icon mr-0"
                                title="History"
                              >
                                <BiHistory color="#008db9" />
                              </button>
                            }
                          />
                          
                        </div>
                      </div>
                    </div>
                   </div>
                  </>
                );
              })}
              </>):(<>
                <div className="bz-norecords-available">No Surcharges Available</div>
              </>)}
            </div>
          </>
        )}
      </div>
      {showAddDialog && AddTaxDialog()}
      {showDisableTaxDialog && renderDiableTaxDialog()}
    </>
  );
};

export default withNotification(ManageTaxes);
import moment from "moment";
import React from "react";
import { getFullImageUrl } from "utils";
import { FaExclamationTriangle } from "react-icons/fa";
import { MdAccessTimeFilled } from "react-icons/md";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import RenderPayRemainingdue from "./RenderPayRemainingDue";
import withNotification from "components/Hoc/withNotification";
import { CheckinFrom } from "./Utils/constants";
const RenderTodayAppointments = (props: any) => {
  const { checkIfCheckInAllows } = props;

  const [transactionState, setTransactionState] = React.useState<any>({
    visible: false,
    data: {},
  });

  const onWindowClose = (status = false) => {
    setTransactionState({
      visible: false,
      data: {},
    });
    if (status) {
      props?.getUpcomingAppointments();
    }
  };
  if (props?.searchAppointments?.length === 0) {
    return (
      <>
        <div
          className={
            "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
          }
        >
          <div className="no-appointment-bz">No Appointments for Today!</div>
        </div>
      </>
    );
  }
  const onPay = (item: any) => {
    setTransactionState({
      visible: true,
      data: item,
    });
  };

  const renderPayRemainingDue = () => {
    if (transactionState?.data) {
      if (transactionState.data?.EntityType === 2 || transactionState.data?.EntityType === 3) {
        return (
          <RenderPayRemainingdue
            transactionState={transactionState}
            onWindowClose={(status: any) => onWindowClose(status)}
            userDetails={props?.userDetails}
          />
        );
      }
    }

  };
  return (
    <>
      <div className="row bz-appoinments-info-selfechekin">
        {props?.searchAppointments.map((item: any, index: number) => {
          if (item?.futureAppointment) {
            return <></>;
          }
          const disabled = !checkIfCheckInAllows(item);
          let classNameForItem = "";
          if (disabled) {
            classNameForItem = "card-disabled";
            if (item.CheckinTimestamp !== null) {
              classNameForItem = `${classNameForItem} border-white`;
            }
          }
          return (
            <div key={`appointment-item-${index}-${item.check}`} className={`col-md-6 card bz-appoinments-info-card ${classNameForItem} ${item.check ? "card-checked" : ""
              }`}>
              <div
                className={` bz-upcoming-apponments-sm-card  bz-overview-appoinmentsInfo `}
                onClick={() => !disabled && props?.handleCheck(item)}
              >
                <div className="card-body render_block d-block">
                  <i
                    className={
                      item?.check ? `fa fa-check fa-lg appoint_check` : ``
                    }
                  />
                  {item?.CheckinTimestamp && (
                    <span className="checkedin-success selfcheckins-success checkedin-light">
                      {" "}
                      <i className="fa fa-check checkicon"></i> Checked-in
                    </span>
                  )}
                  <div className="media">
                    <div className="d-flex flex-column align-items-center mr-1">
                      <img
                        alt={""}
                        className="bz-user-img align-self-center"
                        src={
                          item?.TrainerPhoto?.trim()
                            ? getFullImageUrl(item.TrainerPhoto)
                            : "/images/user.png"
                        }
                      />
                      {item?.CheckinTimestamp && (
                        <div className="checkedIn-info mt-2">
                          <BuzopsTooltip
                            html={
                              <span
                                title="Checked-in"
                                className=" d-inline-block"
                                style={{ fontSize: "12px" }}
                              >
                                <MdAccessTimeFilled />{" "}
                                {moment(item?.CheckinTimestamp).format(
                                  "hh:mm A"
                                )}
                              </span>
                            }
                          />
                        </div>
                      )}
                    </div>

                    <div className="media-body render_block_txt">
                      <div>
                        <div className="d-flex">
                          <h6 className="mt-0 mb-0">{`${item.TrainerName}`}</h6>
                          <div className="bz-appoinments-card-status"></div>
                        </div>

                        <p className="bz-service-type mb-0">
                          {item.PrivateServiceType === 1 &&
                            item.EntityType === 3 && (
                              <span>{`${item.Ordinal} of ${item.NumberOfSessions}`}</span>
                            )}{" "}
                          <span className="mr-1 text-capitalize  d-flex justify-content-start">
                            {`${item.ServiceName}`}
                          </span>
                        </p>
                        {item.PrivateServiceType === 1 && !item.IsPaid ? (
                          <div className="payment-required-appointments">
                            <BuzopsTooltip
                              html={
                                <span className="payment-warning text-danger bz-cursor-pointer pe-all" title={props.from !== CheckinFrom.Kiosk ? "Payment Pending" : "Payment Pending, Please contact Admin to pay for this."}>
                                  <FaExclamationTriangle />
                                </span>
                              }
                            />
                            {
                              props.from !== CheckinFrom.Kiosk ?
                              <span
                                className="bz-cursor-pointer pe-all btn-link"
                                onClick={() => onPay(item)}
                              >
                                Click here to pay
                              </span> : <span className="text-muted">Payment Pending</span>
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        <p className="bz-dateTime text-muted mb-0">
                          <small className="mr-1">
                            {`${moment(item.FromTime).format("MM/DD/YYYY")}`}
                          </small>
                          <small>
                            {`${item.ScheduleName} ${item.EntityType === 2 &&
                              !item.ScheduleName.includes(item.TenantTimeZone)
                              ? item.TenantTimeZone
                              : ""
                              }`}
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {renderPayRemainingDue()}
    </>
  );
};

export default withNotification(RenderTodayAppointments);

import React, { ComponentType, useEffect, useState } from "react";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { useHistory } from "react-router-dom";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import ClientUpdateForm from "components/checkout/ClientUpdateForm";
import ClientManageForm from "components/checkout/ClientManageForm";
import AddClient from "components/booking/AddClient";
function withClientSelection(WrappedComponent: ComponentType) {
  return ({ ...props }: any) => {
    const history = useHistory();
    const [notification, setNotification] = useState({
      error: false,
      success: false,
    });
    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("Pick a client");
    const [url, setUrl] = useState<any>(null);
    const [userId, setUserId] = useState(0);
    const [clientPopup, setClientpopup] = useState(false);
    const [disableCreateClient, setDisableCreateClient] = useState(false);
    const [clientSearchLoading, setClientSearchLoading] = useState(false);
    const [manage,setManage]= useState({
      source:"",
      target:""
    })
    //client details
    const [clientFormValues, setClientFormValues] = useState<any>({});
    const [clientSubmitDetails, setClientSubmitDetails] = useState<any>({});
    const [clientsList, setClientsList] = useState<any>([]);
    // to select client Dropdown
    const [selectedClient, setSelectedClient] = useState<any>({
      type: "search",
      value: "",
    });
    //for buzops button loading for client create submit
    const [clientSubmitBtnLoading, setClientSubmitBtnLoading] = useState(false);
    const [selectedradioValue, setselectedradioValue] = useState("");

    async function autoCompleteClients(str: string) {
      const req = {
        Where: {
          SearchKeyword: str,
          IsGloablSearch: true,
        },
      };
      setClientSearchLoading(true);
      const clientservice = new ClassService();
      const result = await clientservice.autoCompleteClients(req);
      setClientSearchLoading(false);

      setClientsList(result);
    }
    // handle client selection for class
    const handleClientSelecton = (e: any) => {
      if(e.value.isButton){
        return;
      }

      if(e.value === ""){
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: e.value,
        });
        setClientpopup(false);
        return;
      }

      if (e.syntheticEvent?.key === "Enter") {
        if (clientsList?.length > 0) {
          setSelectedClient({
            ...selectedClient,
            type: "select",
            value: clientsList[0],
          });
        }
        return true;
      }
      setSelectedClient({
        ...selectedClient,
        type: "search",
        value: e.value,
      });
      if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
        autoCompleteClients(e.value);
      }
      if (e.syntheticEvent?.type === "click") {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: e.value,
        });
        fetchClientData(e.value.UserMemberId);
      }
    
    };
    const handleManageClient = (e: any,target:string) => {
      if (e.syntheticEvent?.key === "Enter") {
        if (clientsList?.length > 0) {
          setManage({...manage,[target]:{
            type: "select",
             value: clientsList[0],
       
          }
          })
        }
        return true;
      }
      setManage({...manage,[target]:{
        type: "search",
         value: e.value,
   
      }
      })
      if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
        autoCompleteClients(e.value);
      }
      if (e.syntheticEvent?.type === "click") {

        setManage({...manage,[target]:{
          type: "select",
           value: e.value,
     
        }
        })
      }
    
    };
    //to update client details
    const handleChangeClient = () => {
      setClientFormValues({});
      setClientsList([]);
      setClientSubmitDetails({});
      setSelectedClient({
        type: "search",
        value: "",
      });
    };
    // to get client details from api
    async function fetchClientData(userMemberId: any) {
      let clientIntialValues;
      let clientSubmitDetails;
      const clientservice = new TenantService();
      const result = await clientservice.getClient(userMemberId);
      if (result) {
        if (!result?.ParentId) {
          clientIntialValues = {
            FirstName: result?.User?.FirstName,
            LastName: result?.User?.LastName,
            PhoneNumber: result?.User?.Address?.Phone,
            Email: result?.User?.Email,
          };
          clientSubmitDetails = {
            Email: result?.User?.Email,
            FirstName: result?.User?.FirstName,
            LastName: result?.User?.LastName,
            PrimaryMemberId: result?.ParentId || result?.UserMemberId,
            UserId: result?.UserId,
            UserMemberId: result?.UserMemberId,
            MemberNumber: result?.MemberNumber,
            isSub: false,
          };
        } else {
          const res = await clientservice.getClient(result?.ParentId);
          clientIntialValues = {
            FirstName: res?.User?.FirstName,
            LastName: res?.User?.LastName,
            PhoneNumber: res?.User?.Address?.Phone,
            Email: res?.User?.Email,
          };
          clientSubmitDetails = {
            Email: res?.User?.Email,
            FirstName: res?.User?.FirstName,
            LastName: res?.User?.LastName,
            PrimaryMemberId: res?.ParentId || res?.UserMemberId,
            UserId: res?.UserId,
            UserMemberId: res?.UserMemberId,
            MemberNumber: res?.MemberNumber,
            isSub: true,
          };
        }
        setClientFormValues(clientIntialValues);
        setClientSubmitDetails(clientSubmitDetails);
        setClientpopup(!clientPopup);
        if (url !== null) {
          const data = {
            Title: `${clientSubmitDetails?.FirstName} ${clientSubmitDetails?.LastName}`,
          };
          window.parent.postMessage(data, "*");
          if (clientSubmitDetails?.isSub) {
            if (url?.second) {
              history.push(
                `${url?.first}${
                  clientSubmitDetails?.PrimaryMemberId || result?.UserMemberId
                }${url?.second}/${result?.UserMemberId}`
              );
            } else {
              history.push(`${url?.first}${result?.UserMemberId}`);
            }
          } else {
            if (url?.second) {
              history.push(
                `${url?.first}${
                  clientSubmitDetails?.PrimaryMemberId || result?.UserMemberId
                }${url?.second}`
              );
            } else {
              history.push(`${url?.first}${result?.UserMemberId}`);
            }
          }
        }
      }
    }

    const handleAddClient = async (dataItem: any) => {
      const req = {
        FirstName: dataItem?.FirstName,
        LastName: dataItem?.LastName,
        Phone: dataItem?.PhoneNumber,
        Email: dataItem?.Email,
        SalesPersonId: 0,
        UserId: dataItem?.UserId,
      };
      setClientSubmitBtnLoading(true);
      const checkoutClass = new ClassService();
      const result = await checkoutClass.addClient(req);
      if (result.ResponseCode === 100) {
        const userMemberId = result?.Item?.UserMemberId;
        fetchClientData(userMemberId);
        const successMsg = "Client Added Successfully";
        setMessage(successMsg);
        setNotification({ ...notification, success: true, error: false });
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        setMessage(errorMsg);
        setNotification({ ...notification, error: true, success: false });
      }
      setClientSubmitBtnLoading(false);
    };

    const handleClientSelectionSubmit = async (e: any) => {
      e.preventDefault();
      if (selectedClient?.type === "select" && selectedClient?.value !== "") {
        setClientSubmitBtnLoading(true);
        const userMemberId = selectedClient?.value?.UserMemberId;
        await fetchClientData(userMemberId);
        setClientSubmitBtnLoading(false);
      } else {
        const errorMsg = "Please Pick a Client";
        setMessage(errorMsg);
        setNotification({ ...notification, error: true, success: false });
      }
    };
    const handleMergeSelectionSubmit = async (e: any,user:any) => {
      e.preventDefault();
      if (user?.type === "select" && user?.value !== "") {
        setClientSubmitBtnLoading(true);
        const userMemberId = user?.value?.UserMemberId;
        await fetchClientData(userMemberId);
        setClientSubmitBtnLoading(false);
      } else {
        const errorMsg = "Please Pick a Client";
        setMessage(errorMsg);
        setNotification({ ...notification, error: true, success: false });
      }
    };

    //to update client details
    const handleClientSubmit = async (dataItem: any) => {
      setClientFormValues(dataItem);
    };

    const handleRadioButtonChange = () => {
      setselectedradioValue("new");
    };

    console.log('title',title);
    

    const ClientModal = () => {
      return (
        <>
          <Dialog
            className="bz-book-Appoinment-dialog bz-selectClient-dialog"
            title={title}
            width={"35%"}
            onClose={() => {
              setClientpopup(!clientPopup);
              setManage({ source: "", target: "" });
            }}
            style={{ zIndex: 9999 }}
            closeIcon={
              props?.location?.pathname
                ? !props?.location?.pathname?.toLowerCase()?.includes("onetimecharge")
                : true
            }
          >
            {url?.second?.includes("overview") ? (
              <ClientManageForm
                manage={manage}
                clientSubmitBtnLoading={clientSubmitBtnLoading}
                errorShow={(e: any) => { }}
                setClientSubmitBtnLoading={setClientSubmitBtnLoading}
                onClose={() => {
                  setManage({ source: "", target: "" });
                  setClientpopup(!clientPopup);
                }}
                handleMergeSelectionSubmit={handleMergeSelectionSubmit}
                handleManageClient={(val: any, source: string) => handleManageClient(val, source)}
                clientsList={clientsList}
                setNotification={setNotification}
                setMessage={setMessage}
              />
            ) : (
              (title == "Purchase Membership" || title == "Purchase Group" || title == "Purchase Package" || title == "One Time Charge") ? (
                <div style={{marginTop:"10px",marginBottom:"10px"}}>
                <AddClient
                  page={"inhouse-class"}
                  disabled={false}
                  clientFormValues={clientFormValues}
                  clientsList={clientsList}
                  handleClientSubmit={handleClientSubmit}
                  clientSubmitBtnLoading={clientSubmitBtnLoading}
                  clientSearchLoading={clientSearchLoading}
                  handleAddClient={handleAddClient}
                  selectedClient={selectedClient}
                  selectedradioValue={selectedradioValue}
                  handleClientSelecton={(val: any) => handleClientSelecton(val)}
                  checkout={false}
                  fetchClientData={fetchClientData}
                  onChangeRadioValue={handleRadioButtonChange}
                />
                </div>
              ) : (
                <ClientUpdateForm
                  page={"inhouse-class"}
                  clientFormValues={clientFormValues}
                  clientsList={clientsList}
                  disableCreate={disableCreateClient}
                  handleClientSubmit={handleClientSubmit}
                  handleClientSelectionSubmit={handleClientSelectionSubmit}
                  clientSubmitBtnLoading={clientSubmitBtnLoading}
                  clientSearchLoading={clientSearchLoading}
                  handleAddClient={handleAddClient}
                  selectedClient={selectedClient}
                  handleChangeClient={handleChangeClient}
                  handleClientSelecton={(val: any) => handleClientSelecton(val)}
                  setNotification={setNotification}
                  setMessage={setMessage}
                  isPaymentForm={false}
                  fetchClientData={fetchClientData}
                  setUserId={setUserId}
                />
              )
            )}
          </Dialog>

        </>
      );
    };
    const handleClientPopup = (
      parentLink: string,
      childLink: string,
      createClient = false,
      title ="Pick a client"
    ) => {
      setClientpopup(!clientPopup);
      setTitle(title);
      if (parentLink) {
        setUrl({
          first: parentLink,
          second: childLink,
        });
      } else {
        setUrl(null);
      }
      setDisableCreateClient(createClient);
    };
    const handleNotification = (type: string) => {
      setNotification({ ...notification, [type]: false });
    };

    useEffect(() => {
      let timer1 = setTimeout(() => {
        setNotification({ ...notification, error: false, success: false });
      }, 5000);
      return () => {
        clearTimeout(timer1);
      };
    }, [notification.error, notification.success]);
    return (
      <>
        <WrappedComponent
          {...props}
          clientFormValues={clientFormValues}
          clientSubmitDetails={clientSubmitDetails}
          handleClientPopup={(
            parent: string,
            child: string,
            createClient = false,
            title ="Pick a client"
          ) => handleClientPopup(parent, child, createClient,title)}
        />
        {clientPopup && ClientModal()}
        <BuzopsNotification
          notification={notification}
          message={message}
          handleNotification={(type: any) => handleNotification(type)}
        />
      </>
    );
  };
}

export default withClientSelection;

import React, { Suspense, useEffect } from "react";
import { checkInsRoutes } from "../routes/routes";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import FallbackLoading from "../generic-components/FallbackLoading";
import axios from "axios";
import { GetLocalStore, RemoveAllExceptTenant } from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";
import { useDispatch, useSelector } from "react-redux";
import { setRedirectionConfig } from "redux-slices/redirectionSlice";
import CheckInsHeader from "components/header/CheckInsHeader";
import "./CheckinsLayout.scss";
import { setStaffLoggedDetails } from "redux-slices/staffCheckinsSlice";
const CheckinsLayout = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loggedInStaffDetails } = useSelector(
    (state: any) => state?.staffCheckIn
  );
  useEffect(() => {
    const obj = { firstPathValue: "kiosk" };
    dispatch(setRedirectionConfig(obj));
  }, []);

  useEffect(() => {
    const tenantData = GetLocalStore("tenantUserSession");
    if (tenantData) {
      TimeZoneSetup(tenantData?.TenantTime, tenantData);
    }
  }, [GetLocalStore("tenantUserSession")]);

  useEffect(() => {
    if (GetLocalStore("userDetails") && !loggedInStaffDetails) {
      const authUser = GetLocalStore("userDetails");
      const obj = {
        CurrentApplicationType: authUser?.UserSession?.CurrentApplicationType,
        loggedInStaffDetails: authUser?.UserSession,
        isLoggedIn: true,
        ClubTimeZone: authUser?.UserSession?.TimeZone?.Id,
      };
      dispatch(setStaffLoggedDetails(obj));
    } else if (!GetLocalStore("userDetails")) {
      history.push(`/kiosk/login`);
    }
  }, [GetLocalStore("userDetails")]);

  axios.interceptors.request.use((request) => {
    if (!request.url?.includes("issuetoken")) {
      request.headers["Authorization"] = `Bearer ${GetLocalStore("token")}`;
      request.headers["Content-type"] = "application/json";
    }else{
      if(GetLocalStore("userDetails")?.UserSession && GetLocalStore("userDetails")?.UserSession?.CurrentApplicationType ===2){
        request.headers["Usermemberid"] = GetLocalStore('userDetails')?.UserSession?.InternalUserId;
      }
    }
    return request;
  });

  axios.interceptors.response.use(undefined, (error) => {
    const { status } = error.response;
    if (status === 401 || status === 403) {
      RemoveAllExceptTenant();
      history.push("/kiosk/login");
    }
  });

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <div className="App">
        <div className="App-header access-control-layout">
          <CheckInsHeader />
          <div className={"App-body"}>
            <Suspense fallback={FallbackLoading()}>
              <Switch>
                {checkInsRoutes.map((route: any, id: number) => {
                  return route.component ? (
                    <Route
                      key={id}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ) : null;
                })}
                <Redirect from="*" to="/404" />
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckinsLayout;

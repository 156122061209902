import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import { ImportContext } from '../importContext';

export const StatesListDropdown = (props: GridCellProps) => {
    const { statesList } = React.useContext(ImportContext);

    const handleChange = (e: DropDownListChangeEvent) => {
        if(props.onChange){
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.StateId
            });
        }
    }

    const { dataItem } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            className='grid-editors'
            onChange={handleChange}
            value={statesList.find(c => c.StateId === dataValue)}
            data={statesList}
            textField="StateName"
                />
            ) : (
                statesList.find(c => c.StateId === dataValue)?.StateName
                )}
      </td>
    );
}
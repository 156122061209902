import { Card, CardBody } from "@progress/kendo-react-layout";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsNotification from "generic-components/BuzopsNotification";
import BuzopsWindow from "generic-components/BuzopsWindow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import { TenantService } from "services/tenant/index.service";
import { getFullDate, getTimeFormat } from "utils";
import { GetLocalStore } from "utils/storage";
import EditFieldsSession from "./EditFieldsSession";
import SessionTable from "./SessionTable";
import withNotification from "components/Hoc/withNotification";
import { CommunicationPreferenceList } from "utils/form-utils";

export interface EditSessionProps {
  classValue: any;
  handleWindow: any;
}
const coeff = 1000 * 60 * 30;
const roundedStartTime = new Date(
  Math.ceil(new Date().getTime() / coeff) * coeff
);
const EditSession = (props: any) => {
  const defaultAttributes = {
    ScheduleMode: 0,
    ScheduleModeOption: 0,
    InviteePhoneNumber: "",
    TraineePhoneNumber: "",
    OtherAddress: "",
    OtherMeeting: ""
  };
  const InitialValues = {
    startTime: null, //roundedStartTime
    ClassSessionStartDate: new Date(),
    hours: null,
    minutes: null,
    instructor: { FullName: "Select Provider", UserId: -1 },
    slots: null,
    price: null,
    color: null,
    RemainderDuration: null,
    RemainderType: null,
    DurationType: { id: 2, text: "Minutes" },
    MinimumBookingDuration: null,
    MinimumBookingDurationType: null,
    MinimumCancellationDuration: null,
    MinimumCancellationDurationType: null,
    CommunicationPreference: null,
    CancellationFee: null,
    HasVirtualMeetingEnabled: false,
    Attributes: defaultAttributes,
    emailNotification: false,
    appointmentBookNotification: false,
    appointmentCancelNotification: false,
    ClassType: null,
    NonPaidLabel: null,
    PaymentOption: null,
    CanAutoCancelWaitList: false,
    AutoCancelWaitListDuration: null,
    AutoCancelWaitListDurationType: null,
    WaitList: null
  };

  const InitialSwitchValues = {
    startTime: false,
    ClassSessionStartDate: false,
    duration: false,
    instructor: false,
    slots: false,
    price: false,
    color: false,
    client: false,
    subInstructor: false,
    RemainderDuration: false,
    MinimumBookingDuration: false,
    MinimumCancellationDuration: false,
    CancellationFee: false,
    HasVirtualMeetingEnabled: false,
    Location: false,
    ClassType: false,
    PaymentOption: false,
    AutoCancelWaitListDuration: false,
    WaitList: false,
    CommunicationPreference: false
  };
  const [formInitialValues, setFormInitialValues] = useState<any>(InitialValues);
  const [checked, setChecked] = useState(InitialSwitchValues);
  const [form, setform] = useState<any>(InitialValues);
  const [selectedSessions, setSelectedSessions] = useState<any>([]);
  const [selectedItemText, setSelectedItemText] = useState<any>("");
  const [count, setCount] = useState(0);
  const [unAvailableList, setUnAvailableList] = useState<any>([]);
  const [failedList, setFailedList] = useState<any>([]);
  const [unAvailableListDialog, setUnAvailableListDialog] = useState(false);
  const [failedListDialog, setFailedListDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [session, setSession] = useState({
    choose: false,
    view: false,
  });
  const [providersList, setProvidersList] = useState([]);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [message, setMessage] = useState("");
  const originalData = props?.classSessions.map((v: any) => ({
    ...v,
    selected: false,
  }));
  const [tableData, settableData] = useState(originalData);
  const [showEditForm, setShowEditForm] = useState(true);
  const [updateLodaing, setUpdateLodaing] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [chooseScreen, setChooseScreen] = useState(false);

  const handleChange = (e: any, name: string, formKey?: string) => {
    setChecked({ ...checked, [name]: e.value });
    if (!e.value && formKey) {
      const splitFormKey = formKey.split(".");
      setform((prev: any) => {
        splitFormKey.forEach((val) => {
          prev[val] = formInitialValues?.[val];
        })
        return { ...prev }
      })
    }
  };

  const toggleAvailibilityDialog = () => {
    setUnAvailableListDialog((prev) => !prev);
  };
  const toggleFailedListDialog = () => {
    setFailedListDialog((prev) => !prev);
  };
  const handleCloseFailedDialog = () => {
    setFailedListDialog((prev) => !prev);
    setSubmit(true);
    settableData(originalData);
    handleSessionTable("view");
    setFailedList([])
  };

  const handleForm = (value: any, name: any) => {
    if (
      name === "slots" ||
      name === "price" ||
      name === "hours" ||
      name === "minutes" ||
      name === "RemainderDuration" ||
      name === "MinimumBookingDuration" ||
      name === "MinimumCancellationDuration" ||
      name === "AutoCancelWaitListDuration" ||
      name === "CancellationFee"
    ) {
      let val = value;
      if (value <= 0) {
        val = 0;
      }
      if (name === "slots" && value > 150) {
        val = 150;
      }
      if (name === "AutoCancelWaitListDuration") {
        if (value <= 1) {
          val = 1;
        }
        if (selectedSessions?.length === 1) {
          const startTime = moment(form?.startTime).format('YYYY-MM-DD HH:mm:ss')
          const MinimumBookingDurationType = form?.MinimumBookingDurationType?.text?.toLowerCase()
          const minTime = moment(startTime).subtract(val, MinimumBookingDurationType)
          if (moment().format('YYYY-MM-DD HH:mm:ss') > moment(minTime).format('YYYY-MM-DD HH:mm:ss')) {
            props.handleNotificationMessage(`Waitlist Duration cannot be modified, if appointment already reaches cancel Waitlist duration`, "error");
            return
          }
        }

      }
      setform({ ...form, [name]: val });
      if (name === "minutes") {
        setChecked({ ...checked, duration: val === formInitialValues[name] ? false : true });
      } else {
        setChecked({ ...checked, [name]: val === formInitialValues[name] ? false : true });
      }
    } else if (name === "instructor") {
      setform({ ...form, [name]: value });
      if (value?.UserId === formInitialValues[name]?.UserId) {
        setChecked({ ...checked, [name]: false });
      } else {
        setChecked({ ...checked, [name]: true });
      }
    } else if (name === "emailNotification") {
      if (!value) {
        setform({
          ...form,
          [name]: value,
          RemainderDuration: null,
          RemainderType: null,
        });
      } else {
        if (selectedSessions?.length === 1) {
          setform({ ...form, [name]: value, RemainderDuration: formInitialValues?.RemainderDuration, RemainderType: formInitialValues?.RemainderType });
        } else {
          setform({ ...form, [name]: value });
        }
      }
      if (value === formInitialValues[name]) {
        setChecked({ ...checked, RemainderDuration: false });
      } else {
        setChecked({ ...checked, RemainderDuration: true });
      }
    } else if (name === "WaitList") {
      let val = value;
      if (value <= 0) {
        val = 0;
      }
      if (value > 150) {
        val = 150;
      }
      if (!val && selectedSessions?.length === 1) {
        setform({
          ...form,
          [name]: val,
          CanAutoCancelWaitList: false,
          AutoCancelWaitListDuration: null,
          AutoCancelWaitListDurationType: null,
        });
        setChecked({ ...checked, [name]: val === formInitialValues[name] ? false : true, AutoCancelWaitListDuration: formInitialValues["CanAutoCancelWaitList"] === false ? false : true });
      } else {
        setform({ ...form, [name]: val });
        setChecked({ ...checked, [name]: val === formInitialValues[name] ? false : true });
      }

    } else if (name === "CanAutoCancelWaitList") {
      if (!value) {
        setform({
          ...form,
          [name]: value,
          AutoCancelWaitListDuration: null,
          AutoCancelWaitListDurationType: null,
        });
      } else {
        if (selectedSessions?.length === 1) {
          setform({ ...form, [name]: value, AutoCancelWaitListDuration: formInitialValues?.AutoCancelWaitListDuration, AutoCancelWaitListDurationType: formInitialValues?.AutoCancelWaitListDurationType });
        } else {
          setform({ ...form, [name]: value, AutoCancelWaitListDuration: 30, AutoCancelWaitListDurationType: { id: 2, text: "Minutes" } });
        }
      }
      if (value === formInitialValues[name]) {
        setChecked({ ...checked, AutoCancelWaitListDuration: false });
      } else {
        setChecked({ ...checked, AutoCancelWaitListDuration: true });
      }
    } else if (name === "appointmentBookNotification") {
      if (!value) {
        setform({
          ...form,
          [name]: value,
          MinimumBookingDuration: null,
          MinimumBookingDurationType: null,
        });
      } else {
        if (selectedSessions?.length === 1) {
          setform({ ...form, [name]: value, MinimumBookingDuration: formInitialValues?.MinimumBookingDuration, MinimumBookingDurationType: formInitialValues?.MinimumBookingDurationType });
        } else {
          setform({ ...form, [name]: value });
        }
      }
      if (value === formInitialValues[name]) {
        setChecked({ ...checked, MinimumBookingDuration: false });
      } else {
        setChecked({ ...checked, MinimumBookingDuration: true });
      }
    } else if (name === "appointmentCancelNotification") {
      if (!value) {
        setform({
          ...form,
          [name]: value,
          MinimumCancellationDuration: null,
          MinimumCancellationDurationType: null,
        });
      } else {
        if (selectedSessions?.length === 1) {
          setform({ ...form, [name]: value, MinimumCancellationDuration: formInitialValues?.MinimumCancellationDuration, MinimumCancellationDurationType: formInitialValues?.MinimumCancellationDurationType });
        } else {
          setform({ ...form, [name]: value });
        }

      }
      if (value === formInitialValues[name]) {
        setChecked({ ...checked, MinimumCancellationDuration: false });
      } else {
        setChecked({ ...checked, MinimumCancellationDuration: true });
      }
    } else if (name === "MinimumCancellationDurationType") {
      setform({
        ...form,
        [name]: value,
      });
      if (value?.id === formInitialValues[name]?.id) {
        setChecked({ ...checked, MinimumCancellationDuration: false });
      } else {
        setChecked({ ...checked, MinimumCancellationDuration: true });
      }
    } else if (name === "CommunicationPreference") {
      setform({
        ...form,
        [name]: value,
      });
      if (value?.id === formInitialValues[name]?.id) {
        setChecked({ ...checked, CommunicationPreference: false });
      } else {
        setChecked({ ...checked, CommunicationPreference: true });
      }
    } else if (name === "MinimumBookingDurationType") {
      setform({
        ...form,
        [name]: value,
      });
      if (value?.id === formInitialValues[name]?.id) {
        setChecked({ ...checked, MinimumBookingDuration: false });
      } else {
        setChecked({ ...checked, MinimumBookingDuration: true });
      }
    } else if (name === "RemainderType") {
      setform({
        ...form,
        [name]: value,
      });
      if (value?.id === formInitialValues[name]?.id) {
        setChecked({ ...checked, RemainderDuration: false });
      } else {
        setChecked({ ...checked, RemainderDuration: true });
      }
    } else if (name === "AutoCancelWaitListDurationType") {
      if (selectedSessions?.length === 1) {
        const startTime = moment(form?.startTime).format('YYYY-MM-DD HH:mm:ss')
        const MinimumBookingDurationType = value?.text?.toLowerCase()
        const minTime = moment(startTime).subtract(form?.AutoCancelWaitListDuration, MinimumBookingDurationType)
        if (moment().format('YYYY-MM-DD HH:mm:ss') > moment(minTime).format('YYYY-MM-DD HH:mm:ss')) {
          props.handleNotificationMessage(`Waitlist Duration cannot be modified, if appointment already reaches cancel Waitlist duration`, "error");
          return
        }
      }
      setform({
        ...form,
        [name]: value,
      });
      if (value?.id === formInitialValues[name]?.id) {
        setChecked({ ...checked, AutoCancelWaitListDuration: false });
      } else {
        setChecked({ ...checked, AutoCancelWaitListDuration: true });
      }
    } else if (name === "ClassType") {
      if (value === 3) {
        setform({
          ...form,
          [name]: value,
          CancellationFee: 0,
          price: 0,
        });
        let check = false
        if (value !== formInitialValues[name]) {
          check = true
        }
        let cancelFeeChecked = checked.CancellationFee
        if (formInitialValues?.CancellationFee !== 0) {
          cancelFeeChecked = true
        }
        let priceChecked = checked.price
        if (formInitialValues?.price !== 0) {
          priceChecked = true
        }
        setChecked({ ...checked, [name]: check, CancellationFee: cancelFeeChecked, price: priceChecked });
      } else {
        setform({ ...form, [name]: value });
        let check = false
        if (value !== formInitialValues[name]) {
          check = true
        }
        setChecked({ ...checked, [name]: check });
      }
    } else if (name === "Attributes") {

      setform({ ...form, [name]: value });
      let check = false
      if (formInitialValues[name]?.ScheduleMode !== value?.ScheduleMode || formInitialValues[name]?.ScheduleModeOption !== value?.ScheduleModeOption || formInitialValues[name]?.InviteePhoneNumber !== value?.InviteePhoneNumber || formInitialValues[name]?.OtherAddress !== value?.OtherAddress || formInitialValues[name]?.OtherMeeting !== value?.OtherMeeting || formInitialValues[name]?.TraineePhoneNumber !== value?.TraineePhoneNumber) {
        check = true
      }
      setChecked({ ...checked, Location: check });
    } else if (name === "startTime") {
      let check = true
      if (selectedSessions?.length === 1 && moment(value).format("HH:mm") === moment(formInitialValues[name]).format("HH:mm")) {
        check = false
      }
      setform({ ...form, [name]: value });
      setChecked({ ...checked, [name]: check });
    } else if (name === "NonPaidLabel") {
      setform({
        ...form,
        [name]: value,
      });
      let check = false
      if (value !== formInitialValues[name]) {
        check = true
      }
      setChecked({ ...checked, ClassType: check });
    } else {
      setform({ ...form, [name]: value });
      setChecked({ ...checked, [name]: true });
    }
    if (props?.setRestrictNavigation) {
      props?.setRestrictNavigation(true);
    }
  };

  const fetchProviders = async () => {
    const service = new TenantService();
    setLoading(true)
    const res = await service.getTrainers();
    setLoading(false)
    setProvidersList(res);
    return res || [];
  };

  const handleValidation = async (check = false, providerChange = 0) => {
    setButtonLoading(true);
    let Schedules: any = [];
    if (!check) {
      selectedSessions.forEach((item: any) => {
        const obj: any = new Object();
        obj.StartDateTime = item.ClassSessionStartDate;
        obj.EndDateTime = item.ClassSessionEndTime;
        obj.DisplayTime = moment(item.ClassSessionStartDate).format("HH:mm:ss");
        obj.ScheduleId = item.ClassSessionId;
        Schedules.push(obj);
      });
    } else {
      selectedSessions.forEach((item: any) => {
        let validateClassSessionStartDate = item.ClassSessionStartDate;
        let validateClassSessionEndTime = item.ClassSessionEndTime;

        if (
          !checked.instructor ||
          (checked.instructor && item?.TrainerId === form?.instructor?.UserId)
        ) {
          if (checked.duration && !checked.startTime) {
            if (item?.ClassSessionDuration >= form?.minutes) {
              setButtonLoading(false);
              return;
            } else {
              validateClassSessionStartDate = item.ClassSessionEndTime;
              const dur = form?.minutes - item?.ClassSessionDuration;
              validateClassSessionEndTime = moment(item.ClassSessionEndTime)
                .add(dur, "minutes")
                .format("YYYY-MM-DD HH:mm:ss");
            }
          }
          if (checked.duration && checked.startTime) {
            const start = moment(item?.ClassSessionStartDate).format();
            const actual = moment(form?.startTime).format();
            if (
              start === actual &&
              item?.ClassSessionDuration >= form?.minutes
            ) {
              setButtonLoading(false);
              return;
            }
            const startDate = moment(item.ClassSessionStartDate).format(
              "YYYY-MM-DD"
            );
            const startTime = moment(form.startTime).format("HH:mm:ss");
            const StartDateTime = moment(startDate + " " + startTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            let durationInMinutes = 0;
            if (form?.minutes) {
              durationInMinutes = form?.minutes;
            }
            const EndDateTime = moment(StartDateTime)
              .add(durationInMinutes, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
            if (
              StartDateTime >=
              moment(item.ClassSessionStartDate).format(
                "YYYY-MM-DD HH:mm:ss"
              ) &&
              EndDateTime <=
              moment(item.ClassSessionEndTime).format("YYYY-MM-DD HH:mm:ss")
            ) {
              setButtonLoading(false);
              return;
            } else if (
              StartDateTime <
              moment(item.ClassSessionStartDate).format("YYYY-MM-DD HH:mm:ss")
            ) {
              validateClassSessionStartDate = StartDateTime;
              if (
                EndDateTime >
                moment(item.ClassSessionStartDate).format("YYYY-MM-DD HH:mm:ss")
              ) {
                let diff = moment(item.ClassSessionStartDate).diff(
                  moment(StartDateTime),
                  "minutes"
                );
                validateClassSessionEndTime = moment(
                  validateClassSessionStartDate
                )
                  .add(diff, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss");
              } else {
                validateClassSessionEndTime = EndDateTime;
              }
            } else if (
              EndDateTime >
              moment(item.ClassSessionEndTime).format("YYYY-MM-DD HH:mm:ss")
            ) {
              validateClassSessionEndTime = EndDateTime;
              let diff = moment(EndDateTime).diff(
                moment(item.ClassSessionEndTime),
                "minutes"
              );
              validateClassSessionStartDate = moment(
                validateClassSessionEndTime
              )
                .subtract(durationInMinutes, "minutes")
                .format("YYYY-MM-DD HH:mm:ss");
            }
          }
          if (!checked.duration && checked.startTime) {
            const start = moment(item?.ClassSessionStartDate).format();
            const actual = moment(form?.startTime).format();
            if (start === actual) {
              setButtonLoading(false);
              return;
            } else if (actual < start) {
              const startDate = moment(item.ClassSessionStartDate).format(
                "YYYY-MM-DD"
              );
              const startTime = moment(form.startTime).format("HH:mm:ss");
              validateClassSessionStartDate = moment(
                startDate + " " + startTime
              ).format("YYYY-MM-DD HH:mm:ss");
              let diff = moment(start).diff(moment(actual), "minutes");
              if (diff <= item?.ClassSessionDuration) {
                validateClassSessionEndTime = moment(
                  validateClassSessionStartDate
                )
                  .add(diff, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss");
              } else {
                validateClassSessionEndTime = moment(
                  validateClassSessionStartDate
                )
                  .add(item?.ClassSessionDuration, "minutes")
                  .format("YYYY-MM-DD HH:mm:ss");
              }
            } else if (actual > start) {
              const startDate = moment(item.ClassSessionStartDate).format(
                "YYYY-MM-DD"
              );
              const startTime = moment(form.startTime).format("HH:mm:ss");
              let diff = moment(actual).diff(moment(start), "minutes");
              const dur = item?.ClassSessionDuration;
              const changedTime = moment(startDate + " " + startTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              if (diff <= item?.ClassSessionDuration) {
                validateClassSessionStartDate = moment(
                  item.ClassSessionEndTime
                ).format("YYYY-MM-DD HH:mm:ss");
              } else {
                validateClassSessionStartDate = changedTime;
              }
              validateClassSessionEndTime = moment(changedTime)
                .add(dur, "minutes")
                .format("YYYY-MM-DD HH:mm:ss");
            }
          }
        }
        if (
          checked.instructor &&
          item?.TrainerId !== form?.instructor?.UserId
        ) {
          if (checked.duration && !checked.startTime) {
            validateClassSessionStartDate = item.ClassSessionStartDate;
            validateClassSessionEndTime = moment(item.ClassSessionStartDate)
              .add(form?.minutes, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
          }

          if (checked.duration && checked.startTime) {
            const startDate = moment(item.ClassSessionStartDate).format(
              "YYYY-MM-DD"
            );
            const startTime = moment(form.startTime).format("HH:mm:ss");
            validateClassSessionStartDate = moment(
              startDate + " " + startTime
            ).format("YYYY-MM-DD HH:mm:ss");
            validateClassSessionEndTime = moment(validateClassSessionStartDate)
              .add(form?.minutes, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
          }

          if (!checked.duration && checked.startTime) {
            const startDate = moment(item.ClassSessionStartDate).format(
              "YYYY-MM-DD"
            );
            const startTime = moment(form.startTime).format("HH:mm:ss");
            validateClassSessionStartDate = moment(
              startDate + " " + startTime
            ).format("YYYY-MM-DD HH:mm:ss");
            validateClassSessionEndTime = moment(validateClassSessionStartDate)
              .add(item?.ClassSessionDuration, "minutes")
              .format("YYYY-MM-DD HH:mm:ss");
          }
        }

        if (checked?.ClassSessionStartDate) {
          const dateVal = moment(form?.ClassSessionStartDate).format(
            "YYYY-MM-DD"
          );
          const startTimeVal = moment(validateClassSessionStartDate).format(
            "HH:mm:ss"
          );
          const endTimeVal = moment(validateClassSessionEndTime).format(
            "HH:mm:ss"
          );
          validateClassSessionStartDate = moment(
            dateVal + " " + startTimeVal
          ).format("YYYY-MM-DD HH:mm:ss");
          validateClassSessionEndTime = moment(
            dateVal + " " + endTimeVal
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        const obj: any = new Object();
        obj.StartDateTime = moment(validateClassSessionStartDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        obj.EndDateTime = moment(validateClassSessionEndTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        obj.DisplayTime = moment(validateClassSessionStartDate).format(
          "HH:mm:ss"
        );
        obj.ScheduleId = item.ClassSessionId;
        Schedules.push(obj);
      });
    }
    if (Schedules.length > 0) {
      const req: any = new Object();
      req.Schedules = Schedules;
      req.UserId = 0;
      req.IsThirdPartyCalendarEnabled = true;
      req.TrainerUserId = form?.instructor?.UserId;
      req.UserAssociateId = form?.instructor?.AssociateId;
      const service = new ClassService();
      const result = await service.getAvailabilityBySchedules(req);
      setButtonLoading(false);
      if (result.Items.length > 0) {
        const records = result?.Items?.filter(
          (item: any) => item.IsAvailable === false
        );
        if (records.length > 0) {
          toggleAvailibilityDialog();
          setUnAvailableList(records);
        } else {
          setUnAvailableList([]);
          handleSubmit();
        }
      } else {
        setUnAvailableList([]);
        handleSubmit();
      }
    } else {
      setUnAvailableList([]);
      handleSubmit();
    }
  };

  const handleContinue = () => {
    if (selectedSessions.length === 0) {
      setMessage("A appointment is required.");
      setNotification({ ...notification, error: true });
    } else if (checked.startTime && form.startTime === null) {
      setMessage("If selected, the start time must be set.");
      setNotification({ ...notification, error: true });
    } else if (
      checked.duration &&
      // form.hours === null &&
      (form.minutes === null || form.minutes === 0)
    ) {
      setMessage("Please enter a valid duration.");
      setNotification({ ...notification, error: true });
    } else if (checked.instructor && (form.instructor === null || form.instructor?.UserId === -1)) {
      setMessage("Please select a provider.");
      setNotification({ ...notification, error: true });
    } else if (checked.slots && !form.slots) {
      setMessage(
        "Please enter a valid number of Maximum Attendeess from 1 to 150."
      );
      setNotification({ ...notification, error: true });
    } else if (checked.price && form.price === null) {
      setMessage("If selected, Please enter a Price.");
      setNotification({ ...notification, error: true });
    } else if (
      checked.Location &&
      (form.Attributes?.ScheduleMode === 0 || !form.Attributes)
    ) {
      setMessage("If selected, Please select a Location.");
      setNotification({ ...notification, error: true });
    } else if (checked.color && form.color === null) {
      setMessage("If selected, Please select a Color.");
      setNotification({ ...notification, error: true });
    } else if (checked.ClassType && form.ClassType === 3 && !form.NonPaidLabel) {
      setMessage("If selected, Please provide enrollment label for customers.");
      setNotification({ ...notification, error: true });
    } else if (checked.ClassType && form.ClassType === 1 && form?.price <= 0) {
      setMessage("Paid groups must not have a zero price.");
      setNotification({ ...notification, error: true });
    } else if (selectedSessions?.length === 1 && form.ClassType === 1 && form?.price <= 0) {
      setMessage("Paid groups must not have a zero price.");
      setNotification({ ...notification, error: true });
    } else if (checked.PaymentOption && form.ClassType === 1 && !form?.PaymentOption) {
      setMessage("If selected, Please provide a Payment Option.");
      setNotification({ ...notification, error: true });
    } else if (checked.PaymentOption && form.ClassType === 3 && (!form?.PaymentOption || form?.PaymentOption < 2)) {
      setMessage("If selected, Please provide a Payment Option.");
      setNotification({ ...notification, error: true });
    } else if (
      form.CanAutoCancelWaitList &&
      (form.AutoCancelWaitListDuration === null || form.AutoCancelWaitListDurationType === null)
    ) {
      if (
        form.AutoCancelWaitListDuration === null &&
        form.AutoCancelWaitListDurationType === null
      ) {
        setMessage("Please enter Wait List Auto Cancel Duration and Duration Type.");
        setNotification({ ...notification, error: true });
      } else if (
        form.AutoCancelWaitListDuration === null &&
        form.AutoCancelWaitListDurationType !== null
      ) {
        setMessage("Please enter Wait List Auto Cancel Duration.");
        setNotification({ ...notification, error: true });
      } else if (
        form.AutoCancelWaitListDuration !== null &&
        form.AutoCancelWaitListDurationType === null
      ) {
        setMessage("Please enter Wait List Auto Cancel Duration Type.");
        setNotification({ ...notification, error: true });
      }
    } else if (
      form.emailNotification &&
      (form.RemainderDuration === null || form.RemainderType === null)
    ) {
      if (
        form.RemainderDuration === null &&
        form.RemainderType === null
      ) {
        setMessage("Please enter Reminder Duration and Duration Type.");
        setNotification({ ...notification, error: true });
      } else if (
        form.RemainderDuration === null &&
        form.RemainderType !== null
      ) {
        setMessage("Please enter Reminder Duration.");
        setNotification({ ...notification, error: true });
      } else if (
        form.RemainderDuration !== null &&
        form.RemainderType === null
      ) {
        setMessage("Please enter Reminder Duration Type.");
        setNotification({ ...notification, error: true });
      }
    } else if (
      form.appointmentBookNotification &&
      (form.MinimumBookingDuration === null ||
        form.MinimumBookingDurationType === null)
    ) {
      if (
        form.MinimumBookingDuration === null &&
        form.MinimumBookingDurationType === null
      ) {
        setMessage("Please enter Minimum Booking Duration and Duration Type.");
        setNotification({ ...notification, error: true });
      } else if (
        form.MinimumBookingDuration === null &&
        form.MinimumBookingDurationType !== null
      ) {
        setMessage("Please enter Minimum Booking Duration.");
        setNotification({ ...notification, error: true });
      } else if (
        form.MinimumBookingDuration !== null &&
        form.MinimumBookingDurationType === null
      ) {
        setMessage("Please enter Minimum Booking Duration Type.");
        setNotification({ ...notification, error: true });
      }
    } else if (
      form.appointmentCancelNotification &&
      (form.MinimumCancellationDuration === null ||
        form.MinimumCancellationDurationType === null)
    ) {
      if (
        form.MinimumCancellationDuration === null &&
        form.MinimumCancellationDurationType === null
      ) {
        setMessage(
          "Please enter Minimum Cancellation Duration and Duration Type."
        );
        setNotification({ ...notification, error: true });
      } else if (
        form.MinimumCancellationDuration === null &&
        form.MinimumCancellationDurationType !== null
      ) {
        setMessage("Please enter Minimum Cancellation Duration.");
        setNotification({ ...notification, error: true });
      } else if (
        form.MinimumCancellationDuration !== null &&
        form.MinimumCancellationDurationType === null
      ) {
        setMessage("Please enter Minimum Cancellation Duration Type.");
        setNotification({ ...notification, error: true });
      }
    } else if (checked.CancellationFee && form.CancellationFee === null) {
      setMessage("Please enter a Cancellation Fee.");
      setNotification({ ...notification, error: true });
    } else if (
      !checked.startTime &&
      !checked.duration &&
      !checked.instructor &&
      !checked.slots &&
      !checked.WaitList &&
      !checked.price &&
      !checked.subInstructor &&
      !checked.color &&
      !checked.RemainderDuration &&
      !checked.MinimumBookingDuration &&
      !checked.MinimumCancellationDuration &&
      !checked.AutoCancelWaitListDuration &&
      !checked.CancellationFee &&
      !checked.HasVirtualMeetingEnabled &&
      !checked.Location &&
      !checked.ClassSessionStartDate &&
      !checked.ClassType &&
      !checked.PaymentOption &&
      !checked.CommunicationPreference
    ) {
      setMessage("No changes have been made.");
      setNotification({ ...notification, error: true });
    } else {
      if (checked.slots && props?.editType === "single") {
        if (form.slots < selectedSessions[0].BookedSlot) {
          setMessage(
            `It appears that the clients have already been enrolled, and the system does not allow for reducing the number of attendees below the currently enrolled count`
          );
          setNotification({ ...notification, error: true });
          return true;
        }
      }
      if (checked.WaitList && props?.editType === "single") {
        if (form.WaitList < selectedSessions[0].WaitingSlot) {
          setMessage(
            `It appears that the clients have already been enrolled , and the system does not allow for reducing the number of attendees in waitlist below the currently enrolled Waiting Slot`
          );
          setNotification({ ...notification, error: true });
          return true;
        }
      }

      if (checked.instructor && props?.editType === "bulk") {
        handleValidation(false);
      } else if (
        !checked.instructor &&
        props?.editType === "single" &&
        (checked.duration || checked.startTime)
      ) {
        handleValidation(true);
      } else if (
        checked.instructor &&
        props?.editType === "single" &&
        (checked.duration || checked.startTime)
      ) {
        handleValidation(true, 1);
      } else {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 8000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
      if (submit) {
        props.handleWindow();
      }
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);

  useEffect(() => {
    handleSingleSelection();
  }, [props.editType]);

  const handleSingleSelection = () => {
    if (props.editType === "single") {
      const resultData = props?.classSessions.map((v: any) => ({
        ...v,
        selected: true,
      }));
      handleSessionButton(resultData);
    }
  };
  const handleUpdateValuesSingleSession = async (record: any) => {
    const providerData = await fetchProviders();
    const {
      ClassSessionDuration,
      ClassSessionStartDate,
      Slot,
      TrainerId,
      Attributes,
      Cost,
      CancellationFee,
      MinimumBookingDuration,
      MinimumBookingDurationType,
      MinimumCancellationDuration,
      MinimumCancellationDurationType,
      RemainderType,
      RemainderDuration,
      HasVirtualMeetingEnabled,
      ClassType,
      PaymentOption,
      WaitList,
      AutoCancelWaitListDurationType,
      AutoCancelWaitListDuration,
      CanAutoCancelWaitList,
      CommunicationPreference = 2
    } = record;
    const provider = providerData.find(
      (item: any) => item.AssociateId === TrainerId
    );
    let minimumBookingDurationType = null;
    let minimumCancellationDurationType = null;
    let remainderType = null;
    let autoCancelWaitListDurationType = null;
    if (AutoCancelWaitListDurationType === 1) {
      autoCancelWaitListDurationType = { id: AutoCancelWaitListDurationType, text: "Hours" };
    } else if (AutoCancelWaitListDurationType === 2) {
      autoCancelWaitListDurationType = { id: AutoCancelWaitListDurationType, text: "Minutes" };
    } else if (AutoCancelWaitListDurationType === 3) {
      autoCancelWaitListDurationType = { id: AutoCancelWaitListDurationType, text: "Days" };
    }
    if (RemainderType === 1) {
      remainderType = { id: RemainderType, text: "Hours" };
    } else if (RemainderType === 2) {
      remainderType = { id: RemainderType, text: "Minutes" };
    } else if (RemainderType === 3) {
      remainderType = { id: RemainderType, text: "Days" };
    }
    if (MinimumBookingDurationType === 1) {
      minimumBookingDurationType = {
        id: MinimumBookingDurationType,
        text: "Hours",
      };
    } else if (MinimumBookingDurationType === 2) {
      minimumBookingDurationType = {
        id: MinimumBookingDurationType,
        text: "Minutes",
      };
    } else if (MinimumBookingDurationType === 3) {
      minimumBookingDurationType = {
        id: MinimumBookingDurationType,
        text: "Days",
      };
    }
    if (MinimumCancellationDurationType === 1) {
      minimumCancellationDurationType = {
        id: MinimumCancellationDurationType,
        text: "Hours",
      };
    } else if (MinimumCancellationDurationType === 2) {
      minimumCancellationDurationType = {
        id: MinimumCancellationDurationType,
        text: "Minutes",
      };
    } else if (MinimumCancellationDurationType === 3) {
      minimumCancellationDurationType = {
        id: MinimumCancellationDurationType,
        text: "Days",
      };
    }
    const AttributeData = JSON.parse(Attributes)
    const NonPaidLabel = AttributeData?.NonPaidLabel
    const formData = {
      ...form,
      price: Cost,
      slots: Slot,
      WaitList: WaitList,
      instructor: provider,
      startTime: new Date(ClassSessionStartDate),
      ClassSessionStartDate: new Date(ClassSessionStartDate),
      minutes: ClassSessionDuration,
      CancellationFee: CancellationFee,
      MinimumBookingDurationType: minimumBookingDurationType,
      MinimumBookingDuration,
      MinimumCancellationDuration,
      MinimumCancellationDurationType: minimumCancellationDurationType,
      AutoCancelWaitListDurationType: autoCancelWaitListDurationType,
      CommunicationPreference: CommunicationPreferenceList?.find((i: any) => i?.id === CommunicationPreference),
      AutoCancelWaitListDuration,
      RemainderType: remainderType,
      CanAutoCancelWaitList: CanAutoCancelWaitList,
      RemainderDuration,
      ClassType,
      PaymentOption,
      NonPaidLabel,
      HasVirtualMeetingEnabled,
      Attributes: JSON.parse(Attributes),
      emailNotification: RemainderDuration != 0,
      appointmentBookNotification: MinimumBookingDuration != 0,
      appointmentCancelNotification: MinimumCancellationDuration != 0,
    }
    setFormInitialValues(formData)
    setform(formData);
  }

  const startOver = () => {
    setShowEditForm(true);
  };

  const handleEditPage = async () => {
    await handleSubmit();
    toggleAvailibilityDialog();

  };

  const handleSubmit = async () => {
    setUpdateLodaing(true);
    const req = selectedSessions.map((item: any) => {
      const obj: any = new Object();

      obj.ClassSessionId = item.ClassSessionId;
      if (checked?.slots) {
        obj.Slot = form?.slots;
      }
      if (checked?.WaitList) {
        obj.WaitList = form?.WaitList;
      }
      if (checked?.price) {
        obj.DropInPrice = form?.price;
      }
      if (checked?.RemainderDuration) {
        obj.RemainderDuration = form?.RemainderDuration;
        obj.RemainderType = form?.RemainderType?.id;
      }
      if (checked?.MinimumBookingDuration) {
        obj.MinimumBookingDuration = form?.MinimumBookingDuration;
        obj.MinimumBookingDurationType = form?.MinimumBookingDurationType?.id;
      }
      if (checked?.MinimumCancellationDuration) {
        obj.MinimumCancellationDuration = form?.MinimumCancellationDuration;
        obj.MinimumCancellationDurationType =
          form?.MinimumCancellationDurationType?.id;
      }
      if (checked?.AutoCancelWaitListDuration) {
        obj.AutoCancelWaitListDuration = form?.AutoCancelWaitListDuration;
        obj.CanAutoCancelWaitList = form?.CanAutoCancelWaitList;
        obj.AutoCancelWaitListDurationType =
          form?.AutoCancelWaitListDurationType?.id;
      }
      if (checked?.CancellationFee) {
        obj.CancellationFee = form?.CancellationFee;
      }
      if (checked?.HasVirtualMeetingEnabled) {
        obj.HasVirtualMeetingEnabled = form?.HasVirtualMeetingEnabled;
      }
      if (checked?.CommunicationPreference) {
        obj.CommunicationPreference = form?.CommunicationPreference?.id;
      }
      if (checked?.Location) {
        obj.ScheduleMode = form?.Attributes.ScheduleMode;
        obj.ScheduleModeOption = form?.Attributes.ScheduleModeOption;
        obj.InviteePhoneNumber = form?.Attributes.InviteePhoneNumber;
        obj.TraineePhoneNumber = form?.Attributes.TraineePhoneNumber;
        obj.OtherAddress = form?.Attributes.OtherAddress;
        obj.OtherMeeting = form?.Attributes.OtherMeeting;
      }
      if (checked?.ClassType) {
        obj.ClassType = form?.ClassType;
        if (form?.ClassType === 3) {
          obj.DropInPrice = 0;
          obj.CancellationFee = 0;
          obj.NonPaidLable = form?.NonPaidLabel;
        }
      }
      if (checked?.PaymentOption) {
        obj.PaymentOption = form?.PaymentOption
      }
      if (checked?.startTime && !checked?.duration) {
        const startDate = moment(item.ClassSessionStartDate).format(
          "YYYY-MM-DD"
        );
        const startTime = moment(form.startTime).format("HH:mm:ss");
        const StartDateTime = moment(startDate + " " + startTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const ClassSessionDuration = item?.ClassSessionDuration;
        const EndDateTime = moment(StartDateTime)
          .add(ClassSessionDuration, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        obj.EndTime = EndDateTime;
        obj.StartTime = StartDateTime;
      }
      if (!checked?.startTime && checked?.duration) {
        let durationInMinutes = 0;
        if (form?.minutes) {
          durationInMinutes += form?.minutes;
        }
        const StartDateTime = moment(item.ClassSessionStartDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const EndDateTime = moment(StartDateTime)
          .add(durationInMinutes, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        obj.EndTime = EndDateTime;
        obj.StartTime = StartDateTime;
        obj.Duration = durationInMinutes;
      }

      if (checked?.startTime && checked?.duration) {
        const startDate = moment(item.ClassSessionStartDate).format(
          "YYYY-MM-DD"
        );
        const startTime = moment(form.startTime).format("HH:mm:ss");
        const StartDateTime = moment(startDate + " " + startTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        let durationInMinutes = 0;
        if (form?.minutes) {
          durationInMinutes += form?.minutes;
        }
        const EndDateTime = moment(StartDateTime)
          .add(durationInMinutes, "minutes")
          .format("YYYY-MM-DD HH:mm:ss");
        obj.EndTime = EndDateTime;
        obj.StartTime = StartDateTime;
        obj.Duration = durationInMinutes;
      }
      if (checked?.instructor) {
        let userAssociateId = form?.instructor?.AssociateId;
        obj.UserAssociateId = userAssociateId;
      }
      return obj;
    });
    const tenantData = GetLocalStore("tenantUserSession");
    const reqObj: any = {
      ClubDateTime: new Date(),
      CurrentUserId: tenantData?.UserId,
      ClassSessionResults: req,
      CanEmailToClient: checked?.client,
    };
    // reqObj.
    const service = new ClassService();
    const result = await service.updateClassSession(reqObj);
    setUpdateLodaing(false);
    if (result?.ResponseCode === 100) {
      const records = result?.Items?.filter(
        (item: any) => item.ErrorReason
      );
      if (records.length > 0) {
        toggleFailedListDialog()
        setFailedList(records)
      } else {
        if (props?.calendarPage) {
          props.handleNotificationMessage(`Selected Appointments successfully Updated`, "success");
          setTimeout(() => {
            props?.onUpdatedGroup(new Date());
            props.handleWindow(true);
          }, 1500);
        } else {
          props.handleNotificationMessage(`${count} Appointments successfully Updated`, "success");
          props.handleWindow(true);
        }

        setSubmit(true);
        setSelectedSessions([]);
        setFailedList([])
      }
    } else {
      const records = result?.Items?.filter(
        (item: any) => item.ErrorReason
      );
      if (records.length > 0) {
        toggleFailedListDialog()
        setFailedList(records)
      } else {
        const errorMsg =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages?.[0]
            : "Error while editing Appointment";
        setMessage(errorMsg);
        setNotification({ ...notification, error: true });
      }
    }
  };
  const convertHourstoMinute = (hours: any) => {
    return Math.floor(hours * 60);
  };
  const handleSessionTable = (screen: string) => {
    setSession({ ...session, [screen]: true });
  };

  const handleSessionButton = (data: any) => {
    let selectedList = data.filter(
      (dataItem: any) => dataItem.selected === true
    );
    if (selectedList.length > 0) {
      const listCount = selectedList.length;
      setSelectedSessions(selectedList);
      setCount(listCount);
      const firstItem = selectedList[0].ClassSessionStartDate;
      const lastItem = selectedList[listCount - 1].ClassSessionStartDate;
      let text = (
        <p>
          This change will update {listCount} appointments. The first
          appointment selected is{" "}
          <span className={"text-capitalize"}>
            {getFullDate(firstItem)},{getTimeFormat(firstItem)}
          </span>
          ; the last appointment selected is{" "}
          <span className={"text-capitalize"}>
            {getFullDate(lastItem)},{getTimeFormat(lastItem)}
          </span>
        </p>
      );
      if (listCount === 1) {
        text = (
          <p>
            This change will update {listCount} appointment. The appointment
            selected is{" "}
            <span className={"text-capitalize"}>
              {getFullDate(firstItem)},{getTimeFormat(firstItem)}
            </span>
          </p>
        );
      }
      if (selectedList?.length === 1) {
        handleUpdateValuesSingleSession(selectedList[0])
      } else {
        fetchProviders()
        setFormInitialValues(InitialValues)
        setform(InitialValues);
      }

      setSelectedItemText(text);
      setSession({ ...session, choose: false, view: false });
      settableData(data);
      setChooseScreen(false);
    }
  };

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const popUpWindow = () => {
    let Component = <></>;
    let title = "";
    let visible = false;
    let handleEvent = { ...session };
    if (session.choose) {
      Component = (
        <SessionTable
          title={"Upcoming Appointments"}
          type="edit"
          class={props?.classValue}
          tableData={tableData}
          handleSessionBtn={(data: any) => handleSessionButton(data)}
        />
      );
      title = "Select Appointments";
      visible = session.choose;
      handleEvent = { ...session, choose: false };
    } else if (session.view) {
      Component = (
        <SessionTable
          title={"Upcoming Appointments"}
          type="edit"
          class={props?.classValue}
          tableData={tableData}
          handleSessionBtn={(data: any) => handleSessionButton(data)}
        />
      );
      title = "Select Appointments";
      visible = session.view;
      handleEvent = { ...session, view: false };
    }
    return (
      <BuzopsWindow
        title={
          <>
            <div className={"buzops-window-title"}>
              {
                !props?.match?.params?.groupId &&
                <span
                  className={"k-icon k-i-undo buzopsWindowBack"}
                  onClick={() => setSession(handleEvent)}
                ></span>
              }
              &nbsp;{title}
            </div>
          </>
        }
        reduceWidth={10}
        className={"bzo-class-window"}
        visibility={visible}
        key={visible.toString()}
        onWindowClose={() => setSession(handleEvent)}
      >
        {Component}
      </BuzopsWindow>
    );
  };

  if (loading) {
    return <BuzopsLoader type="list" />
  }
  const successMsg = `Disclaimer: It appears that the auto-cancellation setting for waitlisted clients has been disabled. Please be aware that clients on the waitlist will not be automatically cancelled unless a staff member manually cancels or denies their spot.`
  return (
    <>
      <Card>
        <CardBody className={"edit-session-body"}>
          {selectedSessions.length === 1 && form?.WaitList > 0 && !form?.CanAutoCancelWaitList ? <div className="alert alert-warning fade show"
            role="alert">
            {successMsg}
          </div> : null}
          {showEditForm && selectedSessions.length > 0 && !chooseScreen && (
            <EditFieldsSession
              handleSessionTable={(screen: string) =>
                handleSessionTable(screen)
              }
              editType={props.editType}
              providersList={providersList}
              selectedSessions={selectedSessions}
              toggleAvailibilityDialog={toggleAvailibilityDialog}
              toggleFailedListDialog={toggleFailedListDialog}
              handleEditPage={handleEditPage}
              selectedItemText={selectedItemText}
              unAvailableList={unAvailableList}
              failedList={failedList}
              unAvailableListDialog={unAvailableListDialog}
              failedListDialog={failedListDialog}
              handleCloseFailedDialog={handleCloseFailedDialog}
              buttonLoading={buttonLoading}
              count={count}
              handleChange={(event: any, name: string, formKey?: string) =>
                handleChange(event, name, formKey)
              }
              checked={checked}
              handleForm={(value: any, name: string) => handleForm(value, name)}
              form={form}
              handleContinue={() => handleContinue()}
              handleWindow={() => props.handleWindow()}
              updateLodaing={updateLodaing}
              handleAppointmentsPage={(bool = false) => {
                setChooseScreen(bool);
                setChecked(InitialSwitchValues);
              }}
            />
          )}
          {showEditForm &&
            (selectedSessions.length === 0 || chooseScreen) &&
            props.editType === "bulk" && (
              <>
                <SessionTable
                  title={"Total Appointments"}
                  type="edit"
                  class={props?.classValue}
                  key={`session-Table-${selectedSessions?.length}`}
                  tableData={tableData}
                  handleSessionBtn={(data: any) => handleSessionButton(data)}
                />
              </>
            )}
        </CardBody>
      </Card>
      {popUpWindow()}
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
    </>
  );
};

export default withNotification(EditSession);

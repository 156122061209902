import About from 'components/about/About';
import Auth from 'components/authcomponent/Auth';
import React, { useState } from 'react'

const LoginComponent = () => {
    const [loginStatus, setLoginStatus] = useState(true);
    const handleCloseLogin = () => {
        setLoginStatus(false);
    };
    return (
        <>
            <About />
            <Auth
                handleCloseLogin={() => handleCloseLogin()}
                showComponent={loginStatus}
                key={loginStatus.toString()}
            />
        </>
    )
}

export default LoginComponent
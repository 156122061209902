import { useEffect, useState } from "react";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { taxPercentageValidator } from "validators/validator";
const useManageTaxes = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDisableTaxDialog, setShowDisableTaxDialog] = useState(false);
  const [hasTaxEnabled, setHasTaxEnabled] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [taxes, setTaxes] = useState<any>([]);
  const initialState = {
    TaxId: 0,
    TaxName: null,
    Percentage: null,
    IsActive: true,
  };
  const modifyInitialState = {
    TaxName: false,
    Percentage: false,
  };
  const [clubID, setClubId] = useState(props?.ClubId || 0);
  const [tax, setTax] = useState<any>(initialState);
  const [modify, setModify] = useState<any>(modifyInitialState);
  const [taxHistory, setTaxHistory] = useState<any>([]);
  const service = new ManageTaxesService();

  const fetchUserSession = async () => {
    const res = await service.getTenantDetails(
      props?.uuid || props?.match?.params?.uuid
    );
    const clubId = res?.Item?.UserSession?.ClubId;
    const HasTaxEnabledVal =
      res?.Item?.UserSession?.ClubPreferences?.HasTaxEnabled;
    setClubId(clubId);
    setHasTaxEnabled(HasTaxEnabledVal);
    return clubId;
  };

  const handleEnableTax = async () => {
    // setHasTaxEnabled(!hasTaxEnabled)
    await handleEnableTaxForClub(!hasTaxEnabled);
  };

  const handleTaxDialog = () => {
    setShowAddDialog(!showAddDialog);
    setTax(initialState);
    setModify(modifyInitialState);
  };
  const handleChange = (value: any, name: string) => {
    let val=value
    setTax({ ...tax, [name]: val });
    setModify({ ...modify, [name]: true });
  };

  const fetchAllApis = async () => {
    setLoading(true);
    const clubId = await fetchUserSession();
    await fetchTaxes(clubId);
    setLoading(false);
  };

  const fetchTaxesApi = async () => {
    setLoading(true);
    await fetchTaxes();
    setLoading(false);
  };

  const handleSaveTax = async () => {
    if (taxPercentageValidator(tax?.Percentage)) {
      return;
    }
    const req = {
      TaxName: tax?.TaxName,
      Percentage: tax?.Percentage,
      IsActive: tax?.IsActive,
      ClubId: clubID,
    };
    setBtnLoading(true);
    const res = await service.saveTax(req);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Surcharge Saved Successfully`,
        "success"
      );
      setShowAddDialog(!showAddDialog);
      setModify(modifyInitialState)
      await fetchTaxesApi();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Saving Surcharge";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const toggleDisableDialog = (dataItem = null) => {
    if (dataItem) {
      setTax(dataItem);
      setShowDisableTaxDialog(true);
    } else {
      setTax(initialState);
      setShowDisableTaxDialog(false);
    }
  };
  const handleUpdateTax = async () => {
    if (taxPercentageValidator(tax?.Percentage)) {
      return;
    }
    //update Tax API
    const req = {
      ClubId: clubID,
      TaxId: tax?.TaxId,
      TaxName: tax?.TaxName,
      Percentage: tax?.Percentage,
      IsActive: tax?.IsActive,
    };
    setBtnLoading(true);
    const res = await service.updateTax(req);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Surcharge Updated Successfully`,
        "success"
      );
      setShowAddDialog(!showAddDialog);
      setModify(modifyInitialState)
      await fetchTaxesApi();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating Surcharge";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleEnableTaxForClub = async (value: boolean) => {
    const req = {
      ClubId: clubID,
      hasTaxEnabled: value,
    };
    setToggleLoading(true);
    const res = await service.enableTax(req);
    setToggleLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Surcharge ${value ? "Enabled" : "Disabled"} Successfully`,
        "success"
      );
      await fetchAllApis();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleModifyDisable = async () => {
    const req = {
      ClubId: clubID,
      TaxId: tax?.TaxId,
      IsActive: !tax?.IsActive,
    };
    setBtnLoading(true);
    const res = await service.updateTax(req);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Surcharge ${tax?.IsActive ? "Disabled" : "Enabled"} Successfully`,
        "success"
      );
      setShowDisableTaxDialog(false);
      await fetchTaxesApi();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in Updating Surcharge";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleEdit = (dataItem: any) => {
    setTax(dataItem);
    setShowAddDialog(!showAddDialog);
  };
  const handleHistory = async (dataItem: any) => {
    if (!showHistory) {
      setTax(dataItem);
      await fetchTaxHistory(dataItem);
    } else {
      setTax(initialState);
    }
    setShowHistory(!showHistory);
  };

  const fetchTaxes = async (ClubId = clubID) => {
    const req = { ClubId, IncludeInActive: true };
    const res = await service.getTaxes(req);
    setTaxes(res);
  };
  const fetchTaxHistory = async (dataItem: any) => {
    const req = {
      IsHistory: true,
      TaxName: dataItem?.TaxName,
    };
    const res = await service.getTaxes(req);
    setTaxHistory(res);
  };

  useEffect(() => {
    fetchAllApis();
  }, []);
  return {
    loading,
    handleTaxDialog,
    showAddDialog,
    taxes,
    tax,
    handleChange,
    handleSaveTax,
    handleUpdateTax,
    btnLoading,
    handleEdit,
    handleModifyDisable,
    toggleDisableDialog,
    showDisableTaxDialog,
    showHistory,
    handleHistory,
    handleEnableTax,
    hasTaxEnabled,
    toggleLoading,
    modify,
    taxHistory
  };
};

export default useManageTaxes;

import moment from "moment";
import React, { useState, useRef } from "react";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { TaxReportColumns } from "../Utilities/constants";
import { reportFilters } from "components/reports/reportsCommonFilters";

const useTaxReport = (props: any) => {
  const uuid = props?.match?.params?.uuid;
  const gridColumns = TaxReportColumns;
  const initialSort = [
    {
      field: "Year",
    },
  ];
  const [sort, setSort] = useState(initialSort);
  const [fullScreen, setFullscreen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const pageSize = 10;
  const [originalTableData, setOriginalTableData] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [dataState, setDataState] = React.useState<any>({
    take: pageSize,
    skip: 0,
  });
  const [isPageable, setIsPageable] = useState({});
  const [excelData, setExcelData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const [actualFilterData, setActualFilterData] = useState<any>([]);
  const createDataState = (data: any, dataState: any) => {
    let pagerSettings = {
      buttonCount: 5,
      info: true,
      type: "numeric",
      pageSizes: true,
      previousNext: true,
    };
    return {
      result: data,
      dataState: dataState,
      total: totalItems,
      pageable: pagerSettings,
    };
  };

  const [stateGridColumns, setStateGridColumns] =
    React.useState<any>(gridColumns);

  const sortByName = () => {
    return "MemberName";
  };
  const [state, setState] = React.useState({
    value: {
      text: reportFilters.ThisMonth,
      date: moment.invalid(),
    },
  });
  const [isDateRange, setIsDateRange] = useState<any>({});

  let fromDate: any;
  let toDate: any;
  if (state.value.text === "This Year") { 
    const now = moment();
    fromDate = now.format("YYYY-01-01");
    toDate = now.format("YYYY-12-31");
  }
  const fromDateValue =
    Object.keys(isDateRange).length === 0 ? fromDate : isDateRange.from;
  const toDateValue =
    Object.keys(isDateRange).length === 0 ? toDate : isDateRange.to;
  const fetchTaxes = async (
    dataState: any,
    isPageNumber: boolean,
    SortDirection: string,
    SortBy: string,
    FromDate: string,
    ToDate: string,
    forExcel: boolean,
    filterArrayData: any
  ) => {
    if (!forExcel) {
      setLoading(true);
    }
    const service = new ManageTaxesService();
    if (!isPageNumber) {
      dataState.skip = 0;
    }
    const payloadReq = {
      IncludeChrunRateExcluded: true,
      StatusDescription: "",
      ReportType: "All",
      HirecharyLevel: 0,
      GridHirecharyLevel: 0,
      AuditType: "All",
      EFTPaymentType: 0,
      RecurrenceTypeDescription: "",
      IncludeFirstOccurenceOnly: true,
      IncludeNextEFT: true,
      IncludeDateRanges: true,
      SortDirection: SortDirection,
      SortBy: SortBy,
      PageSize: forExcel ? totalItems : dataState.take,
      PageNumber: dataState.skip === 0 ? 0 : dataState.skip / dataState.take,
      TotalItems: 0,
      IsExportRequest: false,
      DataModel: "",
      Logic: "",
      Field: "",
      SearchKeyword: "",
      ShowAllRecords: true,
      FromDate: FromDate,
      Filters: filterArrayData ? filterArrayData : "",
      ToDate: ToDate,
    };
    const response = await service.getTaxReport({ ...payloadReq });
    if (!forExcel) {
      // api
      const data = {
        data: response?.Item?.Result,
      }; //response.Items
      const totalrecords = response?.Item?.TotalItems; //response.TotalItems
      let initialState = createDataState(data.data, {
        take: dataState.take,
        skip: dataState.skip,
      });
      console.log(response);
      setOriginalTableData(data);
      setTableData(initialState.result);
      setDataState(initialState?.dataState);
      setIsPageable(initialState?.pageable);
      setTotalItems(totalrecords);
      setLoading(false);
    }
    if (forExcel) {
      setExcelData(response?.Item?.Result);
    }
  };

  const dataStateChange = (event: any) => {
    let updatedState = createDataState(
      originalTableData?.data,
      event.dataState
    );

    setTableData(updatedState.result);
    setDataState(updatedState?.dataState);

    let actualFilterValue = event?.dataState?.filter;

    setActualFilterData(actualFilterValue);

    var filterArrayData: any = [];
    var eventFilterData = event?.dataState?.filter
      ? event?.dataState?.filter?.filters.map((item: any) =>
          item?.filters.map(
            (fil: { field: any; operator: any; value: any }) => {
              const dataTypeValue =
                typeof fil.value === "string" ? "string" : "number";
              const numberValue = dataTypeValue === "string" ? 0 : fil.value;
              const filterOperators =
                (fil.operator === "eq" && "=") ||
                (fil.operator === "neq" && "<>") ||
                (fil.operator === "gte" && ">=") ||
                (fil.operator === "gt" && ">") ||
                (fil.operator === "lte" && "<=") ||
                (fil.operator === "lt" && "<") ||
                fil.operator;

              var data = {
                Field: fil.field,
                Operator: fil.operator,
                Logic: item.logic,
                FieldDataType: dataTypeValue,
                Condition: item.logic,
                Filter: fil.operator,
                Column: fil.field,
                DataType: dataTypeValue,
                NumberValue: numberValue,
                Value: fil.value,
                DropDownValue: "",
                DateValue: "",
                DisplayName: "",
              };

              stateGridColumns.map((el: any) => {
                stateGridColumns.map((item: any) => {
                  if (fil.field === el.field) {
                    data.DisplayName = el.title;
                  } else if (fil.field === item.field) {
                    data.DisplayName = item.title;
                  }
                });
              });
              filterArrayData.push(data);
              return data;
            }
          )
        )
      : filterData;

    for (let i = 0; i < filterArrayData.length - 1; i++) {
      if (
        filterArrayData[i].DisplayName === filterArrayData[i + 1].DisplayName
      ) {
        filterArrayData[i].Condition = "and";
        filterArrayData[i].Logic = "and";
      }
    }
    setFilterData(filterArrayData);
    fetchTaxes(
      updatedState?.dataState,
      true,
      "desc",
      sortByName(),
      fromDateValue,
      toDateValue,
      false,
      filterArrayData
    );
  };

  const onColumnsSubmit = (columnsState: any) => {
    setStateGridColumns(columnsState);
  };

  const exporterRef = useRef(null);

  const excelExport = async () => {
    await fetchTaxes(
      dataState,
      false,
      "desc",
      sortByName(),
      fromDateValue,
      toDateValue,
      true,
      filterData
    );

    save(exporterRef);
    setExcelData([]);
  };
  function save(component: any) {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;
    let altIdx = 0;
    let format = "YYYY-MM-DD";

    rows.forEach((row: any) => {
      if (row.type === "header") {
        row.cells.forEach((cell: any) => {
          cell.background = "#FFFFFF";
          cell.color = "#000000";
          cell.borderRight = "1";
        });
      }
      if (row.type === "data") {
        row.cells.forEach((cell: any) => {
          if (cell.format === undefined) {
            const value = moment(cell.value).format(format);
            cell.wrap = true;
            cell.value = value;
          }
        });

        altIdx++;
      }
    });
    component.current.save(options);
  }
  const handleChangeSort = (e: any) => {
    setSort(e.sort);
    fetchTaxes(
      dataState,
      false,
      e.sort.length !== 0 ? e.sort[0].dir : "desc",
      e.sort.length !== 0 ? e.sort[0].field : sortByName(),
      fromDateValue,
      toDateValue,
      false,
      filterData
    );
  };

  const dateValues = (props: any) => {
    setIsDateRange(props);
    fetchTaxes(
      dataState,
      false,
      "desc",
      sortByName(),
      props.from,
      props.to,
      false,
      null
    );
  };
  const clearFilterOne = (el: any, id: any) => {
    const updates = filterData.filter((item: any, idx: any) => idx !== id);
    setFilterData(updates);

    fetchTaxes(
      dataState,
      true,
      "desc",
      sortByName(),
      fromDateValue,
      toDateValue,
      false,
      updates
    );

    updates.length === 0 && setActualFilterData(undefined);
  };
  const clearAllFilter = () => {
    setFilterData([]);
    fetchTaxes(
      dataState,
      true,
      "desc",
      sortByName(),
      fromDateValue,
      toDateValue,
      false,
      null
    );
    setActualFilterData(undefined);
  };

  const reportExcelName = () => {
    return "SurchargesReport.xlsx";
  };

  const handleFullScreen = () => {
    setFullscreen(!fullScreen);
  };

  return {
    uuid,
    dateValues,
    isDateRange,
    state,
    handleFullScreen,
    fullScreen,
    excelData,
    tableData,
    stateGridColumns,
    reportExcelName,
    excelExport,
    exporterRef,
    onColumnsSubmit,
    actualFilterData,
    dataStateChange,
    loading,
    clearAllFilter,
    totalItems,
    sort,
    dataState,
    clearFilterOne,
    isPageable,
    handleChangeSort,
    filterData
  };
};

export default useTaxReport;

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import useState from 'react-usestateref';
import { PageChangeEvent } from "@progress/kendo-react-data-tools";
const useAffiliatePromotions = (props: any) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState([]);
  const [rewardTypes, setRewardTypes] = useState<any>([]);
  const [page, setPage,pageRef] = useState({
    skip: 0,
    take: 10,
  });
  const [totalItems, setTotalItems] = useState(0);
  const service = new AffiliateProgramService();
  useEffect(() => {
    fetchRewardTypes();
    getPromotions();
  }, []);
  const getPromotions = async (Skip=pageRef?.current?.skip,Take=pageRef?.current?.take) => {
    const req = {
      AffiliateId: parseInt(props?.associateId),
      PageNumber: Skip / Take, 
      PageSize: Take 
    };
    setLoading(true);
    const res = await service.getPromotions(req);
    setPromotions(res?.Items || []);
    setTotalItems(res?.TotalItems);
    setLoading(false);
  };
  const fetchRewardTypes = async () => {
    const res = await service.getRewardTypes();
    setRewardTypes(res);
  };

  const handleView = (dataItem: any) => {
    if(props?.admin){
      history.replace(
        `/app/affiliatedashboard/${props?.uuid}/${props?.associateId}/promotions/${dataItem?.PromotionId}`
      );
    }else{
      history.replace(
        `/affiliate/promotions/${dataItem?.PromotionId}`
      );
    }
  };
  const handlePageChange = async (e: PageChangeEvent) => {
    setPage({
      skip: e.skip,
      take: e.take,
    });
    getPromotions()
  };


  return {
    promotions,
    loading,
    handleView,
    rewardTypes,
    page,
    handlePageChange,
    totalItems 
  };
};

export default useAffiliatePromotions;

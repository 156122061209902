import { useEffect, useState } from "react";
import { SiteSettingService } from "services/site-setting/index.service";
import LiveStream from "./LiveStream";
import { useHistory } from "react-router-dom";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";

const ThirdPartyLiveStream = (props: any) => {
  const uuid = props.match?.params?.uuid;
  const [loading, setLoading] = useState(uuid ? true : false);
  const [securitySystemType, setSecuritySystemType] = useState(undefined);
  const [configuredEntries, setConfiguredEntries] = useState([]);
  const history = useHistory();
  const siteService = new SiteSettingService();
  const service = new ThirdPartyConfigService();

  useEffect(() => {
    if (uuid) {
      fetchUserSession(uuid);
    } else {
      fetchSecurityConfig();
    }
  }, [uuid]);

  const fetchUserSession = async (uuid: string) => {
    setLoading(true);
    await siteService.getTenantDetails(uuid);
    history.push("/admin/livestream");
    setLoading(false);
  };

  const fetchSecurityConfig = async () => {
    setLoading(true);
    const result = await service.getSecuritySystemConfigs();
    setSecuritySystemType(result.data.SecuritySystemType);
    setConfiguredEntries(
      result.data?.HardwareSecuritySystemPreferences?.OpenPathSettings
        ?.SiteConfiguration?.ConfiguredEntries
    );
    setLoading(false);
  };

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }

  return (
    <>
      <div>
        <LiveStream
          key={loading.toString()}
          {...props}
          securitySystemType={securitySystemType}
          configuredEntries={configuredEntries}
        />
      </div>
    </>
  );
};

export default ThirdPartyLiveStream;

import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  resetStaffLoggedDetails,
  setStaffLoggedDetails,
} from "redux-slices/staffCheckinsSlice";
import { CheckInService } from "services/checkin/index.service";
import { FormInput } from "utils/form-components";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllExceptTenant,
} from "utils/storage";
import { emailValidator, requiredValidator } from "validators/validator";
import utilites from "../../utils/utils";

const SelfSign = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState(true);
  const checkInService = new CheckInService();
  const unloadFunc = () => {
    props.history.replace({
      ...props.location,
      state: undefined,
    });
    const errorMsg = `You have logged out successfully `;
    props?.handleNotificationMessage(errorMsg, "success");
  };

  if (props.location?.state?.logout) {
    dispatch(resetStaffLoggedDetails());
    RemoveAllExceptTenant();
    const hostValues = window.location.host.split(".");
    if (
      hostValues.length === 3 &&
      hostValues[0] !== "www" &&
      hostValues[0] !== "ui"
    ) {
      const tenantName = hostValues[0];
      LocalStore("tenant", tenantName);
    }
  }

  useEffect(() => {
    if (!GetLocalStore("userDetails") && props.location?.state?.logout) {
      unloadFunc();
    }
    if (
      GetLocalStore("userDetails")?.UserSession?.CurrentApplicationType === 2
    ) {
      dispatch(resetStaffLoggedDetails());
      RemoveAllExceptTenant();
    } else if (GetLocalStore("userDetails")) {
      history?.push(`/kiosk/self`);
    }
  }, [GetLocalStore("userDetails")]);

  const handlePasswordDisplay = () => {
    setPasswordType(!passwordType);
  };

  const handleResetForm = (formRenderProps: any) => {
    formRenderProps.onFormReset();
    setPasswordType(true);
  };
  const handleSubmit = async (dataItem: any) => {
    setLoading(true);

    await utilites.checkTenantSession(checkInService);

    if (!GetLocalStore("tenantUserSession")) {
      const hostValues = window.location.host.split(".");
      if (
        hostValues.length === 3 &&
        hostValues[0] !== "www" &&
        hostValues[0] !== "ui"
      ) {
        const tenantName = hostValues[0];
        LocalStore("tenant", tenantName);
      }
      const authUser = await checkInService.getTenantDetails(
        GetLocalStore("tenant")
      );
    }

    const reqData = {
      EmailId: dataItem.email,
      Password: dataItem.password,
    };
    const authUser = await checkInService.authenticateStaff(reqData);
    if (authUser.successResponse) {
      if (!authUser.unAuthorized) {
        const obj = {
          CurrentApplicationType:
            authUser?.data?.Item?.UserSession?.CurrentApplicationType,
          loggedInStaffDetails: authUser?.data?.Item?.UserSession,
          isLoggedIn: true,
          ClubTimeZone: authUser?.data?.Item?.UserSession?.TimeZone?.Id,
        };
        dispatch(setStaffLoggedDetails(obj));
        history.push(`/kiosk/home`);
      } else {
        const errorMsg = `You are not authorized to this site`;
        props?.handleNotificationMessage(errorMsg, "error");
      }
    } else {
      const errorMsg =
        authUser.ErrorMessages?.[0] ||
        `Password is not correct.
      Did you forget your password?`;
      props?.handleNotificationMessage(errorMsg, "error");
    }
    setLoading(false);
  };
  return (
    <div className="self-signin pt-5 self-login-bz">
      <div className="card p-5">
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <div className="">
                <div className="bzo-checkIn-login-card bz-checkIn-emailLogin-card border-0 bz-border-radius-1">
                  <div className="bzo-login-header">
                    <h4 className="h4 mb-0 text-left">Login</h4>
                  </div>
                  <div className="bzo-login-body">
                    <p className="text-left my-1"> Enter your email address</p>
                    <div className="form-group">
                      <Field
                        id={"email"}
                        name={"email"}
                        component={FormInput}
                        validator={(val:any) => emailValidator(val,"Email")}
                        autoFocus={true}
                        hidelabel={true}
                      />
                    </div>
                  </div>
                  <div className="bzo-login-body pb-3">
                    <p className="text-left my-1"> Enter your Password</p>
                    <div className="form-group">
                      <Field
                        type={"password"}
                        id={"password"}
                        name={"password"}
                        passwordType={passwordType}
                        label={"Password"}
                        validator={requiredValidator}
                        customvalidation={true}
                        hidelabel={true}
                        component={FormInput}
                      />
                      <span
                        className="password_eye_icon"
                        onClick={() => handlePasswordDisplay()}
                        key={passwordType.toString()}
                      >
                        <i
                          className={
                            passwordType ? "fa fa-eye-slash" : "fa fa-eye"
                          }
                        ></i>
                      </span>
                    </div>
                    <div className="">
                      <ButtonGroup>
                        <BuzopsButton
                          type={"submit"}
                          label={"Sign In"}
                          loading={loading}
                          disable={loading}
                        />
                        <Button
                          type={"reset"}
                          disabled={loading}
                          onClick={() => handleResetForm(formRenderProps)}
                        >
                          {"Reset"}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default withNotification(SelfSign);

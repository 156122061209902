import CustomCalendar, { FilterOptions } from "atoms/CustomCalendar";
import useAffiliatePromotionActivity from "./AffiliatePromotionActivity.hook";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import moment from "moment";
import { MdDownload } from "react-icons/md";
import React from "react";
import { CurrencyFormat } from "utils";
import {
  PaymentExecutionMode,
  PaymentMode,
  PromotionSubscriptionTypes,
} from "utils/form-utils";
import ExcelExport from "atoms/ExcelExport/ExcelExport";
import { GetLocalStore } from "utils/storage";
const AffiliatePromotionActivityExcelColumns = [
  { field: "TransactionDate", title: "Transaction Date" },
  { field: "TransactionAmount", title: "Transaction Amount" },
  { field: "PaymentExecutionModeText", title: "Transaction Type" },
  { field: "PaymentModeText", title: "Payment Mode" },
  { field: "PromotionName", title: "Promotion Name" },
  { field: "SubscriptionType", title: "Subscription Type" },
  { field: "SubscriptionName", title: "Subscription Name" },
  { field: "CustomerName", title: "Customer Name" },
  { field: "CustomerEmail", title: "Customer Email" },
  { field: "CustomerPhone", title: "Customer Phone" },
  { field: "GeneratedPayoutAmount", title: "Generated Payout Amount" },
];
const DateCell = (props: any) => {
  return (
    <td>
      {props?.dataItem[props?.field]
        ? moment(props?.dataItem[props?.field]).format("L")
        : "NA"}
    </td>
  );
};
const AmountCell = (props: any) => {
  return <td >{CurrencyFormat(props?.dataItem[props?.field])}</td>;
};
const SubscriptionTypeCell = (props: any) => {
  const SubscriptionType = PromotionSubscriptionTypes?.find(
    (i: any) => i?.id === props.dataItem?.EntityType
  )?.text;
  return <td>{SubscriptionType}</td>;
};
const PaymentModeCell = (props: any) => {
  const PaymentModeText = PaymentMode?.find(
    (i: any) => i?.id === props.dataItem?.PaymentModeId
  )?.text;
  return <td>{PaymentModeText}</td>;
};
const PaymentExecutionModeCell = (props: any) => {
  const PaymentExecutionModeText = PaymentExecutionMode?.find(
    (i: any) => i?.id === props.dataItem?.PaymentExecutionModeId
  )?.text;
  return <td>{PaymentExecutionModeText}</td>;
};
const CustomerName = (props: any) => {
  const CustomerName = `${props?.dataItem?.CustomerFirstName} ${props?.dataItem?.CustomerLastName}`;
  return <td>{CustomerName}</td>;
};

const AffiliatePromotionActivity = (props: any) => {
  let ClubName = GetLocalStore("tenantUserSession")?.ClubName;
  if (!props?.admin) {
    ClubName = GetLocalStore("AffiliateDetails")?.UserSession?.ClubName;
  }

  const {
    setFromDateToDateFunc,
    loading,
    dataState,
    page,
    totalItems,
    pageChange,
    getExcelData,
  } = useAffiliatePromotionActivity(props);
  const _exporter = React.createRef<any>();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current?.save();
    }
  };
  return (
    <div className="d-flex w-100 justify-content-center flex-column   pb-1 manage-affiliate-container-grid payout">
      <div className="row py-2 px-3 d-flex align-items-center">
        <div className="col-md-12">
          {" "}
          <h6 className="bz_fs_1_5 affiliatemainheading mb-0">
            Promotion Activity
          </h6>{" "}
        </div>
      </div>
      <div className="d-flex justify-content-between py-1 col-md-12">
        <div className="filter-payouts d-flex">
          <div>
            <CustomCalendar
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              setFromDateToDate={setFromDateToDateFunc}
              selectedDateItem={FilterOptions.ThisMonth}
              onlyPastDates
            />
          </div>
        </div>

        <div>
          {dataState?.length > 0 ? (
            <span className="download-icon">
              <MdDownload
                onClick={excelExport}
                title="Download"
                color="#008DB9"
              />
            </span>
          ) : null}
        </div>
      </div>
      <ExcelExport
        data={getExcelData() || []}
        fileName={`${ClubName} Affiliate Promotion Activity ${moment().format(
          "MM-DD-YYYY HH:mm:ss"
        )}.xlsx`}
        _exporter={_exporter}
        columns={AffiliatePromotionActivityExcelColumns}
      />
      {loading ? (
        <BuzopsLoader type="list" />
      ) : (
        <Grid
          className={"bzc-grid payouts bz-affiliate-payout-grid-no-checkbox"}
          fixedScroll={true}
          data={dataState}
          //   dataItemKey={DATA_ITEM_KEY}
          skip={page.skip}
          take={page.take}
          total={totalItems}
          pageable={{
            info: true,
            type: "numeric",
            previousNext: true,
            pageSizes: [10, 20, 50, 100],
          }}
          onPageChange={pageChange}
        >
          <Column
            field="TransactionDate"
            title="Transaction Date"
            cell={DateCell}
          />
          <Column
            field="TransactionAmount"
            title="Transaction Amount"
            cell={AmountCell}
          />
          <Column
            field="PaymentExecutionModeId"
            title="Transaction Type"
            cell={PaymentExecutionModeCell}
          />
          <Column
            field="PaymentModeId"
            title="Payment Mode"
            cell={PaymentModeCell}
          />
          <Column field="PromotionName" title="Promotion Name" />
          <Column
            field="EntityType"
            title="Subscription Type"
            cell={SubscriptionTypeCell}
          />
          <Column field="SubscriptionName" title="Subscription Name" />
          <Column
            field="CustomerFirstName"
            title="Customer Name"
            cell={CustomerName}
          />
          <Column field="CustomerEmail" title="Customer Email" />
          <Column field="CustomerPhone" title="Customer Phone" />
          <Column
            field="GeneratedPayoutAmount"
            title="Generated Payout Amount"
            cell={AmountCell}
          />
          {/* <Column field="GeneratedPayoutAmount" title="Customer Phone" /> */}
        </Grid>
      )}
    </div>
  );
};

export default AffiliatePromotionActivity;

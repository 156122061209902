import { GridPageChangeEvent } from "@progress/kendo-react-grid";
import moment from "moment";
import React, { useEffect } from "react";
import useState from "react-usestateref";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { CurrencyFormat } from "utils";
import {
  PaymentExecutionMode,
  PaymentMode,
  PromotionSubscriptionTypes,
} from "utils/form-utils";

const useAffiliatePromotionActivity = (props: any) => {
  const [fromDateToDate, setFromDateToDate, fromDateToDateRef] = useState({
    from: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [loading, setLoading] = useState(true);
  const initialDataState: any = { skip: 0, take: 10 };
  const [page, setPage] = React.useState<any>(initialDataState);
  const [dataState, setDataState] = React.useState<any>([]);
  const [excelData, setExcelData] = React.useState<any>([]);
  const [totalItems, setTotalItems] = React.useState<number>(0);
  const service = new AffiliateProgramService();

  const setFromDateToDateFunc = (val: any) => {
    setFromDateToDate(val);
    getAffiliatePromotionActivity();
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const take = event.page.take;
    setPage({
      ...event.page,
      take,
    });
  };

  useEffect(() => {
    getAffiliatePromotionActivity();
  }, [page]);

  const getAffiliatePromotionActivity = async () => {
    let req: any = {
      PageSize: page.take,
      PageNumber: page.skip / page.take,
    };
    if (props?.associateId) {
      req = {
        ...req,
        AffiliateId: props?.associateId,
      };
    }

    if (fromDateToDateRef.current?.from && fromDateToDateRef.current?.to) {
      req = {
        ...req,
        FromDate: fromDateToDateRef.current?.from,
        ToDate: fromDateToDateRef.current?.to,
      };
    }

    setLoading(true);
    const res = await service.getPromotionActivityForAssociateId(req);
    setLoading(false);
    const records = res?.Items || [];

    setDataState(records);
    setTotalItems(res?.TotalItems);
    await getAffiliatePromotionActivityExcelData(req);
  };
  const getAffiliatePromotionActivityExcelData=async(req:any)=>{
    const payload = req
    payload.PageNumber=0;
    payload.PageSize=32767;
    const res = await service.getPromotionActivityForAssociateId(payload);
    setExcelData(res?.Items || [])
  }
  const getExcelData = () => {
    return (
      excelData?.map((row: any) => {
        const SubscriptionType = PromotionSubscriptionTypes?.find(
          (i: any) => i?.id === row?.EntityType
        )?.text;

        const PaymentModeText = PaymentMode?.find(
          (i: any) => i?.id === row?.PaymentModeId
        )?.text;
        const PaymentExecutionModeText = PaymentExecutionMode?.find(
          (i: any) => i?.id === row?.PaymentExecutionModeId
        )?.text;

        return {
          ...row,
          CustomerName: `${row?.CustomerFirstName} ${row?.CustomerLastName}`,
          SubscriptionType: SubscriptionType,
          PaymentModeText: PaymentModeText,
          PaymentExecutionModeText: PaymentExecutionModeText,
          Debit: row?.IsDebit ? "Debit" : "Credit",
          TransactionDate: row?.TransactionDate
            ? moment(row?.TransactionDate).format("L")
            : "NA",
          TransactionAmount: CurrencyFormat(row?.TransactionAmount),
          GeneratedPayoutAmount: CurrencyFormat(row?.GeneratedPayoutAmount),
        };
      }) || []
    );
  };

  return {
    setFromDateToDateFunc,
    fromDateToDate,
    loading,
    dataState,
    page,
    totalItems,
    pageChange,
    getExcelData
  };
};

export default useAffiliatePromotionActivity;

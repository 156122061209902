import * as React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { GridCellProps } from '@progress/kendo-react-grid';
import {getShortDateForList } from 'utils';

export const DateCell = (props: GridCellProps) => {
  const { ariaColumnIndex, columnIndex, dataItem, field } = props;
  const value = field && dataItem[field] ? dataItem[field] : null;
  const onChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem, 
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
  };
  const showForm = () => {
    if(dataItem.inEdit){
        return <div style={{width: "100%"}}>
        <DatePicker
          className='grid-editors'
          name={field}
          defaultValue={new Date(value)}
          format="MM-dd-yyyy"
          onChange={onChange}
        />
      </div>
    }else if(value) {
        return getShortDateForList(value); 
    }else {
        return null;
    }
  }
  return (
    <td
      style={{ textAlign: 'left' }}
      aria-colindex={ariaColumnIndex}
      data-grid-col-index={columnIndex}
    >
        {showForm()}
    </td>
  );
};
import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { CurrencyFormat, PaymentMadeFor } from "utils";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { TenantService } from "services/tenant/index.service";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { FaExclamationTriangle } from "react-icons/fa";

const ViewDetails = (props: any) => {
  const [HistoryTrans, setHistorytrans] = useState<any>(null);
  const [RelatedTrans, setRelatedtrans] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isTaxEnabled,setIsTaxEnabled]=useState(false)

  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await getData();
    setLoading(false);
  };

  async function getData() {
    let Maintransactionid =
      props?.viewdata.ParentPaymentGatewayTransactionHistoryId;
    if (Maintransactionid == 0) {
      Maintransactionid = props?.viewdata.PaymentGatewayTransactionHistoryId;
    }
    const transactionid = props?.viewdata.PaymentGatewayTransactionHistoryId;
    const result1 = await service.ParentPaymentTransactionHistoryId(
      transactionid
    );
    const metaDataInfo = result1?.filter((i: any) => i?.MetaData)
    if(metaDataInfo?.length > 0){
      setIsTaxEnabled(true)
    }
    setHistorytrans(result1 || []);


    const condition = {
      PaymentTransactionHistoryId: Maintransactionid, //props?.viewdata.ParentPaymentGatewayTransactionHistoryId
      UserMemberId: props?.UserMemberId,
    };
    const req = {
      FilterFields: [],
      PageNumber: 0,
      PageSize: 100,
      SortBy: "TransactionDate",
      SortDirection: "desc",
      Where: condition,
    };

    const result2 = await service.GetRelatedPaymentGatewayTransactionHistory(
      req
    );
    setRelatedtrans(result2 || []);
  }

  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }

  const {
    FullName,
    TransactionId,
    TransactionDate,
    TransactionStatus,
    ServiceType,
    AmountPaid,
    CardDescription,
    PaymentMadeForDescription,
    PaymentExeModeDescription,
    InternalProcessingStatusDescription,
    Notes,
    PaymentProfileStatus
  } = props?.viewdata;

  const TransactionIdcell = (propsData: any) => {
    return propsData.dataItem.TransactionId === props.viewdata?.TransactionId ? (
      <td style={{ fontWeight: 600 }}>{propsData.dataItem.TransactionId}</td>
    ) : (
      <td>{propsData.dataItem.TransactionId}</td>
    );
  };

  const PaymentExeModeDescriptioncell = (propsData: any) => {
    return propsData.dataItem.TransactionId === props.viewdata?.TransactionId ? (
      <td style={{ fontWeight: 600 }}>
        {propsData.dataItem.PaymentExeModeDescription}
      </td>
    ) : (
      <td>{propsData.dataItem.PaymentExeModeDescription}</td>
    );
  };

  const amountCell = (propsData: any) => {
    return propsData.dataItem.TransactionId === props.viewdata?.TransactionId ? (
      <td className={"capitalize"} style={{ fontWeight: 600 }}>
        {CurrencyFormat(propsData.dataItem.AmountPaid.toFixed(2))}
      </td>
    ) : (
      <td className={"capitalize"}>
        {CurrencyFormat(propsData.dataItem.AmountPaid.toFixed(2))}
      </td>
    );
  };

  const TransactionStatuscell = (propsData: any) => {
    return propsData.dataItem.TransactionId === props.viewdata?.TransactionId ? (
      <td style={{ fontWeight: 600 }}>{propsData.dataItem.TransactionStatus}</td>
    ) : (
      <td>{propsData.dataItem.TransactionStatus}</td>
    );
  };

  const TransactionDatecell = (propsData: any) => {
    return propsData.dataItem.TransactionId === props.viewdata?.TransactionId ? (
      <td style={{ fontWeight: 600 }}>{propsData.dataItem.TransactionDate}</td>
    ) : (
      <td>{propsData.dataItem.TransactionDate}</td>
    );
  };

  const PaymentModeDescriptioncell = (propsData: any) => {
    return propsData.dataItem.TransactionId === props.viewdata?.TransactionId ? (
      <td style={{ fontWeight: 600 }}>
        {propsData.dataItem.PaymentModeDescription}
      </td>
    ) : (
      <td>{propsData.dataItem.PaymentModeDescription}</td>
    );
  };

  return (
    <>
      <div>
        <h5 className="mb-3 bz_fs_1_5">
          <Button
            className="btn btn-primary bz-back-btn btn-sm back-arrow-btn ml-0"
            onClick={() => props?.handleCloseViewDetail()}
          >
            <i className="fa fa-arrow-left"></i>
          </Button>
          &nbsp;Detailed Transaction View
        </h5>
      </div>
      <hr />
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation general-info-blk">
        <div className="left-section">
          <h5 className="mb-3 pt-3 bz_fs_1_5">General Info</h5>
          <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Full Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{FullName}</span>
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Transaction Id</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{TransactionId}</span>
              </div>
            </div>
            <div className="bz-content-text-row  show-tax-content-row">
              <div className="bz-content-title text-right">
                <div className="d-flex flex-column justify-content-end">
                  <span>Amount Paid {isTaxEnabled && <span>*</span>}</span>
                  {isTaxEnabled && <small style={{fontSize:'10px',color:'#777'}}>(Inclusion of Surcharges)</small>}
                </div>
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{CurrencyFormat(AmountPaid)}</span>
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Transaction Date{" "}
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{TransactionDate}</span>
              </div>
            </div>
      
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Transaction Status
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{TransactionStatus}</span>
              </div>
            </div>

            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Services Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{ServiceType}</span>
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Payment Method</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{CardDescription ? <div className="d-flex align-items-center" >{CardDescription} {PaymentProfileStatus ==="ACL" ? <BuzopsTooltip
            html={
              <span className="pl-1" title="The account has been closed, contact card holder for new card">
                <FaExclamationTriangle  style={{ color: 'red', marginTop: '-5px' }}/>
              </span>
            }
          />:null}</div> : props?.viewdata?.PaymentMadeFor===PaymentMadeFor["Package"] && AmountPaid ===0 ? "N/A since it is $0 package":"N/A"}</span>
              </div>
            </div>

            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Payment Made For
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{PaymentMadeForDescription}</span>
              </div>
            </div>

            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Payment Mode</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{PaymentExeModeDescription}</span>
              </div>
            </div>

            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Processing Status
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{InternalProcessingStatusDescription}</span>
              </div>
            </div>

            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Notes
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <span>{Notes || "N/A"}</span>
              </div>
            </div>
          </div>
          <hr />
          <h5 className="pt-3 bz_fs_1_5">Transaction Details</h5>
          <div className="bz-mb-2_5rem">
            {HistoryTrans.length > 0 ? (
              <>
                <Grid
                  data={HistoryTrans}
                  className="bzc-grid bz-payment-info-grid"
                >
                  <Column field="Description" title="Description"  />
                  <Column
                    field="AmountPaid"
                    title="Amount"
                    cell={amountCell}
                  />
                </Grid>
              </>
            ) : (
              <div className={"bz-no-upcoming-appoinments text-muted"}>
                No Transactions{" "}
              </div>
            )}
          </div>

          <h5 className="bz_fs_1_5">Related Transactions</h5>
          <div className="bz-mb-2_5rem">
            {RelatedTrans.length > 0 ? (
              <>
                <Grid
                  data={RelatedTrans}
                  className="bzc-grid bz-related-transaction-grid"
                >
                  <Column
                    field="TransactionId"
                    title="Transaction Id "
                    cell={TransactionIdcell}
                    width={130}
                  />
                  <Column
                    field="PaymentExeModeDescription"
                    title="Notes"
                    cell={PaymentExeModeDescriptioncell}
                    width={110}
                  />
                  <Column
                    field="AmountPaid"
                    title="Amount"
                    cell={amountCell}
                    width={100}
                  />
                  <Column
                    field="TransactionDate"
                    title="Transaction Date"
                    cell={TransactionDatecell}
                    width={180}
                  />
                  <Column
                    field="TransactionStatus"
                    title="Transaction Status"
                    cell={TransactionStatuscell}
                    width={170}
                  />
                  <Column
                    field="PaymentModeDescription"
                    title="Mode"
                    cell={PaymentModeDescriptioncell}
                    width={130}
                  />
                </Grid>
              </>
            ) : (
              <div className={"bz-no-upcoming-appoinments text-muted"}>
                No Transactions{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetails;

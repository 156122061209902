import React, { useEffect, useState } from "react";
import { importTabs } from "./excelDataTabs";
import { MigrationService } from "services/import/index.service";
import { ImportContext, MigrationFormType } from "./importContext";
import {
  GridCellProps,
  GridItemChangeEvent,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { useHistory } from "react-router-dom";
import { emailValidator } from "validators/validator";
import { MemberTypeDropdown } from "./gridEditor/memberTypeDropdown";
import { SexDropDown } from "./gridEditor/sexDropdown";
import { StatesListDropdown } from "./gridEditor/statesListDropdown";
import { MyCommandCell } from "./myCommandCell";
import { MigrationStatus, editField } from "./tabData";
import { PrimaryMemberDropdown } from "./gridEditor/primaryMemberDropdown";
import { DateCell } from "./gridEditor/dateCel";
import ReactTooltip from 'react-tooltip';

export function flatten(arr: any) {
  var flat: any = [];
  for (var i = 0; i < arr.length; i++) {
      flat = flat.concat(arr[i]);
  }
  return flat;
}
const useFetchImportedData = (type: importTabs, props: any) => {
  const { excelData, formType, setFormType, sortOrder, filters, setTabDataCount, setFilters } =
    React.useContext(ImportContext);
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState<any>({
    data: [],
    total: 0,
  });
  const columns = [
    {
      field: "MemberNumber",
      title: "Client Number",
      width: 165,
    },
    {
      field: "FirstName",
      title: "First Name",
      width: 150,
    },
    {
      field: "LastName",
      title: "Last Name",
      width: 150,
    },
    {
      field: "Email",
      title: "Email",
      width: 250,
    },
    {
      field: "MemberType",
      title: "Client Type",
      cell: MemberTypeDropdown,
      width: 150,
    },
    {
      field: "PrimaryMember",
      title: "Primary Client",
      cell: PrimaryMemberDropdown,
      width: 170,
    },
    {
      field: "Sex",
      title: "Sex",
      cell: SexDropDown,
      width: 100,
    },
    {
      field: "DateOfBirth",
      title: "Date Of Birth",
      cell: DateCell,
      width: 150,
    },
    {
      field: "PhoneNumber",
      title: "Phone No",
      width: 150,
    },
    {
      field: "Address1",
      title: "Address 1",
      width: 150,
    },
    {
      field: "Address2",
      title: "Address 2",
      width: 150,
    },
    {
      field: "City",
      title: "City",
      width: 150,
    },
    {
      field: "StateId",
      title: "State",
      cell: StatesListDropdown,
      width: 150,
    },
    {
      field: "ZipCode",
      title: "Zip Code",
      width: 150,
    },
    {
      field: "UserMemberId",
      title: "Action",
      cell: CommandCell,
      width: 120,
      locked: true,
    }
  ]
  const [originalData, setOriginalData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchApiCalls();
  }, [type, filters]);
  useEffect(() => {
    switch (formType) {
      case MigrationFormType.SAVE:
        saveImportedData();
        break;
      case MigrationFormType.SUBMIT:
        submitImportedData();
        break;
      default:
        break;
    }
  }, [formType]);
  const migrationService = new MigrationService();
  const prepareSaveDraftPayload = () => {
    return {
      RequestId: excelData.data.RequestId,
      Clients: data.data,
    };
  };
  const saveImportedData = async () => {
    const resp = await migrationService.saveMigrationDraft(
      prepareSaveDraftPayload()
    );
    if (resp) {
      props?.handleNotificationMessage("Draft Saved Successfully", "success");
      fetchApiCalls(data?.pageNumber);
    } else {
      props?.handleNotificationMessage("Unable to save Draft", "error");
    }
    setFormType(MigrationFormType.NONE);
  };
  function loadIconForStatus(props:GridCellProps) {
    switch (props?.dataItem?.Status) {
      case MigrationStatus.ValidationError:
        return <><span className="icon-error" data-for={`client-validation-tooltip-${props?.dataItem?.UserMemberId}`}
        data-tip={props?.dataItem?.ErrorDescription} key={`1-${props?.dataItem?.UserMemberId}`}>
          <i className="fas fa-exclamation-circle"></i>
        </span>
        <ReactTooltip id={`client-validation-tooltip-${props?.dataItem?.UserMemberId}`} className='bz-custom-migration-tooltip' place="left" type="dark" effect="float" html={true}/>
        </>
      case MigrationStatus.Completed:
        return <><span className={`icon-completed`}   data-for={`client-validation-tooltip-1-${props?.dataItem?.UserMemberId}`}
        data-tip={"Completed"} key={`2-${props?.dataItem?.UserMemberId}`}>
          <i className="fa fa-check-circle"></i>
        </span>
        <ReactTooltip id={`client-validation-tooltip-1-${props?.dataItem?.UserMemberId}`} place="left" type="dark" effect="float" html={true}/>
        </>
      case MigrationStatus.Failed:
        return <><span className={`icon-failed`} data-for={`client-validation-tooltip-2-${props?.dataItem?.UserMemberId}`}
        data-tip={props?.dataItem?.ErrorDescription} key={`3-${props?.dataItem?.UserMemberId}`}>
          <i className="fas fa-exclamation-circle"></i>
        </span>
        <ReactTooltip id={`client-validation-tooltip-2-${props?.dataItem?.UserMemberId}`} place="left" type="dark" effect="float" html={true}/>
        </>
      // case MigrationStatus.None:
      //   return <><span className={`icon-failed`} data-for={`client-validation-tooltip-2-${props?.dataItem?.UserMemberId}`}
      //   data-tip={props?.dataItem?.ErrorDescription} key={`3-${props?.dataItem?.UserMemberId}`}>
      //     <i className="fas fa-exclamation-circle"></i>
      //   </span>
      //   <ReactTooltip id={`client-validation-tooltip-2-${props?.dataItem?.UserMemberId}`} place="left" type="dark" effect="float" html={true}/>
      //   </>
      default:
        return <span>
        </span>
    }
  }
  function StatusCell(props: GridCellProps){
    return <td  style={{...props.style, background: 'white', color: "black"}} className={props.className} // this adds classes needed for locked columns
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}>
      {
        loadIconForStatus(props)
      }
    </td>
  }
  function CommandCell(props: GridCellProps){
        return <MyCommandCell
          {...props}
          edit={enterEdit}
          remove={remove}
          discard={discard}
          update={update}
          cancel={cancel}
          editField={editField}
          ActionTooltip={loadIconForStatus}
        />
   
  };
  const submitImportedData = async () => {
    const respSaved = await migrationService.saveMigrationDraft(
      prepareSaveDraftPayload()
    );
    if (respSaved) {
      props?.handleNotificationMessage("Draft Saved Successfully. Now Submitting data", "success");

    } else {
      props?.handleNotificationMessage("Unable to save Draft", "error");
    }
    const resp = await migrationService.submitMigration(
      prepareSaveDraftPayload()
    );
    if (resp) {
      props?.handleNotificationMessage(
        "Migration Data Submitted Successfully",
        "success"
      );
      history.push(`/app/migrationHistory/${props?.match?.params?.uuid}/true`);
    } else {
      props?.handleNotificationMessage("Unable to submit Data", "error");
    }
    setFormType(MigrationFormType.NONE);
  };
  const fetchApiCalls = (pageNumber = 0) => {
    switch (type) {
      case importTabs.CLIENTINFO:
        fetchClientData(pageNumber);
        break;
      default:
        break;
    }
  };
  
const downloadFile = async() => {
    setFilters((prev: any) => {
      return {...prev, download: false}
    })
}
  const fetchClientData = async (pageNumber: number = 0) => {
    if(filters?.download){
      downloadFile();
      return;
    }
    setPageLoading(true);
    let migrationStatus: any = [];

    if(filters?.statusFilter?.length > 0){
      filters?.statusFilter.map((val: any) => migrationStatus.push(val?.id))
    }
    const resp = await migrationService.getClientInfoForExcel(
      excelData.data.RequestId,
      pageNumber,
      10,
      sortOrder,
      {
        MigrationStatus: flatten(migrationStatus),
        SearchKeyword: filters?.search,
        ShowAllRecords: filters?.showAllRecords
      }
    );
    setData({
      data: sortOrder ? resp.Item.Result.sort((a: any,b: any) => a.Email.localeCompare(b.Email)) : resp.Item.Result,
      total: resp.Item.TotalItems,
      pageNumber: pageNumber,
    });
    setTabDataCount(resp.Item.TotalItems || 0)
    setOriginalData(resp.Item.Result);
    setPageLoading(false);
  };
  const handlePagination = async (pageNumber: number, pageSize: number) => {
    if (data.data.every((val: any) => !val.ErrorDescription) || data.pageNumber > pageNumber) {
      await fetchClientData(pageNumber);
      return true;
    } else {
      props?.handleNotificationMessage(
        "Please fix errors in current page.",
        "error"
      );
      return false;
    }
  };
  const rowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) => {
    const ErrorDescription = props.dataItem.ErrorDescription;
    const RowUpdated = props.dataItem?.Updated;
    const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };
    const updated = { backgroundColor: "#ADD8E6" };
    const trProps: any = { style: RowUpdated ? updated : ErrorDescription ? red : {} };
    return (
      <tr {...trProps} >
        {trElement.props.children}
      </tr>
    );
  };
  const ID = "UserMemberId";
  const enterEdit = (dataItem: any) => {
    setData({
      ...data,
      data: data.data.map((item: any) =>
        item[ID] === dataItem[ID] ? { ...item, inEdit: true } : item
      ),
    });
  };
  const updateItem = (item: any) => {
    let index = data.data.findIndex((record: any) => record[ID] === item[ID]);
    data[index] = item;
    return data;
  };

  const deleteItem = (item: any) => {
    let index = data.data.findIndex((record: any) => record[ID] === item[ID]);
    data.splice(index, 1);
    return data;
  };
  // modify the data in the store, db etc
  const remove = async (dataItem: any) => {
    const deleteResp = await migrationService.deleteUserMemberFromMigration(
      dataItem.UserMemberId
    );
    if (deleteResp) {
      props?.handleNotificationMessage(
        "User Member Removed Successfully",
        "success"
      );
      fetchClientData(data.pageNumber);
    } else {
      props?.handleNotificationMessage("Unable to Remove", "error");
    }
  };
  const validateDataItem = (dataItem: any) => {
    let returnObj: any = {};
    if (!dataItem?.FirstName) {
      returnObj.FirstName = "First Name is required";
    }
    if (!dataItem?.LastName) {
      returnObj.LastName = "Last Name is required";
    }
    if (!dataItem?.Email) {
      returnObj.Email = "Email is required";
    } else {
      if (emailValidator(dataItem?.Email)) {
        returnObj.Email = "Email format is wrong";
      }
    }
    if (Object.keys(returnObj)?.length > 0) {
      return returnObj;
    }
    return null;
  };
  const update = (dataItem: any) => {
    const validation = validateDataItem(dataItem);
    if (validation) {
      props?.handleNotificationMessage(Object.values(validation)[0], "error");
      dataItem.clientValidations = validation;
    } else {
      dataItem.inEdit = false;
      dataItem.Updated = true;
    }

    const newData = updateItem(dataItem);
    setData({
      ...data,
      newData,
    });
  };

  // Local state operations
  const discard = () => {
    const newData = [...data];
    newData.splice(0, 1);
    setData({
      ...data,
      newData,
    });
  };

  const cancel = (dataItem: any) => {
    const originalItem: any = originalData.find((p) => p[ID] === dataItem[ID]);
    const newData = data.data.map((item: any) =>
      item[ID] === originalItem[ID] ? originalItem : item
    );

    setData({
      ...data,
      data: newData,
    });
  };
  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || "";
    const newData = data.data.map((item: any) =>
      item[ID] === event.dataItem[ID] ? { ...item, [field]: event.value } : item
    );

    setData({
      ...data,
      data: newData,
    });
  };
  return {
    data,
    fetchClientData,
    pageLoading,
    dataLoading,
    handlePagination,
    rowRender,
    enterEdit,
    cancel,
    discard,
    update,
    remove,
    itemChange,
    columns,
    sortOrder,
    filters
  };
};

export default useFetchImportedData;

import { useLocation } from "react-router-dom";
import { getFullImageUrl } from "utils";
import { AffiliateDashboardLinks, UserAffiliateStatus, UserAffiliateStatusEnum } from "utils/form-utils";
const DummyImage = "/images/user.png";

const AffiliateProfileHeader = ({
  affiliateDetails,
  handleMenuSelection,
}: any) => {
  const location = useLocation();
  // console.log(location?.pathname,'location')
  let checkMyprofileRouteInUrl = false
  if (location?.pathname?.includes('myprofile')) {
    checkMyprofileRouteInUrl = true
  }
  const StatusDescription = UserAffiliateStatus?.filter(
    (i: any) => i?.id === affiliateDetails?.Status
  )[0]?.text;
  return (
    <div className="row px-4 py-3 pb-2" style={{ borderBottom: '1px solid #1887a9' }}>
      <div className="col-md-7 col-xl-8 col-12">
        <div className="bz-summary-profile" style={{ padding: '0' }}>
          <div className={"bz-row align-items-sm-center w-100"}>
            <div className={"user-pic text-center "}>
              <img
                width={38}
                height={38}
                style={{ borderRadius: "50%" }}
                src={
                  affiliateDetails?.Photo
                    ? getFullImageUrl(affiliateDetails?.Photo)
                    : DummyImage
                }
                onError={(event: any) => {
                  event.target.src = DummyImage
                }}
                alt="UserImg"
              />
            </div>
            <div className={"user-details pl-0"}>
              <span key={1} className={""}>
                <div className="username-family-member">
                  <h6 className="text-white">{`${affiliateDetails?.FirstName} ${affiliateDetails?.LastName}`}<span className={affiliateDetails?.Status === UserAffiliateStatusEnum.InActive ? "badge badge-status-InActive" : 'badge badge-success'}>
                    {StatusDescription}
                  </span></h6>
                </div>
                <span className="contact mr-2 text-white">
                  <i className="fas fa-envelope"></i>
                  {affiliateDetails?.Email}
                </span>
                <span className="contact text-white">
                  <i className="fas fa-phone-volume"></i>
                  <span className="">{affiliateDetails?.Phone}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 pl-0 col-md-5 col-xl-4 text-md-right ">
        <div>
          <span className={`edit-btn-container`}>
            {!checkMyprofileRouteInUrl ? <button
              className="editprofilebtn"
              onClick={() =>
                handleMenuSelection({
                  to: AffiliateDashboardLinks.MYPROFILE,
                })
              }
            >
              <em className="mdi mdi-account-edit"></em> Edit Profile
            </button> : null}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AffiliateProfileHeader;

import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import { ImportContext } from '../importContext';

export const PrimaryMemberDropdown = (props: GridCellProps) => {
    const {PrimaryMemberDetails} = React.useContext(ImportContext);


    const handleChange = (e: DropDownListChangeEvent) => {
        if(props.onChange){
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.UserMemberId
            });
        }
    }

    const { dataItem } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
          className='grid-editors'
          disabled={props.dataItem['MemberType'] == "0"}
            onChange={handleChange}
            value={PrimaryMemberDetails.find(c => c.UserMemberId === dataValue)}
            data={PrimaryMemberDetails}
            textField="Email"
                />
            ) : (
                PrimaryMemberDetails.find(c => c.UserMemberId === dataValue)?.Email
                )}
      </td>
    );
}
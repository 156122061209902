import * as React from 'react'
import { MigrationService } from 'services/import/index.service';
import { TenantService } from 'services/tenant/index.service';

export const ImportContext = React.createContext<any | null>(null);
export enum MigrationFormType {
  NONE = "",
  SAVE = "Save",
  SUBMIT = "Submit"
}
function ImportProvider({children}: any) {
    const [excelData, setExcelData] = React.useState<any>({
      data: {},
      excelImported: false
    })
    const [statesList, setStatesList] = React.useState<any>([]);
    const [PrimaryMemberDetails, setPrimaryMemberDetails] = React.useState<any>([]);
    const [filters, setFilters] = React.useState<any>({
      statusFilter: [],
      search: "",
      download: false,
      showAllRecords: false,
    });
    const [formType, setFormType] = React.useState(MigrationFormType.NONE);
    const [tabDataCount, setTabDataCount] = React.useState(0);
    React.useEffect(() => {
      fetchStates();
    }, []);
    React.useEffect(() => {
      if(excelData.excelImported){
        fetchPrimaryMemberData();
      }
    }, [excelData])
    const excelUploaded = (data: any) => {
      setExcelData({
        data: data,
        excelImported: true
      });
    }
    const excelRemoved = () => {
      setExcelData({
        data: {},
        excelImported: false
      });
    }
    const fetchStates = async () => {
      const service = new TenantService();
      const req = {};
      const res = await service?.getStates(req);
      setStatesList(res || []);
    };

    const fetchPrimaryMemberData = async () => {
      const service = new MigrationService();
      const req = {
        MigrationRequestId: excelData.data?.RequestId
      }
      const res = await service?.getPrimaryMemberDetailsForRequest(req);
      setPrimaryMemberDetails(res?.Item?.Result || []);
    };
    // NOTE: you *might* need to memoize this value
    // Learn more in http://kcd.im/optimize-context
    // const value = {state, dispatch}
    return <ImportContext.Provider value={{excelData, excelUploaded, excelRemoved, statesList, formType, setFormType, filters, setFilters, PrimaryMemberDetails, tabDataCount, setTabDataCount}}>{children}</ImportContext.Provider>
}
export default ImportProvider
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserConfiguration } from "redux-slices/userSlice";
import { RemoveLocalStore } from "utils/storage";

const AffiliateSSO = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleRedirectToLogin = () => {
    history.push("/");
  };
  const handleRedirectToAffiliateLogin = () => {
    history.push("/affiliatelogin");
  };

  useEffect(() => {
    dispatch(
      setUserConfiguration({
        loggedIn: false,
        affiliateDetails: null,
      })
    );
    RemoveLocalStore("AffiliateDetails");
  }, []);

  return (
    <div className="">
      <div id="bz-notfound">
        <div className="bz-notfound">
          <div className="bz-notfound-404"></div>
          <h1>404</h1>
          <h2 style={{ textTransform: "none", fontSize: "1.2rem" }}>
            {"Looks like SSO is not enabled for Affiliates"} <a className="btn btn-link" onClick={() => handleRedirectToLogin()} style={{ textTransform: "none", fontSize: "0.875rem",color: "#008db9" }}>
            Click here to login page
            </a>
          </h2>
          {/* <div>
            <BuzopsButton
              style={{ textTransform: "none" }}
              label={"Click here to login page"}
              onClick={() => handleRedirectToLogin()}
            />
          </div> */}
          <p><b>Note:</b> If you reached this page after confirming your email update. Please follow this link to <a  className="btn btn-link" style={{ textTransform: "none", color: "#008db9" }} onClick={() => handleRedirectToAffiliateLogin()}>login as an Affiliate</a></p>
        </div>
      </div>
    </div>
  );
};

export default AffiliateSSO;

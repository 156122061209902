import moment from "moment";
import { useEffect, useState } from "react";
import { CheckInService } from "services/checkin/index.service";
import { ClassService } from "services/class/index.service";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import useCheckinUser from "./CheckinUser.hook";
import useSecuritySystemConfigs from "./SecuritysystemConfigs.hook";

const useCheckinFromLiveStream = (props: any) => {
  const checkinService = new CheckInService();
  const {
    isOpenPathSystem,
    isBarcodeSystem
  } = useSecuritySystemConfigs();
  const {
    checkIfUserCanCheckinForBarcode,
    checkIfUserCanCheckinForOpenPath
  } = useCheckinUser(props)
  const [showDialog, setshowDialog] = useState(false);
  const [checkInClient, setCheckInClient] = useState(null);
  const [clientSearchLoading, setClientSearchLoading] = useState(false);
  const [clientsList, setClientsList] = useState<any>([]);
  const clientservice = new ClassService();
  const [selectedClient, setSelectedClient] = useState<any>({
    type: "search",
    value: "",
  });
  const [search, setSearch] = useState("");

  const handleDialogClose = () => {
    setshowDialog(false);
  };

  async function autoCompleteClients(str: string) {
    const payload = {
      Where: {
        SearchKeyword: str,
        IsGloablSearch: true,
      },
    };
    setClientSearchLoading(true);
    const result = await clientservice.autoCompleteClients(payload);
    setClientSearchLoading(false);
    setClientsList(result);
  }

  const handleClientSelecton = (e: any) => {
    if (e.syntheticEvent?.key === "Enter") {
      if (clientsList?.length > 0) {
        setSelectedClient({
          ...selectedClient,
          type: "select",
          value: clientsList[0],
        });
        setClientsList([]);
        handleClearClient();
        setCheckInClient(clientsList[0]);
      }
    } else {
      setSelectedClient({
        ...selectedClient,
        type: "search",
        value: e.value,
      });
    }
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      setSearch(e.value);
    }
    if (e.syntheticEvent?.type === "click") {
      handleClearClient();
      setCheckInClient(e.value);
    }
  };

  const handleClearClient = () => {
    setSelectedClient({
      ...selectedClient,
      type: "search",
      value: "",
    });
  };
  const checkIfUserCanCheckin = (accessCardDetails: any) => {
    if(isOpenPathSystem()) {
      return checkIfUserCanCheckinForOpenPath(accessCardDetails);
    }
    if(isBarcodeSystem()) {
      return checkIfUserCanCheckinForBarcode(accessCardDetails);
    }
    let doCheckin = false;
    return doCheckin;
  };
  const handleLocationCheckin = async (e: any, dataItem: any) => {
    const simpleAccessService = new SimpleAccessService();
    const req = {
      UserMemberId: dataItem?.UserMemberId,
      PrimaryUserMemberId: dataItem?.MainMemberId,
      IncludeSubMember: false,
    };
    const response = await simpleAccessService.getMembersWithAccess(req);
    let doCheckin = true;
    if (!response.length) {
      props?.handleNotificationMessage(
        "Access card is not assigned to the selected user",
        "error"
      );
      doCheckin = false;
    } else {
      doCheckin = checkIfUserCanCheckin(response[0].AccessCard);
    }
    if (!doCheckin) {
      return;
    }
    const payload: any = {
      Ocurred: `${moment().format("YYYY-MM-DDTHH:mm:ss")}`,
      Type: 0, //0-Location, 2-GroupClassAppointment, 3-OneOnOneAppointment
      CheckedInUserId: dataItem.UserId, //<UserId>
      CheckinMode: 1, //0-AccessControlBrivo, 1-ByStaff, 2-UserFromKiosk, 3-OnlineByUser(future)
    };
    const result = await checkinService?.saveCheckins(payload);
    if (result?.ResponseCode === 300) {
      props?.handleNotificationMessage(
        "Check-In successfully completed",
        "success"
      );
    } else {
      props?.handleNotificationMessage(
        result?.ErrorMessages?.[0] || "Check-In unsuccessfull",
        "error"
      );
    }
  };

  const handleCheckInPopup = (e: any, dataItem: any) => {
    e.preventDefault();
    handleClearClient();
    setCheckInClient(dataItem);
    setshowDialog(true);
  };

  useEffect(() => {
    let debouncer = setTimeout(() => {
      search && autoCompleteClients(search);
    }, 1000);
    return () => {
      clearTimeout(debouncer);
    };
  }, [search]);

  return {
    handleDialogClose,
    showDialog,
    handleCheckInPopup,
    handleClientSelecton,
    checkInClient,
    clientSearchLoading,
    clientsList,
    selectedClient,
    handleLocationCheckin,
  };
};

export default useCheckinFromLiveStream;

import { useState, useEffect } from "react";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { SiteSettingService } from "services/site-setting/index.service";
import { ISecuritySystemConfiguration } from "../Utilities/interfaces";
import { HardwareProviderType, SecuritySystemType } from "utils/constants";

const useCheckinSystem = (props: any) => {
  const [selectedSecType, setSelectedSecType] = useState<
    SecuritySystemType | undefined
  >(undefined);
  const [securitySystemConfigs, setSecuritySystemConfig] =
    useState<ISecuritySystemConfiguration>();
  const [Loading, setLoading] = useState<boolean>(false);
  const service = new ThirdPartyConfigService();
  const siteService = new SiteSettingService();
  const [domain, setDomain] = useState("");
  const [copiedKioskUrl, setCopiedKioskUrl] = useState(false);
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async (loadUserSession = true) => {
    setLoading(true);
    let getResult;
    if (loadUserSession) {
      const userSession = await siteService.getTenantDetails(
        props.match?.params?.uuid
      );
      getResult = await service.getSecuritySystemConfigs();
      if (userSession?.Item) {
        if (
          userSession?.Item?.UserSession.UserClubId === 1 &&
          userSession?.Item?.UserSession.CurrentApplicationType === 1 &&
          userSession?.Item?.UserSession.ClubId !== 1 &&
          getResult?.data?.SecuritySystemType ===
            SecuritySystemType.ProximityReaderSystem
        ) {
          if (
            getResult?.data?.HardwareSecuritySystemPreferences?.ProviderType ===
            HardwareProviderType.Brivo
          ) {
            const data = { Type: "SecuritySystem" };
            window.parent.postMessage(data, "*");
            return true;
          } else {
            setIsSystemAdmin(true);
          }
        } else {
          setIsSystemAdmin(false);
        }
        setDomain(userSession.Item?.DomainName);
      }
    } else {
      getResult = await service.getSecuritySystemConfigs();
    }

    if (getResult?.success) {
      setSecuritySystemConfig(getResult?.data);
    } else {
      if (getResult.msg) {
        props?.handleNotificationMessage(getResult.msg, "error");
      }
    }
    setLoading(false);
  };

  const onFormSubmit = (saveResponse: any) => {
    props?.handleNotificationMessage(
      selectedSecType?.toString() === "1"
        ? "Hardware configuration requested"
        : "Simple configuration settings updated successfully",
      "success"
    );
    if (saveResponse?.success) {
      fetchServices(false);
      setSelectedSecType(undefined);
    }
  };

  const infoToUser = (returnBoolean = false) => {
    switch (securitySystemConfigs?.SecuritySystemType) {
      case 1:
        if (securitySystemConfigs?.HardwareSecuritySystemSetupStatus === 0) {
          if (returnBoolean) {
            return false;
          }
          return `Proximity Reader System is pending with Admin`;
        } else if (
          securitySystemConfigs?.HardwareSecuritySystemSetupStatus === 1
        ) {
          if (returnBoolean) {
            return true;
          }
          return `(Configured)`;
        } else {
          if (returnBoolean) {
            return false;
          }
          return ``;
        }
      case 2:
        if (returnBoolean) {
          return true;
        }
        return `(Configured)`;
      default:
        if (returnBoolean) {
          return false;
        }
        return ``;
    }
  };

  const showStatusMessage = () => {
    switch (securitySystemConfigs?.SecuritySystemType) {
      case 1:
        //hardware security
        if (infoToUser(true)) {
          return (
            <span>
              <span style={{ color: "#008db9" }}>{infoToUser()}</span>
            </span>
          );
        }
        break;
      case 2:
        //simple security
        if (infoToUser(true)) {
          return (
            <span>
              <span style={{ color: "#008db9" }}>{infoToUser()}</span>
            </span>
          );
        }
        break;
      default:
        break;
    }
  };

  const copyToClipBoard = () => {
    try {
      window.navigator.clipboard.writeText(
        `https://${domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`
      );
    } catch (error) {
      console.log(error);
    }
    setCopiedKioskUrl(true);
  };

  return {
    Loading,
    securitySystemConfigs,
    showStatusMessage,
    setSelectedSecType,
    copyToClipBoard,
    copiedKioskUrl,
    selectedSecType,
    domain,
    isSystemAdmin,
    setSecuritySystemConfig,
    onFormSubmit,
  };
};

export default useCheckinSystem;

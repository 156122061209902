import CheckinUser from "./CheckinUser";
import withNotification from "components/Hoc/withNotification";
import { useParams } from "react-router-dom";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { useState, useEffect } from "react";
import { TenantService } from "services/tenant/index.service";
import { CheckinFrom } from "./Utils/constants";

const CheckinFromAppoitment = (props: any) => {
  const service = new TenantService();
  const { uuid, userMemberId } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  const fetchUserSession = async () => {
    await service.getTenantDetails(uuid);
  };

  const fetchUserDetails = async (userMemberId: any) => {
    setLoading(true);
    const res = await service?.getUserInfo(userMemberId);
    setLoading(false);
    if (res) {
      const {
        UserMemberId,
        UserId,
        MemberNumber,
        MemberType,
        Status,
        ParentId,
        User: {
          Email,
          FirstName,
          FullName,
          LastName,
          Photo,
          DateOfBirth,
          Address: { Phone },
        },
      } = res;
      const data = {
        UserMemberId,
        UserId,
        Email,
        FirstName,
        FullName,
        LastName,
        Photo,
        DateOfBirth,
        Phone,
        MemberType,
        MemberNumber,
        Status,
        ParentId
      };
      setUserData(data);
    } else {
      setUserData(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchUserSession();
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchUserDetails(userMemberId);
  }, [userMemberId]);

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  console.log(props);
  return (
    <CheckinUser
      userDetails={userData}
      CheckinMode={1}
      {...props}
      from={CheckinFrom.Appointment}
    />
  );
};

export default withNotification(CheckinFromAppoitment);

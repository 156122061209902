import { filterBy } from "@progress/kendo-data-query";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import useState from 'react-usestateref';
import { useHistory } from "react-router-dom";
import { ClassService } from "services/class/index.service";
import { PackageService } from "services/package/index.service";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus, convertToTimezone, getFullDate } from "utils";
import { GetLocalStore, LocalStore, RemoveLocalStore } from "utils/storage";
import { useSelector } from "react-redux";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";

const usePurchaseClassList = (props: any) => {
  const history = useHistory();
  const redirectionObj = useSelector((state: any) => state?.redirection);
  const showMoreRef = useRef<any>();
  const [PackagesData, setPackagesData, packagesDataRef] = useState<any>([]);
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [loginStatus, setLoginStatus] = useState(false);
  const [confirmClientDialog, setConfirmClientDialog] = useState(false);
  const [classSessionsList, setclassSessionsList] = useState<any>({
    pageNumber: 0,
    maxPageNumber: 0,
    TotalItems: 0,
    startDate: moment().format("YYYY-MM-DD"),
    classSessionData: [],
  });
  const [expandedItem, setExpandItem] = React.useState(null);
  const [usePackageFilter, setUsePackageFilter] = React.useState(true);
  //client details
  const [clientFormValues, setClientFormValues] = useState<any>({});
  const [clientSubmitDetails, setClientSubmitDetails, clientSubmitDetailsRef] = useState<any>({});
  const userDetails = GetLocalStore("userDetails");
  const isLoggedIn = userDetails ? true : false;
  const [isClassLoading, setIsClassLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedClasses, setSelectedClasses] = useState<any>(
    props?.selectedClasses ? props?.selectedClasses : []
  );
  const [classSelectedUserMemberId, setClassSelectedUserMemberId] =
    useState<any>(
      props?.classSelectedUserMemberId ? props?.classSelectedUserMemberId : null
    );
  const [classesList, setClassesList] = useState<any>([]);
  const [originalClassList, setOriginalClassList] = useState<any>([]);

  const initialFilterValues = {
    class: props?.selectedParentClass
      ? props?.selectedParentClass
      : { ClassId: 0, Name: "All Group" },
    fromDate: !props.staffPage ? convertToTimezone(new Date(),true): new Date(),
  };
  const [filterContent, setFilterContent, filterContentRef] = useState<any>(initialFilterValues);

  const [take, setTake] = useState(3);
  const [selectedPackage, setSelectedPackage, selectedPackageRef] = useState<any>(props?.selectedPackage || null);
  const [packageBillingCycle, setPackageBillingCycle, packageBillingCycleRef] = useState<any>(props?.selectedPackage?.BillingPeriods || null);
  const [confirmCartClearDialog, setConfirmCartClearDialog] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
const initialPickPackageDialog={
  showDialog:false,
  selectedGroup:null,
  selectedPackage:null
}
  const [pickPackageDialog,setPickPackageDialog,pickPackageDialogRef]=useState<any>(initialPickPackageDialog)

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis=async()=> {
    await fetchAllSubmembers()
    await fetchPackageSummary(true)
    await fetchClassSessions(true,undefined,true);
    if (!props?.classShortName || props?.classShortName === null) {
      fetchClassNames();
    }
  }

  
  useEffect(() => {
    if(props?.selectedPackage){
      setSelectedPackage(props?.selectedPackage);
      setBillingPeriods(props?.selectedPackage?.BillingPeriods);
    }else{
      setSelectedPackage(undefined);
      setBillingPeriods(undefined);
      // fetchPackageSummary(true);
    }
 
    handleClearAllSlots();
    setConfirmCartClearDialog(false);
    // fetchClassSessions(true,undefined,true);
    // if (!props?.classShortName || props?.classShortName === null) {
    //   fetchClassNames();
    // }
    
  }, [props?.selectedPackage,props?.userData]);

  useEffect(() => {
    if(props?.selectedClasses && props?.selectedClasses.length > 0){
      setSelectedClasses(props?.selectedClasses);
    }
  }, [props?.selectedClasses])
  
  const toggleClientConfirmDialog = () => {
    setConfirmClientDialog(!confirmClientDialog);
  };
  const handleUsePackageFilter = (val: boolean) => {
    setUsePackageFilter(val);
  }

  const handleExpand = (item: any) => {
    setExpandItem(item.ClassSessionId);
  };
  const handleCollapse = () => {
    setExpandItem(null);
  };

  const CheckExactPackageItem = (
    packageList: any
  ) => {
    const UnlimitedPackages = packageList.filter((i: any) => i?.SessionType === 2);
    const LimitedPackages = packageList.filter((i: any) => i?.SessionType === 1 && i?.SchedulesToUse > 0);

    if (UnlimitedPackages?.length === 1) {
      return UnlimitedPackages[0];
    }
    if (UnlimitedPackages?.length > 1) {
      const selectedMinExpireOnPackage = UnlimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        UnlimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }

    if (LimitedPackages?.length > 0) {
      const selectedMinExpireOnPackage = LimitedPackages.reduce(
        (min: any, item: any) => {
          return new Date(min.ExpireOn) > new Date(item.ExpireOn) ? item : min;
        },
        LimitedPackages[0]
      );
      return selectedMinExpireOnPackage;
    }
    return null;
  };
  const checkSkipPayment = (sessionId: any) => {
    let sessions: any = [];
    selectedClasses.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    const sessionIds = sessions?.findIndex(
      (kkk: any) =>
        kkk?.ClassSessionId === sessionId &&
        (kkk?.PaymentOption === 2 || kkk?.PaymentOption === 3  || kkk?.ClassType !== 1 || kkk?.Cost <=0)
    );
    return sessionIds !== -1;
  };

  const checkZeroDollar = () => {
    let sessions: any = [];
    selectedClasses.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    const checkUnpaid = sessions?.every(
      (kkk: any) =>
        kkk?.ClassType !== 1 || kkk?.Cost <=0
    );
    return checkUnpaid;
  };
  const checkCompleteReedemViaPackage = () => {
    let sessions: any = [];
    selectedClasses.forEach((j: any) => {
      j?.values?.forEach((k: any) => {
        sessions.push(k);
      });
    });
    const checkUnpaid = sessions?.every(
      (kkk: any) =>
        kkk?.SelectedPackage && usePackageFilter
    );
    return checkUnpaid;
  };
  const handleEnrollWithoutCheckout = async() => {
    let ClassSessionDetails: any = [];
    selectedClasses.forEach((j: any) => {
      j?.values.forEach((k: any) => {
        const checkSkipPaymentStatus = checkSkipPayment(k?.ClassSessionId);
        const { ClassId, ClassSessionId, ClassType, Cost,CanBookAsWaitlist,SelectedPackage=null } = k;
        if(SelectedPackage){
          const { PackageInstanceId, Name } = SelectedPackage;
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            EntityName: Name,
            PayBy: checkSkipPaymentStatus  && (ClassType !==1 || Cost <= 0) ? 3 :1,
            PaymentSourceId: checkSkipPaymentStatus && (ClassType !==1 || Cost <= 0) ? undefined:PackageInstanceId,
            CanBookAsWaitlist
          });
        }
        else if (selectedPackage) {
          const { PackageInstanceId, Name } = selectedPackage;
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            EntityName: Name,
            PayBy: checkSkipPaymentStatus  && (ClassType !==1 || Cost <= 0) ? 3 :1,
            PaymentSourceId: checkSkipPaymentStatus && (ClassType !==1 || Cost <= 0) ? undefined:PackageInstanceId,
            CanBookAsWaitlist
          });
        }else if(props?.selectedPackage){
          const { PackageInstanceId, Name } = props?.selectedPackage;
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            EntityName: Name,
            PayBy: checkSkipPaymentStatus  && (ClassType !==1 || Cost <= 0) ? 3 :1,
            PaymentSourceId: checkSkipPaymentStatus && (ClassType !==1 || Cost <= 0) ? undefined:PackageInstanceId,
            CanBookAsWaitlist
          });
        }else if(k?.Complimentary){
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 5,
            CanBookAsWaitlist
          });
        } else if (checkSkipPaymentStatus) {
          if (ClassType !==1 || Cost <= 0) {
            ClassSessionDetails.push({
              ClassId,
              ClassSessionId,
              PayBy: 3,
              CanBookAsWaitlist
            });
          } else {
            ClassSessionDetails.push({
              ClassId,
              ClassSessionId,
              PayBy: 2,
              CanBookAsWaitlist
            });
          }
        } else {
          ClassSessionDetails.push({
            ClassId,
            ClassSessionId,
            PayBy: 0,
            CanBookAsWaitlist
          });
        }
      });
    })
    let req: any = {
      DownPayment: 0,
      Client: props?.from ==="calender" ?props?.userData : clientSubmitDetails,
      ClassSessionDetails,
      HasTermsAndConditionAccepted: true,
    };
    req = { ...req };
    const checkoutClass = new ClassService();
    try {
      setBtnLoading(true);
      const result = await checkoutClass.purchaseClass(req);
      setBtnLoading(false);

      if (result?.ResponseCode === 100) {
        setSelectedClasses([]);
      setConfirmClientDialog(false);
        handleClearAllSlots();
        fetchPackageSummary(true);
        props?.handleNotificationMessage("Sessions Enrolled Successfully", "success");
      } else {
        const errorMsg = result?.ErrorMessages?.[0];
        props?.handleNotificationMessage(errorMsg, "error");
      }
      setBtnLoading(false);
    } catch (e) {
      console.log(e);
    }
  }
  const handleGroupCheckout = (obj: any) => {
    if(selectedPackage || props?.selectedPackage || checkCompleteReedemViaPackage()){
      handleEnrollWithoutCheckout();
      
      return true;
    }
    if(props?.from==="calender"){
      props?.handleGroupCheckoutProps(obj);
      return true;
    }
    if(props?.staffPage){
      if(checkZeroDollar()){
        handleEnrollWithoutCheckout();
        return
      }
    }
    const { firstPathValue, staffuuid, clientMemberId } = redirectionObj;
    if (firstPathValue === "member") {
      history.push(
        `/${firstPathValue}/${staffuuid}/${clientMemberId}/checkout`,
        obj
      );
    } else if (firstPathValue === "") {
      history.push(`/checkout`, obj);
    } else {
      history.push(`/${firstPathValue}/checkout`, obj);
    }
  };

  const handlePageChangePackage = (status = "more") => {
    if (status === "less") {
      setTake(3);
    } else {
      setTake(take + 4);
    }
  };

  const fetchAllSubmembers = () => {
    if(props?.from==="calender"){
      return
    }
    let memberId;
    if (props?.pickpackage && props?.page === "inhouse") {
      memberId = props?.packageData?.UserMemberId;
    }
    if (props?.userMemberId && props?.page === "inhouse") {
      memberId = props?.userMemberId;
    }
    if (props?.userData) {
      memberId = props?.userData?.UserMemberId;
    }
    if (props?.selectedPackage && props?.selectedPackage?.MemberType === 0) {
      memberId = props?.selectedPackage?.UserMemberId;
    }
    if (memberId) {
      getAllSubMembers(memberId);
    }
    // else{
    //   fetchClassSessions(true)
    //   fetchClassNames()
    // }
  };

  const setSelectedPackageData = () => {
      if(props?.selectedPackage){
        const data = packagesDataRef?.current.find((p:any) => parseInt(p.PackageInstanceId) === parseInt(props?.selectedPackage?.PackageInstanceId));
        setSelectedPackage(data);
        setBillingPeriods(data?.BillingPeriods);
      }else if(selectedPackageRef?.current){
        const data = packagesDataRef?.current.find((p:any) => p.PackageInstanceId === selectedPackageRef?.current?.PackageInstanceId);
        setSelectedPackage(data);
        setBillingPeriods(data?.BillingPeriods);
      }else {
        const data = CheckExactPackageItem(packagesDataRef?.current);
        setSelectedPackage(data);
        setBillingPeriods(data?.BillingPeriods);
      }
  }
  const fetchPackageSummary = async (init = false) => {
    let memberId;
    if (clientSubmitDetailsRef?.current?.UserMemberId) {
      memberId = clientSubmitDetailsRef?.current?.UserMemberId;
    } else {
      if (props?.pickpackage && props?.page === "inhouse") {
        memberId = props?.packageData?.UserMemberId;
      }
      if (props?.userData) {
        memberId = props?.userData?.UserMemberId;
      }
    }
    let startDate =
      moment(filterContentRef?.current?.fromDate).format("YYYY-MM-DD") + "T00:00:00";
    let ToDate = moment().add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
    if(props?.selectedPackage){
      startDate =
      moment(props?.selectedPackage?.StartOn).format("YYYY-MM-DD") + "T00:00:00";
      ToDate =
      moment(props?.selectedPackage?.StartOn).add(1, "year").format("YYYY-MM-DD") + "T23:59:59";
      memberId=props?.selectedPackage?.UserMemberId
    }
    const req = {
      UserMemberId: memberId,
      FromDate: startDate,
      ToDate,
      PackageType:"Class"

    };
    if (memberId) {
      const service = new PackageService();
      const result = await service.packageInstanceToUse(req);
      setPackagesData(result || []);
      if(init){
        if((!props?.selectedClasses || props?.selectedClasses?.length===0) && props?.from !=="calender") {
          setSelectedPackageData();
        }
      }

    }
  };

  const CheckValidPackages = (List: any, j: any) => {
    console.log(List,"list")
    console.log(j,"j")
    const result = List.filter((i: any) => {
      if (
        (i.ClassIds.includes(j?.ClassId) || i.ClassIds.includes(-1)) &&
        j?.Cost > 0
      ) {
        // if (
        //   moment(j?.ClassSessionStartDate).format('YYYY-MM-DD') >=
        //     moment(i?.ExpireFrom).format('YYYY-MM-DD') &&
        //   moment(j?.ClassSessionStartDate).format('YYYY-MM-DD') <=
        //     moment(i?.ExpireOn).format('YYYY-MM-DD')
        // ) {
        //   return true;
        // }
  
        if (i.BillingPeriods?.length > 0) {
          const res = i.BillingPeriods?.filter((bp: any) => {
            if (
              moment(j?.ClassSessionStartDate).format('YYYY-MM-DD') >=
                moment(bp?.ExpireFrom).format('YYYY-MM-DD') &&
              moment(j?.ClassSessionStartDate).format('YYYY-MM-DD') <=
                moment(bp?.ExpireOn).format('YYYY-MM-DD') &&
              bp?.SchedulesToUse > 0
            ) {
              return true;
            }
            return false;
          });
          if (res?.length > 0) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    });
    return result || [];
  };

  const constructClassListForDate = (classList: any, initialCall: boolean) => {
    let oldClassData = initialCall
      ? []
      : JSON.parse(JSON.stringify(classSessionsList.classSessionData));
    if (classList) {
      classList.map((val: any) => {
        let currentTime = moment().format();
        currentTime= !props.staffPage ? convertToTimezone(new Date(currentTime)) : currentTime;
        let bookDuration = val?.MinimumBookingDuration;
        let MinimumBookingDurationType = "minutes";
        if (val?.MinimumBookingDurationType === 1) {
          MinimumBookingDurationType = "hours";
        } else if (val?.MinimumBookingDurationType === 2) {
          MinimumBookingDurationType = "minutes";
        } else if (val?.MinimumBookingDurationType === 3) {
          MinimumBookingDurationType = "days";
        }
        if(props?.staffPage){
          bookDuration = 0;
          MinimumBookingDurationType = "minutes";
        }
        const MimimumBookingBeforTime = moment(val?.ClassSessionStartDate)
          .subtract(bookDuration, MinimumBookingDurationType)
          .format();
        if (currentTime <= MimimumBookingBeforTime) {
          const {
            ClassId,
            ClassName,
            ClassSessionDuration,
            ClassSessionEndTime,
            ClassSessionId,
            ClassSessionStartDate,
            ClientCanSkipPayment,
            Cost,
            ProviderPhoto,
            TrainerId,
            TrainerName,
            AvailableSlot,
            Attributes,
            VirtualMeetingLink,
            PaymentOption,
            ClassType,
            BookedSlot,
            Slot,
            Status,
            Description,
            CanCollectTaxes,
            HasWaitList,
            WaitList,
            WaitingSlot,
            HasPurchased
          } = val;
          let CanBookAsWaitlist=false
         if(Slot-BookedSlot === 0 && HasWaitList && WaitList - WaitingSlot > 0){
            CanBookAsWaitlist=true
          }
          console.log(packagesDataRef?.current,'packagesDataRef?.current')
          const PackagesList = CheckValidPackages(packagesDataRef?.current, val);
          console.log(PackagesList,'PackagesList')
          const record = {
            Status,
            ClassId,
            Attributes,
            VirtualMeetingLink,
            ClassName,
            ClassSessionDuration,
            ClassSessionEndTime,
            ClassSessionId,
            ClassSessionStartDate,
            ClientCanSkipPayment,
            Cost,
            ProviderPhoto,
            TrainerId,
            TrainerName,
            AvailableSlot,
            PaymentOption,
            ClassType,
            BookedSlot,
            Slot,
            Description,
            CanCollectTaxes,
            HasWaitList,
            WaitList,
            WaitingSlot,
            CanBookAsWaitlist,
            HasPurchased,
            PackagesList
          };
          const classIndex = oldClassData.findIndex(
            (val1: any) =>
              val1.date == getFullDate(record?.ClassSessionStartDate)
          );
          if (classIndex !== -1) {
            oldClassData[classIndex].values.push(record);
            oldClassData[classIndex].values?.sort((a: any, b: any) =>
              moment(a?.ClassSessionStartDate).format() >
              moment(b?.ClassSessionStartDate).format()
                ? 1
                : -1
            );
          } else {
            oldClassData.push({
              date: getFullDate(record?.ClassSessionStartDate),
              values: [record],
            });
          }
        }
      });
      const updatedData = oldClassData
        .slice()
        .sort((a: any, b: any) =>
          moment(b.date).format("YYYY-MM-DD") >
          moment(a.date).format("YYYY-MM-DD")
            ? -1
            : 1
        );
      return updatedData;
    }
    const updatedData = oldClassData
      .slice()
      .sort((a: any, b: any) =>
        moment(b.date).format("YYYY-MM-DD") >
        moment(a.date).format("YYYY-MM-DD")
          ? -1
          : 1
      );
    return updatedData;
  };

  const fetchClassNames = async () => {
    const service = new ClassService();
    let req = null;
    if (props?.pickpackage && props?.page === "inhouse") {
      req = {
        packageInstanceId: props?.packageData?.PackageInstanceId,
      };
    }
    if (selectedPackageRef?.current) {
      req = {
        packageInstanceId: selectedPackageRef?.current?.PackageInstanceId
      }
    }
    const result = await service.getClassNames(req);
    const res = [...result];
    const test = { ClassId: 0, Name: "All Group" };
    res.unshift(test);
    setClassesList(res);
    setOriginalClassList(res);
  };

  const ClassNamefilterChange = (e: any) => {
    const filteredData = classNameFilterData(e.filter);
    setClassesList(filteredData);
  };

  const classNameFilterData = (filter: any) => {
    const data = originalClassList.slice();
    return filterBy(data, filter);
  };

  const constructSelectedClassListForClass = (classList: any) => {
    let oldClassData = JSON.parse(JSON.stringify(selectedClasses));
    if (classList) {
      if (oldClassData.length > 0) {
        const classIndex = oldClassData.findIndex(
          (val1: any) => val1?.className == classList?.ClassName
        );

        if (classIndex !== -1) {
          const classValueIndex = oldClassData[classIndex].values.findIndex(
            (val2: any) => val2.ClassSessionId === classList.ClassSessionId
          );

          if (classValueIndex !== -1) {
            oldClassData[classIndex].values.splice(classValueIndex, 1);
            if (oldClassData[classIndex].values.length === 0) {
              oldClassData.splice(classIndex, 1);
            }
          } else {
            oldClassData[classIndex].values.push(classList);
            oldClassData[classIndex].values?.sort((a: any, b: any) =>
              moment(a?.ClassSessionStartDate).format() >
              moment(b?.ClassSessionStartDate).format()
                ? 1
                : -1
            );
          }
        } else {
          oldClassData.push({
            className: classList?.ClassName,
            CanCollectTaxes: classList?.CanCollectTaxes,
            ClassId: classList?.ClassId,
            values: [classList],
        });
        }

        return oldClassData;
      } else {
        oldClassData.push({
          className: classList?.ClassName,
          CanCollectTaxes: classList?.CanCollectTaxes,
          ClassId: classList?.ClassId,
          values: [classList],
      });
      }
    }
    return oldClassData;
  };

  const getCurrentClientData = (allUsersData: any, userMemberId: any) => {
    return (
      allUsersData?.find((val: any) => val.UserMemberId === userMemberId) || {}
    );
  };

  const handleClientSelection = async (SelectedUser: any) => {
    await updateSelectedClientData(SelectedUser);
    handleClearAllSlots();
  };

  async function updateSelectedClientData(userData: any) {
    const result = userData;
    if (result) {
      const clientIntialValues = {
        FirstName: result?.FirstName,
        LastName: result?.LastName,
        PhoneNumber: result?.Phone,
        Email: result?.Email,
        UserMemberId: result?.UserMemberId,
      };
      const clientSubmitDetails = {
        Email: result?.Email,
        FirstName: result?.FirstName,
        Photo: result?.Photo,
        LastName: result?.LastName,
        FullName: result?.FullName,
        PrimaryMemberId: result?.ParentId || result?.UserMemberId,
        UserId: result?.UserId,
        UserMemberId: result?.UserMemberId,
        MemberNumber: result?.MemberNumber,
        MemberType: result?.MemberType,
        Status: result?.UserMemberStatus || result?.Status,
      };
      setClientFormValues(clientIntialValues);
      setClassSelectedUserMemberId(result?.UserMemberId);
      setClientSubmitDetails(clientSubmitDetails);
      // fetchPackageSummary(true);
    }
  }

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
    let Id = memberId;
    if (!props.staffPage && userDetails) {
      Id = parseInt(
        userDetails?.UserSession?.AdditionalAttributes?.LogedInMemberId ||
          memberId
      );
    }
    if (classSelectedUserMemberId) {
      Id = classSelectedUserMemberId;
    }
    if(props?.selectedPackage){
      Id = props?.selectedPackage?.UserMemberId;
    }
    await updateSelectedClientData(getCurrentClientData(res, Id));
    
  };

  async function fetchClassSessions(intialCall = true, SelectedPackageData = selectedPackageRef?.current,filteredDate=false) {
    const service = new ClassService();
    let startDate = moment(filterContentRef?.current?.fromDate).format("YYYY-MM-DD");
    if (!intialCall) {
      startDate = moment(classSessionsList?.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    let FromDate = moment(startDate).format("YYYY-MM-DD") + "T00:00:00";
    let check=false
    if (startDate === moment().format("YYYY-MM-DD")) {
      FromDate = moment().format("YYYY-MM-DDTHH:mm:ss");
      check=true
    }
    let ToDate =
      moment(startDate).add(9, "days").format("YYYY-MM-DD") + "T23:59:59";
    let ClassId = 0;
    if (filterContentRef?.current?.class !== "" && filterContentRef?.current?.class !== "All Group") {
      ClassId = filterContentRef?.current.class?.ClassId;
    }
    if((!filteredDate || check) && !props.staffPage){
      FromDate=convertToTimezone(new Date(FromDate))
      ToDate=convertToTimezone(new Date(ToDate))
    }

    const req = {
      FromDate: FromDate,
      // ToDate: ToDate,
      SortDirection: "ascending",
      IncludeInActive: false,
      ClassId: ClassId,
      SortBy: "ClassSessionStartDate",
      PageSize:100
    };
    const newObj: any = new Object();

    if (props.staffPage) {
      newObj.CanClientBookViaOnline = false;
    } else {
      newObj.CanClientBookViaOnline = true;
    }
    if (props?.providerName) {
      newObj.UserAssociateId = props?.provider?.AssociateId;
    }
    if (props?.classShortName) {
      newObj.ShortCode = props?.classShortName;
    }
    if (props?.classSessionId) {
      newObj.ClassSessionId = parseInt(props?.classSessionId)
      newObj.ClassId = undefined
      newObj.FromDate= undefined
      newObj.ToDate= undefined
    }
    let memberId;
    if (clientSubmitDetailsRef?.current?.UserMemberId) {
      memberId = clientSubmitDetailsRef?.current?.UserMemberId;
    } else {
      if (props?.pickpackage && props?.page === "inhouse") {
        newObj.PackageInstanceId = props?.packageData?.PackageInstanceId;
        memberId = props?.packageData?.UserMemberId;
      }
      if (props?.userData) {
        memberId = props?.userData?.UserMemberId;
      }
    }
    if (memberId) {
      newObj.UserMemberId = memberId;
    }
    if (SelectedPackageData) {
      newObj.PackageInstanceId = SelectedPackageData?.PackageInstanceId;
    }
    if(props?.selectedPackage && props?.from !=="calender"){
      newObj.UserMemberId = props?.selectedPackage?.UserMemberId;
    }
    const reqObj = { ...req, ...newObj };
    if (intialCall) {
      setIsClassLoading(true);
    }

    const result = await service.filterClassSchedule(reqObj);
    if (intialCall) {
      setIsClassLoading(false);
    }
    if (result?.ResponseCode === 100) {
      const classItems = constructClassListForDate(
        result?.Items || [],
        intialCall
      );
      const nextDate=result?.Items[result?.Items?.length-1]?.NextAvailableDate
      if (intialCall) {
        setclassSessionsList({
          ...classSessionsList,
          startDate: nextDate,
          TotalItems: result.TotalItems,
          classSessionData: classItems || [],
        });
      }
      return { classItems, startDate: nextDate,TotalItems: result.TotalItems} || null;
    }
    return null;
  }

  const handleClientConfirmation = () => {
    if (AllClientsList?.length > 1) {
      const selectedClientInfo = AllClientsList?.filter(
        (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
      )[0];
      if (selectedClientInfo?.MemberType === 0) {
        toggleClientConfirmDialog();
        return;
      } else {
        handleSubmit();
      }
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }

    if (props?.providerName) {
      if (isLoggedIn) {

        handleGroupCheckout({
          provider: props?.provider,
          session: selectedClasses,
        });
      } else {
        RemoveLocalStore("serviceItem");
        LocalStore("classItem", selectedClasses);
        setLoginStatus(true);
      }
    } else {
      if (isLoggedIn || props.from==="calender") {
        if(props.from==="calender" && !props?.userData){
          props?.handleNotificationMessage(
            `Please select client to Proceed`,
            "error"
          );
          return true;
        }
        if (props?.staffPage) {
          handleGroupCheckout({
            session: selectedClasses,
            staffPage: true,
            userData: props.from==="calender" ? props?.userData :clientSubmitDetails,
            uuid: props?.uuid,
          });
        } else {
          handleGroupCheckout({
            session: selectedClasses,
            userData: clientSubmitDetails,
          });
        }
      } else {
        RemoveLocalStore("serviceItem");
        LocalStore("classItem", selectedClasses);
        setLoginStatus(true);
      }
    }
  };

  const handleCloseLogin = () => {
    setLoginStatus(false);
  };
  const findItem = (obj: any, arr: any) => {
    let allVals: any[] = [];
    arr.forEach((val: any) => {
      if (val?.values) {
        allVals.push(...val.values);
      }
    });

    const findVal = allVals.findIndex((item: any) => {
      return item.ClassSessionId === obj.ClassSessionId;
    });
    return findVal;
  };

  const handlePageChange = async (status = "more") => {
    if (status === "more") {
      setPageLoading(true);
      let moreData;
      if(props?.selectedPackage){
        moreData = await fetchClassSessions(false,props?.selectedPackage,true);
      }else{
        moreData = await fetchClassSessions(false,undefined,true);
      }
      if (moreData && moreData?.classItems?.length > 0) {
        setclassSessionsList({
          ...classSessionsList,
          classSessionData: moreData?.classItems,
          startDate: moreData?.startDate,
          TotalItems: moreData?.TotalItems,
        });
      } else {
        setclassSessionsList({
          ...classSessionsList,
          startDate: moreData?.startDate,
          TotalItems: moreData?.TotalItems,

        });
      }
      setPageLoading(false);
    }
  };

  const handleShowPickPackageDialog=(item:any)=> {
    setPickPackageDialog({
      ...pickPackageDialogRef?.current,
      showDialog:true,
      selectedGroup:item
    })
  }

  const handleClosePickPackageDialog=()=> {
    setPickPackageDialog(initialPickPackageDialog)
  }

  const handleSelectClass = (item: any, type = "add",pickPackage=null) => {
    if(type==="add" && !selectedPackage && usePackageFilter && item?.PackagesList?.length > 0 && !pickPackageDialogRef?.current?.showDialog){
      handleShowPickPackageDialog(item)
      return
    }
    if (item?.AvailableSlot > 0 || item?.CanBookAsWaitlist) {
      let classItem:any=item
      if(pickPackage){
        classItem={...item,enroll:true,SelectedPackage:pickPackage}
        handleClosePickPackageDialog()
      }
      if (props?.pickpackage && props?.page === "inhouse" && type === "add") {
        if (
          moment(props?.packageData?.ExpireOn).format() <=
          moment(item?.ClassSessionStartDate).format()
        ) {
          props?.handleNotificationMessage(
            `Packages is expiring before Appointment Start Time`,
            "error"
          );
          return true;
        }
      }
      
      // validation for billing cycle
      if(selectedPackage && packageBillingCycle?.length > 0 && selectedPackage?.SessionType === 1) {
        // if selected item date is in between billing periods ExpireFrom and ExpireOn, then already selected item between billing cycle should not be greater than SchedulesToUse 
        const getBillingCycle = getBillingCycleForAPackageAndClass(classItem);
        if(getBillingCycle){
          if(getBillingCycle?.redeemableSchedules === 0 && type === "add") {
            props?.handleNotificationMessage(
              `Maximum appointments already selected for billing cycle.`,
              "error"
            );
            return true;
          } else{
            updateBillingCycleForAPackageAndClass(classItem, type === "add" ? true : false);
          }
        }else{
          props?.handleNotificationMessage(
            `Selected appointment not in the billing cycle of the selected Package.`,
            "error"
          );
          return true
        }
      }
      const classList = constructSelectedClassListForClass(classItem);
      setSelectedClasses(classList);
    } else {
      props?.handleNotificationMessage(
        `Slots are filled for selected appointment`,
        "error"
      );
    }
  };

  const handleFilter = (val: any, name: string) => {
    setFilterContent({ ...filterContent, [name]: val });
    if(name === "fromDate"){
      fetchClassSessions(undefined,undefined,true);
    }else{
      if(moment(filterContentRef?.current?.fromDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")){
        fetchClassSessions(undefined,undefined,true);
      }else{
        fetchClassSessions();
      }
    }
  };

  const handleClearAllSlots = () => {
    setSelectedClasses([]);
    if(selectedPackage){
      setBillingPeriods(selectedPackage?.BillingPeriods);
    }
    if (props?.page !== "inhouse" && props?.addMoreFunctionality) {
      props?.unloadFunc();
    }
  };

  const selectedClassLength = () => {
    let allVals: any[] = [];
    selectedClasses.forEach((val: any) => {
      if (val?.values) {
        allVals.push(...val.values);
      }
    });
    return allVals.length || 0;
  };

  const checkIfADateBetween = (checkDate: any, startDate: any, EndDate: any) => {
    return (moment(checkDate).format('YYYY-MM-DD') >= moment(startDate).format('YYYY-MM-DD') && moment(checkDate).format('YYYY-MM-DD') <= moment(EndDate).format('YYYY-MM-DD'))
  }

  const getBillingCycleForAPackageAndClass = (item: any) => {
    const findCycle = packageBillingCycle?.find((val: any) => checkIfADateBetween(item?.ClassSessionStartDate, val?.ExpireFrom, val?.ExpireOn));
    return findCycle;
  }
  const updateBillingCycleForAPackageAndClass = (item: any, add: boolean) => {
    const findCycle = getBillingCycleForAPackageAndClass(item);

    if(findCycle){
      setPackageBillingCycle((prev: any) => {
        return prev?.map((val: any) => {
          if(checkIfADateBetween(item?.ClassSessionStartDate, val?.ExpireFrom, val?.ExpireOn)){
            if(add){
              return {
                ...val,
                redeemableSchedules: val.redeemableSchedules - 1
              }
            }else {
              return {
                ...val,
                redeemableSchedules: val.redeemableSchedules + 1
              }
            }

          }else {
            return val
          }
        })
      })
    }

  }
  const setBillingPeriods = (data: any) => {
    if(data && data?.length > 0) {
      data = data?.map((val: any) => {
        return {...val, redeemableSchedules: val?.SchedulesToUse}
      })
      setPackageBillingCycle(data);
    }else {
      setPackageBillingCycle([]);
    }
  }

  const handlePackageChange = (e: ComboBoxChangeEvent) => {
    if(!e.target.value){
      setSelectedPackage(undefined);
      setBillingPeriods(undefined);
    }else {
      setSelectedPackage(e.target.value);
      setBillingPeriods(e.target.value?.BillingPeriods);
    }
    handleClearAllSlots();
    setConfirmCartClearDialog(false);
    fetchClassSessions(true,undefined,true);
    if (!props?.classShortName || props?.classShortName === null) {
      fetchClassNames();
    }
  };


  const handleCancelConfirmClearCart = () => {
    setConfirmCartClearDialog(false);
  }
  return {
    PackagesData,
    take,
    handlePageChangePackage,
    AllClientsList,
    clientFormValues,
    toggleClientConfirmDialog,
    handleSubmit,
    isLoggedIn,
    clientSubmitDetails,
    handleClientSelection,
    selectedClassLength,
    handleClearAllSlots,
    selectedClasses,
    handleCloseLogin,
    loginStatus,
    confirmClientDialog,
    handleClientConfirmation,
    classesList,
    handleFilter,
    ClassNamefilterChange,
    filterContent,
    isClassLoading,
    classSessionsList,
    showMoreRef,
    pageLoading,
    handlePageChange,
    handleSelectClass,
    findItem,
    selectedPackage,
    handlePackageChange,
    setConfirmCartClearDialog,
    confirmCartClearDialog,
    handleCancelConfirmClearCart,
    btnLoading,
    checkZeroDollar,
    usePackageFilter,
    handleCollapse,
    handleExpand,
    expandedItem,
    handleUsePackageFilter,
    pickPackageDialog,
    handleClosePickPackageDialog,
    checkCompleteReedemViaPackage
  };
};

export default usePurchaseClassList;

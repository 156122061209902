import { CommonServiceUtils } from "services/common-service-utils";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllLocalStore,
  RemoveLocalStore,
} from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";

export class ClassService extends CommonServiceUtils {
  /**
   * class will be created with provided data
   * @param data
   *
   */
  async getTenantDetails(tenant: string): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenantUserSession", {
        ...getToken?.Item?.UserSession,
        DomainName: getToken.Item.DomainName,
        StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration,
        ChargehubSquarePaymentProfile:
          getToken.Item.ChargehubSquarePaymentProfile,
        Configuration: getToken.Item.Configuration
      });
      LocalStore(
        "CurrentApplicationType",
        getToken?.Item?.UserSession?.CurrentApplicationType
      );
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      const getAboutDetails: any = await this.postData(
        `${this.apiUrlCreator(`site/about`)}`
      );
      if (getAboutDetails?.ResponseCode === 100) {
        LocalStore("tenantDetails", getAboutDetails.Item);
      }
      TimeZoneSetup(
        getToken?.Item?.UserSession?.TenantTime,
        getToken?.Item.UserSession
      );
    }
    return getToken;
  }
  async getQuickLinks(tenant: string): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenantUserSession", {
        ...getToken?.Item?.UserSession,
        DomainName: getToken.Item.DomainName,
        StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration,
        ChargehubSquarePaymentProfile:
          getToken.Item.ChargehubSquarePaymentProfile,
        Configuration: getToken.Item.Configuration,
      });
      LocalStore(
        "CurrentApplicationType",
        getToken?.Item?.UserSession?.CurrentApplicationType
      );
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      TimeZoneSetup(
        getToken?.Item?.UserSession?.TenantTime,
        getToken?.Item.UserSession
      );
    }
    return getToken?.Item?.UserSession?.QuickLinks;
  }

  async addClass(data?: any): Promise<any> {
    const addClassDetails: any = await this.postData(
      this.apiUrlCreator("Class"),
      data
    );
    return addClassDetails;
  }
  async getClassNames(req: any = null): Promise<any> {
    let response = [];
    const status = "Active";
    const getClassNames: any = await this.getData(
      req !== null
        ? this.apiUrlCreator(
            `Class/GetClassNames?packageInstanceId=${req?.packageInstanceId}&Status=${status}`
          )
        : this.apiUrlCreator(
            `Class/GetClassNames?Status=${status}&CanClientBookViaOnline=false`
          )
    );
    if (getClassNames?.ResponseCode === 100) {
      response = getClassNames.Items;
    }
    return response;
  }
  async getClassNamesFilter(req: any): Promise<any> {
    const getClassNamesFilter: any = await this.postData(
      this.apiUrlCreator("Class/Filter"),
      req
    );
    return getClassNamesFilter;
  }
  async getClass(classId: number): Promise<any> {
    let response = null;
    const getClass: any = await this.getData(
      this.apiUrlCreator(`Class?classId=${classId}`)
    );
    if (getClass?.ResponseCode === 100) {
      response = getClass.Item;
    }
    return response;
  }
  async filterClassSchedule(req: any): Promise<any> {
    const filterClassSchedule: any = await this.postData(
      this.apiUrlCreator(`Class/FilterClassSchedule`),
      req
    );
    return filterClassSchedule;
  }

  async getClassSession(classSessionId: number): Promise<any> {
    let response = null;
    const getClassSession: any = await this.getData(
      this.apiUrlCreator(`ClassSession?classSessionId=${classSessionId}`)
    );
    if (getClassSession?.ResponseCode === 100) {
      response = getClassSession.Item;
    }
    return response;
  }

  async addClassSession(data?: any): Promise<any> {
    const addClassSession = await this.postData(
      this.apiUrlCreator("ClassSession"),
      data
    );
    return addClassSession;
  }

  async updateClassSession(data?: any): Promise<any> {
    const updateClassSession = await this.postData(
      this.apiUrlCreator("ClassSession/Put"),
      data
    );
    return updateClassSession;
  }
  async updateClassSessionStatus(data?: any): Promise<any> {
    const updateClassSession = await this.postData(
      this.apiUrlCreator("ClassSession/UpdateSessionStatus"),
      data
    );
    return updateClassSession;
  }

  async cancelClassSession(data?: any): Promise<any> {
    const cancelClassSession = await this.postData(
      this.apiUrlCreator("ClassSession/Delete"),
      data
    );
    return cancelClassSession;
  }

  async purchaseClass(req: any): Promise<any> {
    try {
      const purchaseDetails: any = await this.postData(
        `${this.apiUrlCreator(`Class/Purchase`)}`,
        req
      );
      return purchaseDetails;
    } catch (e) {
      return e;
    }
  }
  async autoCompleteClients(req: any): Promise<any> {
    let response = [];
    const autoCompleteClients: any = await this.postData(
      `${this.apiUrlCreator(`client/AutoComplete`)}`,
      req
    );
    response = autoCompleteClients.Items;
    return response;
  }

  async searchClient(req: any): Promise<any> {
    let response = [];
    const autoCompleteClients: any = await this.postData(
      `${this.apiUrlCreator(`client/PredictiveFilter`)}`,
      req
    );
    response = autoCompleteClients.Items;
    return response;
  }

  async addClient(req: any): Promise<any> {
    const saveClientDetails: any = await this.postData(
      `${this.apiUrlCreator(`Client`)}`,
      req
    );
    return saveClientDetails;
  }
  async getClientsForSession(data?: any): Promise<any> {
    const getClients = await this.postData(
      this.apiUrlCreator("ClassSession/GetClient"),
      data
    );
    return getClients;
  }
  async getAvailabilityBySchedules(data?: any): Promise<any> {
    const getAvailabilityBySchedules = await this.postData(
      this.apiUrlCreator("Validation/GetAvailabilityBySchedules"),
      data
    );
    return getAvailabilityBySchedules;
  }
  async getSessionsForClient(data?: any): Promise<any> {
    const getSessions = await this.postData(
      this.apiUrlCreator("ClassSession/GetBookedSessionByUser"),
      data
    );
    return getSessions;
  }

  async getSearchHeaderInformation(data?: any): Promise<any> {
    const getSearchHeaderData = await this.postData(
      this.apiUrlCreator("ClassInstance/SearchHeaderInformation"),
      data
    );
    return getSearchHeaderData;
  }

  async getClassPayouts(ClassId: number): Promise<any> {
    let response = [];
    const getClassPayouts: any = await this.getData(
      this.apiUrlCreator(`Class/Payouts?ClassId=${ClassId}`)
    );
      response = getClassPayouts?.Items || [];
    return response;
  }

  async persistPayouts(req: any): Promise<any> {
    const persistPayouts: any = await this.postData(
      `${this.apiUrlCreator(`Class/PersistPayouts`)}`,
      req
    );
    return persistPayouts;
  }

  async updateClass(data?: any): Promise<any> {
    const updateClass = await this.postData(
      this.apiUrlCreator("Class/Put"),
      data
    );
    return updateClass;
  }

  async GetChargeBackTransactions(data?: any): Promise<any> {
    let response = null;
    const GetChargeBackTransactions: any = await this.postData(
      this.apiUrlCreator("managechargeback/Get"),
      data
    );
    if (GetChargeBackTransactions?.ResponseCode === 100) {
      response = GetChargeBackTransactions;
    }
    return response;
  }
  async GetByTransactionId(data?: any): Promise<any> {
    const GetByTransactionId: any = await this.postData(
      this.apiUrlCreator("managechargeback/ByTransactionId"),
      data
    );

    return GetByTransactionId;
  }
  async UpdateTransactionAsChargeback(data?: any): Promise<any> {
    const UpdateTransactionAsChargeback: any = await this.postData(
      this.apiUrlCreator("managechargeback/UpdateTransactionStatus"),
      data
    );
    return UpdateTransactionAsChargeback;
  }

  async PayChargeBack(data?: any): Promise<any> {
    const PayChargeBack: any = await this.postData(
      this.apiUrlCreator("managechargeback/Pay"),
      data
    );
    return PayChargeBack;
  }
  async WaiveChargeBack(data?: any): Promise<any> {
    const WaiveChargeBack: any = await this.postData(
      this.apiUrlCreator("managechargeback/Waive"),
      data
    );
    return WaiveChargeBack;
  }
  async AllowServicesForClient(data?: any): Promise<any> {
    const AllowServicesForClient: any = await this.postData(
      this.apiUrlCreator("client/UpdateStatus"),
      data
    );
    return AllowServicesForClient;
  }
  async getAllAgreements(data?: any): Promise<any> {
    const agreements: any = await this.postData(
      this.apiUrlCreator("Agreement/Filter"),
      data
    );
    return agreements;
  }
  async getAgreementDetails(agreementId: number): Promise<any> {
    const agreement: any = await this.getData(
      this.apiUrlCreator(`Agreement/GetAgreement?agreementId=${agreementId}`)
    );
    return agreement;
  }
  async getAgreementDetailsForDownload(
    agreementName: string,
    agreementId: number
  ): Promise<any> {
    const agreement: any = await this.getData(
      this.apiUrlCreator(
        `Agreement?agreementName=${agreementName}&agreementId=${agreementId}`
      ),
      {
        responseType: "blob",
      }
    );
    return agreement;
  }

  async deleteGroup(data?: any): Promise<any> {
    const deleteGroup = await this.postData(
      this.apiUrlCreator(`Class/Delete?classId=${data?.ClassId}`)
    );
    return deleteGroup;
  }
  async ActivateGroup(data?: any): Promise<any> {
    const deleteGroup = await this.postData(
      this.apiUrlCreator(`Class/Activate?classId=${data?.ClassId}`)
    );
    return deleteGroup;
  }

  async validateEmail(email?: any): Promise<any> {
    const emailResponse = await this.postData(
      this.apiUrlCreator(`client/ValidateEmail?email=${email}`)
    );
    return emailResponse;
  }

  async activateMember(UserMemberId?: string): Promise<any> {
    const response = await this.postData(
      this.apiUrlCreator(`client/Activate?UserMemberId=${UserMemberId}`),
      {}
    );
    return response;
  }
  async ApproveWaitListForClient(req: any): Promise<any> {
    const requestData = {
        "ScheduleId" :req?.scheduleId,
        "ClassSessionId" : req.classSessionId
    }
    const ApproveWaitListForClient = await this.postData(
      this.apiUrlCreator(`Class/ApproveWaitList`),
      requestData      
    );
    return ApproveWaitListForClient;
  }

  async payoutHistory(req: any): Promise<any> {
    const responseData: any = await this.postData(
      this.apiUrlCreator(`Class/PayoutHistory`), req
    );
    return responseData;
  }

  async ResetPayout(req: any): Promise<any> {
    const responseData: any = await this.postData(
      this.apiUrlCreator(`Class/ResetPayout?payoutGeneratedFrom=${req.payoutGeneratedFrom}&entityId=${req.entityId}`)
    );
    return responseData;
  }
}

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";

const useAffiliatePromotionOverview = (props: any) => {
  const history=useHistory()
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [promotion, setPromotion] = useState<any>(null);
  const [rewardTypes, setRewardTypes] = useState<any>([]);
  const [PaymentTerms, setPaymentTerms] = useState([]);
  const [showProspectDialog, setShowProspectDialog] = useState<any>(false);
  const [selectedPaymentTermObj, setSelectedPaymentTermObj] =
    useState<any>(null);
  const [showAffiiateDialog, setShowAffiliateDialog] = useState(false);

  const service = new AffiliateProgramService();
  useEffect(() => {
    fetchRewardTypes();
    getPromotion();
    getPaymentTerms();
  }, []);
  const getPromotion = async () => {
    const req = {
      AffiliateId: parseInt(props?.associateId),
      PromotionId: parseInt(props?.PromotionId),
      IncludeProspectDetails:true,
    };
    setLoading(true);
    const res = await service.getPromotions(req);
    setPromotion(res?.Items?.[0]);
    setLoading(false);
  };
  const getPaymentTerms = async () => {
    const data = await service.getPaymentTerms();
    setPaymentTerms(data || []);
  };
  const fetchRewardTypes = async () => {
    const res = await service.getRewardTypes();
    setRewardTypes(res);
  };
  const toggleProspectDialog=()=>{
    setShowProspectDialog(!showProspectDialog)
  }


  const handleBack = () => {
    if(props?.admin){
      history.replace(
        `/app/affiliatedashboard/${props?.uuid}/${props?.associateId}/promotions`
      );
    }else{
      history.replace(
        `/affiliate/promotions/`
      );
    }
  };

  const handlePaymentTerm = (dataItem: any) => {
    let PromotionAffiliateId;
    let PromotionId = dataItem?.PromotionId;
    let PayoutMinValue = dataItem?.PayoutMinValue;
    let PaymentTermId = dataItem?.PaymentTermId;
    if (dataItem?.PromotionAffiliates?.length > 0) {
      PromotionAffiliateId =
        dataItem?.PromotionAffiliates[0]?.PromotionAffiliateId;
      PaymentTermId = dataItem?.PromotionAffiliates[0]?.PaymentTermId;
      PayoutMinValue = dataItem?.PromotionAffiliates[0]?.PayoutMinValue;
    }
    setSelectedPaymentTermObj({
      PromotionAffiliateId,
      AssociateId: props?.associateId,
      PromotionId,
      PayoutMinValue,
      PaymentTermId,
      IsActive: true,
    });
    setShowAffiliateDialog(true);
  };

  const handleCloseDialog = () => {
    setShowAffiliateDialog(false);
  };

  const handleChangePaymentTermObj = (value: any, name: string) => {
    setSelectedPaymentTermObj({ ...selectedPaymentTermObj, [name]: value });
  };

  const handleUpdatePaymentTermObj = async () => {
    const req = [
      {
        PromotionAffiliateId: selectedPaymentTermObj?.PromotionAffiliateId,
        AssociateId: selectedPaymentTermObj?.AssociateId,
        PromotionId: selectedPaymentTermObj?.PromotionId,
        PayoutMinValue: selectedPaymentTermObj?.PayoutMinValue,
        PaymentTermId: selectedPaymentTermObj?.PaymentTermId,
        IsActive: true,
      },
    ];
    setBtnLoading(true);
    const res = await service.UpdatePromotionAffiliatesByPromotionId(
      selectedPaymentTermObj?.PromotionId,
      req
    );
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Min. Payout & Payment Term Updated Successfully`,
        "success"
      );
      setShowAffiliateDialog(false);
      await getPromotion();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in Updating Min. Payout & Payment Term";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  return {
    loading,
    rewardTypes,
    promotion,
    handleBack,
    handlePaymentTerm,
    showAffiiateDialog,
    handleCloseDialog,
    PaymentTerms,
    selectedPaymentTermObj,
    handleChangePaymentTermObj,
    btnLoading,
    handleUpdatePaymentTermObj,
    toggleProspectDialog,
    showProspectDialog
  };
};

export default useAffiliatePromotionOverview;

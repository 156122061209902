import React from "react";
import { GetLocalStore } from "utils/storage";
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import { Dialog } from "@progress/kendo-react-dialogs";
import { MdContentCopy } from "react-icons/md";

const SharableLinksDialog = (props: any) => {
  let ClubName = GetLocalStore("tenantUserSession")?.ClubName;
  if (!props?.uuid) {
    ClubName = GetLocalStore("AffiliateDetails")?.UserSession?.ClubName;
  }
  let title = `Promotions link for ${ClubName}`;
  let DialogTitle = `Share Promotion Link`;
  let summary = `Please click on the link below to view special pricing for ${ClubName}`;
  const { selectedLink } = props;
  if(props?.isForm){
    title=`Promotions link for ${ClubName}`
    summary= `Please click on the link below to fill the form for ${ClubName}`;
    DialogTitle="Share Form Link"
  }
  const name = selectedLink?.name;
  const url = selectedLink?.url;
  return (
    <>
      <Dialog
        title={DialogTitle}
        onClose={() => props?.toggleDialog()}
        style={{ zIndex: 9999 }}
        width={"35%"}
        height={"200px"}
      >
        <div className="">
          <div className="d-flex align-items-center flex-column mb-2">
            <div className="my-2" style={{ width: "100%" }}>
              <div className="d-flex align-items-center linkbg">
                <div className="flex-grow-1">
                  {" "}
                  <a href={url} target="_blank">
                  <div style={{display:'flex', justifyContent:'space-between'}} className="align-items-center">
                      <div>  <i
                      className="fa fa-link"
                      style={{
                        fontSize: "11px",
                        marginRight: "5px",
                      }}
                    ></i></div>
                      <div style={{flexGrow:'1'}}> {name} </div>
                      </div>
                   
                   
                  </a>
                </div>
                <div className="flex-shrink-0">
                  <span className="bz-cursor-pointer">
                    <MdContentCopy
                      onClick={() => props?.copyToClipBoard(url)}
                      title={"Copy"}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="sharableIcons">
              <FacebookShareButton url={url} title={title}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <LinkedinShareButton url={url} title={title} summary={summary}>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
              <TwitterShareButton url={url} title={title}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <EmailShareButton url={url} subject={title} body={summary}>
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};


export default SharableLinksDialog
import React, { useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";


import {
  Input,
  MaskedTextBox,
  MaskedTextBoxChangeEvent,
  RadioButton,
  Switch,
} from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { TenantService } from "services/tenant/index.service";
import { getFullImageUrl } from "utils";
import {
  emailValidator,
  mobileValidator,
  NameValidator,
  zipCodeValidator,
} from "validators/validator";
import Camera from "./camera";
import { isMobile } from "react-device-detect";
import { GetLocalStore, LocalStore } from "utils/storage";
import CreateClient from "./clientCreation/CreateClient";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";
import { useDispatch } from "react-redux";
import { setUserConfiguration } from "redux-slices/userSlice";
import UpdateEmailDialog from "./UpdateEmail";
import { FaEdit } from "react-icons/fa";
import { ClassService } from "services/class/index.service";
import moment from "moment";
const CustomPopup = (props: any) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "center",
        vertical: "bottom",
      }}
      popupAlign={{
        horizontal: "center",
        vertical: "bottom",
      }}
    />
  );
};
const CellForMobileValidation = (props: GridCellProps) => {
  const handleChange = (e: MaskedTextBoxChangeEvent) => {
    if (props.onChange) {
      props?.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };
  if (props.dataItem.inEdit) {
    return (
      <td>
        <MaskedTextBox
          className="FamilyMemberEdit"
          width={"100%"}
          mask="(999) 000-0000"
          value={props.dataItem.Phone}
          onChange={handleChange}
        />
      </td>
    );
  } else {
    return <td>{props.dataItem.Phone}</td>;
  }
};
const MyProfile = (props: any) => {
  const isAdminOrStaff = [1, 8].includes(
    GetLocalStore("CurrentApplicationType")
  );
  const IsAffiliateFeatureEnabled=GetLocalStore("Configuration")?.IsAffiliateFeatureEnabled
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<any>(true);
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [enforcePPDialog, setEnforcePPDialog] = useState<any>(false);
  const [enforceBtnLoading, setEnforceBtnLoading] = useState<any>(false);
  const [addClient, setAddClient] = useState(false);
  const [manageNotifications, setManageNotifications] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [submit, setsubmit] = useState<any>(false);
  const [message, setMessage] = useState("");
  const [userDetails, setUserDetails] = useState<any>(null);
  const [newPhotoStatus, setNewPhotoStatus] = useState<any>(false);
  const [enforcePP, setEnforcePP] = useState<any>(false);
  const [userData, setUserData] = useState<any>(null);
  const [statesList, setStatesList] = useState<any>([]);
  const [editItem, setEditItem] = useState<any>(null);
  const [origstatesList, setOrigStatesList] = useState<any>([]);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [showUploadDialog, setShowUploadDialog] = useState<any>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<any>(false);
  const [showUpdateEmailDialog, setShowUpdateEmailDialog] =
    useState<boolean>(false);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [photoUploadPref, setphotoUploadPref] = React.useState("2");
  const [snapString, setSnapString] = React.useState("");
  const service = new TenantService();
  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 3000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
      if (submit) {
        fetchUserDetails();
        props?.handleAfterSubmit(props?.userData?.UserMemberId);
      }
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);
  useEffect(() => {
    getAllSubMembers();
    fetchUserDetails();
    fetchStates();
  }, []);

  const handleEnforcePPDialog=()=>{
    setsubmit(false);
    setEnforcePPDialog(true)
    setEnforcePP(userDetails?.Status === 16 ? true : false)
  }
  const closeEnforcePPDialog=()=>{
    setEnforcePPDialog(false)
  }
  const handleChangeEnforce=(val:boolean)=>{
    setEnforcePP(val)
  }

  const getAllSubMembers = async () => {
    let memberId = props?.userData?.UserMemberId;
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId,true);
    setAllClientsList(res);
  };

  const handleClose = (userMemberId:any=null) => {
    getAllSubMembers();
    setAddClient(false);
    setEditItem(null);
    setEditClient(false);
  };
  const handleCloseNotification = () => {
    setManageNotifications(false)
  };

  const fetchStates = async () => {
    const req = {};
    const res = await service?.getStates(req);
    setStatesList(res || []);
    setOrigStatesList(res || []);
  };

  const fetchUserDetails = async () => {
    let userMemberId = props?.userData?.UserMemberId;
    setsubmit(false);
    setLoading(true);
    setNewPhotoStatus(false);
    const res = await service?.getUserInfo(userMemberId);
    setLoading(false);

    const {
      UserMemberId,
      UserId,
      AccessTagId,
      MemberNumber,
      MemberType,
      Status,
      User: {
        UserAssociateId,
        Email,
        FirstName,
        LastName,
        Photo,
        DateOfBirth,
        Address: { Phone, StateId, City, ZipCode, Address1 },
      },
    } = res;
    const data = {
      UserAssociateId,
      UserMemberId,
      AccessTagId,
      UserId,
      Email,
      FirstName,
      LastName,
      MemberType,
      Photo,
      Phone,
      MemberNumber,
      StateId,
      City,
      DateOfBirth,
      ZipCode,
      Address1,
      Status
    };
    console.log(data);
    setUserDetails(data);
    setUserData(data);
  };

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          const b64 = reader?.result?.replace(/^data:.+;base64,/, "");
          //console.log(reader?.result); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);
  const handleValidation = () => {
    if (
      mobileValidator(userDetails?.Phone) ||
      zipCodeValidator(userDetails?.ZipCode) ||
      emailValidator(userDetails?.Email) ||
      NameValidator(userDetails?.FirstName, "First Name") ||
      NameValidator(userDetails?.LastName, "Last Name")
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = async () => {
    const check = handleValidation();
    if (check) {
      const recUser = GetLocalStore("userDetails");
      setBtnLoading(true);
      if(userDetails.DateOfBirth){
        userDetails.DateOfBirth = moment(userDetails.DateOfBirth).format('YYYY-MM-DD');
      }
      const res = await service?.saveUserInfo(userDetails);
      setBtnLoading(false);
      if (res?.ResponseCode === 100) {
        const updatedres = {
          ...recUser,
          UserSession: {
            ...recUser?.UserSession,
            FullName: `${userDetails.FirstName} ${userDetails.LastName}`,
          },
        };
        LocalStore("userDetails", updatedres);
        dispatch(
          setUserConfiguration({
            loggedIn: true,
            userDetails: updatedres,
          })
        );
        const successMsg = "Profile Updated Successfully";
        setMessage(successMsg);
        setNotification({ ...notification, success: true, error: false });
        setsubmit(true);
      } else {
        const errorMsg = res?.ErrorMessages?.[0] || "Error in Updating Profile";
        setMessage(errorMsg);
        setNotification({ ...notification, error: true, success: false });
      }
    }
  };
  const handleCancel = () => {
    setUserDetails(userData);
  };

  const StateNamefilterChange = (e: any) => {
    const filteredData = stateNameFilterData(e.filter);
    setStatesList(filteredData);
  };

  const stateNameFilterData = (filter: any) => {
    const data = origstatesList.slice();
    return filterBy(data, filter);
  };

  const handleChange = (val: any, name: string) => {
    setUserDetails({
      ...userDetails,
      [name]: val,
    });
  };
  const deleteImage = (status: boolean) => {
    if (status) {
      setUserDetails({
        ...userDetails,
        Photo: null,
      });
      if (files.length > 0) {
        setFiles([]);
        setOriginalFiles([]);
      }
    }
    setNewPhotoStatus(false);
    toggleDeleteDialog();
  };

  const UploadImage = () => {
    if (photoUploadPref === "2") {
      if (files?.length === 0) {
        setMessage("Please select image to upload");
        setNotification({ ...notification, error: true });
        return true;
      }
      const allowdFileTypes = [".jpeg", ".jpg", ".png"];
      let check = false;
      files.length > 0 &&
        files.forEach((file: any) => {
          if (!allowdFileTypes.includes(file?.Extension.toLowerCase())) {
            const errorMsg = "Please upload allowed File Type";
            setMessage(errorMsg);
            setNotification({ ...notification, error: true });
            check = true;
            return true;
          }
          if (file?.Size > 500000) {
            const errorMsg = "Please upload image less than 500KB";
            setMessage(errorMsg);
            setNotification({ ...notification, error: true });
            check = true;
            return true;
          }
        });
      if (check) {
        return true;
      }
      setUserDetails({
        ...userDetails,
        Photo: files[0]?.ImageAsBase64,
      });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    } else if (photoUploadPref === "1") {
      setUserDetails({
        ...userDetails,
        Photo: snapString,
      });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    }
  };
  const toggleUplaodDialog = () => {
    setShowUploadDialog(false);
  };
  const toggleDeleteDialog = () => {
    setShowDeleteDialog(false);
  };
  const renderDeleteDialog = () => {
    return (
      <Dialog onClose={() => toggleDeleteDialog()}>
        <p className={"dialogContainer"}>
          Are you sure you want to remove the photo?
        </p>
        <DialogActionsBar>
          <Button onClick={() => deleteImage(false)}>{"No,keep it."}</Button>
          <Button primary={true} onClick={() => deleteImage(true)}>
            Yes
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };
  const handleAllowService = async () => {
    const req = {
      UserMemberId: userDetails?.UserMemberId,
      Status: enforcePP ? 16 : 0
    };
    setEnforceBtnLoading(true);
    const service = new ClassService();
    const res = await service.AllowServicesForClient(req);
    setEnforceBtnLoading(false);
    if (res?.ResponseCode === 100) {
      closeEnforcePPDialog()
      const successMsg = res?.SuccessMessage || "Successfully Modified";
      props?.handleNotificationMessage(successMsg, "success");
      setsubmit(true);
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in Updating Status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const renderEnforcePPDialog = () => {
    return (
      <Dialog onClose={() => closeEnforcePPDialog()}  title={'Modify Enforce Payment Profile Status'} className="bz-book-Appoinment-dialog" >
          <span >Enforce Payment Profile</span>
          <span className="px-3">:</span>
          <span className="">
            <Switch
              checked={enforcePP}
              onChange={(e: any) => handleChangeEnforce(e.value)}
            />
          </span>
        <DialogActionsBar>
          <Button onClick={() => closeEnforcePPDialog()} disabled={enforceBtnLoading}>{"Cancel"}</Button>
          <BuzopsButton onClick={() => handleAllowService()} label={'Submit'} loading={enforceBtnLoading} disable={enforceBtnLoading} />
        </DialogActionsBar>
      </Dialog>
    );
  };
  const handleTakeASnapClick = () => {
    setphotoUploadPref("1");
  };
  const handleUploadAFileClick = () => {
    setphotoUploadPref("2");
  };
  const handleCapture = (e: any) => {
    setSnapString(e);
  };
  const handleRefreshCamera = (e: any) => {
    setphotoUploadPref("2");
  };
  const renderUploadDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-familyMember-dialog"
        title={"Upload a Profile Photo"}
        onClose={() => toggleUplaodDialog()}
        width={"34rem"}
      >
        <div style={{ textAlign: "justify" }} className="k-grid bzc-grid">
          <div className="row">
            <div className="col-sm-12">
              <div className="sales-radio-button-headers">
                <RadioButton
                  name="uploadPhoto2"
                  value="2"
                  checked={photoUploadPref === "2"}
                  label="Upload a File"
                  onClick={handleUploadAFileClick}
                />
                {"   "}
                {!isMobile && (
                  <RadioButton
                    name="uploadPhoto1"
                    value="1"
                    checked={photoUploadPref === "1"}
                    label="Take a Snap"
                    onClick={handleTakeASnapClick}
                  />
                )}
              </div>
              <hr />
              <div className="k-form-field">
                {/* <label className="k-label pt-3"></label> */}
                {photoUploadPref === "2" && (
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 500000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                )}
                {photoUploadPref === "1" && (
                  <div>
                    <Camera
                      handleCameraCapture={handleCapture}
                      handleRefreshCamera={handleRefreshCamera}
                    />
                    {/* <Hint>*Please make sure camera permissions are provided</Hint> */}
                  </div>
                )}
                <Hint>
                  {
                    "Only JPEG and PNG files are allowed. Allowed size below 500KB"
                  }
                </Hint>
              </div>
            </div>
          </div>
        </div>
        <Button
          className={"float-right mt-3"}
          onClick={() => toggleUplaodDialog()}
        >
          Cancel
        </Button>
        <Button
          className={"float-right mt-3"}
          primary={true}
          onClick={() => UploadImage()}
        >
          Upload
        </Button>
      </Dialog>
    );
  };

  const handleEdit = (dataItem: any) => {
    setEditItem(dataItem);
    setEditClient(true);
  };

  const EditForm = () => {
    return (
      <>
        {editItem?.MemberType === 0 ? (
          <>
            {" "}
            <h5 className="mb-3 bz_fs_1_5">
              <button
                className="btn btn-primary bz-back-btn btn-sm"
                onClick={() => handleClose()}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
              &nbsp;Update Client Details
            </h5>
            <hr />
            <>{ParentMemberForm()}</>
          </>
        ) : (
          <>
            <CreateClient
              staffPage={props?.staffPage}
              handleClose={(success=false) => handleClose()}
              editItem={editItem}
              parentUser={AllClientsList?.find((i:any)=>i?.MemberType===0)}

            />
          </>
        )}
      </>
    );
  };

  const ParentMemberForm = () => {
    return (
      <>
        {!(
          userDetails?.UserAssociateId === 0 ||
          userDetails?.UserAssociateId === null
        ) && (
          <div
            className="alert alert-warning mb-0 py-2 px-3"
            role="alert"
          >
           {` This profile is already associated with a ${IsAffiliateFeatureEnabled ? "staff/affiliate":"staff"} in this club.
            Profile changes cannot be made. Changes can only be made from the
            ${IsAffiliateFeatureEnabled ? "Staff/Affiliate":"Staff"} page.`}
          </div>
        )}
        <div className="bz-subsection-row bz-myprofile-container">
          <div className="left-section">
            <div className="row bz-mt-2rem bz-mb-2_5rem">
              <div className="col-sm-9 col-xl-10 col-xxl-9 order-2 order-sm-1">
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">First Name</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="FirstName"
                      id="FirstName"
                      className="form-control col-sm-10 col-xl-10"
                      value={userDetails?.FirstName}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "FirstName")
                      }
                      disabled={
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                    />
                    {NameValidator(userDetails?.FirstName, "First Name") && (
                      <Error>
                        {NameValidator(userDetails?.FirstName, "First Name")}
                      </Error>
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Last Name</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="LastName"
                      id="LastName"
                      className="form-control col-sm-10 col-xl-10"
                      value={userDetails?.LastName}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "LastName")
                      }
                      disabled={
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                    />
                    {NameValidator(userDetails?.LastName, "Last Name") && (
                      <Error>
                        {NameValidator(userDetails?.LastName, "Last Name")}
                      </Error>
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Phone Number
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <MaskedTextBox
                        mask="(999) 000-0000"
                        name="Phone"
                        id="Phone"
                        className="form-control col-sm-10 col-xl-10"
                        value={userDetails?.Phone}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "Phone")
                        }
                        disabled={
                          !(
                            userDetails?.UserAssociateId === 0 ||
                            userDetails?.UserAssociateId === null
                          )
                        }
                      />
                      {mobileValidator(userDetails?.Phone) && (
                        <Error>{mobileValidator(userDetails?.Phone)}</Error>
                      )}
                    </FieldWrapper>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Email</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <div className="input-group edit-email-group col-sm-10 col-xl-10 d-flex p-0"> 
                      <FieldWrapper>
                        <Input
                          autoComplete={"off"}
                          name="Email"
                          id="Email"
                          className="form-control"
                          value={userDetails?.Email}
                          onChange={(e: any) =>
                            handleChange(e.target.value, "Email")
                          }
                          disabled={true}
                        />
                        {emailValidator(userDetails?.Email) && (
                          <Error>{emailValidator(userDetails?.Email)}</Error>
                        )}
                      </FieldWrapper>
                      <div className="input-group-append">
                      <BuzopsTooltip
                    html={
                      <span
                        title="Update Email"
                      > 
                     <Button
                          disabled={
                            !(
                              userDetails?.UserAssociateId === 0 ||
                              userDetails?.UserAssociateId === null
                            )
                          }
                          onClick={() => setShowUpdateEmailDialog(true)}
                          className="email-edit-icon"
                        >
                     <FaEdit />
    </Button>
    </span>
                    }
                  />
                   
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Date of Birth
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <DatePicker
                        max={new Date()}
                        name="DateOfBirth"
                        placeholder={`Month/Day/Year`}
                        id="DateOfBirth"
                        width={"100%"}
                        className="col-sm-10 col-xl-10"
                        value={
                          userDetails?.DateOfBirth
                            ? new Date(userDetails?.DateOfBirth)
                            : userDetails?.DateOfBirth
                        }
                        onChange={(e) => {
                          handleChange(e.value, "DateOfBirth");
                        }}
                        popup={CustomPopup}
                        disabled={
                          !(
                            userDetails?.UserAssociateId === 0 ||
                            userDetails?.UserAssociateId === null
                          )
                        }
                      />
                    </FieldWrapper>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Address</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="Address1"
                      id="Address1"
                      className="form-control col-sm-10 col-xl-10"
                      value={userDetails?.Address1}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "Address1")
                      }
                      disabled={
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                    />
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">City</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="City"
                      id="City"
                      className="form-control col-sm-10 col-xl-10"
                      value={userDetails?.City}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "City")
                      }
                      disabled={
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                    />
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">State</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      data={statesList}
                      textField="StateName"
                      dataItemKey="StateId"
                      name="StateId"
                      id="StateId"
                      className="form-control col-sm-10 col-xl-10"
                      onChange={(e) =>
                        handleChange(e.value?.StateId, "StateId")
                      }
                      value={
                        statesList?.filter(
                          (i: any) => i.StateId === userDetails?.StateId
                        )?.[0] || 0
                      }
                      filterable={true}
                      onFilterChange={(e) => StateNamefilterChange(e)}
                      disabled={
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                    />
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Zipcode</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <MaskedTextBox
                        name="ZipCode"
                        mask="00000"
                        id="ZipCode"
                        className="form-control col-sm-10 col-xl-10"
                        value={userDetails?.ZipCode}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "ZipCode")
                        }
                        disabled={
                          !(
                            userDetails?.UserAssociateId === 0 ||
                            userDetails?.UserAssociateId === null
                          )
                        }
                      />
                      {zipCodeValidator(userDetails?.ZipCode) && (
                        <Error>{zipCodeValidator(userDetails?.ZipCode)}</Error>
                      )}
                    </FieldWrapper>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">
                    Client Number
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="MemberNumber"
                      id="MemberNumber"
                      disabled={true}
                      className="form-control col-sm-10 col-xl-10"
                      value={userDetails?.MemberNumber}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "MemberNumber")
                      }
                    />
                  </div>
                </div>
                <div className="bz-content-text-row mt-4 pt-2">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <Button onClick={() => handleCancel()} className="ml-0">
                      Reset
                    </Button>
                    <BuzopsButton
                      disabled={
                        btnLoading ||
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                      type={"submit"}
                      loading={btnLoading}
                      label={"Save"}
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-xl-2 col-xxl-3 order-1 order-sm-2">
                <div className="bz-Manage-User-Img d-flex justify-content-center pb-4">
                  <div className="mx-auto ProfilePicOptions">
                    <img
                      width={120}
                      src={
                        !newPhotoStatus
                          ? userDetails?.Photo
                            ? getFullImageUrl(userDetails?.Photo)
                            : DummyImage
                          : userDetails?.Photo
                      }
                      className="rounded-circle img-circle"
                    />
                    <span
                      className="bz-delete-userImg customtooltip"
                      onClick={() => {
                        handleDeleteDialog();
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                      <span className="tooltiptext">Delete</span>
                    </span>
                    <span
                      className="bz-upload-userImg customtooltip"
                      onClick={() => {
                        setShowUploadDialog(true);
                      }}
                    >
                      <i className="fa fa-camera" aria-hidden="true"></i>
                      <span className="tooltiptext">Upload</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const CellForMemberType = (props: any) => {
    let memberTypeVal = "Primary";
    if (props?.dataItem?.MemberType === 1) {
      memberTypeVal = "Family Member";
    } else if (props?.dataItem?.MemberType === 2) {
      memberTypeVal = "Child";
    }
    return <td>{memberTypeVal}</td>;
  };

  const CommandCell = (props: any) => {
    return (
      <td>
        <a
          title="Edit"
          onClick={() => handleEdit(props?.dataItem)}
          className="bz-cursor-pointer"
        >
          <i
            title="Edit"
            style={{ fontSize: "16px" }}
            className="fa fa-edit"
          ></i>
        </a>
      </td>
    );
  };
  const activateMember = async (UserMemberId:any) => {
    const service =new ClassService()
    const res = await service.activateMember(UserMemberId);
    if (res?.ResponseCode === 100) {
      const successMsg = "Activated Successfully";
      setMessage(successMsg);
      setNotification({ ...notification, success: true, error: false });
      getAllSubMembers();
    } else {
      const errorMsg = res?.ErrorMessages?.[0] || "Unable to activate member";
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
    }
  };

  const StatusCell = (props: any) => {
    return (
      <td>
       <div className="bz-profile-activate">
       {props?.dataItem?.IsActive ? "Active":"Archived"}
        {!props?.dataItem?.IsActive &&  <span
          title="Activate"
          onClick={() => activateMember(props?.dataItem?.UserMemberId)}
          className="btn btn-link bz-cursor-pointer"
          style={{marginLeft:'0.125rem'}}
        >
         (Activate)
        </span>}
       </div>
      </td>
    );
  };

  const AllMembersGrid = () => {
    return (
      <>
        <Grid
          dataItemKey="UserMemberId"
          data={AllClientsList}
          className="bzc-grid bz-personalInfo-grid"
          
          // style={{ maxHeight: "300px", border: 0 }}
        >
          <Column
            field="MemberType"
            title="Member Type"
            cell={CellForMemberType}
          />
          <Column field="FullName" title="Full Name" />
          <Column field="Email" title="Email" />
          <Column
            field="Phone"
            title="Phone Number"
            cell={CellForMobileValidation}
          />
          <Column cell={StatusCell}  title="Status" />
          <Column cell={CommandCell} width={"45px"} />
        </Grid>
      </>
    );
  };

  const handleDeleteDialog = () => {
    if (userDetails?.Photo) {
      setShowDeleteDialog(true);
    }
    return;
  };

  const DummyImage = "/images/user.png";
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  const Image = userDetails?.Photo || DummyImage;
  if (addClient) {
    return (
      <CreateClient
        handleClose={(success=false) => handleClose()}
        UserMemberId={props?.userData?.UserMemberId}
        parentUser={AllClientsList?.find((i:any)=>i?.MemberType===0)}
      />
    );
  }
  return (
    <>
      {editClient ? (
        <>{EditForm()}</>
      ) : (
        <>
          <h5 className="d-flex justify-content-between mb-3 bz_fs_1_5">
            Personal Information{" "}
            <span className={`edit-btn-container`}>
              {userDetails?.MemberType===0 && (
                <>
                  <button
                    className="btn btn-link bz-overview-add-member-btn"
                    onClick={() => setAddClient(true)}
                  >
                    Add Family Member
                  </button>
                </>
              )}
            </span>
          </h5>
          <hr />
          {AllClientsList?.length > 1 ? (
            <>
              <AllMembersGrid />
            </>
          ) : (
            <>{ParentMemberForm()}</>
          )}
        </>
      )}
      {showUploadDialog && renderUploadDialog()}
      {showDeleteDialog && renderDeleteDialog()}
      {enforcePPDialog && isAdminOrStaff && renderEnforcePPDialog()}
      {showUpdateEmailDialog && (
        <UpdateEmailDialog
          setShowUpdateEmailDialog={setShowUpdateEmailDialog}
          setMessage={setMessage}
          setNotification={setNotification}
          notification={notification}
          handleChange={handleChange}
          setUserDetails={setUserDetails}
          userDetails={userDetails}
          isAdminOrStaff={isAdminOrStaff}
        />
      )}

      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
    </>
  );
};

export default MyProfile;

import withNotification from "components/Hoc/withNotification";
import PurchasePlanList from "components/plans/PurchasePlanList";
import { useExternalItem } from "./useExternalItem";
const Membership = (props: any) => {
    const data = useExternalItem(props, props?.shortCodeValue ? props?.shortCodeValue : 'membership')

    return (
        <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
            <div className="col-12 col-xl-10">
                {!props?.shortCodeValue && <h5 className="bzo-primary-heading">{`Membership`}</h5>}
                {props?.shortCodeValue ? <PurchasePlanList page={"online"}
                    guest={true} ShortCodeStatus={props?.ShortCodeStatus}
                    planShortName={props?.shortCodeValue} /> : <PurchasePlanList ShortCodeStatus={props?.ShortCodeStatus} />}
            </div>
        </div>
    );
};

export default withNotification(Membership);

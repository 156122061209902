import { AutoComplete, ListItemProps } from "@progress/kendo-react-dropdowns";
import React from "react";
import CheckinUser from "./CheckinUser";
import { MdPersonSearch, MdLocationPin, MdCalendarToday } from "react-icons/md";
import useCheckinFromLiveStream from "./Container/CheckinFromLiveStream.hook";
import DialogPopup from "atoms/DialogPopup/DialogPopup";
import withNotification from "components/Hoc/withNotification";
import { CheckinFrom, CheckinMode } from "./Utils/constants";

const CheckinFromLiveStream = (props: any) => {
  const checkinFormLiveStream = useCheckinFromLiveStream(props);
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <div className="d-flex justify-content-between align-items-center">
        <div className="live-stream-left-search d-inline-block">
          <div className="livestreamname bz-Global-userResult">
            <h6 className="d-inline">{itemProps?.dataItem?.FullName}</h6>
            <small className="text-muted">{itemProps?.dataItem?.Email}</small>
          </div>
        </div>
        <div className="live-stream-right-search d-inline-block">
          <div>
            <div className="locationicon">
              <span>
                <MdLocationPin
                  className="icon-color"
                  onClick={async (event) =>
                    await checkinFormLiveStream.handleLocationCheckin(
                      event,
                      itemProps?.dataItem
                    )
                  }
                  title="Location Check-In"
                />
              </span>
            </div>
            <div className="mdcalendaricon">
              <span>
                <MdCalendarToday
                  className="icon-color"
                  onClick={(event) =>
                    checkinFormLiveStream.handleCheckInPopup(
                      event,
                      itemProps?.dataItem
                    )
                  }
                  title="Appointment Check-In"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <>
      <div className="bz-global-search">
        <AutoComplete
          data={checkinFormLiveStream.clientsList}
          style={{
            maxWidth: "300px",
            width: "100%",
          }}
          className="livestream_search"
          itemRender={itemRender}
          value={
            checkinFormLiveStream.selectedClient?.type === "search"
              ? checkinFormLiveStream.selectedClient?.value
              : checkinFormLiveStream.selectedClient?.value?.FullName
          }
          onChange={(e: any) => checkinFormLiveStream.handleClientSelecton(e)}
          placeholder={"Select Client for Check-In"}
          loading={checkinFormLiveStream.clientSearchLoading}
          listNoDataRender={() => (
            <span className={"bzo-noData-found"}>
              {checkinFormLiveStream.clientSearchLoading
                ? "Searching for Client..."
                : "Client is not available please add new client"}
            </span>
          )}
        />
        <MdPersonSearch />
      </div>
      {checkinFormLiveStream.showDialog && (
        <DialogPopup
          className="bz-mybookings-dialog-box bz-custom-content-height bz-checkins-dialog"
          title={"Client Appointment Check-In"}
          onClose={checkinFormLiveStream.handleDialogClose}
          height={650}
        >
          <div className="Self-CheckIn">
            <CheckinUser
              userDetails={checkinFormLiveStream.checkInClient}
              CheckinMode={CheckinMode.ByStaff}
              {...props}
              from={CheckinFrom.LiveStream}
            />
          </div>
        </DialogPopup>
      )}
    </>
  );
};

export default withNotification(CheckinFromLiveStream);

import React, { useEffect, useState } from "react";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { useRouteMatch, useHistory } from "react-router-dom";
import { AffiliateDashboardLinks } from "utils/form-utils";
import { useSelector } from "react-redux";

const useAffiliateDashboard = (props: any) => {
  const history = useHistory();
  const routeMatch: any = useRouteMatch();
  const uuid = props?.match?.params?.uuid;
  const staffPage = uuid ? true : false;
  const [loading, setLoading] = useState(true);
  const service = new AffiliateProgramService();
  const [width, setWidth] = useState(window.innerWidth);
  const [affiliateDetails, setAffilateDetails] = useState<any>(null);
  const [selectedMenu, setselectedMenu] = useState<any>(
    props?.match?.params?.text || AffiliateDashboardLinks.OVERVIEW
  );
  const affiliateInfo = useSelector(
    (state: any) => state?.userConfiguration?.affiliateDetails
  );
  let UserAssociateId = affiliateInfo?.UserSession?.UserAssociateId;
  if (staffPage) {
    UserAssociateId = routeMatch?.params?.associateId;
  }

  useEffect(() => {
    if(UserAssociateId){
      fetchAffiliateDetails()
    }else{
      if(!staffPage){
        history.push('/')
      }
    }
  }, [])
  useEffect(() => {
    if(props?.match?.params?.text){
      setselectedMenu(props?.match?.params?.text)
    }
  }, [props?.match?.params?.text])

  const fetchAffiliateById=async()=>{
    const req={}
    const res = await service.getAffiliateById(UserAssociateId, req);
    setAffilateDetails(res);
  }
  

  const fetchAffiliateDetails = async () => {
    setLoading(true);
    await fetchAffiliateById()
    setLoading(false);
  };

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const MainMenuItems = [
    {
      id: 1,
      name: "overview",
      text: "Overview",
      visible: true,
      iconClass: "mdi mdi-view-dashboard affiliateicon",
      parent: true,
      to: AffiliateDashboardLinks.OVERVIEW,
    },
    {
      id: 2,
      name: "Promotions",
      text: "Promotions",
      visible: true,
      iconClass: "mdi mdi-bullhorn-variant affiliateicon",
      parent: true,
      to: AffiliateDashboardLinks.PROMOTIONS,
    },
    {
      id: 3,
      name: "Payouts",
      text: "Payouts",
      visible: true,
      iconClass: "mdi mdi-cash-multiple affiliateicon",
      parent: true,
      to: AffiliateDashboardLinks.PAYOUTS,
    },
    {
      id: 4,
      name: "MyLinks",
      text: "My Links",
      visible: true,
      iconClass: "mdi mdi-link-variant affiliateicon",
      parent: true,
      to: AffiliateDashboardLinks.MYLINKS,
    },
    {
      id: 5,
      name: "MyProfile",
      text: "My Profile",
      visible: false,
      iconClass: "fas fa-user-circle affiliateicon",
      parent: true,
      to: AffiliateDashboardLinks.MYPROFILE,
    },
    {
      id: 6,
      name: "PromotionActivity",
      text: "Promotion Activity",
      visible: true,
      iconClass: "mdi mdi-history affiliateicon",
      parent: true,
      to: AffiliateDashboardLinks.PROMOTIONACTIVITY,
    },
  ];

  const handleMenuSelection = (dataItem: any) => {
    setselectedMenu(dataItem?.to);
    if (staffPage) {
      history.replace(
        `/app/affiliatedashboard/${uuid}/${routeMatch?.params?.associateId}/${dataItem?.to}`
      );
    } else {
      history.replace(`/affiliate/${dataItem?.to}`);
    }
  };

  return {
    MainMenuItems,
    routeMatch,
    handleMenuSelection,
    selectedMenu,
    width,
    loading,
    uuid,
    staffPage,
    UserAssociateId,
    affiliateDetails,
    fetchAffiliateById
  };
};

export default useAffiliateDashboard;

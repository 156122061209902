import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import Transactions from "./../Transactions";

const AddonPayments = (props: any) => {
  const [selectedAddon, setSelectedAddon] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const service = new TenantService();

  useEffect(() => {
    fetchAddonSummary();
  }, []);

  const fetchAddonSummary = async () => {
    const req = {
      EntityId: props?.selectedAddonId,
      PageSize: 32767,
      PageNumber: 0,
      UserMemberId: props?.userData?.UserMemberId,
    };
    setLoading(true);
    const res = await service?.getAddOnSummary(req);
    setLoading(false);
    setSelectedAddon(res);
  };
  const renderTransactions = () => {
    return (
      <Transactions
        allItems={false}
        staffPage={props?.staffPage}
        EntityId={props?.selectedAddonId}
        FilterType={2}
        userData={props?.userData}
        onSuccessfullPayment={() => props?.onSuccessfullPayment()}

      />
    );
  };
  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          {renderTransactions()}
        </>
      )}
    </>
  );
};

export default AddonPayments;

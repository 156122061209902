import React, { useEffect, useState } from "react";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import OneonOneLinks from "./OneonOneLinks";
import PackageLinks from "./PackageLinks";
import MembershipLinks from "./MembershipLinks";
import { GetLocalStore } from "utils/storage";
import { MdContentCopy } from "react-icons/md";
import SharableLinksDialog from "./SharableLinkDialog";
import { FormTemplateCategoryIntEnum, FormTemplateStatusIntEnum } from "utils/form-utils";
import { useApiClient } from "services/axios-service-utils";
import withNotification from "components/Hoc/withNotification";

const MyLinks = (props: any) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [selectedLink, setSelectedLink] = React.useState({
    url: "",
    name: "",
  });
  const { axiosRequest } = useApiClient();
  const [formLinkdata, setFormLinkData] = useState([]);

  const [promotions, setPromotions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPromotion, setSelectedPromotion] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>({ typeId: 1, Name: 'Subscription Promotions' });
  const [selectedCategory, setSelectedCategory] = useState<any>({ CategoryId: 1, Name: 'Appointments' });
  const types = [
    { typeId: 1, Name: 'Subscription Promotions' },
    { typeId: 2, Name: 'Referral Promotions' },
  ];

  const categories = [
    { CategoryId: 1, Name: 'Appointments' },
    { CategoryId: 2, Name: 'Packages' },
    { CategoryId: 3, Name: 'Memberships' },
  ];

  const service = new AffiliateProgramService();

  const handleSelectLink = (link: string, name: string) => {
    setSelectedLink({
      url: link,
      name,
    });
    setShowDialog(true);
  };

  
  useEffect(()=>{
    getPromotions();
  },[selectedType])

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const handleChange = (dataItem: any) => {
    setSelectedPromotion(dataItem);
  };
  const handleTypeChange = (dataItem: any) => {
    if(dataItem.typeId===1){
      setSelectedPromotion([]);
    }
    if(dataItem.typeId===2){
      setSelectedPromotion([])
      fetchFormsList();
    }
    setSelectedType(dataItem);
  };

  

  const handleCategoryChange = (value: any) => {
    setSelectedCategory(value);
  };
  const copyToClipBoard = (text: any) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
     props?.handleNotificationMessage("Copied", "success");
        console.log('Text successfully copied to clipboard');
      })
        .catch((error) => {
          console.error('Unable to copy text to clipboard:', error);
        });
    } else {
      console.warn('Clipboard API is not supported in this browser. You may need to use a different approach.');
    }
  };

  let DomainName = GetLocalStore("tenantUserSession")?.DomainName;
  if (!props?.uuid) {
    DomainName = GetLocalStore("AffiliateDetails")?.DomainName;
  }

  const link = `https://${DomainName}.${process.env.REACT_APP_DOMAIN}.com/promolink/${props?.affiliateDetails?.AffiliateCode}/${selectedPromotion?.ShortCode}`;

  
  const getPromotions = async () => {
    const req = {
      AffiliateId: parseInt(props?.associateId),
      PromotionType: selectedType?.typeId
    };
    setLoading(true);
    const res = await service.getPromotions(req);
    setPromotions(res?.Items || []);
    setSelectedPromotion(res?.Items?.[0]);
    setLoading(false);
  };


  //form links

  const fetchFormsList = async () => {
    let req: any = {
      SortDirection: "ASC",
      SortBy: "FormName",
      PageSize: 32767,
      PageNumber: 0,
      Status: FormTemplateStatusIntEnum["PUBLISHED"],
      FormCategory: FormTemplateCategoryIntEnum["REFERRAL"],
    };
    setLoading(true);
    await axiosRequest.post("Forms/FormTemplate/filter", req, {
      successCallBack: (response: any) => {
        setFormLinkData(response.data?.Items || []);
        setLoading(false);
      },
      errorCallBack: (response: any) => {
        setLoading(false);
        props?.handleNotificationMessage( response?.response?.data?.Messages?.[0] || response?.data?.Messages?.[0] || "Internal server error", "error");
      },
    });
  };


  //
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <div className="promotion-overview">
      {/* <h5 className="mb-1 bz_fs_1_5 affiliatemainheading">{"My Links"}</h5> */}
      <p className="text-muted">  Share the promotion link to provide clients with access to a comprehensive list of services or direct service links for easy client sign-up.</p>

      <div className="metric-list-row row px-2 mt-lg-2 mb-3">
        <div className="col-lg-3 reports-date-range pl-1 col-12 col-md-4">
            <DropDownList
              data={types}
              textField="Name"
              dataItemKey="typeId"
              name="typeId"
              label="Type"
              style={{ width: '100%' }}
              onChange={(e) => handleTypeChange(e.value)}
              value={
                types.find((i:any) => i?.typeId === selectedType?.typeId)
              }
            />
        </div>
       <div className="col-lg-3 reports-date-range pl-1 col-12 col-md-4">
          <DropDownList
            data={promotions}
            textField="Name"
            dataItemKey="PromotionId"
            name="PromotionId"
            label="Select a Promotion"
            style={{ width: "100%" }}
            value={
              promotions?.filter((i: any) => i?.PromotionId === selectedPromotion?.PromotionId)?.[0] || {
                PromotionId: 0,
                Name: "Please Select Promotion",
              }
            }
            onChange={(e) => handleChange(e.value)}
          />
        </div>
        {selectedType.typeId===1 && <div className="col-lg-3 reports-date-range pl-1 col-12 col-md-4">
          <DropDownList
            data={categories}
            textField="Name"
            dataItemKey="CategoryId"
            name="categoryId"
            label="Select a Services"
            style={{ width: '100%' }}
            value={
              categories.find((i) => i.CategoryId === selectedCategory?.CategoryId) 
            }
            onChange={(e) => handleCategoryChange(e.value)}
          />
      </div>}
        {selectedType.typeId===1 && selectedPromotion?.PromotionId ? <div className="col-lg-3 col-md-4 col-12 mylinkspadding">
          <div className="d-flex align-items-center mb-0 mt-lg-4 mt-sm-2">
            <div className="flex-grow-1">
              <div className="d-flex align-items-center linkbg">
                <div className="flex-grow-1">
                  {" "}
                  <a href={link} target="_blank">
                    <i
                      className="fa fa-link"
                      style={{
                        fontSize: "11px",
                        marginRight: "5px",
                      }}
                    ></i>
                    Promo Link
                  </a>
                </div>
                <div className="flex-shrink-0">
                  <span className="bz-cursor-pointer">
                    <MdContentCopy
                      onClick={() => copyToClipBoard(link)}
                      title={"Copy"}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-shrink-0 px-2">
              {" "}
              <a href="#" className="btn btn-primary-buzops btn-sm text-white" onClick={() => handleSelectLink(link, "Promo Link")}>
                {" "}
                <em className="mdi mdi-share-variant-outline"></em>
              </a>
            </div>
          </div>
        </div> : null}

      </div>

      <div className="row ">
      {selectedCategory?.CategoryId === 1 && selectedType.typeId===1 && (
        <div className="col-12 pdx14">
          <div className=" summary-card-payout w-100  mb-4" >
            <h5 className="affiliatemainheading pb-2">Appointments</h5>
            <div className="linkdescriptions" style={{maxHeight:'500px', overflowY:'scroll',  overflowX:'hidden'}}>
              <OneonOneLinks
                affiliateDetails={props?.affiliateDetails}
                Promotion={selectedPromotion}
                handleSelectLink={handleSelectLink}
                DomainName={DomainName}
                handleNotificationMessage={props?.handleNotificationMessage}
              />
            </div>
          </div>
        </div>
        )}
        {selectedCategory?.CategoryId === 2 && selectedType.typeId===1 && (
        <div className="col-12">
          <div className=" summary-card-payout w-100  mb-4 ">
            <h5 className="affiliatemainheading pb-2">Packages</h5>
            <div className="linkdescriptions" style={{maxHeight:'500px', overflowY:'scroll', overflowX:'hidden'}}>
              <PackageLinks
                affiliateDetails={props?.affiliateDetails}
                Promotion={selectedPromotion}
                handleSelectLink={handleSelectLink}
                DomainName={DomainName}
                handleNotificationMessage={props?.handleNotificationMessage}

              />
            </div>
          </div>
        </div>
        )}
        {selectedCategory?.CategoryId === 3 && selectedType.typeId===1 && (
        <div className="col-12">
          <div className=" summary-card-payout w-100 mb-4">
            <h5 className="affiliatemainheading pb-2">Memberships</h5>
            <div className="linkdescriptions" style={{maxHeight:'500px', overflowY:'scroll',  overflowX:'hidden'}}>

            <MembershipLinks
              affiliateDetails={props?.affiliateDetails}
              Promotion={selectedPromotion}
              handleSelectLink={handleSelectLink}
              DomainName={DomainName}
              handleNotificationMessage={props?.handleNotificationMessage}

            />
            </div>
          </div>
        </div>
        )}

        {selectedType.typeId ===2 &&
           <div className="row">
           <div className="col-12">
             <div className=" summary-card-payout w-100  mb-4">
               <h5 className="affiliatemainheading pb-2">Referral Forms</h5>
               <div
                 className="linkdescriptions row"
                 style={{
                   maxHeight: "500px",
                   overflowY: "scroll",
                   overflowX: "hidden",
                 }}
               >
                 <div className="3mt-">
                   {loading ? (
                     <BuzopsLoader type="list" />
                   ) : (
                     <div>
                       {formLinkdata?.length === 0 ? (
                         <div
                           className={
                             "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                           }
                         >
                           <div className="bz-norecords-available">
                             No Links Available
                           </div>
                         </div>
                       ) : (
                         <>
                         <div className="row">
                           {formLinkdata?.map((i: any) => {
                             const link = `https://${DomainName}.${process.env.REACT_APP_DOMAIN}.com/form/referral/${selectedPromotion?.ShortCode}/${i?.ShortCode}/${props?.affiliateDetails?.AffiliateCode}`;
   
                             return (
                              <div className="col-6">
                               <div
                                 className="d-flex align-items-center mb-2 "
                                 key={i?.FormTemplateId}
                               >
                                 <div className="flex-grow-1">
                                   <div
                                     className="d-flex align-items-center linkbg align-items-center"
                                     style={{ gap: "10px" }}
                                   >
                                     <div
                                       className="flex-grow-1"
                                       style={{ wordBreak: "break-all" }}
                                     >
                                       {" "}
                                       <a href={link} target="_blank">
                                         <div
                                           style={{
                                             display: "flex",
                                             justifyContent: "space-between",
                                           }}
                                           className="align-items-center"
                                         >
                                           <div>
                                             {" "}
                                             <i
                                               className="fa fa-link"
                                               style={{
                                                 fontSize: "11px",
                                                 marginRight: "5px",
                                               }}
                                             ></i>
                                           </div>
                                           <div style={{ flexGrow: "1" }}>
                                             {" "}
                                             {i?.FormName}
                                           </div>
                                         </div>
                                       </a>
                                     </div>
                                     <button
                                      className="actiobtnform" 
                                      onClick={() => copyToClipBoard(link)}
                                    >
                                      {" "}
                                      <i
                                        style={{ fontSize: "16px", color: "#607d8b" }}
                                          className="mdi mdi-content-copy"
                                        />Copy{" "}
                                    </button>

                                    <button
                                      className="actiobtnform" 
                                      onClick={() => handleSelectLink(link, i?.FormName)}
                                    >
                                      {" "}
                                      <i
                                        style={{ fontSize: "16px", color: "#607d8b" }}
                                          className="mdi mdi-share-variant"
                                        />Share{" "}
                                    </button>
                                     {/* <div
                                       className="flex-shrink-0"
                                       style={{ width: "20px" }}
                                     >
                                       <span className="bz-cursor-pointer">
                                         <MdContentCopy
                                           onClick={() => copyToClipBoard(link)}
                                           title={"Copy"}
                                         />
                                       </span>
                                     </div> */}
                                   </div>
                                 </div>
                                 {/* <div className="flex-shrink-0 px-2">
                                   {" "}
                                   <a
                                     href="#"
                                     onClick={() =>
                                       handleSelectLink(link, i?.FormName)
                                     }
                                     className="btn btn-primary-buzops btn-sm text-white"
                                   >
                                     {" "}
                                     <em className="mdi mdi-share-variant-outline"></em>
                                   </a>
                                 </div> */}
                               </div>
                               </div>
                             );
                           })}
                           </div>
                         </>
                       )}
                     </div>
                   )}
                 </div>
               </div>
             </div>
           </div>
         </div>
        }



      </div>
      {showDialog && <SharableLinksDialog {...props} copyToClipBoard={copyToClipBoard} selectedLink={selectedLink} toggleDialog={toggleDialog} />}
    </div>
  );
};

export default withNotification(MyLinks);

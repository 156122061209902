import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useEffect } from "react";
import PageExpireCounter from "./PageExpireCounter";
import { Button } from "@progress/kendo-react-buttons";
export const IdleTimeOutModal = ({
  showModal,
  handleContinue,
  handleLogout,
}: any) => {
    let timer: any = undefined;
    
    useEffect(() => {
        if(showModal) {
            handleUserInteraction()
        }
        return (()=>{
            clearTimeout(timer);
        })
    }, [showModal])

    const handleUserInteraction = () => {
        timer = setTimeout(()=>{
           window.location.reload();
        }, 5000); 
    }
  return (
    <>
      {showModal && (
        <Dialog title={"You Have Been Idle!"} className="idelpop">
          <p className="popupidel">
            Your session is Timed Out. You want to stay?
          </p>
          <DialogActionsBar>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base  bz-cursor-pointer"
              onClick={handleLogout}
            >
              No (<PageExpireCounter timeOutIntervals={5000} />)
            </Button>
            <Button
              primary
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bz-cursor-pointer"
              onClick={() => { clearTimeout(timer); handleContinue() }}
            >
              Yes 
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

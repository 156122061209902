import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Error, Label, Hint } from "@progress/kendo-react-labels";

import React from "react";
interface IdataItem {
  ParentKey: string;
  SelectionIds: string;
  SelectionIdKey: string;
  dataItemKey: string;
  serviceStatusKey: string;
}
interface IGHLPipeLineStageUI {
  dataItem: IdataItem;
  configurationData: any;
  subscriptionData: any;
  pipelineData: any;
  pipeLineStages: any;
  label: string;
  handleAddPipeLine: (parent: string, itemKey: string) => void;
  handleDeletePipeline: (parent: string, index: number) => void;
  fetchStagesWithPipelineId: (PipelineId: string) => any;
  handleChange: (
    val: any,
    name: string,
    parent?: any,
    index?: number
  ) => Promise<void>;
}
const GHLPipeLineStageUI = ({
  dataItem,
  configurationData,
  handleAddPipeLine,
  subscriptionData,
  fetchStagesWithPipelineId,
  handleChange,
  handleDeletePipeline,
  pipelineData,
  pipeLineStages,
  label,
}: IGHLPipeLineStageUI) => {
  const itemRenderer = (li: any, itemProps: any) => {
    const item = itemProps.dataItem;
    const itemElement = (
      <li
        {...li.props}
        className={item.disabled ? 'k-disabled p-1' : 'cursor-pointer p-1'}
        aria-disabled={item.disabled ? 'true' : undefined}
      >
        {li.props.children}
      </li>
    );
    return itemElement;
  };

  if (configurationData?.[dataItem?.ParentKey]?.length > 0) {
    return (
      <>
        <div className="row pt-3 pb-3 prospect-blk ghlblks">
          <div className="form-group mb-0 bz-form-group-row align-items-center">

            <h6 className="h6 d-flex justify-content-between mb-0  bz-form-group-row">

              <span>    {label}</span>
              <span> <small className="bz-ribbon-1">Pipeline</small></span>
            </h6>
            <div className="form-dropdown">
              <Button
                onClick={() =>
                  handleAddPipeLine(dataItem?.ParentKey, dataItem?.SelectionIds)
                }
                className="btnaddghl"
              >
                Add +
              </Button>
            </div>
          </div>
          {configurationData?.[dataItem?.ParentKey]?.map(
            (config: any, index: number) => {
              const records = fetchStagesWithPipelineId(
                config?.[dataItem?.dataItemKey]
              );
              const stagesDropdownItems = [
                { id: null, name: "Do Nothing" },
                ...records,
              ];
              let IdValues = [];
              if (config?.[dataItem?.SelectionIds]?.length > 0) {
                IdValues = subscriptionData?.filter((i: any) => {
                  const findItem = config?.[dataItem?.SelectionIds]?.find(
                    (j: any) => j === i?.[dataItem?.SelectionIdKey]
                  );
                  if (findItem) {
                    return true;
                  }
                  return false;
                });
              }
              let disabled = false;
              if (configurationData?.[dataItem?.ParentKey]?.length === 1) {
                disabled = true;
              }
              return (
                <div className="row mb-3 subscription-blk p-0 m-0">
                  <div className="col-12 mb-0">
                    <div className="form-group bz-form-group-row align-items-center">
                      <div className="multiselectinput mt-1">
                        <MultiSelect
                          data={subscriptionData}
                          textField="Name"
                          dataItemKey={dataItem?.SelectionIdKey}
                          placeholder="Please select"

                          itemRender={itemRenderer}
                          onChange={(e) => {
                            handleChange(
                              e.value,
                              dataItem?.SelectionIds,
                              dataItem?.ParentKey,
                              index
                            );
                          }}
                          value={IdValues || []}
                          className="bz-multiselect-arrow"
                        />
                        <div className="text-right">
                          <button
                            className="btn btn-link"
                            onClick={() =>
                              handleDeletePipeline(dataItem?.ParentKey, index)
                            }
                            style={{
                              color: "red",
                              opacity: disabled ? 0.5 : 1,
                            }}
                            disabled={disabled}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                      <div className="form-dropdown">
                        <DropDownButton
                          textField={"name"}
                          items={pipelineData}
                          iconClass="fas fa-chevron-down"
                          text={
                            pipelineData.find(
                              (i: any) =>
                                i?.id === config?.[dataItem?.dataItemKey]
                            )?.name
                          }
                          look="flat"
                          className={"bz-white-btn bz-dropdown-btn px-0"}
                          onItemClick={(e: any) => {
                            handleChange(
                              e.item?.id,
                              dataItem?.dataItemKey,
                              dataItem?.ParentKey,
                              index
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    {pipeLineStages?.[dataItem?.serviceStatusKey]?.map(
                      (item: any) => {
                        let itemName = item?.Description;
                        if (dataItem?.serviceStatusKey !== "UnPaidStatus") {
                          itemName = item?.Description?.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          itemName = `${itemName}StageId`;
                        }
                        return (
                          <>
                            <div className="stage-list mb-0">
                              <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                                <Label className="form-label mb-0 d-flex justify-content-between">
                                  <span> {item?.Label}</span>
                                  <span>  <small className="bz-ribbon-1 stage">
                                    Stage
                                  </small></span>
                                </Label>
                                <div className="form-dropdown">
                                  <DropDownButton
                                    textField={"name"}
                                    items={stagesDropdownItems}
                                    iconClass="fas fa-chevron-down"
                                    text={
                                      stagesDropdownItems?.find(
                                        (i: any) => i?.id === config?.[itemName]
                                      )?.name
                                    }
                                    look="flat"
                                    className={
                                      "bz-white-btn bz-dropdown-btn px-0"
                                    }
                                    onItemClick={(e: any) => {
                                      handleChange(
                                        e.item?.id,
                                        itemName,
                                        dataItem?.ParentKey,
                                        index
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                  </div>

                </div>
              );
            }
          )}
        </div>
      </>
    );
  }

  return null;
};

export default GHLPipeLineStageUI;

import { useEffect, useState, useCallback } from "react";
import { ManageCustomFieldsService } from "services/managecustomfields/index.service";
import { guid } from "@progress/kendo-react-common";
import { CustomFieldsTypeOptions } from "utils/form-utils";

export const FORM_DATA_INDEX = "formDataIndex";
export const DATA_ITEM_KEY = "CustomId";
const useManageCustomFields = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [modified, setModified] = useState(false);
    const [dataState, setDataState] = useState<any>([]);
    const [editIndex, setEditIndex] = useState<number | undefined>(undefined);


    const service = new ManageCustomFieldsService();

    const fetchUserSession = async () => {
        await service.getTenantDetails(
            props.from ? props?.uuid : props?.match?.params?.uuid
        );
    };

    const fetchAllApis = async () => {
        setLoading(true);
        await fetchUserSession();
        await fetchCustomFields();
        setLoading(false);
    };

    const fetchCustomFields = async (customLoading = false) => {
        if (customLoading) {
            setLoading(true)
        }
        const data = await service.getCustomFields();
        if (customLoading) {
            setLoading(false)
        }
        const result = data?.map((dataItem: any, idx: any) => ({
            ...dataItem,
            [FORM_DATA_INDEX]: idx,
            [DATA_ITEM_KEY]: guid(),
            FieldType: CustomFieldsTypeOptions?.filter(
                (i: any) => i?.Id === dataItem?.FieldType
            )?.[0],
        }));
        setDataState(result);
    };

    useEffect(() => {
        fetchAllApis();
    }, []);
    const onRowAction = useCallback(
        (options: {
            rowIndex: number;
            operation: "save" | "remove" | "add";
            dataItem?: any;
        }) => {
            const newDataState = [...dataState];
            switch (options.operation) {
                case "remove":
                    const findIndex=newDataState?.findIndex((i:any)=>i?.CustomId=== options?.dataItem?.CustomId)
                    newDataState.splice(findIndex, 1);
                    setModified(true)
                    break;
                case "save":
                    let modifiedRecord =options.dataItem
                    let index = dataState.findIndex(
                        (dataItem: any) => dataItem.CustomId === modifiedRecord?.CustomId
                    );
                    const str = modifiedRecord?.FieldName;
                    const updatedString = str?.trim()?.split(/\s+/)?.join(" ");
                    modifiedRecord = {...modifiedRecord,FieldName:updatedString}
                    const checkDuplicate = dataState.findIndex((i:any)=> i?.FieldName?.toLowerCase() === modifiedRecord?.FieldName?.toLowerCase() && i?.CustomId !== modifiedRecord?.CustomId
                    );
                    if(checkDuplicate !==-1){
                        props?.handleNotificationMessage(
                            `Duplicate Field Name (${modifiedRecord?.FieldName}) provided`,
                            "error"
                        );
                    }else{
                        newDataState[index] = modifiedRecord;
                        setEditIndex(undefined);
                        setModified(true)
                    }
                    break;
                case "add":
                    newDataState.unshift({
                        FieldName: "",
                        FieldType: null,
                        IsRequired: false,
                        CanShow: true,
                        Value: null,
                        Order: options.rowIndex + 1,
                        [FORM_DATA_INDEX]: options.rowIndex,
                        [DATA_ITEM_KEY]: guid(),
                    });
                    setEditIndex(options.rowIndex);
                    setModified(true)
                    break;
                default:
            }
            if (options?.operation === "remove") {
                const data = newDataState.map((dataItem: any, idx: any) => ({
                    ...dataItem,
                    [FORM_DATA_INDEX]: idx,
                }));
                setDataState(data);
            } else {
                setDataState(newDataState);
            }
        },
        [dataState]
    );
    const onAddClick = useCallback(() => {
        const findInvalid = dataState.some(checkFieldName);
        if (findInvalid) {
            return;
        }
        onRowAction({ rowIndex: dataState.length, operation: "add" });
    }, [onRowAction, dataState]);
    const checkFieldName = (element: any, index: number, array: any) => {
        return element?.FieldName === "";
    };
    const handleSave = async () => {
        if (editIndex !== undefined) {
            props?.handleNotificationMessage(
                `Please Update the data before Save`,
                "error"
            );
            return;
        }
        const findInvalid = dataState.some(checkFieldName);
        if (findInvalid) {
            props?.handleNotificationMessage(
                `Please fill all details before Save`,
                "error"
            );
            return;
        }
        const updatedData = dataState?.map((dataItem: any) => {
            const obj = {
                FieldName: dataItem?.FieldName,
                FieldType: dataItem?.FieldType?.Id,
                Value: "",
                IsRequired: dataItem?.IsRequired,
                CanShow: dataItem?.CanShow,
                Order: dataItem?.Order,
            };
            return obj;
        });
        setBtnLoading(true)
        const saveCustomFields = await service.saveCustomFields(updatedData);
        setBtnLoading(false)
        if (saveCustomFields?.ResponseCode === 100) {
            const successMsg = "Successfully updated additional information";
            props?.handleNotificationMessage(successMsg, "success");
            await fetchCustomFields(true);
        } else {
            const errorMsg = saveCustomFields.ErrorMessages[0];
            props?.handleNotificationMessage(errorMsg, "error");
        }
        setModified(false)
    };

    const handleCancel=async()=>{
        await fetchCustomFields(true);
        setModified(false)
    }
    return {
        loading,
        dataState,
        editIndex,
        modified,
        handleSave,
        handleCancel,
        setEditIndex,
        onRowAction,
        onAddClick,
        btnLoading
    };
};

export default useManageCustomFields;

import React from "react";
import ActivateSuspended from "components/summary/Plans/ActivateSuspended";
import Transactions from "./Transactions";

const ComponentForInActiveOrSuspendedTransaction = (props: any) => {
  const { status, InstanceId, userMemberID, userMemberStatus, onWindowClose, ServiceTypeDescription, Name, userDetails } =
    props;
  const [paymentUpdated, setPaymentUpdated] = React.useState(false);
  const refreshComponent = false
  const renderActivateOrPayComponent = () => {
    if (status === 16) {
      return (
        <ActivateSuspended
          canAddCardOrBank={true}
          key={refreshComponent.toString()}
          selectedPlanId={InstanceId}
          userData={{
            MainUserId: userDetails?.ParentId || userMemberID,
            UserMemberId: userMemberID,
            status: userMemberStatus,
          }}
          onActivate={() => setPaymentUpdated(true)}
        />
      );
    } else {
      return <Transactions {...props} key={refreshComponent.toString()} onPaymentUpdate={(status: boolean) => {
        setPaymentUpdated(status)
        if (!status) {
          onWindowClose(false);
        }
      }} />;
    }
  };

  const renderSuccessMessage = () => {
    if (status === 16) {
      return <span><b>{ServiceTypeDescription} - "{Name}"</b> is activated successfully.</span>
    } else {
      return <span>Overdue payment for <b>{ServiceTypeDescription} - "{Name}"</b> has been successfully collected.</span>
    }
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-primary bz-back-btn btn-sm mr-2"
        onClick={() => onWindowClose(paymentUpdated)}
      >
        <i className="fas fa-arrow-left"></i>
      </button>
      {
        !paymentUpdated ? (
          <>
            <div key={refreshComponent.toString()}>{renderActivateOrPayComponent()}</div>
          </>
        ) : (
          <div className="bz-text-center">
            <span className="k-notification k-notification-success">{renderSuccessMessage()} <span className="btn-link" onClick={() => onWindowClose(true)}><u>Please Continue</u></span></span>
          </div>
        )
      }
    </>
  );
};

export default ComponentForInActiveOrSuspendedTransaction;

import React, { useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import {
  Input,
  MaskedTextBox,
  RadioButton,
} from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import {
  AffiliateCodeValidator,
  emailValidator,
  mobileValidator,
  NameValidator,
  AffiliateZipCodeValidator,
} from "validators/validator";
import { isMobile} from "react-device-detect";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";
import { getFullImageUrl } from "utils";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import Camera from "components/summary/camera";
import { TenantService } from "services/tenant/index.service";
import withNotification from "components/Hoc/withNotification";
import moment from "moment";
import { FaEdit } from "react-icons/fa";
import UpdateAffiliateEmailDialog from "./UpdateAffiliateEmail";

const CustomPopup = (props: any) => {
  return (
    <Popup
      {...props}
      anchorAlign={{
        horizontal: "center",
        vertical: "bottom",
      }}
      popupAlign={{
        horizontal: "center",
        vertical: "bottom",
      }}
    />
  );
};

const MyProfile = (props: any) => {
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [promoterDetails, setPromoterDetails] = useState<any>(null);
  const [originalPromoterDetails, setOriginalPromoterDetails] = useState<any>(null);
  const [newPhotoStatus, setNewPhotoStatus] = useState<any>(false);
  const [statesList, setStatesList] = useState<any>([]);
  const [origstatesList, setOrigStatesList] = useState<any>([]);
  const [showUploadDialog, setShowUploadDialog] = useState<any>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<any>(false);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [photoUploadPref, setphotoUploadPref] = React.useState("2");
  const [snapString, setSnapString] = React.useState("");
  const service = new AffiliateProgramService();
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [showUpdateAffililateEmailDialog, setShowUpdateAffililateEmailDialog] =
    useState<boolean>(false);
  const loading = false;
  useEffect(() => {
    fetchPromoter();
  }, [props?.affiliateDetails]);

  useEffect(() => {
    fetchStates();
    getPaymentTerms()
  }, []);
  const getPaymentTerms = async () => {
    const data = await service.getPaymentTerms();
    setPaymentTerms(data || []);
  };
  const fetchPromoter = async () => {
    setPromoterDetails(props?.affiliateDetails);
    setOriginalPromoterDetails(props?.affiliateDetails);
  };
  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };
  const fetchStates = async () => {
    const req = {};
    const service = new TenantService();
    const res = await service?.getStates(req);
    setStatesList(res || []);
    setOrigStatesList(res || []);
  };
  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };
  const handleChange = (val: any, name: string) => {
    setPromoterDetails({
      ...promoterDetails,
      [name]: val,
    });
  };

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);

  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          
          //console.log(reader?.result); //-> "R0lGODdhAQABAPAAAP8AAAAAACwAAAAAAQABAAACAkQBADs="
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const handleCancel = () => {
    setPromoterDetails(originalPromoterDetails);
  };

  const handleValidation = () => {
    if (
      mobileValidator(promoterDetails?.Phone) ||
      AffiliateZipCodeValidator(promoterDetails?.ZipCode) ||
      emailValidator(promoterDetails?.Email) ||
      NameValidator(promoterDetails?.FirstName, "First Name") ||
      NameValidator(promoterDetails?.LastName, "Last Name") ||
      AffiliateCodeValidator(promoterDetails?.AffiliateCode)  ||
      ((moment(promoterDetails?.DateOfBirth).format(
        "YYYY-MM-DD"
      ) !== "Invalid date") && (moment(promoterDetails?.DateOfBirth).format(
        "YYYY-MM-DD"
      ) >= moment().format("YYYY-MM-DD")))
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = async () => {
    const check = handleValidation();
    if(!check){
      return
    }
    let req={...promoterDetails}
    if(moment(promoterDetails?.DateOfBirth).format("L") !=="Invalid date"){
      req = {
        ...promoterDetails,
        DateOfBirth: moment(promoterDetails?.DateOfBirth).format("L"),
      };
    }else{
      req = {
        ...promoterDetails,
        DateOfBirth: null,
      };
    }
    setBtnLoading(true);
    const result = await service?.saveAffiliateProfile(req);
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = "Profile Updated Successfully";
      props?.handleNotificationMessage(successMsg, "success");
      setNewPhotoStatus(false);
      await props?.fetchAffiliateById();
    } else {
      const errorMsg =
        result?.ErrorMessages?.[0] || "Error in updating profile";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const StateNamefilterChange = (e: any) => {
    const filteredData = stateNameFilterData(e.filter);
    setStatesList(filteredData);
  };

  const deleteImage = (status: boolean) => {
    if (status) {
      setPromoterDetails({
        ...promoterDetails,
        Photo: null,
      });
      if (files.length > 0) {
        setFiles([]);
        setOriginalFiles([]);
      }
    }
    setNewPhotoStatus(false);
    toggleDeleteDialog();
  };
  const toggleUplaodDialog = () => {
    setShowUploadDialog(false);
  };
  const toggleDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const UploadImage = () => {
    if (photoUploadPref === "2") {
      if (files?.length === 0) {
        props?.handleNotificationMessage(
          `Please select image to upload`,
          "error"
        );
        return true;
      }
      const allowdFileTypes = [".jpeg", ".jpg", ".png"];
      let check = false;
      files.length > 0 &&
        files.forEach((file: any) => {
          if (!allowdFileTypes.includes(file?.Extension.toLowerCase())) {
            props?.handleNotificationMessage(
              `Please upload allowed File Type`,
              "error"
            );
            check = true;
            return true;
          }
          if (file?.Size > 500000) {
            props?.handleNotificationMessage(
              `Please upload image less than 500KB`,
              "error"
            );
            check = true;
            return true;
          }
        });
      if (check) {
        return true;
      }
      setPromoterDetails({
        ...promoterDetails,
        Photo: files[0]?.ImageAsBase64,
      });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    } else if (photoUploadPref === "1") {
      setPromoterDetails({
        ...promoterDetails,
        Photo: snapString,
      });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    }
  };
  const stateNameFilterData = (filter: any) => {
    const data = origstatesList.slice();
    return filterBy(data, filter);
  };
  const handleDeleteDialog = () => {
    if (promoterDetails?.Photo) {
      setShowDeleteDialog(true);
    }
    return;
  };
  const renderDeleteDialog = () => {
    return (
      <Dialog onClose={() => toggleDeleteDialog()}>
        <p className={"dialogContainer"}>
          Are you sure you want to remove the photo?
        </p>
        <DialogActionsBar>
          <Button onClick={() => deleteImage(false)}>{"No,keep it."}</Button>
          <Button primary={true} onClick={() => deleteImage(true)}>
            Yes
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };
  const handleTakeASnapClick = () => {
    setphotoUploadPref("1");
  };
  const handleUploadAFileClick = () => {
    setphotoUploadPref("2");
  };
  const handleCapture = (e: any) => {
    setSnapString(e);
  };
  const handleRefreshCamera = (e: any) => {
    setphotoUploadPref("2");
  };
  const renderUploadDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-familyMember-dialog"
        title={"Upload a Profile Photo"}
        onClose={() => toggleUplaodDialog()}
        width={"34rem"}
      >
        <div style={{ textAlign: "justify" }} className="k-grid bzc-grid">
          <div className="row">
            <div className="col-sm-12">
              <div className="sales-radio-button-headers">
                <RadioButton
                  name="uploadPhoto2"
                  value="2"
                  checked={photoUploadPref === "2"}
                  label="Upload a File"
                  onClick={handleUploadAFileClick}
                />
                {"   "}
                {!isMobile && (
                  <RadioButton
                    name="uploadPhoto1"
                    value="1"
                    checked={photoUploadPref === "1"}
                    label="Take a Snap"
                    onClick={handleTakeASnapClick}
                  />
                )}
              </div>
              <hr />
              <div className="k-form-field">
                {/* <label className="k-label pt-3"></label> */}
                {photoUploadPref === "2" && (
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 500000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                )}
                {photoUploadPref === "1" && (
                  <div>
                    <Camera
                      handleCameraCapture={handleCapture}
                      handleRefreshCamera={handleRefreshCamera}
                    />
                    {/* <Hint>*Please make sure camera permissions are provided</Hint> */}
                  </div>
                )}
                <Hint>
                  {
                    "Only JPEG and PNG files are allowed. Allowed size below 500KB"
                  }
                </Hint>
              </div>
            </div>
          </div>
        </div>
        <Button
          className={"float-right mt-3"}
          onClick={() => toggleUplaodDialog()}
        >
          Cancel
        </Button>
        <Button
          className={"float-right mt-3"}
          primary={true}
          onClick={() => UploadImage()}
        >
          Upload
        </Button>
      </Dialog>
    );
  };

  const DummyImage = "/images/user.png";
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <>
      <div className="promotion-overview">
        <h5 className="mb-1 bz_fs_1_5 affiliatemainheading">{"Manage Your Profile"}</h5>
        <p> Keep Your Information Up-to-Date and Personalized</p>
        <div className="bz-subsection-row bz-myprofile-container">
          <div className="left-section">
            <div className="row bz-mt-2rem bz-mb-2_5rem">
              <div className="col-sm-9 col-xl-10 col-xxl-9 order-2 order-sm-1">
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">First Name <span className="required-asterisk">*</span></div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="FirstName"
                      id="FirstName"
                      className="form-control col-sm-10 col-xl-10"
                      value={promoterDetails?.FirstName}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "FirstName")
                      }
                    />
                    {NameValidator(
                      promoterDetails?.FirstName,
                      "First Name"
                    ) && (
                        <Error>
                          {NameValidator(
                            promoterDetails?.FirstName,
                            "First Name"
                          )}
                        </Error>
                      )}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">Last Name  <span className="required-asterisk">*</span></div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="LastName"
                      id="LastName"
                      className="form-control col-sm-10 col-xl-10"
                      value={promoterDetails?.LastName}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "LastName")
                      }
                    />
                    {NameValidator(promoterDetails?.LastName, "Last Name") && (
                      <Error>
                        {NameValidator(promoterDetails?.LastName, "Last Name")}
                      </Error>
                    )}
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">
                    Phone Number  <span className="required-asterisk">*</span>
                  </div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <MaskedTextBox
                        mask="(999) 000-0000"
                        name="Phone"
                        id="Phone"
                        className="form-control col-sm-10 col-xl-10"
                        value={
                          promoterDetails?.Phone === null
                            ? ""
                            : promoterDetails?.Phone
                        }
                        onChange={(e: any) =>
                          handleChange(e.target.value, "Phone")
                        }
                      />
                      {mobileValidator(promoterDetails?.Phone) && (
                        <Error>{mobileValidator(promoterDetails?.Phone)}</Error>
                      )}
                    </FieldWrapper>
                  </div>
                </div>
                {/* <div className="bz-content-text-row">
                  <div className="bz-content-title text-right">Email</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <Input
                        autoComplete={"off"}
                        name="Email"
                        id="Email"
                        className="form-control col-sm-10 col-xl-10"
                        value={promoterDetails?.Email}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "Email")
                        }
                      />
                      {emailValidator(promoterDetails?.Email) && (
                        <Error>{emailValidator(promoterDetails?.Email)}</Error>
                      )}
                    </FieldWrapper>
                  </div>
                </div> */}
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">Email  <span className="required-asterisk">*</span></div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <div className="input-group edit-email-group  col-sm-10 col-xl-10 p-0">
                      <FieldWrapper>
                        <Input
                          autoComplete={"off"}
                          name="Email"
                          id="Email"
                          className="form-control"
                          value={promoterDetails?.Email}
                          onChange={(e: any) =>
                            handleChange(e.target.value, "Email")
                          }
                          disabled={true}
                        />
                        {emailValidator(promoterDetails?.Email) && (
                          <Error>{emailValidator(promoterDetails?.Email)}</Error>
                        )}
                      </FieldWrapper>
                      <div className="input-group-append">
                        <Button
                          // disabled={
                          //   (
                          //     promoterDetails?.AssociateId === 0 ||
                          //     promoterDetails?.AssociateId === null
                          //   )
                          // }
                          onClick={() => setShowUpdateAffililateEmailDialog(true)}
                          className="email-edit-icon"
                        >
                          <FaEdit />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">
                    Date of Birth
                  </div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <DatePicker
                        max={new Date()}
                        name="DateOfBirth"
                        placeholder={`Month/Day/Year`}
                        id="DateOfBirth"
                        width={"100%"}
                        className="col-sm-10 col-xl-10"
                        value={
                          promoterDetails?.DateOfBirth
                            ? new Date(promoterDetails?.DateOfBirth)
                            : promoterDetails?.DateOfBirth
                        }
                        onChange={(e) => {
                          handleChange(e.value, "DateOfBirth");
                        }}
                        popup={CustomPopup}
                      />
                        {(moment(promoterDetails?.DateOfBirth).format(
        "YYYY-MM-DD"
      ) !== "Invalid date") && (moment(promoterDetails?.DateOfBirth).format(
                          "YYYY-MM-DD"
                        ) >= moment().format("YYYY-MM-DD"))  ? <Error> {"Please provide valid Date Of Birth"}
                      </Error>:null}
                    
                    </FieldWrapper>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">Address</div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="Address1"
                      id="Address1"
                      className="form-control col-sm-10 col-xl-10"
                      value={promoterDetails?.Address1}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "Address1")
                      }
                    />
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">City</div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <Input
                      autoComplete={"off"}
                      name="City"
                      id="City"
                      className="form-control col-sm-10 col-xl-10"
                      value={promoterDetails?.City}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "City")
                      }
                    />
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">State</div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      data={statesList}
                      textField="StateName"
                      dataItemKey="StateId"
                      name="StateId"
                      id="StateId"
                      className="form-control col-sm-10 col-xl-10"
                      onChange={(e) =>
                        handleChange(e.value?.StateId, "StateId")
                      }
                      value={
                        statesList?.filter(
                          (i: any) => i.StateId === promoterDetails?.StateId
                        )?.[0] || 0
                      }
                      filterable={true}
                      onFilterChange={(e) => StateNamefilterChange(e)}
                    />
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">Zip Code  <span className="required-asterisk">*</span></div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <Input
                        name="ZipCode"
                        // mask="00000"
                        id="ZipCode"
                        className="form-control col-sm-10 col-xl-10"
                        value={promoterDetails?.ZipCode}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "ZipCode")
                        }
                      />
                      {AffiliateZipCodeValidator(promoterDetails?.ZipCode) && (
                        <Error>
                          {AffiliateZipCodeValidator(promoterDetails?.ZipCode)}
                        </Error>
                      )}
                    </FieldWrapper>
                  </div>
                </div>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">Affilate Code  <span className="required-asterisk">*</span></div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <FieldWrapper>
                      <Input
                        autoComplete={"off"}
                        name="AffiliateCode"
                        id="AffiliateCode"
                        className="form-control col-sm-10 col-xl-10"
                        value={promoterDetails?.AffiliateCode}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "AffiliateCode")
                        }
                      />
                      {AffiliateCodeValidator(
                        promoterDetails?.AffiliateCode
                      ) && (
                          <Error>
                            {AffiliateCodeValidator(
                              promoterDetails?.AffiliateCode
                            )}
                          </Error>
                        )}
                    </FieldWrapper>
                  </div>
                </div>
                {props?.uuid ? <div className="bz-content-text-row">
                  <div className="bz-content-title text-right align-self-baseline">Payment Term</div>
                  <span className="bz-colon text-center align-self-baseline">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      data={paymentTerms}
                      textField="Description"
                      dataItemKey="PaymentTermId"
                      name="PaymentTermId"
                      id="PaymentTermId"
                      className="form-control col-sm-10 col-xl-10"
                      onChange={(e) =>
                        handleChange(e.value?.PaymentTermId, "PaymentTermId")
                      }
                      value={
                        paymentTerms?.filter(
                          (i: any) => i.PaymentTermId === promoterDetails?.PaymentTermId
                        )?.[0] || {
                          PaymentTermId: 0,
                          Description: "Please Select Payment Term",
                        }
                      }
                    />
                  </div>
                </div> :null}
                <div className="bz-content-text-row mt-4 pt-2">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <Button onClick={() => handleCancel()} className="ml-0">
                      Reset
                    </Button>
                    <BuzopsButton
                      disabled={btnLoading}
                      type={"submit"}
                      loading={btnLoading}
                      label={"Save"}
                      onClick={() => handleSubmit()}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-xl-2 col-xxl-3 order-1 order-sm-2">
                <div className="bz-Manage-User-Img d-flex justify-content-center pb-4">
                  <div className="mx-auto ProfilePicOptions">
                    <img
                    key={promoterDetails?.Photo || DummyImage}
                      width={120}
                      src={
                        !newPhotoStatus
                          ? promoterDetails?.Photo
                            ? getFullImageUrl(promoterDetails?.Photo)
                            : DummyImage
                          : promoterDetails?.Photo
                      }
                      alt="profilePic"
                      className="rounded-circle img-circle"
                    />
                    {promoterDetails?.Photo ?  <span
                      className="bz-delete-userImg customtooltip"
                      onClick={() => {
                        handleDeleteDialog();
                      }}
                    >
                      <i className="fa fa-trash"  aria-hidden="true"></i>
                      <span className="tooltiptext">Delete</span>
                    </span>:null}
                   
                    <span
                      className={promoterDetails?.Photo ?"bz-upload-userImg customtooltip":"bz-upload-userImg bz-upload-userImg-only customtooltip"}
                      onClick={() => {
                        setShowUploadDialog(true);
                      }}
                    >
                      <i className="fa fa-camera" aria-hidden="true"></i>
                      <span className="tooltiptext">Upload</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showUploadDialog && renderUploadDialog()}
        {showDeleteDialog && renderDeleteDialog()}
        {showUpdateAffililateEmailDialog && (
        <UpdateAffiliateEmailDialog
          setShowUpdateAffililateEmailDialog={setShowUpdateAffililateEmailDialog}
          setPromoterDetails={setPromoterDetails}
          promoterDetails={promoterDetails}
          isAdminOrStaff={props?.uuid ? true : false}
          fetchAffiliateById={props?.fetchAffiliateById}
        />
      )}
      </div>
    </>
  );
};

export default withNotification(MyProfile);

import React, { useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";
import "./index.scss";
import moment from "moment";
import ReportConstants from "components/reports/Utilities/constants";

export enum reportFilters {
  TODAY = "Today",
  ThisWeek = "This Week",
  ThisMonth = "This Month",
  ThisQuarter = "This Quarter",
  ThisYear = "This Year",
  Yesterday = "Yesterday",
  PreviousWeek = "Previous Week",
  PreviousMonth = "Previous Month",
  PreviousQuarter = "Previous Quarter",
  PreviousYear = "Previous Year",
  Custom = "Custom",
}
const TaxReportFilter = ({
  history,
  location,
  dateValues,
  showDate = true,
  defaultValue,
  showReports = true,
  match,
  onlyCustom = false,
  hideDayValues = false,
}: any) => {
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(false);
  const [calendarShow, setCalendarShow] = React.useState(false);
  const [dropdownValue, setDropdownValue] = React.useState(defaultValue);
  const uuid = match?.params?.uuid;

  const dropDownData = [
    ...ReportConstants.reports,
    ...ReportConstants.subscribers,
    ...ReportConstants.sales,
    ...ReportConstants.attendance,
    ...ReportConstants.revenue,
    ...ReportConstants.payroll,
    ...ReportConstants.payments,
  ];

  const getDropDownValues = () => {
    if (hideDayValues) {
      return Object.values(reportFilters).filter(
        (val) =>
          ![
            reportFilters.TODAY,
            reportFilters.PreviousWeek,
            reportFilters.ThisWeek,
            reportFilters.Yesterday,
          ].includes(val)
      );
    } else if (onlyCustom) {
      return [reportFilters.Custom];
    } else {
      return Object.values(reportFilters);
    }
  };

  const rangeSizes: reportFilters[] = getDropDownValues();

  const handleDateRange = (event: any) => {
    defaultValue = event.target.value;
    if (event.target.value === reportFilters.TODAY) {
      const now = moment();
      const date = now.format("YYYY-MM-DD");

      dateValues({ from: date, to: date });
    } else if (event.target.value === reportFilters.ThisWeek) {
      const now = moment();

      const date = now.clone().startOf("isoWeek").format("YYYY-MM-DD");
      const date1 = now.format("YYYY-MM-DD");

      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.ThisMonth) {
      const now = moment();
      const date = now.format("YYYY-MM-01");
      const date1 = now.format("YYYY-MM-DD");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.ThisQuarter) {
      const now = moment().subtract(2, "month");
      const date = now.format("YYYY-MM-01");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.ThisYear) {
      const now = moment();
      const date = now.format("YYYY-01-01");
      const date1 = now.format("YYYY-12-31");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.Yesterday) {
      const now = moment().subtract(1, "day");
      const date = now.format("YYYY-MM-DD");

      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.PreviousWeek) {
      const now = moment().subtract(6, "day");
      const date = now.clone().startOf("isoWeek").format("YYYY-MM-DD");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.PreviousMonth) {
      const moment = require("moment");
      const now = moment().subtract(1, "month");
      const date = now.format("YYYY-MM-01");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.PreviousQuarter) {
      const moment = require("moment");
      const now = moment().subtract(5, "month");
      const date = now.format("YYYY-MM-01");
      const now1 = moment();
      const date1 = now1.format("YYYY-MM-DD");
      dateValues({ from: date, to: date1 });
    } else if (event.target.value === reportFilters.PreviousYear) {
      const moment = require("moment");
      const now = moment().subtract(1, "year");
      const date = now.format("YYYY-01-01");
      const now1 = moment().subtract(1, "year");
      const date1 = now1.format("YYYY-12-31");
      dateValues({ from: date, to: date1 });
    } else if (event?.target?.value === reportFilters.Custom) {
      setShow(!show);
    }
    setDropdownValue(event.target.value);
  };

  const handleDateChange = (event: any) => {
    const now = moment(event.value.start);
    const date = now.format("YYYY-MM-DD");

    const now1 = moment(event.value.end);
    const date1 = now1.format("YYYY-MM-DD");

    if (date1 !== "Invalid date") {
      dateValues({ from: date, to: date1 });
      setCalendarShow(!calendarShow);
    }
  };

  const handleChange = (event: any) => {
    let data = dropDownData.filter((el) => el.name === event.target.value);
    let link;
    if (data) {
      link = `/app/reports/${uuid}` + data[0].url;
    }
    history.push(data && link);
  };

  useEffect(() => {
    if (defaultValue && showDate) {
      handleDateRange({ target: { value: defaultValue } });
    }
  }, []);

  return (
    <div className="row reports-container">
      <div
        className="col-4 reports-header bz-cursor-pointer"
        onClick={() => history.push(`/app/reports/${uuid}`)}
      >
        <h2>Reports</h2>
      </div>
      <div className="col-4 reports-date-range">
        {showDate && !onlyCustom && (
          <>
            {/* <h2>Date Range</h2> */}
            <DropDownList
              className="reports-date-range-dropdown"
              data={rangeSizes}
              value={dropdownValue}
              defaultItem="Select..."
              onChange={handleDateRange}
            />
            <span ref={anchor}></span>
          </>
        )}
        {!onlyCustom && (
          <Popup
            anchor={anchor.current}
            show={show}
            popupClass={"reports-date-range-custom"}
          >
            <div>
              {
                <DateRangePicker
                  onChange={handleDateChange}
                  calendarSettings={
                    hideDayValues
                      ? { bottomView: "year", topView: "decade" }
                      : {}
                  }
                />
              }
              <li
                className="k-icon k-i-close ml-3"
                onClick={() => {
                  setShow(!show);
                }}
              ></li>
            </div>
          </Popup>
        )}
        {onlyCustom && (
          <DateRangePicker
            onChange={handleDateChange}
            calendarSettings={{ bottomView: "year", topView: "decade" }}
            show={calendarShow}
            onFocus={() => setCalendarShow(true)}
          />
        )}
      </div>
      <div className="col-4 reports-side-header">
        {showReports && (
          <DropDownList
            className="reports-date-dropdown"
            data={dropDownData.map((dropdown) => dropdown.name)}
            value={dropDownData.map((dropdown) =>
              `/app/reports/${uuid}` + dropdown.url === location.pathname
                ? dropdown.name
                : ""
            )}
            onChange={handleChange}
          />
        )}
      </div>
      <div className="reports-horizontal-line">
        {location.pathname === "/reports" && <hr />}
      </div>
    </div>
  );
};

export default TaxReportFilter;

import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React from "react";
import { Error } from "@progress/kendo-react-labels";
import withNotification from "components/Hoc/withNotification";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CurrencyFormat } from "utils";
import useSMSIntegration from "./SMSIntegration.hook";

const SMSIntegration = (props: any) => {
  const {
    loading,
    handleModify,
    handleSubmit,
    handleCancel,
    handleNext,
    handleChange,
    configuration,
    smsConfigured,
    submit,
    phoneNumbersList,
    buttonLoading,
  } = useSMSIntegration(props);
  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          <Card
            className="bz-pipedrive-container"
            style={{ background: "white", height: "auto" }}
          >
            <CardHeader className="py-0">
              <CardTitle className="d-flex flex-column bz-card-title">
                <span>{"Provide Account SID and Auth Token to configure"}</span>
                <small style={{textTransform:'none'}}>
                  To know how to configure{" "}
                  <a
                    rel="noreferrer"
                    className="btn btn-link text-left mb-1"
                    href="https://www.twilio.com/blog/better-twilio-authentication-csharp-twilio-api-keys"
                    target="_blank"
                  >
                    <u> click here</u>
                  </a>
                </small>
              </CardTitle>
            </CardHeader>
            <CardBody className="pt-2 pipedriveCard">
              <div className="bz-pipedrive-url-row pb-3">
                <div className="section-1">
                  <label>{"Account SID"}</label>
                  <Input
                    autoComplete={"off"}
                    name="AccountSId"
                    id="AccountSId"
                    style={{ width: "100%" }}
                    value={configuration?.AccountSId}
                    disabled={smsConfigured}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "AccountSId")
                    }
                  />
                  {submit && !configuration?.AccountSId?.trim() && (
                    <Error>{"Account SID is required"}</Error>
                  )}
                </div>
              </div>
              <div className="bz-pipedrive-url-row pb-3">
                <div className="section-1">
                  <label>{"Auth Token"}</label>
                  <Input
                    autoComplete={"off"}
                    name="AuthToken"
                    id="AuthToken"
                    style={{ width: "100%" }}
                    value={configuration?.AuthToken}
                    disabled={smsConfigured}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "AuthToken")
                    }
                  />
                  {submit && !configuration?.AuthToken?.trim() && (
                    <Error>{"Auth Token is required"}</Error>
                  )}
                </div>
              </div>
              {phoneNumbersList?.length > 0 ? (
                <>
                  <div className="bz-pipedrive-url-row pb-3">
                    <div className="section-1">
                      <label>{"Phone Numbers"}</label>
                      <DropDownList
                        id={"PhoneNumber"}
                        name={"PhoneNumber"}
                        data={phoneNumbersList}
                        textField="PhoneNumber"
                        style={{ width: "100%" }}
                        disabled={smsConfigured}
                        value={
                          phoneNumbersList?.filter(
                            (i: any) =>
                              i?.PhoneNumber === configuration?.PhoneNumber
                          )[0] || {
                            PhoneNumber: "Please Select a Phone Number",
                          }
                        }
                        dataItemKey="PhoneNumber"
                        onChange={(e: any) =>
                          handleChange(e.value.PhoneNumber, "PhoneNumber")
                        }
                      />

                      {submit && !configuration?.PhoneNumber?.trim() && (
                        <Error>{"Phone Number is required"}</Error>
                      )}
                    </div>
                  </div>
                  {configuration?.PhoneNumber && !smsConfigured ?
                    <div className="bz-pipedrive-url-row pb-1">
                    <div className="section-1">
                      <div>
                        <span className="pr-3 buz-font-600">
                          {props?.smsConfig?.ProratedCost
                            ? "Prorated cost for the current billing period"
                            : "Monthly Cost"}
                        </span>
                        <span className="pr-3">:</span>
                        <span>
                          {CurrencyFormat(props?.smsConfig?.ProratedCost ? props?.smsConfig?.ProratedCost : props?.smsConfig?.FeatureRate)}
                        </span>
                      </div>
                    </div>
                  </div>: null}
                  {smsConfigured ? (
                    <>
                      <div className="bz-pipedrive-url-row pb-3">
                        <div className="section-1">
                          <label>{"Next billing Date"}</label>
                          <div>
                            <DatePicker
                              min={new Date()}
                              width={"100%"}
                              disabled={true}
                              value={
                                new Date(props?.smsConfig?.NextBillingDate)
                              }
                              id={"NextBillingDate"}
                              name={"NextBillingDate"}
                              // onChange={(e) => {
                              //   handleChange(e.value, "NextBillingDate");
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="bz-pipedrive-url-row pb-1">
                        <div className="section-1">
                          <div>
                            <span className="pr-3 buz-font-600">
                              {props?.smsConfig?.ProratedCost
                                ? "Prorated cost for the current billing period"
                                : "Monthly Cost"}
                            </span>
                            <span className="pr-3">:</span>
                            <span>
                              {CurrencyFormat(props?.smsConfig?.ProratedCost ? props?.smsConfig?.ProratedCost : props?.smsConfig?.FeatureRate)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              <div className="bz-pipedrive-url-row pb-3">
                <div className="section-1 btn-container align-self-center">
                  <label className="k-label">&nbsp;</label>
                  <Button
                    disabled={buttonLoading}
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  {smsConfigured ? (
                    <BuzopsButton
                      label={"Modify"}
                      onClick={() => handleModify()}
                    />
                  ) : null}
                  {!smsConfigured && phoneNumbersList?.length > 0 ? (
                    <BuzopsButton
                      onClick={() => handleSubmit()}
                      primary
                      disabled={buttonLoading || smsConfigured}
                      type={"submit"}
                      loading={buttonLoading}
                      label={"Complete"}
                    />
                  ) : null}
                  {!smsConfigured && phoneNumbersList?.length === 0 ? (
                    <BuzopsButton
                      onClick={() => handleNext()}
                      primary
                      disabled={buttonLoading}
                      type={"submit"}
                      loading={buttonLoading}
                      label={"Validate"}
                    />
                  ) : null}
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
};

export default withNotification(SMSIntegration);

import React from "react";
import { NavLink } from "react-router-dom";
import { IReportsSectionProps } from "./Utilities/interfaces";

const ReportsSection: React.FunctionComponent<IReportsSectionProps> = ({
  heading,
  uuid,
  reports,
}) => {
  return (
    <>
      <div className="reports-landing-section">
        <h2>{heading}</h2>
        <div className="reports-landing-box">
          {reports.map((report, id) => (
            <>
              <div key={id} className="reports-landing-nav-item mb-3">
                <div className="reports-landing-content-view">
                  <span className="reports-landing-text-wrap">
                    {report.image ? (
                      <img
                        className="reports-landing-image"
                        src={report.image}
                      />
                    ) : (
                      <li
                        style={{ color: "#C0C0C0" }}
                        className="k-icon k-i-star-outline"
                      ></li>
                    )}
                    <NavLink
                      className="reports-landing-sub-heading"
                      to={`/app/reports/${uuid}${report.url}`}
                    >
                      {report.name}
                    </NavLink>{" "}
                  </span>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
export default ReportsSection;

import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  emailValidator,
  mobileValidator,
  passwordValidator,
} from "validators/validator";
import {
  AffiliateFormInput,
  CustomFormMaskedTextBox,
  FormPasswordInput,
} from "utils/form-components";
import useAffiliateSignup from "./useAffiliateSignup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import Camera from "components/summary/camera";
import { Hint } from "@progress/kendo-react-labels";
import { isMobile } from "react-device-detect";
import withNotification from "components/Hoc/withNotification";
import { getFullImageUrl } from "utils";
import BuzopsButton from "generic-components/BuzopsButton";

const AffiliateSignup = (props: any) => {
  const {
    handleChange,
    handlePasswordDisplay,
    touched,
    formData,
    btnLoading,
    passwordType,
    handleOnBlur,
    submit,
    handleSignup,
    setShowUploadDialog,
    handleDeleteDialog,
    toggleUplaodDialog,
    showDeleteDialog,
    toggleDeleteDialog,
    deleteImage,
    photoUploadPref,
    handleUploadAFileClick,
    handleTakeASnapClick,
    onAdd,
    onRemove,
    originalFiles,
    handleCapture,
    handleRefreshCamera,
    showUploadDialog,
    UploadImage,
    newPhotoStatus,
    submitdisable
  } = useAffiliateSignup(props);
  const showErrorMessage = (key: any, mainKey: any) => {
    if (key === "Email") {
      return emailValidator(formData[key]);
    } else if (key === "Password") {
      return passwordValidator(formData[key]);
    } else if (key === "ConfirmPassword") {
      if (
        formData?.Password.length > 0 &&
        formData?.Password !== formData?.ConfirmPassword
      ) {
        return `Password not matched`;
      }
    } else if (key === "PhoneNumber") {
      return mobileValidator(formData[key]);
    } else if (!formData[key] || formData[key].length <= 0) {
      return `${mainKey} is required`;
    }
    return "";
  };
  const renderDeleteDialog = () => {
    return (
      <Dialog onClose={() => toggleDeleteDialog()}>
        <p className={"dialogContainer"}>
          Are you sure you want to remove the photo?
        </p>
        <DialogActionsBar>
          <Button onClick={() => deleteImage(false)}>{"No,keep it."}</Button>
          <Button primary={true} onClick={() => deleteImage(true)}>
            Yes
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  const renderUploadDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-familyMember-dialog"
        title={"Upload a Profile Photo"}
        onClose={() => toggleUplaodDialog()}
        width={"34rem"}
      >
        <div style={{ textAlign: "justify" }} className="k-grid bzc-grid">
          <div className="row">
            <div className="col-sm-12">
              <div className="sales-radio-button-headers">
                <RadioButton
                  name="uploadPhoto2"
                  value="2"
                  checked={photoUploadPref === "2"}
                  label="Upload a File"
                  onClick={handleUploadAFileClick}
                />
                {"   "}
                {!isMobile && (
                  <RadioButton
                    name="uploadPhoto1"
                    value="1"
                    checked={photoUploadPref === "1"}
                    label="Take a Snap"
                    onClick={handleTakeASnapClick}
                  />
                )}
              </div>
              <hr />
              <div className="k-form-field">
                {/* <label className="k-label pt-3"></label> */}
                {photoUploadPref === "2" && (
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 500000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                )}
                {photoUploadPref === "1" && (
                  <div>
                    <Camera
                      handleCameraCapture={handleCapture}
                      handleRefreshCamera={handleRefreshCamera}
                    />
                    {/* <Hint>*Please make sure camera permissions are provided</Hint> */}
                  </div>
                )}
                <Hint>
                  {
                    "Only JPEG and PNG files are allowed. Allowed size below 500KB"
                  }
                </Hint>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <Button
            className={"mt-3"}
            onClick={() => toggleUplaodDialog()}
          >
            Cancel
          </Button>
          <Button
            className={"mt-3"}
            primary={true}
            onClick={() => UploadImage()}
          >
            Upload
          </Button>
        </div>

      </Dialog>
    );
  };
  const DummyImage = "/images/user.png";

  return (
    <>
      <div className="container h-100">
        <div className="row">
          <div className="col-12 col-lg-7 col-xl-7 mx-auto">
            <div className="login-box card mx-auto bz-border-radius-1 shadow-sm px-4 py-4 affliatesignup">
              <div className="h3 mb-3 mt-0 affliatesignupheader">
                <h6 className="">
                  Affiliate <span>Sign Up</span>
                </h6>
              </div>

              <div className="d-block justify-content-center">
                <div className="row   p-0">
                  <div className="col-md-12 align-self-center">
                    <div className="bz-Manage-User-Img d-flex justify-content-center pb-4">
                      <div className="mx-auto ProfilePicOptions">
                        <img
                          width={120}
                          src={
                            !newPhotoStatus
                              ? formData?.Photo
                                ? getFullImageUrl(formData?.Photo)
                                : DummyImage
                              : formData?.Photo
                          }
                          alt=""
                          className="rounded-circle img-circle"
                        />
                        {formData?.Photo ? <span
                          className="bz-delete-userImg customtooltip"
                          onClick={() => {
                            handleDeleteDialog();
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          <span className="tooltiptext">Delete</span>
                        </span> : null}
                        <span
                          className={formData?.Photo ? "bz-upload-userImg customtooltip" : "bz-upload-userImg bz-upload-userImg-only customtooltip"}
                          onClick={() => {
                            setShowUploadDialog(true);
                          }}
                        >
                          <i className="fa fa-camera" aria-hidden="true"></i>
                          <span className="tooltiptext">Upload</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="form-group col-md-6">
                        <AffiliateFormInput
                          tabindex="1"
                          id={"FirstName"}
                          name={"FirstName"}
                          style={{ width: "100%" }}
                          placeholder="First Name"
                          label="First Name"
                          autoFocus={true}
                          required={true}
                          handleOnBlur={(name: string, value: any) =>
                            handleOnBlur(name, value)
                          }
                          handleChange={(name: string, value: any) =>
                            handleChange(name, value)
                          }
                          value={formData?.FirstName}
                          touched={touched?.FirstName}
                          submit={submit}
                          showErrorMessage={showErrorMessage(
                            "FirstName",
                            "First Name"
                          )}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <AffiliateFormInput
                          tabindex="2"
                          id={"LastName"}
                          name={"LastName"}
                          style={{ width: "100%" }}
                          placeholder="Last Name"
                          label="Last Name"
                          required={true}
                          handleOnBlur={(name: string, value: any) =>
                            handleOnBlur(name, value)
                          }
                          autoFocus={false}
                          handleChange={(name: string, value: any) =>
                            handleChange(name, value)
                          }
                          value={formData?.LastName}
                          touched={touched?.LastName}
                          submit={submit}
                          showErrorMessage={showErrorMessage("LastName", "Last Name")}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '5px' }}>
                          <div style={{ flexGrow: '1' }} >  <AffiliateFormInput
                            tabindex="0"
                            id={"Email"}
                            name={"Email"}
                            style={{ width: "100%" }}
                            placeholder="Email"
                            label="Email"
                            required={true}
                            className={'AffiliateEmail'}
                            disabled={true}
                            autoFocus={false}
                            submit={submit}
                            handleOnBlur={(name: string, value: any) =>
                              handleOnBlur(name, value)
                            }
                            handleChange={(name: string, value: any) =>
                              handleChange(name, value)
                            }
                            value={formData?.Email}
                            touched={touched?.Email}
                            showErrorMessage={showErrorMessage("Email", "Email")}
                          /></div>
                          <span className="email_envelope_icon">   <i className='fa fa-envelope'></i></span>

                        </div>

                      </div>
                      <div className="form-group col-md-6">
                        <CustomFormMaskedTextBox
                          tabindex={"3"}
                          id={"PhoneNumber"}
                          name={"PhoneNumber"}
                          placeholder="Phone Number"
                          width={"100%"}
                          submit={submit}
                          className={"CustomMaskedTextBox"}
                          label="Phone Number"
                          handleOnBlur={(name: string, value: any) =>
                            handleOnBlur(name, value)
                          }
                          handleChange={(name: string, value: any) =>
                            handleChange(name, value)
                          }
                          value={formData?.PhoneNumber}
                          required={true}
                          mask="(999) 000-0000"
                          touched={touched?.PhoneNumber}
                          showErrorMessage={showErrorMessage("PhoneNumber", "Phone")}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <FormPasswordInput
                          tabindex="4"
                          type={passwordType?.Password ? "password" : "text"}
                          id={"Password"}
                          name={"Password"}
                          placeholder="Password"
                          label="Password"
                          style={{ width: "100%" }}
                          required={true}
                          submit={submit}
                          autoFocus={false}
                          handleOnBlur={(name: string, value: any) =>
                            handleOnBlur(name, value)
                          }
                          handleChange={(name: string, value: any) =>
                            handleChange(name, value)
                          }
                          value={formData?.Password}
                          touched={touched?.Password}
                          validate={true}
                          passwordType={passwordType?.Password}
                          handlePasswordDisplay={() =>
                            handlePasswordDisplay("Password")
                          }
                          showErrorMessage={showErrorMessage("Password", "Password")}
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <FormPasswordInput
                          tabindex="5"
                          style={{ width: "100%" }}
                          type={passwordType?.ConfirmPassword ? "password" : "text"}
                          id={"ConfirmPassword"}
                          name={"ConfirmPassword"}
                          placeholder="Confirm Password"
                          label="Confirm Password"
                          required={true}
                          submit={submit}
                          autoFocus={false}
                          handleOnBlur={(name: string, value: any) =>
                            handleOnBlur(name, value)
                          }
                          handleChange={(name: string, value: any) =>
                            handleChange(name, value)
                          }
                          value={formData?.ConfirmPassword}
                          touched={touched?.ConfirmPassword}
                          passwordType={passwordType?.ConfirmPassword}
                          handlePasswordDisplay={() =>
                            handlePasswordDisplay("ConfirmPassword")
                          }
                          showErrorMessage={showErrorMessage(
                            "ConfirmPassword",
                            "Password"
                          )}
                        />
                      </div>
                      {/* <div className="form-group col-md-12">
                  <CustomFormTextArea
                    tabindex="7"
                    id={"ShortDescription"}
                    name={"ShortDescription"}
                    style={{ width: "100%" }}
                    placeholder="Description"
                    label="Description"
                    autoFocus={true}
                    required={false}
                    handleOnBlur={(name: string, value: any) =>
                      handleOnBlur(name, value)
                    }
                    handleChange={(name: string, value: any) =>
                      handleChange(name, value)
                    }
                    value={formData?.ShortDescription}
                    touched={touched?.ShortDescription}
                    submit={submit}
                    showErrorMessage={showErrorMessage(
                      "ShortDescription",
                      "Description"
                    )}
                  />
                </div> */}
                    </div>
                  </div>

                  <div className="buttons-container">
                    <div>
                      {/* Already a Member ?{" "}
            <span className="btn btn-link">Please Login</span>{" "} */}
                    </div>
                    <BuzopsButton
                      className="sign-up-btn m-0"
                      label={"Sign Up"}
                      disabled={submitdisable || btnLoading}
                      loading={btnLoading}
                      onClick={() => handleSignup()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showUploadDialog && renderUploadDialog()}
      {showDeleteDialog && renderDeleteDialog()}
    </>
  );
};

export default withNotification(AffiliateSignup);

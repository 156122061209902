export enum HardwareSecuritySystemSetupStatusType {
  Pending = 0,
  Enabled = 1,
}

export enum CheckinNotificationPreference {
  None = 0,
  Email = 1,
  SMS = 2,
  Both = 3,
}

export enum TrainerCheckinNotificationPreference {
  None = 0,
  Email = 1,
  SMS = 2,
  Both = 3,
}

import { useEffect, useState } from "react";
import { ManageTaxesService } from "services/managetaxes/index.service";
const useSettings = (props: any) => {
    const uuid = props?.match?.params?.uuid;
    const [loading, setLoading] = useState(true);
    const [toggleLoading, setToggleLoading] = useState(false);
    const [hasTaxEnabled, setHasTaxEnabled] = useState(false);
    const [clubID, setClubId] = useState(props?.ClubId || 0);
    const service = new ManageTaxesService();
    const initialState = {
        tax: false,
    };
    const [page, setPage] = useState(initialState);

    const handleClose = () => {
        setPage(initialState);
    };

    useEffect(() => {
        fetchAllApis();
    }, []);

    const handleTax = () => {
        setPage({ ...page, tax: true });
    };
    const fetchUserSession = async () => {
        const res = await service.getTenantDetails(
            uuid
        );
        const clubId = res?.Item?.UserSession?.ClubId;
        const HasTaxEnabledVal =
            res?.Item?.UserSession?.ClubPreferences?.HasTaxEnabled;
        setClubId(clubId);
        setHasTaxEnabled(HasTaxEnabledVal);
        return clubId;
    };

    const handleEnableTax = async (val:boolean) => {
        await handleEnableTaxForClub(val);
    };

    const fetchAllApis = async () => {
        setLoading(true);
        await fetchUserSession();
        setLoading(false);
    };

    const handleEnableTaxForClub = async (value: boolean) => {
        const req = {
            ClubId: clubID,
            hasTaxEnabled: value,
        };
        setToggleLoading(true);
        const res = await service.enableTax(req);
        setToggleLoading(false);
        if (res.ResponseCode === 100) {
            props?.handleNotificationMessage(
                `Surcharges ${value ? "Enabled" : "Disabled"} Successfully`,
                "success"
            );
            await fetchAllApis();
        } else {
            const errorMsg = res?.ErrorMessages[0] || "Error in Updating";
            props?.handleNotificationMessage(errorMsg, "error");
        }
    };


    return {
        loading,
        uuid,
        handleEnableTax,
        hasTaxEnabled,
        toggleLoading,
        handleTax, handleClose,page
    };
};

export default useSettings;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LogoutCheckin = (props: any) => {

    const {isLoggedIn} = useSelector((state:any)=>state?.staffCheckIn)
    const history = useHistory();

    useEffect(() => {
        console.log(isLoggedIn);
        if(isLoggedIn){
            handleLogout();
        }
    }, [isLoggedIn]);
    
    const handleLogout = () => {
        history.push({
            pathname: `/kiosk/login`,
            state: {
                logout: true,
            },
        });
    };
    return (
        <div>

        </div>
    )
}

export default LogoutCheckin
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { ListView } from "@progress/kendo-react-listview";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useState } from "react";
import DeclinedItem from "./declinedItem";
import DeclineFilter from "./DeclineFilter";
import { TenantService } from "services/tenant/index.service";
import withNotification from "components/Hoc/withNotification";
import { Dialog } from "@progress/kendo-react-dialogs";

const ManageDeclines = (props: any) => {
  const {
    declinedData,
    setDeclinedTransactions,
    fetchDeclinedPayments,
    loading,
    calculateTotal,
    handleRefreshData
  } = props;
  const [failedTransactionLog, setFailedTransactionLog] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const service = new TenantService();
  const chargeDeclinedPayments = async () => {
    const filterChargeData = declinedData?.filter(
      (val: any) => val.IsNeedToCharge
    );
    if (filterChargeData?.length > 0) {
      setButtonLoading(true);
      const resp = await service.ChargeDeclinedPayments(filterChargeData);
      const filterFailedLog = resp.filter(
        (val: any) => val.CurrentTransactionStatus
      );
      if (!filterFailedLog?.length) {
        fetchDeclinedPayments();
        props?.handleNotificationMessage(
          "Declined payment charged Successfully",
          "success"
        );
      } else {
        setFailedTransactionLog(filterFailedLog);
      }
      setButtonLoading(false);
    } else {
      props?.handleNotificationMessage(
        "Please select atleast one transaction to charge.",
        "error"
      );
    }
  };

  const handleCheckData = (add: boolean, index = -1) => {
    const declinedPaymentsTemp = [...declinedData];
    if (index === -1) {
      setDeclinedTransactions((val: any) =>
        val.map((dt: any) => {
          return { ...dt, IsNeedToCharge: add };
        })
      );
    } else {
      declinedPaymentsTemp[index] = {
        ...declinedPaymentsTemp[index],
        IsNeedToCharge: add,
      };
      setDeclinedTransactions(declinedPaymentsTemp);
    }
  };

  const renderfailedTransactions = (records: any) => {
    let item = records.dataItem;
    if (typeof item === "string") {
      return <div>{item}</div>;
    }
    return (
      <div className="col-12">
        <div className="row p-3 align-middle buz-online-list-view">
          <div className="col-12">
            <div className="buzopsListValidateItem m-0 text-capitalize ">
              Name: {item?.ParentName}{" "}
              <span className="text-muted text-capitalize d-block mb-0">
                Total Amount:{" "}
                {item?.TotalAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="buzopsListValidateItem m-0 text-danger"
                dangerouslySetInnerHTML={{
                  __html: item.CurrentTransactionStatus,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onclose = () => {
    setFailedTransactionLog([]);
    fetchDeclinedPayments();
  };
  const renderfailedTransactionDialog = () => {
    return (
      <Dialog
        className="bz-mybookings-dialog-box bz-custom-content-height bz-checkin-status"
        title={"Transaction Status"}
        onClose={() => onclose()}
        width={440}
      >
        <ListView
          data={failedTransactionLog}
          item={(props: any) => renderfailedTransactions(props)}
          style={{
            width: "100%",
          }}
        />
      </Dialog>
    );
  };

  return (
    <>
      {
        <>
          <span style={{lineHeight:'28px'}}>
          {`Total revenue of ${calculateTotal.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  })} is pending collection for Suspended Memberships. This report excludes Suspended Memberships. `}
  <span style={{ background: '#f1f4f7', padding: '5px', borderRadius: '.25rem', fontWeight:'600' }}>
    Kindly refer to the 'Subscribers Suspended' section for further information.
  </span>
          </span>
          <div className="row my-2">
            <div className="col-md-8">
              <DeclineFilter fetchDeclinedPayments={fetchDeclinedPayments} />
            </div>
            <div className="col-md-4 insertbtn text-right">
              Select All{" "}
              <Switch
                onChange={(e: SwitchChangeEvent) =>
                  handleCheckData(e.value, -1)
                }
                checked={declinedData?.every((val: any) => val?.IsNeedToCharge)}
              />
            </div>
          </div>

          <div className="bz-class-list">
            {loading ? (
              <BuzopsLoader type={"list"} />
            ) : declinedData.length === 0 ? (
              <>{"No Declined Data Available"}</>
            ) : (
              <>
                <ListView
                  item={(prop1: any) => (
                    <DeclinedItem
                      {...props}
                      {...prop1}
                      key={
                        prop1?.PaymentGatewayPaymentProfileId + prop1?.selected
                      }
                      handleCheckData={handleCheckData}
                      handleRefreshData={handleRefreshData}
                    />
                  )}
                  data={declinedData || []}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  className={"bz-manage-declines decline"}
                />
                <div className="float-right">
                  <BuzopsButton
                    onClick={() => chargeDeclinedPayments()}
                    className={"m-4"}
                    key={`${buttonLoading}btn`}
                    disabled={buttonLoading}
                    loading={buttonLoading}
                    label="RUN DECLINED PAYMENTS"
                  />
                </div>
              </>
            )}
          </div>
        </>
      }
      {failedTransactionLog.length > 0 && renderfailedTransactionDialog()}
    </>
  );
};

export default withNotification(ManageDeclines);

import {
  ExcelExport as Excel,
  ExcelExportColumn as Column,
} from "@progress/kendo-react-excel-export";

interface IExcelExport {
  data: any[];
  collapsible?: boolean;
  fileName: string;
  _exporter: any;
  columns: { field: string; title: string; width?: number }[];
}

const ExcelExport: React.FunctionComponent<IExcelExport> = ({
  data,
  collapsible = true,
  fileName,
  _exporter,
  columns,
}) => {
  return (
    <>
      <Excel
        data={data}
        collapsible={collapsible}
        fileName={fileName}
        ref={_exporter}
      >
        {columns.map((column) => (
          <Column
            field={column.field}
            title={column.title}
            width={column.width || 200}
          />
        ))}
      </Excel>
    </>
  );
};

export default ExcelExport;

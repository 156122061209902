import { useFormik } from "formik";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { IOpenPathFormProps } from "../Utilities/interfaces";
import { useEffect, useState } from "react";
import * as Yup from "yup";

const schema = Yup.object({
  UserName: Yup.string().required("UserName Required"),
  Password: Yup.string().required("Password Required"),
  OrganizationId: Yup.string().required("Organization Id Required"),
  NamespaceId: Yup.number()
    .min(1, "NamespaceId must be greater than 1")
    .max(999999999, "NamespaceId must be less than 999999999")
    .typeError("Namespace Id must be a number"),
  SiteConfiguration: Yup.object({
    ConfiguredSiteId: Yup.string().required("Configured Site Id Required"),
    ConfiguredSiteName: Yup.string().required("Site Name  Required"),
    ConfiguredAcuId: Yup.number()
      .typeError("ACU Id must be a number")
      .required("ACU Id Required"),

    ConfiguredEntries: Yup.array().of(
      Yup.object().shape({
        Id: Yup.number().required("Entry Id Required"),
        Name: Yup.string().required("Entry Name Required"),
      })
    ),
    ConfiguredGroups: Yup.array().of(
      Yup.object().shape({
        Id: Yup.number().required("Group Id Required"),
        Name: Yup.string().required("Group Name Required"),
      })
    ),

    DefaultGroupIdForMembers: Yup.string().required(
      "Default Group Id For Members Required"
    ),
    DefaultGroupIdForStaff: Yup.string().required(
      "Default Group Id For Staff Required"
    ),
  }),
});

const OpenPathForm = (props: any) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      UserName: undefined,
      Password: undefined,
      OrganizationId: undefined,
      NamespaceId: undefined,
      SiteConfiguration: {
        ConfiguredSiteId: undefined,
        ConfiguredSiteName: undefined,
        ConfiguredAcuId: undefined,
        ConfiguredEntries: [
          {
            Id: undefined,
            Name: undefined,
            UsedForSiteAccess: true,
          },
        ],
        ConfiguredGroups: [
          {
            Id: undefined,
            Name: undefined,
            ForStaffOnly: false,
          },
        ],
        DefaultGroupIdForMembers: undefined,
        DefaultGroupIdForStaff: undefined,
      },
    } as IOpenPathFormProps,
    enableReinitialize: true,
    validationSchema: schema,

    onSubmit: (values) => {
      props.handleSubmit(values, setBtnLoading);
    },
  });

  useEffect(() => {
    if (props.OpenPathSettings.SiteConfiguration === null) {
      props.OpenPathSettings.SiteConfiguration = {
        ConfiguredSiteId: undefined,
        ConfiguredSiteName: undefined,
        ConfiguredAcuId: 0,
        ConfiguredEntries: [
          {
            Id: undefined,
            Name: undefined,
            UsedForSiteAccess: true,
          },
        ],
        ConfiguredGroups: [
          {
            Id: undefined,
            Name: undefined,
            ForStaffOnly: false,
          },
        ],
        DefaultGroupIdForMembers: undefined,
        DefaultGroupIdForStaff: undefined,
      };
    }
    if (props.OpenPathSettings) {
      formik.setValues({ ...props.OpenPathSettings });
    }
    formik.setErrors({});
  }, [props.OpenPathSettings]);

  const addHorizontalLine =
    formik.values?.SiteConfiguration?.ConfiguredEntries.length > 1 ||
    formik.values?.SiteConfiguration?.ConfiguredGroups.length > 1;
  const errorClass = formik.errors ? "bz-error" : "";
  return (
    <>
      <h5
        className="d-flex justify-content-between mb-3 bz_fs_1_5"
        style={{ fontSize: "1rem", fontWeight: 600 }}
      >
        OpenPath Settings
      </h5>
      <div className="row bz-mt-2rem bz-mb-2_5rem bz-checkins-settings-container bz-openpath-settings-container">
        <div className="col-12">
          <form onSubmit={formik.handleSubmit}>
            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">Username</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"text"}
                    id={"UserName"}
                    name="UserName"
                    onChange={(e) =>
                      formik.setFieldValue("UserName", e.target.value)
                    }
                    value={formik.values.UserName}
                  />
                  {formik.errors.UserName ? (
                    <p style={{ color: "red" }}>{formik.errors.UserName}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">Password</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"password"}
                    id={"Password"}
                    name="Password"
                    onChange={(e) =>
                      formik.setFieldValue("Password", e.target.value)
                    }
                    value={formik.values.Password}
                  />

                  {formik.errors.Password ? (
                    <p style={{ color: "red" }}>{formik.errors.Password}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">Organization Id</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"number"}
                    id={"OrganizationId"}
                    name="OrganizationId"
                    onChange={(e) =>
                      formik.setFieldValue("OrganizationId", e.target.value)
                    }
                    value={formik.values.OrganizationId}
                  />
                  {formik.errors.OrganizationId ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.OrganizationId}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">Namespace Id</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"number"}
                    id={"NamespaceId"}
                    name="NamespaceId"
                    onChange={(e) =>
                      formik.setFieldValue("NamespaceId", e.target.value)
                    }
                    value={formik.values.NamespaceId}
                    min={1}
                    max={999999999}
                  />
                  {formik.errors.NamespaceId ? (
                    <p style={{ color: "red" }}>{formik.errors.NamespaceId}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">
                Configured Site Id
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"number"}
                    id={"SiteConfiguration.ConfiguredSiteId"}
                    name="SiteConfiguration.ConfiguredSiteId"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "SiteConfiguration.ConfiguredSiteId",
                        e.target.value
                      )
                    }
                    value={formik.values.SiteConfiguration?.ConfiguredSiteId}
                  />
                  {formik.errors?.SiteConfiguration?.ConfiguredSiteId ? (
                    <p style={{ color: "red" }}>
                      {formik.errors?.SiteConfiguration?.ConfiguredSiteId}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">Site Name</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"string"}
                    id={"SiteConfiguration.ConfiguredSiteName"}
                    name="SiteConfiguration.ConfiguredSiteName"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "SiteConfiguration.ConfiguredSiteName",
                        e.target.value
                      )
                    }
                    value={formik.values.SiteConfiguration?.ConfiguredSiteName}
                  />
                  {formik.errors?.SiteConfiguration?.ConfiguredSiteName ? (
                    <p style={{ color: "red" }}>
                      {formik.errors?.SiteConfiguration?.ConfiguredSiteName}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className={`bz-content-text-row ${errorClass}`}>
              <div className="bz-content-title text-right">ACU Id</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <Input
                    type={"number"}
                    id={"SiteConfiguration.ConfiguredAcuId"}
                    name="SiteConfiguration.ConfiguredAcuId"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "SiteConfiguration.ConfiguredAcuId",
                        e.target.value
                      )
                    }
                    value={formik.values.SiteConfiguration?.ConfiguredAcuId}
                  />
                  {formik.errors?.SiteConfiguration?.ConfiguredAcuId ? (
                    <p style={{ color: "red" }}>
                      {formik.errors?.SiteConfiguration?.ConfiguredAcuId}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            {formik.values?.SiteConfiguration?.ConfiguredEntries.map(
              (entries, index) => (
                <div className="bz-entry-items">
                  {addHorizontalLine && <hr />}
                  <div className={`bz-content-text-row ${errorClass}`}>
                    <div className="bz-content-title text-right">Entry Id</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {" "}
                      <Input
                        type={"number"}
                        id={`SiteConfiguration.ConfiguredEntries${index}.Id`}
                        name={`SiteConfiguration.ConfiguredEntries${index}.Id`}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `SiteConfiguration.ConfiguredEntries.${index}.Id`,
                            e.target.value
                          )
                        }
                        value={entries.Id}
                      />
                      {formik.errors?.SiteConfiguration?.ConfiguredEntries?.[
                        index
                      ] ? (
                        <p style={{ color: "red" }}>
                          {
                            formik.errors.SiteConfiguration.ConfiguredEntries[
                              index
                            ]?.Id
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={`bz-content-text-row ${errorClass}`}>
                    <div className="bz-content-title text-right">
                      Entry Name
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {" "}
                      <Input
                        type={"text"}
                        id={`SiteConfiguration.ConfiguredEntries${index}.Name`}
                        name={`SiteConfiguration.ConfiguredEntries${index}.Name`}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `SiteConfiguration.ConfiguredEntries.${index}.Name`,
                            e.target.value
                          )
                        }
                        value={entries.Name}
                      />
                      {formik.errors?.SiteConfiguration?.ConfiguredEntries?.[
                        index
                      ] ? (
                        <p style={{ color: "red" }}>
                          {
                            formik.errors.SiteConfiguration.ConfiguredEntries[
                              index
                            ]?.Name
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={`bz-content-text-row ${errorClass}`}>
                    <div className="bz-content-title text-right">
                      Used For Site Access
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <Checkbox
                        id={`SiteConfiguration.ConfiguredEntries${index}.UsedForSiteAccess`}
                        name={`SiteConfiguration.ConfiguredEntries${index}.UsedForSiteAccess`}
                        checked={
                          formik.values.SiteConfiguration.ConfiguredEntries[
                            index
                          ].UsedForSiteAccess
                        }
                        defaultChecked={false}
                        onChange={(e) =>
                          formik.setFieldValue(
                            `SiteConfiguration.ConfiguredEntries.${index}.UsedForSiteAccess`,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <BuzopsButton
                        label="Remove Entry"
                        type="button"
                        className="btn-link remove-entry-btn m-0"
                        onClick={() => {
                          const entries = [
                            ...formik.values.SiteConfiguration
                              .ConfiguredEntries,
                          ];
                          entries.splice(index, 1);
                          formik.setFieldValue(
                            "SiteConfiguration.ConfiguredEntries",
                            [...entries]
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <BuzopsButton
                  label="Add Entry"
                  type="button"
                  onClick={() =>
                    formik.setFieldValue(
                      "SiteConfiguration.ConfiguredEntries",
                      [
                        ...formik.values.SiteConfiguration.ConfiguredEntries,
                        {
                          Id: undefined,
                          Name: undefined,
                          UsedForSiteAccess: true,
                        },
                      ]
                    )
                  }
                  className="m-0"
                />
              </div>
            </div>
            {formik.values?.SiteConfiguration?.ConfiguredGroups.map(
              (groups, index) => (
                <div className="row">
                  <div className="bz-entry-items">
                    {addHorizontalLine && <hr />}
                    <div className={`bz-content-text-row ${errorClass}`}>
                      <div className="bz-content-title text-right">
                        Group Id
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <Input
                          type={"number"}
                          id={`SiteConfiguration.ConfiguredGroups${index}.Id`}
                          name={`SiteConfiguration.ConfiguredGroups${index}.Id`}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `SiteConfiguration.ConfiguredGroups.${index}.Id`,
                              e.target.value
                            )
                          }
                          value={groups.Id}
                        />

                        {formik.errors?.SiteConfiguration?.ConfiguredGroups?.[
                          index
                        ] ? (
                          <p style={{ color: "red" }}>
                            {
                              formik.errors.SiteConfiguration.ConfiguredGroups[
                                index
                              ]?.Id
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className={`bz-content-text-row ${errorClass}`}>
                      <div className="bz-content-title text-right">
                        Group Name
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <Input
                          type={"text"}
                          id={`SiteConfiguration.ConfiguredGroups${index}.Name`}
                          name={`SiteConfiguration.ConfiguredGroups${index}.Name`}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `SiteConfiguration.ConfiguredGroups.${index}.Name`,
                              e.target.value
                            )
                          }
                          value={groups.Name}
                        />
                        {formik.errors?.SiteConfiguration?.ConfiguredGroups?.[
                          index
                        ] ? (
                          <p style={{ color: "red" }}>
                            {
                              formik.errors.SiteConfiguration.ConfiguredGroups[
                                index
                              ]?.Name
                            }
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        For Staff Only
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        <Checkbox
                          id={`SiteConfiguration.ConfiguredGroups${index}.ForStaffOnly`}
                          name={`SiteConfiguration.ConfiguredGroups${index}.ForStaffOnly`}
                          checked={
                            formik.values.SiteConfiguration.ConfiguredGroups[
                              index
                            ].ForStaffOnly
                          }
                          defaultChecked={false}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `SiteConfiguration.ConfiguredGroups.${index}.ForStaffOnly`,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right"></div>
                      <span className="bz-colon text-center"></span>
                      <div className="bz-content-body">
                        <BuzopsButton
                          label="Remove Group"
                          type="button"
                          className="btn-link remove-entry-btn m-0"
                          onClick={() => {
                            const groups = [
                              ...formik.values.SiteConfiguration
                                .ConfiguredGroups,
                            ];
                            groups.splice(index, 1);
                            formik.setFieldValue(
                              "SiteConfiguration.ConfiguredGroups",
                              [...groups]
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <BuzopsButton
                  label="Add Group"
                  type="button"
                  className="m-0"
                  onClick={() =>
                    formik.setFieldValue("SiteConfiguration.ConfiguredGroups", [
                      ...formik.values.SiteConfiguration.ConfiguredGroups,
                      {
                        Id: undefined,
                        Name: undefined,
                        ForStaffOnly: false,
                      },
                    ])
                  }
                />
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Default GroupId For Members
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <DropDownList
                    id={"SiteConfiguration.DefaultGroupIdForMembers"}
                    name="SiteConfiguration.DefaultGroupIdForMembers"
                    value={
                      formik.values.SiteConfiguration?.DefaultGroupIdForMembers
                    }
                    data={formik.values.SiteConfiguration?.ConfiguredGroups.filter(
                      (group) =>
                        group.Id !== undefined && group.ForStaffOnly === false
                    ).map((group) => group.Id)}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "SiteConfiguration.DefaultGroupIdForMembers",
                        e.target.value
                      );
                    }}
                  />
                  {formik.errors?.SiteConfiguration
                    ?.DefaultGroupIdForMembers ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.SiteConfiguration.DefaultGroupIdForMembers}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">
                Default GroupId For Staff
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <div>
                  <DropDownList
                    id={"DefaultGroupIdForStaff"}
                    name="SiteConfiguration.DefaultGroupIdForStaff"
                    value={
                      formik.values.SiteConfiguration?.DefaultGroupIdForStaff
                    }
                    data={formik.values.SiteConfiguration?.ConfiguredGroups.filter(
                      (group) =>
                        group.Id !== undefined && group.ForStaffOnly === true
                    ).map((group) => group.Id)}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "SiteConfiguration.DefaultGroupIdForStaff",
                        e.target.value
                      )
                    }
                  />
                  {formik.errors?.SiteConfiguration?.DefaultGroupIdForStaff ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.SiteConfiguration.DefaultGroupIdForStaff}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="bz-content-text-row mb-0">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <BuzopsButton
                  type="submit"
                  label="Submit"
                  className="m-0"
                  loading={btnLoading}
                  disabled={btnLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <hr className="pb-3" />
    </>
  );
};

export default OpenPathForm;

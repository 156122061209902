import { SelfCheckInType } from "utils/constants";

interface tabProps {
  selectedType: SelfCheckInType;
  handleToggle: (type: SelfCheckInType) => void;
  renderIf: boolean
}
const SelfCheckinTabs = (props: tabProps) => {
  const { selectedType, handleToggle, renderIf } = props;
  if(!renderIf){
    return <></>;
  }
  return (
    <div className="self-radio-checkgroup">
      <div
        className="btn-group btn-group-toggle d-flex pb-0 row"
        data-toggle="buttons"
      >
        <div dir="ltr" className="selectbarcode self-barcode col">
          <div
            className={` ${
              selectedType === SelfCheckInType.BARCODE ? "  active" : " "
            } btn-sm d-block pb-0`}
          >
            <input
              type="radio"
              name="options"
              id="option1"
              checked={selectedType === SelfCheckInType.BARCODE}
              onClick={() => handleToggle(SelfCheckInType.BARCODE)}
              className="self-btn-check"
            />
            <label
              className="k-radio-label selfcheck-radio-btn"
              htmlFor="option1"
            >
              Access code
            </label>
          </div>
        </div>
        <div dir="ltr" className="selectbarcode self-barcode col">
          <div
            className={` ${
              selectedType === SelfCheckInType.EMAIL ? " active" : " "
            } btn-sm d-block pb-0`}
          >
            <input
              type="radio"
              name="options"
              id="option2"
              checked={selectedType === SelfCheckInType.EMAIL}
              onClick={() => handleToggle(SelfCheckInType.EMAIL)}
              className="self-btn-check"
            />
            <label
              className="k-radio-label selfcheck-radio-btn"
              htmlFor="option2"
            >
              Email
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelfCheckinTabs;

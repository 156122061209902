/* eslint-disable camelcase */
import Dustbin from './dustbin';
import { FormItemTypes } from 'utils/form-utils';
import { AddElementInForm, UpdateOrder } from 'redux-slices/formSlice';
import { useDispatch, useSelector } from 'react-redux';
import ComponentHeader from '../ComponentHeader';
import ComponentLabel from '../ComponentLabel';
import ID from 'utils/UUID';
import { useEffect, useState } from 'react';

const accepts = [FormItemTypes.BOX, FormItemTypes.CARD];


const MultiColumnRowBase = (props) => {
  const {
    controls,
    data,
    editModeOn,
    getDataById,
    setAsChild,
    removeChild,
    seq,
    className,
    index,
  } = props;
  const { childItems, pageBreakBefore,element } = data;
  let baseClasses = 'SortableItem rfb-item';
  if (pageBreakBefore) {
    baseClasses += ' alwaysbreak';
  }
  return (
    <div style={{ ...props.style }} className={baseClasses}>
      <ComponentHeader {...props} />
      <div>
        <ComponentLabel {...props} />
        <div className="row">
          {childItems?.map((x, i) => (
            <div key={`${i}_${x || '_'}`} className={className}>
              {controls ? (
                controls[i]
              ) : (
                <Dustbin
                  style={{ width: '100%' }}
                  data={data}
                  accepts={accepts}
                  items={childItems}
                  col={i}
                  parentIndex={index}
                  editModeOn={editModeOn}
                  _onDestroy={() => removeChild(data, i)}
                  getDataById={getDataById}
                  setAsChild={setAsChild}
                  seq={seq}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const TwoColumnRow = ({ data, class_name, ...rest }) => {
  const className = class_name || 'col-md-6';
  if (!data.childItems) {
    // eslint-disable-next-line no-param-reassign
    data.childItems = [null, null];
    data.isContainer = true;
  }
  return <MultiColumnRowBase {...rest} className={className} data={data} />;
};

const ThreeColumnRow = ({ data, class_name, ...rest }) => {
  const className = class_name || 'col-md-4';
  if (!data.childItems) {
    // eslint-disable-next-line no-param-reassign
    data.childItems = [null, null, null];
    data.isContainer = true;
  }
  return <MultiColumnRowBase {...rest} className={className} data={data} />;
};

const MultiColumnRow = ({ data, ...rest }) => {
  const colCount = data.col_count || 4;
  const className = data.class_name || (colCount === 4 ? 'col-md-3' : 'col');
  if (!data.childItems) {
    // eslint-disable-next-line no-param-reassign
    data.childItems = Array.from({ length: colCount }, (v, i) => null);
    data.isContainer = true;
  }
  return <MultiColumnRowBase {...rest} className={className} data={data} />;
};

const Address = ({ data, ...rest }) => {
  const dispatch = useDispatch()
  
  const StoreState = useSelector(state => state.form)
  const [generatedRecords,setGeneratedRecords] =useState([])
  const AddressFieldTemplate=StoreState?.fieldTemplates?.find(i=> i?.element==="Address")
  const addressItems=AddressFieldTemplate?.childItems
  const create = (item) => {
    const elementKey = item.element || item.key;
    const elementOptions = {
      id: ID.uuid(),
      element: elementKey,
      text: item.name,
      group_name: item.group_name,
      static: item.static,
      required: false,
    }

    if (item.canHaveAnswer) {
      elementOptions.canHaveAnswer = item.canHaveAnswer;
    }

    if (item.canReadOnly) {
      elementOptions.readOnly = false;
    }

    if (item.canDefaultToday) {
      elementOptions.defaultToday = false;
    }

    if (item.content) {
      elementOptions.content = item.content;
    }

    if (item.inherited !== undefined) {
      elementOptions.inherited = item.inherited;
    }

    elementOptions.canHavePageBreakBefore =
      item.canHavePageBreakBefore !== false;
    elementOptions.canHaveAlternateForm = item.canHaveAlternateForm !== false;
    elementOptions.canHaveDisplayHorizontal =
      item.canHaveDisplayHorizontal !== false;
    if (elementOptions.canHaveDisplayHorizontal) {
      elementOptions.inline = item.inline;
    }
    elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
    elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
    elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

    if (item.class_name) {
      elementOptions.class_name = item.class_name;
    }

    if (elementKey === 'DatePicker') {
      elementOptions.dateFormat = item.dateFormat;
      elementOptions.timeFormat = item.timeFormat;
      elementOptions.showTimeSelect = item.showTimeSelect;
      elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
      elementOptions.showTimeInput = item.showTimeInput;
    }

    if (item.element === 'MultiColumnRow') {
      elementOptions.col_count = item.col_count;
    }

    if (item.element === 'Address') {
      elementOptions.col_count = item.col_count;
    }
    if (item.element === 'Prospect') {
      elementOptions.col_count = item.col_count;
    }

    if (item.defaultValue) {
      elementOptions.defaultValue = item.defaultValue;
    }

    if (item.field_name) {
      elementOptions.field_name = item.field_name + ID.uuid();
    }

    if (item.label) {
      elementOptions.label = item.label;
    }

    if (item.options) {
      if (item.options.length > 0) {
        elementOptions.options = item.options.map((x) => ({
          ...x,
          key: `custom_option_${ID.uuid()}`,
        }));
      }
    }

    return elementOptions;
  };
  let maindata ={...data}
  const className = `${maindata.class_name} MultiRowLabel`
  useEffect(() => {
    const findIndex = maindata?.childItems?.findIndex(i => i===null)
    if(findIndex !== -1) {
      handleChildItemsInParent()
    }
  }, [])

  useEffect(() => {
    if(generatedRecords?.length > 0){
      const findIndex = StoreState?.data.findIndex(i => i.id === maindata?.id)
      const res=generatedRecords?.filter(j=>j?.id !== maindata?.id).map((i,index) => {
        // rest.setAsChild(maindata, i, index, false,true);
        return {...i,col:index,parentId:maindata?.id,parentIndex:findIndex}
      })
      const childItems= res?.map(i => i?.id)
      const result = StoreState?.data?.map(i=> {
        const findIndex= childItems?.findIndex(j=> j ===i?.id)
        if(maindata?.id ===i?.id){
          return {...i,childItems:childItems}
        }else if(findIndex !== -1){
          const findItem=res[findIndex]
          return {...findItem}
        }
        return {...i}
      })
  
      dispatch(UpdateOrder(result));
    }
  }, [generatedRecords])

 
  const handleChildItemsInParent=()=> {
    if (maindata) {
      let reccc=[]
      addressItems?.forEach((i, index) => {
        const generatedRecord = create(i);
        dispatch(AddElementInForm(generatedRecord))
        // rest.setAsChild(maindata, generatedRecord, index, false,true);
        // return generatedRecord?.id
        reccc.push(generatedRecord)
      });
      setGeneratedRecords(reccc)
      // maindata.childItems = res
      // console.log(res,'res')
      // maindata.isContainer = true;
    }
  }
  
  
  return <MultiColumnRowBase {...rest} className={className} data={maindata}  />;
};
const Prospect = ({ data, ...rest }) => {
  const dispatch = useDispatch()
  
  const StoreState = useSelector(state => state.form)
  const [generatedRecords,setGeneratedRecords] =useState([])
  const ProspectFieldTemplate=StoreState?.fieldTemplates?.find(i=> i?.element==="Prospect")
  const prospectItems=ProspectFieldTemplate?.childItems
  const create = (item) => {
    const elementKey = item.element || item.key;
    const elementOptions = {
      id: ID.uuid(),
      element: elementKey,
      text: item.name,
      group_name: item.group_name,
      static: item.static,
      required: true,
    }

    if (item.canHaveAnswer) {
      elementOptions.canHaveAnswer = item.canHaveAnswer;
    }

    if (item.canReadOnly) {
      elementOptions.readOnly = false;
    }

    if (item.canDefaultToday) {
      elementOptions.defaultToday = false;
    }

    if (item.content) {
      elementOptions.content = item.content;
    }

    if (item.inherited !== undefined) {
      elementOptions.inherited = item.inherited;
    }

    elementOptions.canHavePageBreakBefore =
      item.canHavePageBreakBefore !== false;
    elementOptions.canHaveAlternateForm = item.canHaveAlternateForm !== false;
    elementOptions.canHaveDisplayHorizontal =
      item.canHaveDisplayHorizontal !== false;
    if (elementOptions.canHaveDisplayHorizontal) {
      elementOptions.inline = item.inline;
    }
    elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
    elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
    elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

    if (item.class_name) {
      elementOptions.class_name = item.class_name;
    }

    if (elementKey === 'DatePicker') {
      elementOptions.dateFormat = item.dateFormat;
      elementOptions.timeFormat = item.timeFormat;
      elementOptions.showTimeSelect = item.showTimeSelect;
      elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
      elementOptions.showTimeInput = item.showTimeInput;
    }

    if (item.element === 'MultiColumnRow') {
      elementOptions.col_count = item.col_count;
    }

    if (item.element === 'Address') {
      elementOptions.col_count = item.col_count;
    }
    if (item.element === 'Prospect') {
      elementOptions.col_count = item.col_count;
    }

    if (item.defaultValue) {
      elementOptions.defaultValue = item.defaultValue;
    }

    if (item.field_name) {
      elementOptions.field_name = item.field_name + ID.uuid();
    }

    if (item.label) {
      elementOptions.label = item.label;
    }

    if (item.options) {
      if (item.options.length > 0) {
        elementOptions.options = item.options.map((x) => ({
          ...x,
          key: `custom_option_${ID.uuid()}`,
        }));
      }
    }

    return elementOptions;
  };
  let maindata ={...data}
  const className = `${maindata.class_name} MultiRowLabel`
  useEffect(() => {
    const findIndex = maindata?.childItems?.findIndex(i => i===null)
    if(findIndex !== -1) {
      handleChildItemsInParent()
    }
  }, [])

  useEffect(() => {
    if(generatedRecords?.length > 0){
      const findIndex = StoreState?.data.findIndex(i => i.id === maindata?.id)
      const res=generatedRecords?.filter(j=>j?.id !== maindata?.id).map((i,index) => {
        // rest.setAsChild(maindata, i, index, false,true);
        return {...i,col:index,parentId:maindata?.id,parentIndex:findIndex}
      })
      const childItems= res?.map(i => i?.id)
      const result = StoreState?.data?.map(i=> {
        const findIndex= childItems?.findIndex(j=> j ===i?.id)
        if(maindata?.id ===i?.id){
          return {...i,childItems:childItems}
        }else if(findIndex !== -1){
          const findItem=res[findIndex]
          return {...findItem}
        }
        return {...i}
      })
  
      dispatch(UpdateOrder(result));
    }
  }, [generatedRecords])

 
  const handleChildItemsInParent=()=> {
    if (maindata) {
      let reccc=[]
      prospectItems?.forEach((i, index) => {
        const generatedRecord = create(i);
        dispatch(AddElementInForm(generatedRecord))
        // rest.setAsChild(maindata, generatedRecord, index, false,true);
        // return generatedRecord?.id
        reccc.push(generatedRecord)
      });
      setGeneratedRecords(reccc)
      // maindata.childItems = res
      // console.log(res,'res')
      // maindata.isContainer = true;
    }
  }
  
  
  return <MultiColumnRowBase {...rest} className={className} data={maindata}  />;
};

export { TwoColumnRow, ThreeColumnRow, MultiColumnRow, Address,Prospect };

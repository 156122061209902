import * as React from "react";
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import BuzopsLoader from "generic-components/BuzopsLoader";

interface PageState {
  skip: number;
  take: number;
}

interface IColumns {
  field: string;
  title: string;
  width?: number;
  cell?: any;
  editor?: any;
  locked?: boolean;
  hide?: boolean;
}

interface IGridTableProps {
  title: string;
  total: number;
  columns: IColumns[];
  data: any[];
  handlePagination: any;
  pageSize: number;
  className?: string;
  rowRender?: any;
  editField?: string;
  onItemChange?: any;
  loading?: boolean;
  resizable?: boolean;
  expandField?: string;
  onExpandChange?: any;
  detail?: any
}

const GridTable: React.FunctionComponent<IGridTableProps> = ({
  title,
  total,
  columns,
  data,
  handlePagination,
  pageSize,
  className,
  rowRender,
  editField,
  onItemChange,
  loading,
  resizable,
  expandField,
  onExpandChange,
  detail
}) => {
  const [page, setPage] = React.useState<PageState>({
    skip: 0,
    take: pageSize,
  });
  const pageChange = async(event: GridPageChangeEvent) => {
    if(await handlePagination(event.page.skip / 10, event.page.take)){
      setPage({ skip: event.page.skip, take: event.page.take });
    }
  };

  if(loading){
    return <BuzopsLoader />
  }

  return (
    <div className="payment-transaction-container">
      <h5 className="mb-3 bz_fs_1_5">{title}</h5>
      
      {data.length > 0 ? (
        <>
          <div className={className}>
            <Grid
              data={data}
              className="bzc-grid"
              skip={page.skip}
              take={page.take}
              total={total}
              pageable={true}
              onPageChange={pageChange}
              pageSize={pageSize}
              rowRender={rowRender}
              editField={editField}
              onItemChange={onItemChange}
              resizable={resizable}
              onExpandChange={onExpandChange}
              expandField={expandField}
              detail={detail}
            >
              {columns.map((column, index) => (
                !column.hide &&
                <GridColumn
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  cell={column.cell}
                  key={index}
                  editor={column.editor}
                  locked={column.locked}
                />
              ))}
            </Grid>
          </div>
        </>
      ) : (
        <div className={"bz-norecords-available text-muted"}>No data</div>
      )}
    </div>
  );
};

export default GridTable;

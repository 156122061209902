import withNotification from "components/Hoc/withNotification";
import React, { useEffect } from "react";
import { useApiClient } from "services/axios-service-utils";
import { Error, Label, Hint } from "@progress/kendo-react-labels";
import useState from "react-usestateref";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { PipeDriveService } from "services/pipedrive/index.service";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import BuzopsButton from "generic-components/BuzopsButton";
import { TenantService } from "services/tenant/index.service";
import GHLPipeLineStageUI from "./GHLPipeLineStage";
import { PackageService } from "services/package/index.service";
interface IGhlConfiguration {
  uuid?: any;
  from?: string;
  handleCRMSave: () => void;
  handleNotificationMessage: (text: any, type: string) => void;
}

const sortOptionsInArraybyPosition = (arrayItem: any) => {
  arrayItem.sort((a: any, b: any) => {
    return a.position - b.position;
  });
  return arrayItem;
};

const GhlConfiguration = ({
  handleCRMSave,
  uuid,
  from,
  handleNotificationMessage,
}: IGhlConfiguration) => {
  const { axiosRequest } = useApiClient();
  const service = new PipeDriveService();

  const [validateForm, setValidateForm] = useState(false);
  const [unLink, setUnlink] = useState(false);
  const [loading, setLoading] = useState(true);
  const [configurationData, setConfigurationData, configurationDataRef] =
    useState<any>({});
  const [pipelineData, setPipelineData, pipeLineDataRef] = useState<any>([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [unlinkButtonLoading, setUnlinkButtonLoading] = useState(false);
  const [validations, setValidations] = useState<any>([]);
  const [planData, setPlanData] = useState<any>([]);
  const [unPaidSubscriptionData, setUnPaidSubscriptionData] = useState<any>([]);
  const [packageData, setPackageData] = useState<any>([]);
  const [presetPackageData, setPresetPackageData] = useState<any>([]);
  const [pipeLineStages, setPipeLineStages] = useState<any>(null);

  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchGHLConfiguration();
    if (configurationDataRef?.current) {
      await getPlanData();
      await fetchPackages();
      await getUnpaidAppointments();
      await getGHLPipeline();
      await fetchServiceStatus();
    }
    setLoading(false);
  };

  const fetchStagesWithPipelineId = (PipelineId: string) => {
    const getStages = pipeLineDataRef?.current?.find(
      (i: any) => i?.id === PipelineId
    )?.stages;
    return getStages || [];
  };

  const getPlanData = async () => {
    const req = {};
    const service = new TenantService();
    const res = await service.AvailablePlansToSubscribe(req);
    const combinedIds = configurationDataRef?.current?.PlanPipelines
      .map((i: any) => i.MembershipPlanIds)
      .flat();
    const mainData = res.map((j: any) => ({
      Name: j?.Name,
      PlanManagementId: j?.PlanManagementId,
      disabled: combinedIds.includes(j.PlanManagementId)
    }));

    setPlanData(mainData);
  };
  const getUnpaidAppointments = async () => {
    let req: any = { PageNumber: 0, PageSize: 32657 };
    setLoading(true);
    const servicedet = await axiosRequest.post("Appointments/Inventory", req);
    let result = servicedet.data?.Items;
    let data = []
    if (result?.length > 0) {
      data = result?.filter((i: any) => i?.PrivateServiceType === "Consultation" || i?.PrivateServiceType === "SemiPrivateUnpaid")
    }
    const combinedIds = configurationDataRef?.current?.UnPaidSubscriptionPipelines
      .map((i: any) => i.PrivateServiceInstanceIds)
      .flat();
    const mainData = data.map((j: any) => ({
      Name: j?.Name,
      PrivateServiceId: j?.PrivateServiceId,
      disabled: combinedIds.includes(j.PrivateServiceId)
    }));
    setUnPaidSubscriptionData(mainData);
  };
  const fetchPackages = async () => {
    const req: any = {
      SortDirection: "ASC",
      SortBy: "Name",
      PageSize: 32657,
      PageNumber: 0,
    };
    const service = new PackageService();

    const result = await service.getPackageNamesFilter(req);
    let data = []
    if (result?.ResponseCode === 100) {
      data = result?.Items
    }
    const presetData = data?.filter((i: any) => i?.SessionType === 3)
    const nonPresetData = data?.filter((i: any) => i?.SessionType !== 3)

    const combinedPackageIds = configurationDataRef?.current?.PackagePipelines
      .map((i: any) => i.PackageIds)
      .flat();
    const nonPresetMainData = nonPresetData.map((j: any) => ({
      Name: j?.Name,
      PackageId: j?.PackageId,
      disabled: combinedPackageIds.includes(j.PackageId)
    }));
    const combinedPresetPackageIds = configurationDataRef?.current?.PresetPackagePipelines
      .map((i: any) => i.PresetPackageIds)
      .flat();
    const PresetMainData = presetData.map((j: any) => ({
      Name: j?.Name,
      PackageId: j?.PackageId,
      disabled: combinedPresetPackageIds.includes(j.PackageId)
    }));

    setPresetPackageData(PresetMainData)
    setPackageData(nonPresetMainData)
  };

  const fetchServiceStatus = async () => {
    const getServiceStatus = await service.getServiceStatus();
    const ProspectStatus = [
      {
        Description: "StageId",
        Label: "When a customer enrolls in Buzops as prospect",
        Id: 1,
      },
    ];
    const UnPaidStatus = [
      {
        Description: "UnPaidSubscriptionStageId",
        Label:
          "When a prospect enrolls in non-paid subscription(Complimentary, Consultation, Free Subscriptions)",
        Id: 1,
      },
    ];
    const res = { ...getServiceStatus, ProspectStatus, UnPaidStatus };
    setPipeLineStages(res);
  };

  const getGHLPipeline = async () => {
    await axiosRequest.get("CRM/GHL/GetPipelines", {
      successCallBack: (response: any) => {
        const data = response.data.Item.Items;
        const mainData = [
          { id: null, name: "Do Nothing", stages: [] },
          ...data,
        ];
        setPipelineData(mainData || []);
      },
      errorCallBack: (error: any) => {
        setPipelineData([]);
        const errorMsg =
          error?.response?.data?.Messages?.[0] ||
          error?.data?.Messages?.[0] ||
          "Internal Server Error";
        handleNotificationMessage(errorMsg, "error");
      },
    });
  };

  const fetchGHLConfiguration = async () => {
    await axiosRequest.get("CRM/GHL/configuration", {
      successCallBack: async (response: any) => {
        const result = response.data?.Item || null;
        setConfigurationData(result);
        if (
          result?.AccessToken &&
          result?.AccessToken !== "" &&
          result?.AccessToken?.length > 0
        ) {
          setValidateForm(true);
          return true;
        } else {
          setValidateForm(false);
          return false;
        }
      },
      errorCallBack: (error: any) => {
        setConfigurationData(null);
      },
    });
  };

  const handleAddPipeLine = (parent: string, itemKey: string) => {
    const record = [...configurationDataRef?.current?.[parent]][0];
    const obj: any = new Object();
    for (let keyItem in record) {
      if (keyItem === itemKey) {
        obj[keyItem] = [];
      } else {
        obj[keyItem] = null;
      }
    }
    const main = [...configurationDataRef?.current?.[parent]];
    main.push(obj);
    const item = {
      ...configurationDataRef?.current,
      [parent]: main,
    };
    setConfigurationData(item);
  };

  const handleDeletePipeline = (parent: string, index: number) => {
    const record = [...configurationDataRef?.current?.[parent]];
    if (record?.length === 1) {
      return;
    }
    record?.splice(index, 1);
    const item = {
      ...configurationDataRef?.current,
      [parent]: record,
    };
    setConfigurationData(item);
  };

  const handlePipelineSelection = (
    parent: string,
    pipeLinekeyItem: string,
    value: any,
    index: number
  ) => {
    const allPipelines = [...configurationDataRef?.current?.[parent]];

    const res = allPipelines?.map((i: any, indexVal) => {
      if (indexVal === index) {
        const obj: any = new Object();
        for (let keyItem in i) {
          if (keyItem === pipeLinekeyItem) {
            obj[keyItem] = value;
          } else if (
            keyItem === "PrivateServiceInstanceIds" ||
            keyItem === "MembershipPlanIds" ||
            keyItem === "PresetPackageIds" ||
            keyItem === "PackageIds"

          ) {
            obj[keyItem] = i[keyItem];
          } else {
            obj[keyItem] = null;
          }
        }
        return obj;
      } else {
        return { ...i };
      }
    });
    const item = {
      ...configurationDataRef?.current,
      [parent]: res,
    };
    return item;
  };
  const handleParentPipelineSelection = (
    parent: string,
    pipeLinekeyItem: string,
    val: any,
    index: number
  ) => {
    const allPipelines = [...configurationDataRef?.current?.[parent]];
    let value = val;
    switch (pipeLinekeyItem) {
      case "MembershipPlanIds":
        value = val?.map((i: any) => i?.PlanManagementId);
        break;
      case "PrivateServiceInstanceIds":
        value = val?.map((i: any) => i?.PrivateServiceId);
        break;
      case "PresetPackageIds":
        value = val?.map((i: any) => i?.PackageId);
        break;
      case "PackageIds":
        value = val?.map((i: any) => i?.PackageId);
        break;
      default:
        break;
    }
    const res = allPipelines?.map((i: any, indexVal) => {
      if (indexVal === index) {
        return { ...i, [pipeLinekeyItem]: value };
      } else {
        return { ...i };
      }
    });
    const item = {
      ...configurationDataRef?.current,
      [parent]: res,
    };
    return item;
  };

  const handleChange = async (
    val: any,
    name: string,
    parent: any = null,
    index = 0
  ) => {
    if (parent === null) {
    } else {
      let item = { ...configurationDataRef?.current };
      if (
        name === "PlanPipelineId" ||
        name === "PipelineId" ||
        name === "PackagePipelineId" ||
        name === "PresetPackagePipelineId"
      ) {
        switch (name) {
          case "PlanPipelineId":
            item = handlePipelineSelection("PlanPipelines", name, val, index);
            break;
          case "PackagePipelineId":
            item = handlePipelineSelection(
              "PackagePipelines",
              name,
              val,
              index
            );
            break;
          case "PresetPackagePipelineId":
            item = handlePipelineSelection(
              "PresetPackagePipelines",
              name,
              val,
              index
            );
            break;
          case "PipelineId":
            const obj: any = new Object();
            if (parent === "ProspectPipeline") {
              for (let keyItem in item?.ProspectPipeline) {
                if (keyItem === "PipelineId") {
                  obj[keyItem] = val;
                } else {
                  obj[keyItem] = null;
                }
              }
              item = {
                ...configurationDataRef?.current,
                [parent]: obj,
              };
            } else if (parent === "UnPaidSubscriptionPipelines") {
              item = handlePipelineSelection(parent, "PipelineId", val, index);
            }
            break;
          default:
            break;
        }
      } else {
        if (
          parent === "PlanPipelines" ||
          parent === "PresetPackagePipelines" ||
          parent === "PackagePipelines" ||
          parent === "UnPaidSubscriptionPipelines"
        ) {
          item = handleParentPipelineSelection(parent, name, val, index);
        } else {
          item = {
            ...configurationDataRef?.current,
            [parent]: {
              ...configurationDataRef?.current[parent],
              [name]: val,
            },
          };
        }
      }
      setConfigurationData(item);
      switch (name) {
        case "PrivateServiceInstanceIds":
          const combinedIds = configurationDataRef?.current?.UnPaidSubscriptionPipelines
            .map((i: any) => i.PrivateServiceInstanceIds)
            .flat();
          const mainData = unPaidSubscriptionData.map((j: any) => ({
            Name: j?.Name,
            PrivateServiceId: j?.PrivateServiceId,
            disabled: combinedIds.includes(j.PrivateServiceId)
          }));
          setUnPaidSubscriptionData(mainData)
          break;
        case "PresetPackageIds":
          const combinedPresetPackageIds = configurationDataRef?.current?.PresetPackagePipelines
            .map((i: any) => i.PresetPackageIds)
            .flat();
          const PresetMainData = presetPackageData.map((j: any) => ({
            Name: j?.Name,
            PackageId: j?.PackageId,
            disabled: combinedPresetPackageIds.includes(j.PackageId)
          }));
          setPresetPackageData(PresetMainData)
          break;
        case "PackageIds":
          const combinedPackageIds = configurationDataRef?.current?.PackagePipelines
            .map((i: any) => i.PackageIds)
            .flat();
          const MainData = packageData.map((j: any) => ({
            Name: j?.Name,
            PackageId: j?.PackageId,
            disabled: combinedPackageIds.includes(j.PackageId)
          }));
          setPackageData(MainData)
          break;
        case "MembershipPlanIds":
          const combinedPlanIds = configurationDataRef?.current?.PlanPipelines
            .map((i: any) => i.MembershipPlanIds)
            .flat();
          const mainPlanData = planData.map((j: any) => ({
            Name: j?.Name,
            PlanManagementId: j?.PlanManagementId,
            disabled: combinedPlanIds.includes(j.PlanManagementId)
          }));

          setPlanData(mainPlanData);
          break;
        default:
          break;
      }
    }
  };

  const ValidateMultiselectItems = (dataItem: any, key: string) => {
    const filterData = dataItem?.filter((i: any) => i[key]?.length === 0)
    return filterData || []
  }

  const handleValidation = (config: any, all = true) => {
    if (config?.UnPaidSubscriptionPipelines?.length > 0 && ValidateMultiselectItems(config?.UnPaidSubscriptionPipelines, "PrivateServiceInstanceIds")?.length > 0) {
      return ["Please Select Non Paid Appointments"]
    }
    if (config?.PlanPipelines?.length > 0 && ValidateMultiselectItems(config?.PlanPipelines, "MembershipPlanIds")?.length > 0) {
      return ["Please Select Memberships"]
    }
    if (config?.PresetPackagePipelines?.length > 0 && ValidateMultiselectItems(config?.PresetPackagePipelines, "PresetPackageIds")?.length > 0) {
      return ["Please Select Preset Packages"]
    }
    if (config?.PackagePipelines?.length > 0 && ValidateMultiselectItems(config?.PackagePipelines, "PackageIds")?.length > 0) {
      return ["Please Select Packages"]
    }
    return [];
  };

  const handleSubmit = async () => {
    const validateData = handleValidation(configurationDataRef?.current, false);
    if (validateData.length > 0) {
      handleNotificationMessage(validateData[0], "error");
      return true;
    } else {
      setButtonLoading(true);
      await axiosRequest.post(
        "CRM/GHL/configuration",
        configurationDataRef?.current,
        {
          successCallBack: async (response: any) => {
            setValidateForm(true);
            handleNotificationMessage(
              "GHL Configuration Successfully Saved",
              "success"
            );
            setButtonLoading(false);
            await fetchAllApis();
          },
          errorCallBack: (error: any) => {
            const errorMsg =
              error?.response?.data?.Messages?.[0] ||
              error?.data?.Messages?.[0] ||
              "Error in Saving GHL Configuration";
            handleNotificationMessage(errorMsg, "error");
            setButtonLoading(false);
          },
        }
      );
    }
  };

  const handleUnlinkGHL = async () => {
    setUnlinkButtonLoading(true);
    await axiosRequest.post(
      "CRM/GHL/Unlink",
      {},
      {
        successCallBack: async (response: any) => {
          setValidateForm(true);
          handleNotificationMessage(
            "GHL Configuration Successfully Unlinked",
            "success"
          );
          setUnlinkButtonLoading(false);
          handleCRMSave();
        },
        errorCallBack: (error: any) => {
          const errorMsg =
            error?.response?.data?.Messages?.[0] ||
            error?.data?.Messages?.[0] ||
            "Error in unlinking GHL Configuration";
          handleNotificationMessage(errorMsg, "error");
          setUnlinkButtonLoading(false);
        },
      }
    );
  };

  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          <Card
            className="bz-pipedrive-container"
            style={{ background: "white", height: "auto" }}
          >   {!validateForm && (
            <CardHeader className="pt-0">

              <CardTitle className="bz-card-title">
                {"Configure GHL to connect"}
              </CardTitle>

            </CardHeader>
          )}
            <CardBody className="pt-0 pipedriveCard">
              {!validateForm ? (
                <div className="bz-pipedrive-url-row pb-3"></div>
              ) : (
                <div className="text-start mb-2">
                  <BuzopsButton
                    loading={unlinkButtonLoading}
                    disabled={unlinkButtonLoading}
                    onClick={() => handleUnlinkGHL()}
                    label={"Unlink"}
                    className="unlinkbtn"
                  />
                </div>
              )}

              {validateForm && (
                <>

                  <div className="row pt-3 pb-3 prospect-blk ghlblks">
                    <div className="col-12 mb-0">
                      <div className="form-group mb-0 bz-form-group-row align-items-center">
                        <h6 className="h6 d-flex justify-content-between mb-0">

                          <span>  {"Choose a pipeline for prospect"}</span>
                          <span> <small className="bz-ribbon-1">Pipeline</small></span>
                        </h6>
                        <div className="form-dropdown">
                          <DropDownButton
                            textField={"name"}
                            items={pipelineData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipelineData.find(
                                (i: any) =>
                                  i?.id ===
                                  configurationDataRef?.current
                                    ?.ProspectPipeline?.PipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e?.item?.id,
                                "PipelineId",
                                "ProspectPipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {pipeLineStages?.ProspectStatus?.map((item: any) => {
                        let itemName = item?.Description;
                        const Pipeline = fetchStagesWithPipelineId(
                          configurationDataRef?.current?.ProspectPipeline
                            ?.PipelineId
                        );
                        const PipelinesStages = [
                          { id: null, name: "Do Nothing" },
                          ...Pipeline,
                        ];
                        return (
                          <>
                            <div className="stage-list mb-0">
                              <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                                <Label className="form-label mb-0">
                                  <div className="d-flex justify-content-between">
                                    <span> {item?.Label}</span>
                                    <span> <small className="bz-ribbon-1 stage">
                                      Stage
                                    </small></span>
                                  </div>
                                  {configurationDataRef?.current
                                    ?.ProspectPipeline?.[itemName] === null && (
                                      <small>
                                        <Hint>
                                          {
                                            "Clients will be created in pipedrive as contacts when selected do nothing"
                                          }
                                        </Hint>
                                      </small>
                                    )}
                                </Label>
                                <div className="form-dropdown">
                                  <DropDownButton
                                    textField={"name"}
                                    items={PipelinesStages}
                                    iconClass="fas fa-chevron-down"
                                    text={
                                      PipelinesStages?.find(
                                        (i: any) =>
                                          i?.id ===
                                          configurationDataRef?.current
                                            ?.ProspectPipeline?.[itemName]
                                      )?.name
                                    }
                                    look="flat"
                                    className={
                                      "bz-white-btn bz-dropdown-btn px-0"
                                    }
                                    onItemClick={(e: any) => {
                                      handleChange(
                                        e?.item?.id,
                                        itemName,
                                        "ProspectPipeline"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                  </div>
                  {console.log(unPaidSubscriptionData, 'unPaidSubscriptionData')}
                  <GHLPipeLineStageUI
                    dataItem={{
                      ParentKey: "UnPaidSubscriptionPipelines",
                      SelectionIds: "PrivateServiceInstanceIds",
                      SelectionIdKey: "PrivateServiceId",
                      dataItemKey: "PipelineId",
                      serviceStatusKey: "UnPaidStatus",
                    }}
                    label={"Choose a pipeline for Non Paid Appointments"}
                    configurationData={configurationDataRef?.current}
                    subscriptionData={unPaidSubscriptionData}
                    pipelineData={pipelineData}
                    pipeLineStages={pipeLineStages}
                    handleAddPipeLine={handleAddPipeLine}
                    handleDeletePipeline={handleDeletePipeline}
                    fetchStagesWithPipelineId={fetchStagesWithPipelineId}
                    handleChange={handleChange}
                  />

                  <GHLPipeLineStageUI
                    dataItem={{
                      ParentKey: "PlanPipelines",
                      SelectionIds: "MembershipPlanIds",
                      SelectionIdKey: "PlanManagementId",
                      dataItemKey: "PlanPipelineId",
                      serviceStatusKey: "PlanStatus",
                    }}
                    label={"Choose a pipeline for plan subscription"}
                    configurationData={configurationDataRef?.current}
                    subscriptionData={planData}
                    pipelineData={pipelineData}
                    pipeLineStages={pipeLineStages}
                    handleAddPipeLine={handleAddPipeLine}
                    handleDeletePipeline={handleDeletePipeline}
                    fetchStagesWithPipelineId={fetchStagesWithPipelineId}
                    handleChange={handleChange}
                  />
                  <GHLPipeLineStageUI
                    dataItem={{
                      ParentKey: "PresetPackagePipelines",
                      SelectionIds: "PresetPackageIds",
                      SelectionIdKey: "PackageId",
                      dataItemKey: "PresetPackagePipelineId",
                      serviceStatusKey: "PackageInstanceStatus",
                    }}
                    label={"Choose a pipeline for Preset Package subscription"}
                    configurationData={configurationDataRef?.current}
                    subscriptionData={presetPackageData}
                    pipelineData={pipelineData}
                    pipeLineStages={pipeLineStages}
                    handleAddPipeLine={handleAddPipeLine}
                    handleDeletePipeline={handleDeletePipeline}
                    fetchStagesWithPipelineId={fetchStagesWithPipelineId}
                    handleChange={handleChange}
                  />
                  <GHLPipeLineStageUI
                    dataItem={{
                      ParentKey: "PackagePipelines",
                      SelectionIds: "PackageIds",
                      SelectionIdKey: "PackageId",
                      dataItemKey: "PackagePipelineId",
                      serviceStatusKey: "PackageInstanceStatus",
                    }}
                    label={"Choose a pipeline for Package subscription"}
                    configurationData={configurationDataRef?.current}
                    subscriptionData={packageData}
                    pipelineData={pipelineData}
                    pipeLineStages={pipeLineStages}
                    handleAddPipeLine={handleAddPipeLine}
                    handleDeletePipeline={handleDeletePipeline}
                    fetchStagesWithPipelineId={fetchStagesWithPipelineId}
                    handleChange={handleChange}
                  />
                </>
              )}
            </CardBody>
            <div className="text-center mt-2">
              {from && (
                <div className="py-2">
                  <div className="section-1 btn-container align-self-center justify-self-start">
                    {validateForm && (
                      <BuzopsButton
                        onClick={() => handleSubmit()}
                        primary
                        disabled={buttonLoading}
                        type={"submit"}
                        loading={buttonLoading}
                        label={"Save"}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default withNotification(GhlConfiguration);

import { lazy } from "react";
import Reports from "components/reports";
import Plan from "components/reports/Subscribers/Components/Plan";
import Addons from "components/reports/Subscribers/Components/AddOns";
import IndividualServices from "components/reports/Subscribers/Components/IndividualServices";
import GroupServices from "components/reports/Subscribers/Components/GroupServices";
import Packages from "components/reports/Subscribers/Components/Packages";
import SalesByAddons from "components/reports/Sales/Addons/index";
import SalesByOneTimeCharge from "components/reports/Sales/OneTimeCharge/index";
import SalesByGroupServices from "components/reports/Sales/GroupServices/index";
import SalesByIndividualServices from "components/reports/Sales/One-on-One/index";
import SalesByPackages from "components/reports/Sales/Packages/index";
import Compare from "components/reports/Sales/commonCompare";
import AllCustomers from "components/reports/Customers/allCustomers";
import SalesByAllSignups from "components/reports/Sales/allSignups";
import ServiceLog from "components/reports/Attendance/Components/ServiceLog";
import StaffPayout from "components/reports/Payroll/Components/StaffPayout";
import SalesByPlanGeneric from "components/reports/Sales/Plan/byPlan";
import RevenueGeneric from "components/reports/Revenue/Revenue/revenueGeneric";
import TransactionsGeneric from "components/reports/Revenue/Transactions";
import AccountUpdater from "components/reports/Payments/Components/AccountUpdater";
import MRR from "components/reports/Revenue/Components/MRR";
import OnSiteLog from "components/reports/Attendance/Components/OnSiteLog";
import Deliquency from "components/reports/Revenue/Deliquency";
import SubscriberStatistics from "components/reports/Subscribers/Components/Statistics";
import ExternalHome from "components/external/ExternalHome";
import SessionExpired from "generic-components/SessionExpired";
import SelfSign from "components/CheckIns/SelfSign";
import SelfCheckIn from "components/CheckIns/SelfCheckIn";
import CheckInByStaff from "components/CheckIns/CheckInByStaff";
import LiveStream from "components/CheckIns/LiveStream";
import ThirdPartyLiveStream from "components/CheckIns/ThirdPartyLiveStream";
import CheckinSystem from "components/integrations/Components/CheckinSystem";
import LogoutCheckin from "components/CheckIns/LogoutCheckin";
import CheckinFromAppointment from "components/CheckIns/CheckinFromAppointment";
import ConfirmationComponent from "components/booking/ConfirmationComponent";
import Group from "components/external/Group";
import Package from "components/external/Package";
import Membership from "components/external/Membership";
import OneonOne from "components/external/OneonOne";
import LiveStreamSessionExpired from "components/CheckIns/LiveStreamSessionExpired";
import SubscriptionsImport from "components/subscriptionsImport";
import SubscriptionsImportListView from "components/subscriptionsImport/subscriptionsImportListView";
import LoginComponent from "components/login/LoginComponent";
import TaxReport from "components/reports/Revenue/Components/TaxReport";
import StaffOnsiteAccess from "components/StaffOnsiteAccess/Components/StaffOnsiteAccess.view";
import Settings from "components/settings/Settings";
import OneTimeCharge from "components/summary/oneTimeCharge/OneTimeCharge";
import AffiliateRegistration from "components/AffiliateRegistration/AffiliateRegistration";
import AffiliateLogin from "components/AffiliateLogin/AffiliateLogin";
import AffiliateDashboard from "../components/AffiliateDashboard/AffiliateDashboard";
import AffiliateSubscriptions from "components/AffiliateSubscriptions/AffiliateSubscriptions.view";
import ManageDeclinePayments from "components/manageDeclinePayments";
import CheckinsReport from "components/reports/Checkins/Components/CheckinsReport.view";
import ManageAppointments from "components/class/new/ManageAppointments";
import ExternalWaiverAgreement from "components/summary/Agreements/ExternalWaiverAgreement";
import AffiliateSSO from "components/AffiliateDashboard/AffiliateSSO";
import SuccessForm from "components/BuzopsForms/BuzopsOnlineIntakeForms/SuccessForm";
import BookingConfirmation from "components/Appointments/BookingConfirmation";
const About = lazy(() => import("components/about/About"));
const Home = lazy(() => import("components/AccessControl/Home"));
const Booking = lazy(() => import("components/booking/Booking"));
const ScheduleAppointment = lazy(() => import("components/Appointments/AppointmentBooking"));
const Checkout = lazy(() => import("components/checkout/Checkout"));
const ParentClassComponent = lazy(() =>
  import("components/class/ParentClassComponent")
);
const ParentPackageComponent = lazy(() =>
  import("components/class/ParentPackageComponent")
);
const PurchaseClassList = lazy(() =>
  import("components/class/PurchaseClassList")
);
const BuzopsForms = lazy(() =>
  import("components/BuzopsForms")
);
const Enroll = lazy(() => import("components/client/Enroll.tsx"));
const GroupServiceDashboard = lazy(() =>
  import("components/client/GroupServiceDashboard")
);
const PackageDashboard = lazy(() =>
  import("components/client/PackageDashboard")
);
const PipeDrive = lazy(() => import("components/pipedrive/PipeDrive"));
const Provider = lazy(() => import("components/provider/Provider"));
const ExternalProvider = lazy(() => import("components/external/Provider"));
const ResetPassword = lazy(() => import("components/resetPassword"));
const Services = lazy(() => import("components/services"));
const SessionRoster = lazy(() => import("components/session/SessionRoster"));
const GroupServicesSubscribed = lazy(() =>
  import("components/subscriptions/GroupServices")
);
const PackagesSubscribed = lazy(() =>
  import("components/subscriptions/PackagesSubscribed")
);
const PresetPackagesSubscribed = lazy(() =>
  import("components/subscriptions/PresetPackagesSubscribed")
);
const Summary = lazy(() => import("components/summary/summary"));
const ClientCheckIn = lazy(() =>
  import("components/ClientCheckIn/ClientCheckIn")
);
const ManageSubscriptions = lazy(() =>
  import("components/siteSetting/ManageSubscriptions")
);
const Billing = lazy(() => import("components/billing/Billing"));
const ChargeBack = lazy(() => import("components/ChargeBack"));
const QuickLinks = lazy(() => import("components/quicklinks/QuickLinks"));
const GuestBooking = lazy(() => import("components/booking/GuestBooking"));
const PlanCheckout = lazy(() => import("components/plancheckout/PlanCheckout"));
const PaymentGateway = lazy(() =>
  import("components/integrations/Components/PaymentGtaeway")
);

const ThirdParty = lazy(() =>
  import("components/integrations/Components/ThirdParty")
);
const EmailLog = lazy(() => import("components/EmailLog/EmailLog.view"));
const ManageCustomFields = lazy(() => import("components/ManageCustomFields/ManageCustomFields.view"));
const ManageLeads = lazy(() => import("components/ManageLeads/ManageLeads.view"));
const ManageTaxesView = lazy(() => import("components/ManageTaxes/ManageTaxes.view"));
const AffiliateProgram = lazy(() => import("components/AffiliateProgram/AffiliateProgram"));
const AffiliatePayoutTab = lazy(() => import("components/AffiliateProgram/AffiliatePayoutTab"));
const Calender = lazy(() => import("components/Calender/FullCalender"));
const PurchasePackage = lazy(() => import("components/package/PurchasePackage"));
const BuzopsIntakeForm = lazy(() => import("components/BuzopsForms/BuzopsOnlineIntakeForms/BuzopsIntakeForm"));

export const publicRoutes = [
  {
    path: "/home",
    exact: true,
    name: "Tenant",
    component: About,
    key: "Tenant",
  },
  {
    path: "/services",
    exact: true,
    name: "Services",
    component: Services,
    key: "Services",
  },
  {
    path: "/classes",
    exact: true,
    name: "OnlineClasses",
    component: PurchaseClassList,
    key: "OnlineClasses",
  },
  {
    path: "/resetPassword",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
    key: "Reset Password",
  },
  {
    path: "/checkout/:id?",
    exact: true,
    name: "Checkout",
    component: Checkout,
    key: "Checkout",
  },
  {
    path: "/plancheckout",
    exact: true,
    name: "PlanCheckout",
    component: PlanCheckout,
    key: "PlanCheckout",
  },
  {
    path: "/mybooking",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/scheduleappointment",
    exact: true,
    name: "ScheduleAppointment",
    component: ScheduleAppointment,
    key: "ScheduleAppointment",
  },
  {
    path: "/purchasepackage",
    exact: true,
    name: "PurchasePackage",
    component: PurchasePackage,
    key: "PurchasePackage",
  },
  {
    path: "/mybooking/confirmation",
    exact: true,
    name: "ConfirmationComponent",
    component: ConfirmationComponent,
    key: "ConfirmationComponent",
  },
  {
    path: "/scheduleappointment/confirmation",
    exact: true,
    name: "BookingConfirmation",
    component: BookingConfirmation,
    key: "BookingConfirmation",
  },
  {
    path: "/mybooking/:id",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/summary/:uuid?",
    exact: true,
    name: "Summary",
    component: Summary,
    key: "Summary",
  },
  {
    path: "/login",
    exact: true,
    name: "LoginComponent",
    component: LoginComponent,
    key: "LoginComponent",
  },
  {
    path: "/affiliatelogin",
    exact: true,
    name: "Affiliatelogin",
    component: AffiliateLogin,
    key: "Affiliatelogin",
  },
  {
    path: "/",
    exact: true,
    name: "Tenant Home",
    component: About,
    key: "Tenant Home",
  },
  {
    path: "/promolink/:affiliateCode/:promotionCode/:subscriptionCode?",
    exact: true,
    name: "AffiliateSubscriptions",
    component: AffiliateSubscriptions,
    key: "AffiliateSubscriptions",
  },
];
export const staffRoutes = [
  {
    path: "/mybooking",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/member/:uuid/:userMemberId/checkin/appointment",
    exact: true,
    name: "CheckinFromAppointment",
    component: CheckinFromAppointment,
    key: "CheckinFromAppointment",
  },
  {
    path: "/member/:uuid/:clientId/checkin-0",
    exact: true,
    name: "CheckInByStaff",
    component: CheckInByStaff,
    key: "CheckInByStaff",
  },
  {
    path: "/member/:uuid/:clientId/mybooking",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/member/:uuid/:clientId/scheduleappointment",
    exact: true,
    name: "ScheduleAppointment",
    component: ScheduleAppointment,
    key: "ScheduleAppointment",
  },

  {
    path: "/member/:uuid/:clientId/mybooking/confirmation",
    exact: true,
    name: "ConfirmationComponent",
    component: ConfirmationComponent,
    key: "ConfirmationComponent",
  },
  {
    path: "/member/:uuid/:clientId/scheduleappointment/confirmation",
    exact: true,
    name: "BookingConfirmation",
    component: BookingConfirmation,
    key: "BookingConfirmation",
  },
  
  {
    path: "/member/:uuid/:clientId/checkout/:id?",
    exact: true,
    name: "Checkout",
    component: Checkout,
    key: "Checkout",
  },
  {
    path: "/member/:uuid/:clientId/purchasepackage/:id?",
    exact: true,
    name: "PurchasePackage",
    component: PurchasePackage,
    key: "PurchasePackage",
  },
  {
    path: "/member/:uuid/:clientId",
    exact: true,
    name: "member",
    component: Summary,
    key: "member",
  },
  {
    path: "/member/:uuid/:clientId/:subscriptionId",
    exact: true,
    name: "member",
    component: Summary,
    key: "member",
  },
  {
    path: "/member/:uuid/:clientId/:subscriptionId/:assign",
    exact: true,
    name: "member",
    component: Summary,
    key: "member",
  },
  {
    path: "/member/:uuid/:clientId/:subscriptionId/:assign",
    exact: true,
    name: "member",
    component: Summary,
    key: "member",
  },
  
];

export const inHouseRoutes = [
  {
    path: "/app/calender/:uuid",
    exact: true,
    name: "Calender",
    component: Calender,
    key: "Calender",
  },
  {
    path: "/app/scheduleappointment/:uuid",
    exact: true,
    name: "ScheduleAppointment",
    component: ScheduleAppointment,
    key: "ScheduleAppointment",
  },
  {
    path: "/app/classes/:uuid?",
    exact: true,
    name: "Classes",
   
    component: ParentClassComponent,
    key: "Classes",
  },
  
  {
    path: "/app/:uuid/manageGroup/:groupId/:SessionId?",
    exact: true,
    name: "ManageGroupAppointments",
  
    component: ManageAppointments,
    key: "ManageGroupAppointments",
  },
  {
    path: "/app/packages/:uuid?/:type?",
    exact: true,
    name: "Packages",
    component: ParentPackageComponent,
    key: "Classes",
  },
  {
    path: "/app/classes/:uuid/:type/:memberId?",
    exact: true,
    name: "Add Class",
    component: ParentClassComponent,
    key: "AddClass",
  },
  {
    path: "/app/packages/:uuid/:type/:memberId?",
    exact: true,
    name: "Add Package",
    component: ParentPackageComponent,
    key: "AddPackage",
  },
  {
    path: "/app/classSession/:uuid/:sessionId",
    exact: true,
    name: "SessionRoster",
    component: SessionRoster,
    key: "SessionRoster",
  },
  {
    path: "/app/client",
    exact: true,
    name: "Client",
    component: Enroll,
    key: "Client",
  },
  {
    path: "/app/groupServiceSubscriptions/:uuid?",
    exact: true,
    name: "Group Services",
    component: GroupServicesSubscribed,
    key: "GroupServices",
  },
  {
    path: "/app/groupServiceClientDashboard/:classId/:uuid?",
    exact: true,
    name: "Group Services Client Dashboard",
    component: GroupServiceDashboard,
    key: "GroupServicesDashboard",
  },
  {
    path: "/app/:uuid/packageSubscriptions",
    exact: true,
    name: "Packages Subscribers",
    component: PackagesSubscribed,
    key: "PackageSubscribers",
  },
  {
    path: "/app/:uuid/presetpackageSubscriptions",
    exact: true,
    name: "Preset Packages Subscribers",
    component: PresetPackagesSubscribed,
    key: "PresetPackageSubscribers",
  },
  {
    path: "/app/:uuid/packageClientDashboard/:packageId",
    exact: true,
    name: "Package Client Dashboard",
    component: PackageDashboard,
    key: "PackageClientDashboard",
  },
  {
    path: "/app/:uuid/pipedrive/",
    exact: true,
    name: "PipeDrive",
    component: PipeDrive,
    key: "PipeDrive",
  },
  {
    path: "/app/mysubscription/:uuid",
    exact: true,
    name: "ManageSubscriptions",
    component: ManageSubscriptions,
    key: "ManageSubscriptions",
  },
  {
    path: "/app/paymentgateway/:uuid",
    exact: true,
    name: "PaymentGateway",
    component: PaymentGateway,
    key: "PaymentGateway",
  },
  {
    path: "/app/:uuid/thirdparty",
    exact: true,
    name: "ThirdParty",
    component: ThirdParty,
    key: "ThirdParty",
  },
  {
    path: "/app/:uuid/checkin-system",
    exact: true,
    name: "CheckinSystem",
    component: CheckinSystem,
    key: "CheckinSystem",
  },
  {
    path: "/app/:uuid/:userId/onsiteaccess",
    exact: true,
    name: "StaffOnsiteAccess",
    component: StaffOnsiteAccess,
    key: "StaffOnsiteAccess",
  },
  {
    path: "/app/mysubscription/:uuid/:page",
    exact: true,
    name: "ManageSubscriptions",
    component: ManageSubscriptions,
    key: "ManageSubscriptions",
  },
  {
    path: "/app/:uuid/clientCheckIn/:memberId?",
    exact: true,
    name: "clientCheckIn",
    component: ClientCheckIn,
    key: "clientCheckIn",
  },
  {
    path: "/app/billing/:uuid",
    exact: true,
    name: "billing",
    component: Billing,
    key: "billing",
  },
  {
    path: "/app/reports/:uuid",
    exact: true,
    name: "Reports Landing Page",
    component: Reports,
    key: "Reports Landing Page",
  },
  {
    path: "/app/reports/:uuid/subscribers/plan",
    exact: true,
    name: "Subscriber By Paln",
    component: Plan,
    key: "Subscriber By Paln",
  },
  {
    path: "/app/reports/:uuid/subscribers/addons",
    exact: true,
    name: "Subscriber By Addons",
    component: Addons,
    key: "Subscriber By Addons",
  },
  {
    path: "/app/reports/:uuid/subscribers/individualServices",
    exact: true,
    name: "Subscriber By Individual Services",
    component: IndividualServices,
    key: "Subscriber By Individual Services",
  },
  {
    path: "/app/reports/:uuid/subscribers/groupServices",
    exact: true,
    name: "Subscriber By Group Services",
    component: GroupServices,
    key: "Subscriber By Group Services",
  },
  {
    path: "/app/reports/:uuid/subscribers/packages",
    exact: true,
    name: "Subscriber By Passes",
    component: Packages,
    key: "Subscriber By Passes",
  },
  {
    path: "/app/reports/:uuid/sales/plan",
    exact: true,
    name: "Sales By Plan",
    component: SalesByPlanGeneric,
    key: "Sales By Plan",
  },
  {
    path: "/app/reports/:uuid/sales/compare",
    exact: true,
    name: "Compare",
    component: Compare,
    key: "Compare",
  },
  {
    path: "/app/reports/:uuid/sales/addons",
    exact: true,
    name: "Sales By Addons",
    component: SalesByAddons,
    key: "Sales By Addons",
  },
  {
    path: "/app/reports/:uuid/sales/oneTimeCharge",
    exact: true,
    name: "Sales By OneTimeCharge",
    component: SalesByOneTimeCharge,
    key: "Sales By OneTimeCharge",
  },
  {
    path: "/app/reports/:uuid/sales/groupServices",
    exact: true,
    name: "Sales By Group Classes",
    component: SalesByGroupServices,
    key: "Sales By Group Classes",
  },
  {
    path: "/app/reports/:uuid/sales/individualServices",
    exact: true,
    name: "Sales By Individual Services",
    component: SalesByIndividualServices,
    key: "Sales By Individual Services",
  },
  {
    path: "/app/reports/:uuid/sales/packages",
    exact: true,
    name: "Sales By Passes",
    component: SalesByPackages,
    key: "Sales By Passes",
  },
  {
    path: "/app/reports/:uuid/sales/allSignups",
    exact: true,
    name: "Sales By All Signups",
    component: SalesByAllSignups,
    key: "Sales By All Signups",
  },
  {
    path: "/app/reports/:uuid/customers",
    exact: true,
    name: "All Customers",
    component: AllCustomers,
    key: "All Customers",
  },
  {
    path: "/app/reports/:uuid/serviceLog",
    exact: true,
    name: "Service Log",
    component: ServiceLog,
    key: "Service Log",
  },
  {
    path: "/app/reports/:uuid/staffPayouts",
    exact: true,
    name: "Staff Payouts",
    component: StaffPayout,
    key: "Staff Payouts",
  },
  {
    path: "/app/reports/:uuid/revenue",
    exact: true,
    name: "Revenue",
    component: RevenueGeneric,
    key: "Revenue",
  },
  {
    path: "/app/reports/:uuid/transactions",
    exact: true,
    name: "Transactions",
    component: TransactionsGeneric,
    key: "Transactions",
  },
  {
    path: "/app/reports/:uuid/paymentMethodUpdater",
    exact: true,
    name: "Account Payment Method Updater",
    component: AccountUpdater,
    key: "Account Payment Method Updater",
  },
  {
    path: "/app/reports/:uuid/mrr",
    exact: true,
    name: "MRR",
    component: MRR,
    key: "MRR",
  },
  {
    path: "/app/reports/:uuid/onsitelog",
    exact: true,
    name: "onsitelog",
    component: OnSiteLog,
    key: "onsitelog",
  },
  {
    path: "/app/reports/:uuid/deliquency",
    exact: true,
    name: "deliquency",
    component: Deliquency,
    key: "deliquency",
  },
  {
    path: "/app/reports/:uuid/subscribers/statistics",
    exact: true,
    name: "SubscriberStatistics",
    component: SubscriberStatistics,
    key: "SubscriberStatistics",
  },
  {
    path: "/app/managedisputes/:uuid/:clientId?",
    exact: true,
    name: "managedisputes",
    component: ChargeBack,
    key: "ManageDisputes",
  },
  {
    path: "/app/quicklinks/:uuid",
    exact: true,
    name: "QuickLinks",
    component: QuickLinks,
    key: "QuickLinks",
  },
  {
    path: "/app/:uuid/emaillogs",
    exact: true,
    name: "EmailLog",
    component: EmailLog,
    key: "EmailLog",
  },
  {
    path: "/app/managecustomfields/:uuid",
    exact: true,
    name: "ManageCustomFields",
    component: ManageCustomFields,
    key: "ManageCustomFields",
  },
  {
    path: "/app/manageLeads/:uuid",
    exact: true,
    name: "ManageLeads",
    component: ManageLeads,
    key: "ManageLeads",
  },
  {
    path: "/app/managetaxes/:uuid",
    exact: true,
    name: "ManageTaxes",
    component: ManageTaxesView,
    key: "ManageTaxes",
  },
  {
    path: "/app/migrationHistory/:uuid/:oldRequest?",
    exact: true,
    name: "Migration History",
    component: SubscriptionsImportListView,
    key: "ExternalSubscriptions",
  },
  {
    path: "/app/migrationDetails/:uuid",
    exact: true,
    name: "ExternalImport",
    component: SubscriptionsImport,
    key: "ExternalImport",
  },
  {
    path: "/app/affiliateprogram/:uuid/promotion-payouts",
    exact: true,
    name: "AffiliatePayoutTab",
    component: AffiliatePayoutTab,
    key: "AffiliatePayoutTab",
  },
  {
    path: "/app/affiliateprogram/:uuid/:text?/:id?/:type?",
    exact: true,
    name: "AffiliateProgram",
    component: AffiliateProgram,
    key: "AffiliateProgram",
  },
  {
    path: "/app/reports/:uuid/TaxReport",
    exact: true,
    name: "TaxReport",
    component: TaxReport,
    key: "TaxReport",
  },
  {
    path: "/app/settings/:uuid",
    exact: true,
    name: "Settings",
    component: Settings,
    key: "Settings",
  },
  {
    path: "/app/oneTimeCharge/:uuid/:userId?",
    exact: true,
    name: "OneTimeCharge",
    component: OneTimeCharge,
    key: "OneTimeCharge",
  },
  {
    path: "/app/affiliatedashboard/:uuid/:associateId/:text?/:id?",
    exact: true,
    name: "AffiliateDashboard",
    component: AffiliateDashboard,
    key: "AffiliateDashboard",
  },
  {
    path: "/app/manageDeclinePayments/:uuid?",
    exact: true,
    name: "Manage Decline Payments",
    component: ManageDeclinePayments,
    key: "ManageDeclinePayments",
  },
  {
    path: "/app/checkinsReport/:uuid?",
    exact: true,
    name: "Checkins Report",
    component: CheckinsReport,
    key: "CheckinsReport",
  },
  {
    path: "/app/:uuid/:userMemberId/waiverAgreement",
    exact: true,
    name: "ExternalWaiverAgreement",
    component: ExternalWaiverAgreement,
    key: "ExternalWaiverAgreement",
  },
  {
    path: "/app/forms/:uuid?",
    exact: true,
    name: "BuzopsForms",
    component: BuzopsForms,
    key: "BuzopsForms",
  },
];
export const accessControlRoutes = [
  {
    path: "/admin/livestream/session-expired",
    exact: true,
    name: "LiveStreamSessionExpired",
    component: LiveStreamSessionExpired,
    key: "LiveStreamSessionExpired",
  },

  {
    path: "/admin/livestream/:uuid?",
    exact: true,
    name: "ThirdPartyLiveStream",
    component: ThirdPartyLiveStream,
    key: "ThirdPartyLiveStream",
  },
  {
    path: "/admin/:id",
    exact: true,
    name: "Home",
    component: Home,
    key: "Home",
  },
  {
    path: "/admin/:id/:menuId",
    exact: true,
    name: "Home",
    component: Home,
    key: "HomeWithMenu",
  },
  
];

export const guestRoutes = [
  {
    path: "/:providerId",
    exact: true,
    name: "Providers",
    component: Provider,
    key: "Providers",
  },
  {
    path: "/:providerId/mybooking",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/:providerId/mybooking/confirmation",
    exact: true,
    name: "ConfirmationComponent",
    component: ConfirmationComponent,
    key: "ConfirmationComponent",
  },
  {
    path: "/:providerId/scheduleappointment/confirmation",
    exact: true,
    name: "BookingConfirmation",
    component: BookingConfirmation,
    key: "BookingConfirmation",
  },
  {
    path: "/:providerId/mybooking/:id",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/:providerId/guestbooking",
    exact: true,
    name: "Booking",
    component: GuestBooking,
    key: "Booking",
  },
  {
    path: "/:providerId/checkout/:id?",
    exact: true,
    name: "Checkout",
    component: Checkout,
    key: "Checkout",
  },
  {
    path: "/:providerId/scheduleappointment/:id?",
    exact: true,
    name: "ScheduleAppointment",
    component: ScheduleAppointment,
    key: "ScheduleAppointment",
  },
  {
    path: "/:providerId/plancheckout",
    exact: true,
    name: "PlanCheckout",
    component: PlanCheckout,
    key: "PlanCheckout",
  },
  {
    path: "/:providerId/:serviceId",
    exact: true,
    name: "Providers",
    component: Provider,
    key: "Providers",
  },
];

export const reportsRoutes = [];

export const externalRoutes = [
  {
    path: "/external/",
    exact: true,
    name: "ExternalHome",
    component: ExternalHome,
    key: "ExternalHome",
  },
  {
    path: "/external/checkout/:id?",
    exact: true,
    name: "Checkout",
    component: Checkout,
    key: "Checkout",
  },

  {
    path: "/external/mybooking",
    exact: true,
    name: "Booking",
    component: Booking,
    key: "Booking",
  },
  {
    path: "/external/mybooking/confirmation",
    exact: true,
    name: "ConfirmationComponent",
    component: ConfirmationComponent,
    key: "ConfirmationComponent",
  },
  {
    path: "/external/plancheckout",
    exact: true,
    name: "PlanCheckout",
    component: PlanCheckout,
    key: "PlanCheckout",
  },
  {
    path: "/external/summary",
    exact: true,
    name: "Summary",
    component: Summary,
    key: "Summary",
  },
  {
    path: "/external/401",
    exact: true,
    name: "SessionExpired",
    component: SessionExpired,
    key: "SessionExpired",
  },
  {
    path: "/external/group",
    exact: true,
    name: "Group",
    component: Group,
    key: "Group",
  },
  {
    path: "/external/package",
    exact: true,
    name: "Package",
    component: Package,
    key: "Package",
  },
  {
    path: "/external/membership",
    exact: true,
    name: "Membership",
    component: Membership,
    key: "Membership",
  },
  {
    path: "/external/oneonone",
    exact: true,
    name: "OneonOne",
    component: OneonOne,
    key: "OneonOne",
  },
  {
    path: "/external/:providerId",
    exact: true,
    name: "ExternalProvider",
    component: ExternalProvider,
    key: "ExternalProvider",
  },
  {
    path: "/external/:providerId/mybooking",
    exact: true,
    name: "providerIdBooking",
    component: Booking,
    key: "providerIdBooking",
  },
  {
    path: "/external/:providerId/mybooking/confirmation",
    exact: true,
    name: "ConfirmationComponent",
    component: ConfirmationComponent,
    key: "ConfirmationComponent",
  },
  {
    path: "/external/:providerId/checkout/:id?",
    exact: true,
    name: "Checkout",
    component: Checkout,
    key: "Checkout",
  },
  {
    path: "/external/:providerId/:serviceId",
    exact: true,
    name: "ExternalProvider",
    component: ExternalProvider,
    key: "ExternalProvider",
  },
];

export const checkInsRoutes = [
  {
    path: "/kiosk/login",
    exact: true,
    name: "SelfSign",
    component: SelfSign,
    key: "SelfSign",
  },
  {
    path: "/kiosk",
    exact: true,
    name: "CheckInsHome",
    component: SelfCheckIn,
    key: "CheckInHome",
  },
  {
    path: "/kiosk/self",
    exact: true,
    name: "SelfCheckIn",
    component: SelfCheckIn,
    key: "SelfCheckIn",
  },
  {
    path: "/kiosk/logout",
    exact: true,
    name: "LogoutCheckIn",
    component: LogoutCheckin,
    key: "LogoutCheckIn",
  },
  {
    path: "/checkins/livestream",
    exact: true,
    name: "LiveStream",
    component: LiveStream,
    key: "LiveStream",
  },
];


export const affiliateRoutes=[
  {
    path: "/affiliate/register",
    exact: true,
    name: "AffiliateRegistration",
    component: AffiliateRegistration,
    key: "AffiliateRegistration",
  },
  {
    path: "/affiliate/summary",
    exact: true,
    name: "AffiliateDashboard",
    component: AffiliateDashboard,
    key: "AffiliateDashboard",
  },
  {
    path: "/affiliate/summary/:uuid",
    exact: true,
    name: "AffiliateSummary",
    component: AffiliateSSO,
    key: "AffiliateSummary",
  },
  {
    path: "/affiliate/:text/:id?",
    exact: true,
    name: "AffiliateDashboard",
    component: AffiliateDashboard,
    key: "AffiliateDashboard",
  },
]

export const inTakeFormRoutes=[
  {
    path: "/form/success",
    exact: true,
    name: "SuccessForm",
    component: SuccessForm,
    key: "SuccessForm",
  },
  {
    path: "/form/response/:formMappingInternalId",
    exact: true,
    name: "BuzopsIntakeForm",
    component: BuzopsIntakeForm,
    key: "formMappingInternalId",
  },
  {
    path: "/form/referral/:referralPromotionalShortcode/:formTemplateShortCode/:affiliateShortCode",
    exact: true,
    name: "BuzopsIntakeForm",
    component: BuzopsIntakeForm,
    key: "affiliateShortCode",
  },
  {
    path: "/form/:formTemplateShortCode",
    exact: true,
    name: "BuzopsIntakeForm",
    component: BuzopsIntakeForm,
    key: "BuzopsIntakeForm",
  },
]

import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsNotification from "generic-components/BuzopsNotification";
import React, { useEffect, useState } from "react";
import { PipeDriveService } from "services/pipedrive/index.service";
import { Hint } from "@progress/kendo-react-labels";
import "../pipedrive/style.scss";
const PipeDrive = (props: any) => {
  const service = new PipeDriveService();
  const [validateForm, setValidateForm] = useState(false);
  const [unLink, setUnlink] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [validations, setValidations] = useState<any>([]);
  const [pipeLineDropDownData, setPipeLineDropDownData] = useState<any>([]);
  const [pipeLineStages, setPipeLineStages] = useState<any>(null);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [message, setMessage] = useState("");
  useEffect(() => {
    fetchAllApis();
  }, []);

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchUserSession();
    const check = await fetchInitialPipelineConfiguration();
    if (check) {
      await fetchallPipelines();
      await fetchServiceStatus();
      await fetchPipelineConfiguration();
    }
    setLoading(false);
  };

  const pipeLineDropDownOptions: any = {
    PlanPipeline: [],
    AddOnPipeline: [],
    PresetPackagePipeline: [],
    ProspectPipeline: [],
    PackagePipeline: [],
  };
  const [pipeLineStagesOptions, setPipeLineStagesOptions] = useState<any>(
    pipeLineDropDownOptions
  );
  const [configuration, setConfiguration] = useState<any>(null);
  const fetchUserSession = async () => {
    const result = await service.getTenantDetails(props.from? props?.uuid : props?.match?.params?.uuid);
  };

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const sortOptionsInArraybyId = (arrayItem: any) => {
    arrayItem.sort((a: any, b: any) => {
      return a.id - b.id;
    });
    return arrayItem;
  };

  const fetchPipelineConfiguration = async () => {
    const result = await service.getPipeDriveConfiguration();
    let PlanPipeline: any = [{ id: 0, name: "Do Nothing" }];
    let AddOnPipeline: any = [{ id: 0, name: "Do Nothing" }];
    let PresetPackagePipeline: any = [{ id: 0, name: "Do Nothing" }];
    let PackagePipeline: any = [{ id: 0, name: "Do Nothing" }];
    let ProspectPipeline: any = [{ id: 0, name: "Do Nothing" }];
    let UnPaidSubscriptionPipeline: any = [{ id: 0, name: "Do Nothing" }];
    if (result?.ApiKey && result?.ApiKey !== "" && result?.ApiKey?.length > 0) {
      setValidateForm(true);
    }
    if (result?.PlanPipeline?.PlanPipelineId) {
      PlanPipeline = await fecthStagesWithPipelineId(
        result?.PlanPipeline?.PlanPipelineId
      );
      PlanPipeline = [...PlanPipeline, { id: 0, name: "Do Nothing" }];
    }
    if (result?.AddOnPipeline?.AddOnPipelineId) {
      AddOnPipeline = await fecthStagesWithPipelineId(
        result?.AddOnPipeline?.AddOnPipelineId
      );
      AddOnPipeline = [...AddOnPipeline, { id: 0, name: "Do Nothing" }];
    }
    if (result?.PresetPackagePipeline?.PresetPackagePipelineId) {
      PresetPackagePipeline = await fecthStagesWithPipelineId(
        result?.PresetPackagePipeline?.PresetPackagePipelineId
      );
      PresetPackagePipeline = [
        ...PresetPackagePipeline,
        { id: 0, name: "Do Nothing" },
      ];
    }
    if (result?.PackagePipeline?.PackagePipelineId) {
      PackagePipeline = await fecthStagesWithPipelineId(
        result?.PackagePipeline?.PackagePipelineId
      );
      PackagePipeline = [
        ...PackagePipeline,
        { id: 0, name: "Do Nothing" },
      ];
    }
    if (result?.ProspectPipeline?.PipelineId) {
      ProspectPipeline = await fecthStagesWithPipelineId(
        result?.ProspectPipeline?.PipelineId
      );
      ProspectPipeline = [...ProspectPipeline, { id: 0, name: "Do Nothing" }];
    }
    if (result?.UnPaidSubscriptionPipeline?.PipelineId) {
      UnPaidSubscriptionPipeline = await fecthStagesWithPipelineId(
        result?.UnPaidSubscriptionPipeline?.PipelineId
      );
      UnPaidSubscriptionPipeline = [...UnPaidSubscriptionPipeline, { id: 0, name: "Do Nothing" }];
    }
    setPipeLineStagesOptions({
      PlanPipeline: sortOptionsInArraybyId(PlanPipeline),
      AddOnPipeline: sortOptionsInArraybyId(AddOnPipeline),
      PresetPackagePipeline: sortOptionsInArraybyId(
        PresetPackagePipeline
      ),
      ProspectPipeline: sortOptionsInArraybyId(ProspectPipeline),
      PackagePipeline: sortOptionsInArraybyId(PackagePipeline),
      UnPaidSubscriptionPipeline: sortOptionsInArraybyId(UnPaidSubscriptionPipeline),
    });
    setConfiguration(result);
  };
  const fetchInitialPipelineConfiguration = async () => {
    const result = await service.getPipeDriveConfiguration();
    if (result?.ApiKey && result?.ApiKey !== "" && result?.ApiKey?.length > 0) {
      setValidateForm(true);
      return true;
    } else {
      setValidateForm(false);
      return false;
    }
  };

  const fetchallPipelines = async () => {
    const getPipeLines = await service.getPipeLines();
    const data = [...getPipeLines, { id: 0, name: "Do Nothing" }];
    const rec=data?.map((i:any)=>{return {...i,selected:false}})
    setPipeLineDropDownData(sortOptionsInArraybyId(rec));
  };
  const fetchServiceStatus = async () => {
    const getServiceStatus = await service.getServiceStatus();
    const ProspectStatus = [
      {
        Description: "StageId",
        Label: "When a customer enrolls in Buzops as prospect",
        Id: 1,
      },
    ];
    const UnPaidStatus = [
      {
        Description: "UnPaidSubscriptionStageId",
        Label: "When a prospect enrolls in non-paid subscription(Complimentary, Consultation, Free Subscriptions)",
        Id: 1,
      },
    ];
    const res = { ...getServiceStatus, ProspectStatus,UnPaidStatus };
    setPipeLineStages(res);
  };
  const fecthStagesWithPipelineId = async (PipelineId: number) => {
    const req = {
      PipelineId,
    };
    const getStages = await service.getStages(req);
    return getStages;
  };

  const handleSubmit = async () => {
    if (!validateForm) {
      const validateData = handleValidation(configuration, false);
      if (validateData.length > 0) {
        return true;
      } else {
        setButtonLoading(true);
        const res = await service.updatePipedriveConfiguration(configuration);
        setButtonLoading(false);
        if (res?.ResponseCode === 100) {
          setValidateForm(true);
          setMessage(`PipeDrive Configuration Successfully Saved`);
          setNotification({ ...notification, success: true });
          await fetchAllApis();
        } else {
          const errorMsg =
            res?.ErrorMessages.length > 0
              ? res?.ErrorMessages[0]
              : "Error in Saving PipeDrive Configuration";
          setMessage(errorMsg);
          setNotification({ ...notification, error: true });
        }
      }
    } else {
      const validateData = handleValidation(configuration, false);
      if (validateData.length > 0) {
        return true;
      } else {
        setButtonLoading(true);
        const res = await service.updatePipedriveConfiguration(configuration);
        setButtonLoading(false);
        if (res?.ResponseCode === 100) {
          setMessage(`PipeDrive Configuration Successfully Saved`);
          setNotification({ ...notification, success: true });
          props?.handleCRMSave()
        } else {
          const errorMsg =
            res?.ErrorMessages.length > 0
              ? res?.ErrorMessages[0]
              : "Error in Saving PipeDrive Configuration";
          setMessage(errorMsg);
          setNotification({ ...notification, error: true });
        }
      }
    }
  };

  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 3000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, timer);
    if (unLink) {
      fetchAllApis();
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);

  const handleUnLink = async () => {
    const res = await service.unLinkPipedriveConfiguration();
    if (res?.ResponseCode === 100) {
      setMessage(`PipeDrive Configuration Successfully Unlinked`);
      setNotification({ ...notification, success: true });
      setUnlink(true);
      setConfiguration(null);
    } else {
      const errorMsg =
        res?.ErrorMessages.length > 0
          ? res?.ErrorMessages[0]
          : "Error in UnLinking PipeDrive Configuration";
      setMessage(errorMsg);
      setNotification({ ...notification, error: true });
    }
  };

  const handleChange = async (val: any, name: string, parent: any = null) => {
    if (parent === null) {
      const item = {
        ...configuration,
        [name]: val,
      };
      setConfiguration(item);
    } else {
      let item = { ...configuration };
      if (
        name === "PlanPipelineId" ||
        name === "AddOnPipelineId" ||
        name === "PresetPackagePipelineId" ||
        name === "PackagePipelineId" ||
        name === "PipelineId"
      ) {
        let res = [{ id: 0, name: "Do Nothing" }];
        if (val) {
          const result = await fecthStagesWithPipelineId(val);
          const resVal = [...result, { id: 0, name: "Do Nothing" }];
          res = sortOptionsInArraybyId(resVal);
        }
        let obj: any = new Object();
        switch (name) {
          case "PlanPipelineId":
            for (let keyItem in item?.PlanPipeline) {
              if (keyItem === "PlanPipelineId") {
                obj[keyItem] = val;
              } else {
                obj[keyItem] = 0;
              }
            }
            item = {
              ...configuration,
              [parent]: obj,
            };
            setPipeLineStagesOptions({
              ...pipeLineStagesOptions,
              PlanPipeline: res,
            });
            break;
          case "AddOnPipelineId":
            for (let keyItem in item?.AddOnPipeline) {
              if (keyItem === "AddOnPipelineId") {
                obj[keyItem] = val;
              } else {
                obj[keyItem] = 0;
              }
            }
            item = {
              ...configuration,
              [parent]: obj,
            };

            setPipeLineStagesOptions({
              ...pipeLineStagesOptions,
              AddOnPipeline: res,
            });
            break;
          case "PresetPackagePipelineId":
            for (let keyItem in item?.PresetPackagePipeline) {
              if (keyItem === "PresetPackagePipelineId") {
                obj[keyItem] = val;
              } else {
                obj[keyItem] = 0;
              }
            }
            item = {
              ...configuration,
              [parent]: obj,
            };

            setPipeLineStagesOptions({
              ...pipeLineStagesOptions,
              PresetPackagePipeline: res,
            });
            break;
          case "PackagePipelineId":
            for (let keyItem in item?.PackagePipeline) {
              if (keyItem === "PackagePipelineId") {
                obj[keyItem] = val;
              } else {
                obj[keyItem] = 0;
              }
            }
            item = {
              ...configuration,
              [parent]: obj,
            };

            setPipeLineStagesOptions({
              ...pipeLineStagesOptions,
              PackagePipeline: res,
            });
            break;
          case "PipelineId":
            if(parent==="ProspectPipeline"){
              for (let keyItem in item?.ProspectPipeline) {
                if (keyItem === "PipelineId") {
                  obj[keyItem] = val;
                } else {
                  obj[keyItem] = 0;
                }
              }
              item = {
                ...configuration,
                [parent]: obj,
              };
  
              setPipeLineStagesOptions({
                ...pipeLineStagesOptions,
                ProspectPipeline: res,
              });
            }else if(parent==="UnPaidSubscriptionPipeline"){
              for (let keyItem in item?.UnPaidSubscriptionPipeline) {
                if (keyItem === "PipelineId") {
                  obj[keyItem] = val;
                } else {
                  obj[keyItem] = 0;
                }
              }
              item = {
                ...configuration,
                [parent]: obj,
              };
  
              setPipeLineStagesOptions({
                ...pipeLineStagesOptions,
                UnPaidSubscriptionPipeline: res,
              });
            }
            
            break;
          default:
            break;
        }
      } else {
        item = {
          ...configuration,
          [parent]: {
            ...configuration[parent],
            [name]: val,
          },
        };
      }
      setConfiguration(item);
    }
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (validations?.length > 0) {
      if (validations.findIndex((i: any) => i === key) !== -1) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    }
    return <></>;
  };

  const handleValidation = (dataItem: any, all = true) => {
    const validationArray = [];
    if (!all) {
      if (!dataItem?.ApiKey || dataItem?.ApiKey === "") {
        validationArray.push("ApiKey");
      }
    } else {
      for (let key in dataItem) {
        if (
          key === "PlanPipeline" ||
          key === "PresetPackagePipeline" ||
          key === "PackagePipeline" ||
          key === "ProspectPipeline"
        ) {
          for (let keyItem in dataItem[key]) {
            if (!dataItem[key][keyItem] || dataItem[key][keyItem] === "") {
              const item1 = `${key}-${keyItem}`;
              validationArray.push(item1);
            }
          }
        } else {
          if (
            key !== "DealInternalDataIdKey" &&
            key !== "EndpointUrl" &&
            (!dataItem[key] || dataItem[key] === "")
          ) {
            validationArray.push(key);
          }
        }
      }
    }
    setValidations(validationArray);
    return validationArray;
  };

  return (
    <>
      {loading ? (
        <>
          <BuzopsLoader type={"list"} />
        </>
      ) : (
        <>
          <Card
            className="bz-pipedrive-container"
            style={{ background: "white", height: "auto" }}
          >
            <CardHeader className="pt-0">
              {!validateForm && (
                <CardTitle className="bz-card-title">
                  {"Provide api key to connect"}
                </CardTitle>
              )}
            </CardHeader>
            <CardBody className="p-lg-4 pipedriveCard">
              <div className="bz-pipedrive-url-row pb-3">
                <div className="section-1">
                  <Label>{"Api Key"}</Label>
                  <Input
                    autoComplete={"off"}
                    name="ApiKey"
                    id="ApiKey"
                    style={{ width: "100%" }}
                    value={configuration?.ApiKey}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "ApiKey")
                    }
                  />
                  {!configuration?.ApiKey &&
                    showErrorMessage("ApiKey", "Api Key")}
                </div>
                <div className="section-1 btn-container align-self-center">
                  <label className="k-label">&nbsp;</label>
                  {validateForm ? (
                    <Button onClick={() => handleUnLink()}>UnLink</Button>
                  ) : (
                    <BuzopsButton
                      onClick={() => handleSubmit()}
                      primary
                      disabled={buttonLoading}
                      type={"submit"}
                      loading={buttonLoading}
                      label={"Next"}
                    />
                  )}
                </div>
              </div>
              {validateForm && (
                <>
                  <hr />
                  <div className="row pt-3 pb-3 prospect-blk">
                    <div className="col-12 mb-3">
                      <div className="form-group bz-form-group-row align-items-center">
                        <h6 className="h6">
                          {"Choose a pipeline for prospect"}<small className="bz-ribbon-1">Pipeline</small>
                        </h6>
                        <div className="form-dropdown">
                          <DropDownButton
                            textField={"name"}
                            items={pipeLineDropDownData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipeLineDropDownData.find(
                                (i: any) =>
                                  i?.id ===
                                  configuration?.ProspectPipeline?.PipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e?.item?.id,
                                "PipelineId",
                                "ProspectPipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {pipeLineStages?.ProspectStatus?.map((item: any) => {
                        let itemName =item?.Description
                        return (
                          <>
                            <div className="stage-list">
                            <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                              <Label className="form-label mb-0">
                                {item?.Label}<small className="bz-ribbon-1 stage">Stage</small>
                                {configuration?.ProspectPipeline?.[itemName] ===0 && <small><Hint>{'Clients will be created in pipedrive as contacts when selected do nothing'}</Hint></small>}
                              </Label>
                              <div className="form-dropdown">
                                <DropDownButton
                                  textField={"name"}
                                  items={
                                    pipeLineStagesOptions?.ProspectPipeline
                                  }
                                  iconClass="fas fa-chevron-down"
                                  text={
                                    pipeLineStagesOptions?.ProspectPipeline?.find(
                                      (i: any) =>
                                        i?.id ===
                                        configuration?.ProspectPipeline?.[
                                        itemName
                                        ]
                                    )?.name
                                  }
                                  look="flat"
                                  className={
                                    "bz-white-btn bz-dropdown-btn px-0"
                                  }
                                  onItemClick={(e: any) => {
                                    handleChange(
                                      e?.item?.id,
                                      itemName,
                                      "ProspectPipeline"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <hr />
                  </div>
                  <div className="row pt-3 pb-3 prospect-blk">
                    <div className="col-12 mb-3">
                      <div className="form-group bz-form-group-row align-items-center">
                        <h6 className="h6">
                          {"Choose a pipeline for Non Paid Appointments"}<small className="bz-ribbon-1">Pipeline</small>
                        </h6>
                        <div className="form-dropdown">
                          <DropDownButton
                            textField={"name"}
                            items={pipeLineDropDownData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipeLineDropDownData.find(
                                (i: any) =>
                                  i?.id ===
                                  configuration?.UnPaidSubscriptionPipeline?.PipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e?.item?.id,
                                "PipelineId",
                                "UnPaidSubscriptionPipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {pipeLineStages?.UnPaidStatus?.map((item: any) => {
                        let itemName =item?.Description
                        return (
                          <>
                            <div className="stage-list">
                            <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                              <Label className="form-label mb-0">
                                {item?.Label}<small className="bz-ribbon-1 stage">Stage</small>
                              </Label>
                              <div className="form-dropdown">
                                <DropDownButton
                                  textField={"name"}
                                  items={
                                    pipeLineStagesOptions?.UnPaidSubscriptionPipeline
                                  }
                                  iconClass="fas fa-chevron-down"
                                  text={
                                    pipeLineStagesOptions?.UnPaidSubscriptionPipeline?.find(
                                      (i: any) =>
                                        i?.id ===
                                        configuration?.UnPaidSubscriptionPipeline?.[
                                        itemName
                                        ]
                                    )?.name
                                  }
                                  look="flat"
                                  className={
                                    "bz-white-btn bz-dropdown-btn px-0"
                                  }
                                  onItemClick={(e: any) => {
                                    handleChange(
                                      e?.item?.id,
                                      itemName,
                                      "UnPaidSubscriptionPipeline"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <hr />
                  </div>
                  <div className="row mb-3 subscription-blk">
                    <div className="col-12 mb-3">
                      <div className="form-group bz-form-group-row align-items-center">
                        <h6 className="h6">
                          {"Choose a pipeline for plan subscription"}<small className="bz-ribbon-1">Pipeline</small>
                        </h6>
                        <div className="form-dropdown">
                          <DropDownButton
                            textField={"name"}
                            items={pipeLineDropDownData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipeLineDropDownData.find(
                                (i: any) =>
                                  i?.id ===
                                  configuration?.PlanPipeline?.PlanPipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e.item?.id,
                                "PlanPipelineId",
                                "PlanPipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      {pipeLineStages?.PlanStatus?.map((item: any) => {
                        let itemName = item?.Description?.replace(
                          /[^a-zA-Z ]/g,
                          ""
                        );
                        itemName = `${itemName}StageId`;
                        return (
                          <>
                            <div className="stage-list">
                            <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                              <Label className="form-label mb-0">
                                {item?.Label}<small className="bz-ribbon-1 stage">Stage</small>
                              </Label>
                              <div className="form-dropdown">
                                <DropDownButton
                                  textField={"name"}
                                  items={pipeLineStagesOptions?.PlanPipeline}
                                  iconClass="fas fa-chevron-down"
                                  text={
                                    pipeLineStagesOptions?.PlanPipeline?.find(
                                      (i: any) =>
                                        i?.id ===
                                        configuration?.PlanPipeline?.[itemName]
                                    )?.name
                                  }
                                  look="flat"
                                  className={
                                    "bz-white-btn bz-dropdown-btn px-0"
                                  }
                                  onItemClick={(e: any) => {
                                    handleChange(
                                      e.item?.id,
                                      itemName,
                                      "PlanPipeline"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            </div>
                           
                          </>
                        );
                      })}
                    </div>
                    <hr />
                  </div>
                  <div className="row one-on-one-blk">
                    <div className="col-12 mb-3">
                      <div className="form-group bz-form-group-row align-items-center">
                        <h6 className="h6">
                          {"Choose a pipeline for Preset Package subscription"}<small className="bz-ribbon-1">Pipeline</small>
                        </h6>
                        <div className="form-dropdown">
                          <DropDownButton
                            textField={"name"}
                            items={pipeLineDropDownData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipeLineDropDownData.find(
                                (i: any) =>
                                  i?.id ===
                                  configuration?.PresetPackagePipeline
                                    ?.PresetPackagePipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e?.item?.id,
                                "PresetPackagePipelineId",
                                "PresetPackagePipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {pipeLineStages?.PackageInstanceStatus?.map(
                        (item: any) => {
                          let itemName = item?.Description?.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          itemName = `${itemName}StageId`;
                          return (
                            <>
                            <div className="stage-list">
                            <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                                <Label className="form-label mb-0">
                                  {item?.Label}<small className="bz-ribbon-1 stage">Stage</small>
                                </Label>
                                <div className="form-dropdown">
                                  
                                  <DropDownButton
                                    textField={"name"}
                                    items={
                                      pipeLineStagesOptions?.PresetPackagePipeline
                                    }
                                    iconClass="fas fa-chevron-down"
                                    text={
                                      pipeLineStagesOptions?.PresetPackagePipeline?.find(
                                        (i: any) =>
                                          i?.id ===
                                          configuration
                                            ?.PresetPackagePipeline?.[
                                          itemName
                                          ]
                                      )?.name
                                    }
                                    look="flat"
                                    className={
                                      "bz-white-btn bz-dropdown-btn px-0"
                                    }
                                    onItemClick={(e: any) => {
                                      handleChange(
                                        e?.item?.id,
                                        itemName,
                                        "PresetPackagePipeline"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                             
                            </>
                          );
                        }
                      )}
                    </div>
                    <hr/>
                  </div>
                  <div className="row one-on-one-blk">
                    <div className="col-12 mb-3">
                      <div className="form-group bz-form-group-row align-items-center">
                        <h6 className="h6">
                          {"Choose a pipeline for Package subscription"}<small className="bz-ribbon-1">Pipeline</small>
                        </h6>
                        <div className="form-dropdown">
                          <DropDownButton
                            textField={"name"}
                            items={pipeLineDropDownData}
                            iconClass="fas fa-chevron-down"
                            text={
                              pipeLineDropDownData.find(
                                (i: any) =>
                                  i?.id ===
                                  configuration?.PackagePipeline
                                    ?.PackagePipelineId
                              )?.name
                            }
                            look="flat"
                            className={"bz-white-btn bz-dropdown-btn px-0"}
                            onItemClick={(e: any) => {
                              handleChange(
                                e?.item?.id,
                                "PackagePipelineId",
                                "PackagePipeline"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-3">
                      {pipeLineStages?.PackageInstanceStatus?.map(
                        (item: any) => {
                          let itemName = item?.Description?.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          itemName = `${itemName}StageId`;
                          return (
                            <>
                            <div className="stage-list">
                            <div className="form-group bz-form-group-row align-items-center pipedrive-stage">
                                <Label className="form-label mb-0">
                                  {item?.Label}<small className="bz-ribbon-1 stage">Stage</small>
                                </Label>
                                <div className="form-dropdown">
                                  
                                  <DropDownButton
                                    textField={"name"}
                                    items={
                                      pipeLineStagesOptions?.PackagePipeline
                                    }
                                    iconClass="fas fa-chevron-down"
                                    text={
                                      pipeLineStagesOptions?.PackagePipeline?.find(
                                        (i: any) =>
                                          i?.id ===
                                          configuration
                                            ?.PackagePipeline?.[
                                          itemName
                                          ]
                                      )?.name
                                    }
                                    look="flat"
                                    className={
                                      "bz-white-btn bz-dropdown-btn px-0"
                                    }
                                    onItemClick={(e: any) => {
                                      handleChange(
                                        e?.item?.id,
                                        itemName,
                                        "PackagePipeline"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                             
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </>
              )}
            </CardBody>
            <div>
              {props.from && (<div className="py-2">
                <div className="section-1 btn-container align-self-center justify-self-start">
                  {validateForm && (
                    <BuzopsButton
                      onClick={() => handleSubmit()}
                      primary
                      disabled={buttonLoading}
                      type={"submit"}
                      loading={buttonLoading}
                      label={"Save"}
                    />
                  )}
                </div>
              </div>
            )}
            </div>
          </Card>
          <BuzopsNotification
            notification={notification}
            message={message}
            handleNotification={(type: any) => handleNotification(type)}
          />
        </>
      )}
    </>
  );
};

export default PipeDrive;

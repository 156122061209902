import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import PaymentMethods from "./../PaymentMethods";
import Transactions from "./../Transactions";

const PlanPayments = (props: any) => {
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const service = new TenantService();

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi=async()=>{
    setLoading(true);
    await fetchPlanSummary()
    setLoading(false);
  }

  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
  };
  const renderTransactions = () => {
    return (
      <Transactions
        userData={props?.userData}
        staffPage={props?.staffPage}
        allItems={false}
        EntityId={props?.selectedPlanId}
        FilterType={1}
        onSuccessfullPayment={() => props?.onSuccessfullPayment()}
      />
    );
  };

  const renderPaymentMethods = () => {
    const entityDetails = {
      MemberRecurringDetailId: selectedPlan?.MemberRecurringDetailId,
      PaymentGatewayProfileId: selectedPlan?.PaymentGatewayProfileId,
      FilterType: "plan",
      UserMemberId: props?.userData?.UserMemberId,
      AuditType: 2,
    };
    return (
      <PaymentMethods
        entityDetails={entityDetails}
        fetchPlanSummary={() => fetchPlanSummary()}
        userData={props?.userData}
        staffPage={props?.staffPage}
      />
    );
  };
  return (
    <>
      {/* <h5 className="mb-3 bz_fs_1_5">Payments</h5> */}
      <>
        {loading ? (
          <>
            <BuzopsLoader type={"list"} />
          </>
        ) : (
          <div className="bz-subsection-row bz-plan-overview">
            <div className="left-section">{renderPaymentMethods()}</div>
          </div>
        )}
        <hr />
        {renderTransactions()}
      </>
    </>
  );
};

export default PlanPayments;

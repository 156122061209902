import {
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadListItemProps,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import React from "react";
import { ImportContext } from "./importContext";
import { GetLocalStore } from "utils/storage";
import withNotification from "components/Hoc/withNotification";

const ExcelImport = (props: any) => {
  const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
  const { excelUploaded, excelRemoved, excelData } =
    React.useContext(ImportContext);
  const onAdd = (event: UploadOnAddEvent) => {
    setFiles(event.newState);
  };

  const onRemove = () => {

    setFiles([]);
    excelRemoved();
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState);
  };
  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    if (event.response?.response.ErrorMessages?.length > 0) {
      setFiles([
        {
          ...event.newState[0],
          status: UploadFileStatus.UploadFailed,
        },
      ]);
      props?.handleNotificationMessage(
        event.response?.response.ErrorMessages[0],
        "error"
      );
      return;
    }
    if (event.response?.response.ResponseCode === 100) {
      excelUploaded(event.response?.response?.Item);

      setFiles(event.newState);
      props?.handleNotificationMessage("File Uploaded successfully", "success");
      return;
    }
  };
  const CustomListItemUI = (props: UploadListItemProps) => {
    if (!files.length) {
      return <></>;
    }
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        {files.map((file) => (
          <>
            <div className="bz-file-block">
            <div className="bz-file-info">
              {" "}
              <i className="fas fa-file-alt mr-1"></i>
              <span className="file-name" key={file.name}>
                {file.name}
              </span>{" "}
            </div>
              <span className="k-icon k-i-close bz-cursor-pointer ml-3" onClick={() => onRemove()}></span>
            </div>
          </>
        ))}
      </div>
    );
  };
  if (excelData?.excelImported) {
    return <></>;
  }
  return (
    <div>
      <Upload
        batch={false}
        multiple={false}
        disabled={excelData?.excelImported}
        restrictions={{
          allowedExtensions: [".xlsx", ".xls"],
        }}
        //   key={files[0]?.name}
        files={files}
        listItemUI={CustomListItemUI}
        onAdd={onAdd}
        // onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        withCredentials={false}
        saveUrl={`${process.env.REACT_APP_API_URL}/api/Migration/Upload`}
        // removeUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/remove'}
        saveHeaders={{
          Authorization: `Bearer ${GetLocalStore("token")}`,
        }}
      />
      <div className="d-flex justify-content-end align-items-center">
        <a
          href="/MigrationTemplate.xlsx"
          className="btn btn-link ml-1"
          download
        >
          <i className="fa fa-download"></i> Download sample File
        </a>
      </div>
    </div>
  );
};

export default withNotification(ExcelImport);

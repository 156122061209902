import React, { useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import ManageDeclines from "./manageDeclines";
import { TenantService } from "services/tenant/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import withNotification from "components/Hoc/withNotification";
import { CurrencyFormat } from "utils";
import { Button } from "@progress/kendo-react-buttons";

const ManageDeclinePayments = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [result, setResult] = React.useState<any>([]);
  const [declinedTransactions, setDeclinedTransactions] = React.useState<any>([]);
  React.useEffect(() => {
    fetchData();
  }, [])

  const handleRefreshData=async()=>{
    const successMsg = "Payment creation successfully done";
    props?.handleNotificationMessage(successMsg,'success');
    setLoading(true);
    await fetchDeclinedPayments();
  }

  const service = new TenantService();
  const fetchData = async () => {
    setLoading(true);
    await fetchUserSession();
    fetchDeclinedPayments();
  }

  const fetchDeclinedPayments = async (req = null) => {
    setPageLoading(true);
    const itemData = await service.GetDeclinedPayments(req);
    setDeclinedTransactions(itemData.DeclinedTransactions);
    setResult(itemData.DeclinedTransactionsSummary);
    setPageLoading(false);

    setLoading(false);
  }

  const fetchUserSession = async () => {
    if (props.match?.params?.uuid) {
      await service.getTenantDetails(props.match?.params?.uuid);
    }
  };
  const AmountCell = (props: any) => {
    return <td >{CurrencyFormat(props?.dataItem[props?.field])}</td>;
  };

  const calculateTotal = () => {
    return result?.reduce((partialSum:any, a:any) => partialSum + a.TotalAmount, 0)
  };
  if (loading) {
    return <BuzopsLoader />
  }
  return (
      <div className="m-4">
        <div className="d-flex flex-column">
          <h2 className="customer-header" style={{ fontSize: '18px', fontWeight: '600' }}>Declined Payments</h2>
          <p>Execute Declined Payment processing for unpaid dues. Run all or selected Transactions.</p>
        </div>
        <hr />
        <div className="d-flex flex-column">
            <ManageDeclines
            {...props}
              declinedData={declinedTransactions}
              setDeclinedTransactions={setDeclinedTransactions}
              fetchDeclinedPayments={fetchDeclinedPayments}
              loading={pageLoading}
              calculateTotal={calculateTotal()}
              handleRefreshData={handleRefreshData}
            />
          <div className="customer-grid-border">
            {
              pageLoading ? <BuzopsLoader /> :
                <>
                  <div className="d-flex flex-column">
                    <h2 className="customer-header" style={{ fontSize: '18px', fontWeight: '600' }}>Summary</h2>
                    <p>Total amount categorized by service type, clients, and transactions</p>
                  </div>
                  <hr />
                  <Grid
                    data={result}
                    sortable={true}
                    pageable={false}
                    className="summardeclinedpayments"
                  >
                    <Column field="PaymentMadeForDescription" title="Service Type" />
                    <Column field="MembersCount" title="Clients" />
                    <Column field="TransactionsCount" title="Transactions" />
                    <Column
                      field="TotalAmount"
                      title="Amount"
                      format="{0:c1}"
                      cell={AmountCell}
                      footerCell={(props) => (
                        <td colSpan={props.colSpan} style={props.style}>
                        <Button primary style={{cursor:'default'}}>
                        {`Total: ${CurrencyFormat(calculateTotal())}`}
                          </Button>                         
                          </td>
                      )}
                    />
                  </Grid>
                </>
            }
          </div>
        </div>
      </div>
  );
};

export default withNotification(ManageDeclinePayments)

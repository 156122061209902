import {
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserConfiguration } from "redux-slices/userSlice";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { CheckInService } from "services/checkin/index.service";
import { ApplicationTypeEnum } from "utils/form-utils";
import { LocalStore } from "utils/storage";
import { emailValidator, passwordValidator } from "validators/validator";

const useAffiliateSignup = (props: any) => {
  const history=useHistory()
  const dispatch=useDispatch()
  const [submit, setSubmit] = useState<any>(false);
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [submitdisable, setSubmitDisable] = useState<any>(true);
  const [showUploadDialog, setShowUploadDialog] = useState<any>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<any>(false);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [photoUploadPref, setphotoUploadPref] = React.useState("2");
  const [newPhotoStatus, setNewPhotoStatus] = useState<any>(false);
  const service=new AffiliateProgramService()
  const [snapString, setSnapString] = React.useState("");
  const [formData, setFormData] = useState<any>({
    FirstName: props?.promoter?.FirstName,
    LastName: props?.promoter?.LastName,
    Email: props?.promoter?.Email,
    Password: "",
    ConfirmPassword: "",
    PhoneNumber: "",
    ShortDescription: "",
    Photo: "",
    UserActivationId: props?.promoter?.UserActivationId,
    AssociateId: props?.promoter?.AssociateId,
    UserId: props?.promoter?.UserId,
    AddressId: props?.promoter?.AddressId,
    ClubId: props?.promoter?.ClubId,
    UserActivationInternalDataId: props?.promoter?.UserActivationInternalDataId,
  });
  const [touched, setTouched] = useState({
    FirstName: false,
    LastName: false,
    Email: false,
    Password: false,
    ConfirmPassword: false,
    PhoneNumber: false,
    ShortDescription: false,
    Photo: true,
  });

  const [passwordType, setPasswordType] = useState({
    Password: true,
    ConfirmPassword: true,
  });

  const handlePasswordDisplay = (name: any) => {
    if (name === "Password") {
      setPasswordType({
        ...passwordType,
        Password: !passwordType.Password,
      });
    } else if (name === "ConfirmPassword") {
      setPasswordType({
        ...passwordType,
        ConfirmPassword: !passwordType.ConfirmPassword,
      });
    }
  };

  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);

  const deleteImage = (status: boolean) => {
    if (status) {
      setFormData({
        ...formData,
        Photo: null,
      });
      if (files.length > 0) {
        setFiles([]);
        setOriginalFiles([]);
      }
    }
    setNewPhotoStatus(false);
    toggleDeleteDialog();
  };

  const UploadImage = () => {
    setSubmitDisable(false)
    if (photoUploadPref === "2") {
      if (files?.length === 0) {
        props?.handleNotificationMessage("Please select image to upload", "error");
        return true;
      }
      const allowdFileTypes = [".jpeg", ".jpg", ".png"];
      let check = false;
      files.length > 0 &&
        files.forEach((file: any) => {
          if (!allowdFileTypes.includes(file?.Extension.toLowerCase())) {
            const errorMsg = "Please upload allowed File Type";
            props?.handleNotificationMessage(errorMsg, "error");

            check = true;
            return true;
          }
          if (file?.Size > 500000) {
            const errorMsg = "Please upload image less than 500KB";
            props?.handleNotificationMessage(errorMsg, "error");
            check = true;
            return true;
          }
        });
      if (check) {
        return true;
      }
      setFormData({
        ...formData,
        Photo: files[0]?.ImageAsBase64,
      });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    } else if (photoUploadPref === "1") {
      setFormData({
        ...formData,
        Photo: snapString,
      });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    }
  };
  const toggleUplaodDialog = () => {
    setShowUploadDialog(false);
  };
  const toggleDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleDeleteDialog = () => {
    if (formData?.Photo) {
      setShowDeleteDialog(true);
    }
    return;
  };
  const handleOnBlur = async (name: string, value: any) => {
    setTouched({
      ...touched,
      [name]: true,
    });
  };
  const ValdiateForm = () => {
    if (emailValidator(formData?.Email) !== "") {
      return true;
    } else if (passwordValidator(formData?.Password) !== "") {
      return true;
    } else if (formData?.Password.length > 0 &&
      formData?.Password !== formData?.ConfirmPassword) {
      return true;
    } else {
      const checkvalues: any = {
        FirstName: formData?.FirstName,
        PhoneNumber: formData?.PhoneNumber,
        LastName: formData?.LastName,
        Email: formData?.Email,
        Password: formData?.Password,
        ConfirmPassword: formData?.ConfirmPassword,
      };
      for (let key in checkvalues) {
        if (
          !checkvalues[key] ||
          checkvalues[key] === null ||
          checkvalues[key] === ""
        )
          return true;
      }
      return false;
    }
  };

  const handleSignup=async() => {
    setSubmit(true);
    const check = ValdiateForm();
    if (!check) {
        setBtnLoading(true)
        const req = JSON.parse(JSON.stringify(formData));
        delete req.ConfirmPassword;
        const complete = await service?.CompleteAffiliateSignup(req);
        if (complete.ResponseCode === 100) {
          props?.handleNotificationMessage("Signup Completed Successfully", "success");
          const payload={
            EmailId:req.Email,
            Password:req.Password,
          }
          await handleLogin(payload)
          setBtnLoading(false)
        } else {
          const errorMsg = complete?.ErrorMessages[0];
          props?.handleNotificationMessage(errorMsg, "error");
          setBtnLoading(false)

        }
      }
  }
  const handleLogin = async (dataItem: any) => {
    const reqData = {
      EmailId: dataItem?.EmailId,
      Password: dataItem?.Password,
      ApplicationType:ApplicationTypeEnum.AFFILIATE
    };
    const service = new CheckInService();
    const authUser = await service.authenticateStaff(reqData);
    if (authUser.successResponse) {
      const affiliateInfo = authUser?.data?.Item;
      LocalStore('AffiliateDetails',affiliateInfo)
      dispatch(
        setUserConfiguration({
          loggedIn: true,
          affiliateDetails: affiliateInfo,
        })
      );
      history.push("/affiliate/summary");
    }else{
      history.push('/')
    }
  };

  const handleChange = (name: string, value: any) => {
    setSubmitDisable(false)
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCapture = (e: any) => {
    setSnapString(e);
  };
  const handleRefreshCamera = (e: any) => {
    setphotoUploadPref("2");
  };
  const handleTakeASnapClick = () => {
    setphotoUploadPref("1");
  };
  const handleUploadAFileClick = () => {
    setphotoUploadPref("2");
  };
  return {
    handleChange,
    handlePasswordDisplay,
    touched,
    formData,
    btnLoading,
    submit,
    handleOnBlur,
    passwordType,
    setShowUploadDialog,
    handleDeleteDialog,
    toggleUplaodDialog,
    showDeleteDialog,
    toggleDeleteDialog,
    deleteImage,
    photoUploadPref,
    handleUploadAFileClick,
    handleTakeASnapClick,
    onAdd,
    onRemove,
    originalFiles,
    handleCapture,
    handleRefreshCamera,
    showUploadDialog,
    UploadImage,
    newPhotoStatus,
    handleSignup,
    submitdisable
  };
};

export default useAffiliateSignup;

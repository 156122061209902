import { useEffect, useState } from "react";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import { TenantService } from "services/tenant/index.service";
import { HardwareProviderType } from "utils/constants";

const useStaffOnsiteAccessHook = (props: any) => {
  const service = new ThirdPartyConfigService();
  const tenantService = new TenantService();
  const simpleAccessService = new SimpleAccessService();
  const [processing, setProcessing] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [memberAccess, setMemberAccess] = useState<any>({});
  const [securityConfig, setSecurityConfig] = useState<any>(null);
  const [passportMemberAccess, setPassportMemberAccess] = useState<any>(null);
  const [ConfiguredGroups, setConfiguredGroups] = useState<any>([]);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    const userSession = await tenantService.getTenantDetails(
      props.match?.params?.uuid
    );
    const securityConfigRes = await service.getSecuritySystemConfigs();
    setSecurityConfig(securityConfigRes?.data)
    switch(securityConfigRes?.data?.HardwareSecuritySystemPreferences?.ProviderType){
      case HardwareProviderType.Openpath:
        setConfiguredGroups(
          securityConfigRes?.data?.HardwareSecuritySystemPreferences?.OpenPathSettings?.SiteConfiguration?.ConfiguredGroups?.filter(
            (g: any) => g?.ForStaffOnly === true
          )
        );
        await checkMemberHardwareAccess();
        break;
      case HardwareProviderType.Passport:
        await checkMemberPassportAccess();
        break;
    }
    setLoading(false);
  };

  const checkMemberHardwareAccess = async () => {
    setLoading(true);
    const data = await simpleAccessService.getSatffsWithAccess({
      userId: props.match?.params?.userId,
    });
    const item = data[0];
    setMemberAccess({
      FullName: item?.FullName,
      MemberType: item?.MemberType,
      OpenpathCredentialType: item.AccessCard?.OpenpathCredentialType,
      IsSuspended: item.AccessCard?.IsSuspended,
      OpenpathCredentialEndDate: item.AccessCard?.OpenpathCredentialEndDate,
      OpenpathCredentialSetupEmailSentOn:
        item.AccessCard?.OpenpathCredentialSetupEmailSentOn,
      OpenpathCredentialStartDate: item.AccessCard?.OpenpathCredentialStartDate,
      IsOpenpathCredentialSetupComplete:
        item.AccessCard?.IsOpenpathCredentialSetupComplete,
      UserMemberId: item.UserMemberId,
      UserId: item.UserId,
      ExternalUserId: item.AccessCard?.ExternalUserId,
      CredentialId: item.AccessCard?.CredentialId,
      CanDisableEditAndActivate: item?.CanDisableEditAndActivate,
      CanDisableRemoveAccess: item?.CanDisableRemoveAccess,
      CardNumber: item?.AccessCard?.CardNumber,
      CardId: item?.AccessCard?.CardId,
      FacilityCode: item?.AccessCard?.FacilityCode,
      UserGroups: item?.AccessCard?.UserGroups,
      HasMemberAccessGroup: item?.HasMemberAccessGroup,
      HasStaffAccessGroup: item?.HasStaffAccessGroup,
      Email: item?.Email
    });
    setLoading(false);
  };
  const checkMemberPassportAccess = async () => {
    setLoading(true);
    const data = await simpleAccessService.getStaffwithPassportAccess({
      userId: props.match?.params?.userId,
    });
    const item = data[0];
    setPassportMemberAccess({
      FullName: item?.FullName,
      MemberType: item?.MemberType,
      CanDisableEditAndActivate: item?.CanDisableEditAndActivate,
      CanDisableRemoveAccess: item?.CanDisableRemoveAccess,
      HasMemberAccessGroup: item?.HasMemberAccessGroup,
      HasStaffAccessGroup: item?.HasStaffAccessGroup,
      Email: item?.Email,
      PassportTechCardType:!item?.AccessCard?.CardNumber ? null: item?.AccessCard?.PassportTechCardType,
      IsSuspended: item.AccessCard?.IsSuspended,
      UserMemberId: item.UserMemberId,
      UserId: item.UserId,
      ExternalUserId: item.AccessCard?.ExternalUserId,
      CredentialId: item.AccessCard?.CredentialId,
      CardNumber: item?.AccessCard?.CardNumber,
      CardId: item?.AccessCard?.CardId,
      FacilityCode: item?.AccessCard?.FacilityCode,
      EntityId: item.AccessCard?.EntityId,
    });
    setLoading(false);
  };

  return {
    Loading,
    memberAccess,
    ConfiguredGroups,
    processing,
    setProcessing,
    checkMemberHardwareAccess,
    checkMemberPassportAccess,
    securityConfig,
    passportMemberAccess
  };
};

export default useStaffOnsiteAccessHook;

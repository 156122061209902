import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TenantService } from "services/tenant/index.service";
import CheckinUser from "./CheckinUser";
import { CheckinMode } from "./Utils/constants";

const CheckInByStaff = (props: any) => {
  const service = new TenantService();
  const { clientId } = useParams<any>();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    fetchUserDetails(clientId);
  }, [clientId]);

  const fetchUserDetails = async (userMemberId: any) => {
    setLoading(true);
    const res = await service?.getUserInfo(userMemberId);
    setLoading(false);
    if (res) {
      const {
        UserMemberId,
        UserId,
        MemberNumber,
        MemberType,
        ParentId,
        Status,
        User: {
          Email,
          FirstName,
          FullName,
          LastName,
          Photo,
          DateOfBirth,
          Address: { Phone },
        },
      } = res;
      const data = {
        UserMemberId,
        UserId,
        Email,
        FirstName,
        FullName,
        LastName,
        Photo,
        DateOfBirth,
        Phone,
        MemberType,
        MemberNumber,
        Status,
        ParentId
      };
      setUserData(data);
    } else {
      setUserData(null);
    }
  };
  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  return (
    <div className="Self-CheckIn">
      <CheckinUser
        userDetails={userData}
        CheckinMode={CheckinMode.ByStaff}
        {...props}
      />
    </div>
  );
};

export default CheckInByStaff;

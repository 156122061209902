import { CommonServiceUtils } from "services/common-service-utils";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllLocalStore,
  RemoveLocalStore,
} from "utils/storage";

export class SiteSettingService extends CommonServiceUtils {
  async getTenantDetails(tenant: string): Promise<any> {
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore();
    }
    RemoveLocalStore("tenant");
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenantUserSession", {...getToken?.Item?.UserSession, DomainName: getToken.Item.DomainName, StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: getToken.Item.ChargehubSquarePaymentProfile, 
      Configuration: getToken.Item.Configuration});
      LocalStore("CurrentApplicationType", getToken?.Item?.UserSession?.CurrentApplicationType);
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      return getToken;
    }
  }
  async AddCard(req:any): Promise<any> {
    const AddCard: any =  await this.postData(this.apiUrlCreator(`site/AddCard`),req);
    return AddCard;
  }
  async UpdateSubscriptionCredit(req:any): Promise<any> {
    const AddCard: any =  await this.postData(this.apiUrlCreator(`site/UpdateSubscriptionCredit`),req);
    return AddCard;
  }
  async UpdateSubscriptionPlan(req:any): Promise<any> {
    const UpdateSubscriptionPlan: any =  await this.postData(this.apiUrlCreator(`site/UpdateSubscriptionPlan`),req);
    return UpdateSubscriptionPlan;
  }
  async SubscriptionPayment(req:any): Promise<any> {
    const UpdateSubscriptionPlan: any =  await this.postData(this.apiUrlCreator(`site/SubscriptionPayment`),req);
    return UpdateSubscriptionPlan;
  }

  async fetchPlans(id:any): Promise<any> {
    let response =null;
    const fetchPlans: any =  await this.getData(this.apiUrlCreator(`site/Plan?clubId=${id}`));
    if (fetchPlans?.ResponseCode === 100) {
      response = fetchPlans;
    }
    return response;
  }
  async fetchCards(id:any): Promise<any> {
    let response = [];
    const fetchPlans: any =  await this.getData(this.apiUrlCreator(`site/Cards?clubId=${id}`));
    if (fetchPlans?.ResponseCode === 100) {
      response = fetchPlans?.Items;
    }
    return response;
  }
  async fetchSubscriptionCredit(id:any): Promise<any> {
    let response = null;
    const fetchPlans: any =  await this.getData(this.apiUrlCreator(`site/SubscriptionCredit?clubId=${id}`));
    if (fetchPlans?.ResponseCode === 100) {
      response = fetchPlans?.Item;
    }
    return response;
  }

  async SubscribePlan(req:any): Promise<any> {
    const SubscribePlan: any =  await this.postData(this.apiUrlCreator(`site/Subscribe`),req);
    return SubscribePlan;
  }

  async fetchInvoices(id:any): Promise<any> {
    let response = [];
    const fetchInvoices: any =  await this.getData(this.apiUrlCreator(`site/Invoices?clubId=${id}`));
    if (fetchInvoices?.ResponseCode === 100) {
      response = fetchInvoices?.Items;
    }
    return response;
  }

  async fetchSMSConfiguration(): Promise<boolean | null> {
    try {
      const response = await this.getData(this.apiUrlCreator(`sms/configuration`));
      if (response?.ResponseCode === 100) {
        return response.data?.Item;
      }
      return response;
    } catch (error) {
      console.error('Error fetching SMS configuration:', error);
    }
  }


}

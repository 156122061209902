import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import * as React from "react";
import { MigrationStatus } from "./tabData";

export const MyCommandCell = (props: any) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem.UserMemberId === undefined;
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const disableClass = () => {
    if(props?.dataItem?.Status === MigrationStatus.Completed) {
      return "disabled pe-none"
    }

    return "bz-cursor-pointer"
  }
  return inEdit ? (
    <td
      style={{...props.style, background: 'white'}} // this applies styles that lock the column at a specific position
      className={props.className} // this adds classes needed for locked columns
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
    >
     
      <span
        className={`bz-custom-link mr-2 ${disableClass()}`}
        onClick={() => (isNewItem ? props.add(dataItem) : props.update(dataItem))}
        title="Save"
      >
        <i className="fa fa-save"></i>
      </span>
      <span
        className={`bz-custom-link text-danger mr-2 ${disableClass()}`}
        onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}
        title="Cancel"
      >
        <i className="fa fa-times-circle"></i>

      </span>
      <span>
        {props.ActionTooltip({dataItem})}
      </span>
    </td>
  ) : (
    <td
      style={{...props.style, background: 'white'}} // this applies styles that lock the column at a specific position
      className={props.className} // this adds classes needed for locked columns
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
      {...navigationAttributes}
    >
     
      <span
        className={`bz-custom-link mr-2 ${disableClass()}`}
        onClick={() => props.edit(dataItem)}
        title="Edit"
        
      >
        <i className="fa fa-edit"></i>
      </span>
      <span
        className={`bz-custom-link text-danger mr-2 ${disableClass()}`}
        onClick={() => 
          props.remove(dataItem)
        }
        title="Delete"
      >
        <i className="fa fa-trash "></i>
      </span>
      <span>
        {props.ActionTooltip({dataItem})}
      </span>
    </td>
  );
};
import React, { useEffect, useState } from "react";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import { ThirdPartyConfigService } from "services/thirdparty-configuration/index.service";
import { HardwareSecuritySystemSetupStatusType } from "../Utilities/constants";
import { HardwareProviderType, SecuritySystemType } from "utils/constants";
import { ISecuritySystemConfiguration } from "../Utilities/interfaces";
import HardwareProviderTypeForm from "./HarwareProviderTypeForm";
import OpenPathForm from "./OpenPathForm";
import { Card } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import moment from "moment";
import { getTimeFormat } from "utils";
import CheckinsForm from "./CheckinsForm";

interface IHardwareConfigurationProps {
  securitySystemConfigs: ISecuritySystemConfiguration;
  handleNotification: any;
  setSecuritySystemConfig: any;
  isSystemAdmin: boolean;
  setSelectedSecType: any;
}

const MyItemRender = (props: any) => {
  let item = props.dataItem;
  return (
    <div
      style={{ backgroundColor: "#fff" }}
      className="py-2 bz-package-audit-history-list"
    >
      {" "}
      <span className="text-muted d-block mb-0 audit-flex">
        <span>
          <i className="far fa-calendar-alt"></i>
        </span>
        <div>
          <span className="bz-activity-time-date text-capitalize">
            {" "}
            {moment(item?.AuditDate).format("L")}{" "}
            {getTimeFormat(item?.AuditDate)}
          </span>
          <span className="bz-activity-dis-div">{item?.Action}</span>
          <label className="bz-requestedBy font-italic bz-fw-600">
            {`${item?.RequestedByFirstName} ${item?.RequestedByLastName}`}
          </label>{" "}
        </div>
      </span>
    </div>
  );
};

const HardwareConfiguration: React.FunctionComponent<
  IHardwareConfigurationProps
> = ({
  securitySystemConfigs,
  handleNotification,
  setSecuritySystemConfig,
  isSystemAdmin,
  setSelectedSecType,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnCheckLoading, setCheckBtnLoading] = useState(false);
  const [btnStartLoading, setStartBtnLoading] = useState(false);
  const [btnStopLoading, setStopBtnLoading] = useState(false);
  const service = new ThirdPartyConfigService();
  const [activityLog, setActivityLog] = useState<any>([]);
  const [showMore, setShowMore] = useState<boolean>(true);

  const showMsg = (type: HardwareSecuritySystemSetupStatusType) => {
    switch (type) {
      case HardwareSecuritySystemSetupStatusType.Enabled:
        return {
          message: `Proximity Reader System is successfully enabled for your location.`,
          alertText: "text-success",
        };

      case HardwareSecuritySystemSetupStatusType.Pending:
        return {
          message: `Your request to configure your location to support the Proximity Reader System is pending with Admin`,
          alertText: " alert-primary",
        };

      default:
        return {
          message: `You are requesting to configure your location to support the Proximity Reader System, our support team will reach you for the hardware installation and the configuration settings.`,
          alertText: "alert-warning",
        };
    }
  };

  const requestForConfigs = async () => {
    setBtnLoading(true);
    const saveConfig = await service.saveSecuritySystemConfigs({
      SecuritySystemType: SecuritySystemType.ProximityReaderSystem,
      CheckinsEnabled: false,
    });
    setBtnLoading(false);
    if (saveConfig?.success) {
      setSecuritySystemConfig(saveConfig.data);
      handleNotification(
        "Proximity Reader System configuration requested",
        "success"
      );
      setSelectedSecType(undefined);
    } else {
      handleNotification(saveConfig.msg, "error");
      setSelectedSecType(undefined);
    }
  };

  const cancelRequest = async () => {
    const req = {
      SecuritySystemType: SecuritySystemType.None,
      CheckinsEnabled: false,
      SimpleSecuritySystemPreferences: null,
      HardwareSecuritySystemPreferences: null,
    };
    setBtnLoading(true);
    const saveConfig = await service.saveSecuritySystemConfigs(req);
    setBtnLoading(false);
    if (saveConfig?.success) {
      setSecuritySystemConfig(saveConfig.data);
      handleNotification(
        "Proximity Reader System configuration request cancelled",
        "success"
      );
      setSelectedSecType(undefined);
    } else {
      handleNotification(saveConfig.msg, "error");
      setSelectedSecType(undefined);
    }
  };

  const handleSubmit = async (values: any, setLoading: any) => {
    const req = {};
    setLoading(true);
    const saveConfig = await service.saveHardwareType(
      values.hardwareProviderType,
      req
    );
    setLoading(false);
    if (saveConfig?.success) {
      setSecuritySystemConfig(saveConfig.data);
      handleNotification("Proximity reader type saved successfully", "success");
      if (values.hardwareProviderType === HardwareProviderType.Brivo) {
        const data = { Type: "SecuritySystem" };
        window.parent.postMessage(data, "*");
        return true;
      }
    } else {
      handleNotification(saveConfig.msg, "error");
    }
  };

  const saveOpenPathConfigureation = async (payload: any, setLoading: any) => {
    const req = {
      ...securitySystemConfigs,
      CheckinsEnabled: true,
      DaysToWaitToSuspendAccessAfterPaymentDecline:
        securitySystemConfigs?.DaysToWaitToSuspendAccessAfterPaymentDecline ||
        0,
      AllowAppointmentCheckinsIfPaymentOverdue:
        securitySystemConfigs?.AllowAppointmentCheckinsIfPaymentOverdue ||
        false,
      LocationCheckinPreference: {
        Enabled:
          securitySystemConfigs?.LocationCheckinPreference?.Enabled || true,
      },
      OneOnOneCheckinPreference: {
        Enabled:
          securitySystemConfigs?.OneOnOneCheckinPreference?.Enabled || true,
        MaxTimeSpanInMinutesBeforeScheduleForCheckin:
          securitySystemConfigs?.OneOnOneCheckinPreference
            ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin || 60,
      },
      GroupClassCheckinPreference: {
        Enabled:
          securitySystemConfigs?.GroupClassCheckinPreference?.Enabled || true,
        MaxTimeSpanInMinutesBeforeScheduleForCheckin:
          securitySystemConfigs?.GroupClassCheckinPreference
            ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin || 60,
      },
    };
    req.HardwareSecuritySystemPreferences.OpenPathSettings = { ...payload };

    setLoading(true);
    const saveConfig = await service.saveSecuritySystemConfigs(req);
    setLoading(false);
    if (saveConfig?.success) {
      setSecuritySystemConfig(saveConfig.data);
      handleNotification("Openpath config setup successfully", "success");
      setSelectedSecType(undefined);
    } else {
      handleNotification(saveConfig.msg, "error");
    }
  };

  const saveOpenPathCheckinConfiguration = async (
    payload: any,
    setLoading: any
  ) => {
    const req = {
      ...securitySystemConfigs,
      ...payload,
    };

    if (!isSystemAdmin && req.HardwareSecuritySystemPreferences !== null) {
      req.HardwareSecuritySystemPreferences.OpenPathSettings = null;
    }

    console.log(req);

    setLoading(true);
    const saveConfig = await service.saveSecuritySystemConfigs(req);
    setLoading(false);
    if (saveConfig?.success) {
      setSecuritySystemConfig(saveConfig.data);
      handleNotification(
        "Openpath check-ins settings updated successfully",
        "success"
      );
      setSelectedSecType(undefined);
    } else {
      handleNotification(saveConfig.msg, "error");
    }
  };

  const getActivityLog = async (pageNumber: number, pageSize: number) => {
    const resp = await service.getSecuritySystemActivityLogPagination(
      pageNumber,
      pageSize
    );
    if (resp.success) {
      setActivityLog([...activityLog, ...resp?.data]);
    } else {
      if (resp?.data === null) {
        setActivityLog([...activityLog]);
        setShowMore(false);
      } else {
        setActivityLog([]);
      }
    }
  };

  const checkLivestreamStatus = async () => {
    setCheckBtnLoading(true);
    const response = await service.checkLivestreamStatus();
    setCheckBtnLoading(false);
    if (response?.success) {
      handleNotification(JSON.stringify(response?.data), "success");
    } else {
      handleNotification("Error", "error");
    }
  };

  const startLiveStream = async () => {
    setStartBtnLoading(true);
    const response = await service.startLiveStream();
    setStartBtnLoading(false);
    if (response?.success) {
      handleNotification(JSON.stringify(response?.data), "success");
    } else {
      handleNotification("Error", "error");
    }
  };

  const stopLiveStream = async () => {
    setStopBtnLoading(true);
    const response = await service.stopLiveStream();
    setStopBtnLoading(false);
    if (response?.success) {
      handleNotification(JSON.stringify(response?.data), "success");
    } else {
      handleNotification("Error", "error");
    }
  };

  useEffect(() => {
    getActivityLog(0, 5);
  }, []);

  return (
    <>
      <div className="d-flex h-100">
        <div className="bz-checkins-config w-100">
          {isSystemAdmin &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences ===
              null && <HardwareProviderTypeForm handleSubmit={handleSubmit} />}
          {isSystemAdmin &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences
              ?.ProviderType === HardwareProviderType.Openpath && (
              <OpenPathForm
                handleSubmit={saveOpenPathConfigureation}
                OpenPathSettings={
                  securitySystemConfigs?.HardwareSecuritySystemPreferences
                    ?.OpenPathSettings
                }
              />
            )}

          {!isSystemAdmin &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences
              ?.ProviderType === HardwareProviderType.Brivo && (
              <div className="bz-text-center">
                <span
                  className={`alert bz-fs-1rem bz-fw-600 ${
                    showMsg(
                      securitySystemConfigs?.HardwareSecuritySystemSetupStatus
                    ).alertText
                  }`}
                >
                  {
                    showMsg(
                      securitySystemConfigs?.HardwareSecuritySystemSetupStatus
                    ).message
                  }
                </span>
              </div>
            )}
          {isSystemAdmin &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences
              ?.ProviderType === HardwareProviderType.Openpath && (
              <>
                <BuzopsButton
                  label={"Check Status"}
                  // className="btn btn-secondary"
                  themeColor={"base"}
                  onClick={() => checkLivestreamStatus()}
                  style={{ marginRight: "10px", padding: "10px 20px" }}
                  loading={btnCheckLoading}
                  disabled={btnCheckLoading}
                />
                <BuzopsButton
                  label={"Start"}
                  // className="btn btn-secondary"
                  themeColor={"success"}
                  onClick={() => startLiveStream()}
                  style={{ marginRight: "10px", padding: "10px 20px" }}
                  loading={btnStartLoading}
                  disabled={btnStartLoading}
                />
                <BuzopsButton
                  themeColor={"error"}
                  label={"Stop"}
                  // className="btn btn-secondary"
                  onClick={() => stopLiveStream()}
                  style={{ marginRight: "10px", padding: "10px 20px" }}
                  loading={btnStopLoading}
                  disabled={btnStopLoading}
                />
                <hr className="pb-3" />
              </>
            )}

          {!isSystemAdmin &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences ===
              null && (
              <div className="bz-text-center">
                <span
                  className={`alert bz-fs-1rem bz-fw-600 ${
                    showMsg(
                      securitySystemConfigs?.HardwareSecuritySystemSetupStatus
                    ).alertText
                  }`}
                >
                  {
                    showMsg(
                      securitySystemConfigs?.HardwareSecuritySystemSetupStatus
                    ).message
                  }
                </span>
              </div>
            )}

          {!isSystemAdmin && securitySystemConfigs === undefined && (
            <div className="bz-text-center">
              <span className={`alert bz-fs-1rem bz-fw-600 text-warning`}>
                You are requesting to configure your location to support the
                Proximity Reader System, our support team will reach you for the
                hardware installation and the configuration settings.
              </span>
            </div>
          )}

          {!isSystemAdmin &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences
              ?.ProviderType === HardwareProviderType.Openpath &&
            securitySystemConfigs?.HardwareSecuritySystemSetupStatus !==
              HardwareSecuritySystemSetupStatusType.Enabled && (
              <div className="bz-text-center">
                <span
                  className={`alert bz-fs-1rem bz-fw-600 ${
                    showMsg(
                      securitySystemConfigs?.HardwareSecuritySystemSetupStatus
                    ).alertText
                  }`}
                >
                  {
                    showMsg(
                      securitySystemConfigs?.HardwareSecuritySystemSetupStatus
                    ).message
                  }
                </span>
              </div>
            )}

          {securitySystemConfigs?.HardwareSecuritySystemSetupStatus !==
            HardwareSecuritySystemSetupStatusType.Pending &&
            securitySystemConfigs?.HardwareSecuritySystemSetupStatus !==
              HardwareSecuritySystemSetupStatusType.Enabled && (
              <div className="col-6 pull-right float-right">
                <BuzopsButton
                  label={"Submit Request"}
                  className="btn float-right"
                  onClick={() => requestForConfigs()}
                  style={{ width: "7.5rem" }}
                  loading={btnLoading}
                  disabled={btnLoading}
                />
              </div>
            )}
          {securitySystemConfigs?.HardwareSecuritySystemSetupStatus ===
            HardwareSecuritySystemSetupStatusType.Pending && (
            <div>
              <BuzopsButton
                label={"Cancel Request"}
                className="btn btn-secondary float-right"
                onClick={() => cancelRequest()}
                style={{ marginRight: "10px", padding: "10px 20px" }}
                loading={btnLoading}
                disabled={btnLoading}
              />
            </div>
          )}

          {securitySystemConfigs?.HardwareSecuritySystemSetupStatus ===
            HardwareSecuritySystemSetupStatusType.Enabled &&
            securitySystemConfigs?.HardwareSecuritySystemPreferences
              ?.ProviderType === HardwareProviderType.Openpath && (
              <CheckinsForm
                handleSubmit={saveOpenPathCheckinConfiguration}
                securitySystemConfigs={securitySystemConfigs}
              />
            )}

          {securitySystemConfigs?.HardwareSecuritySystemPreferences
            ?.ProviderType === HardwareProviderType.Openpath && (
            <div className="bz-activity-log-section">
              <h5 className="logactivity">Activity Log</h5>
              <div className="my-2">
                {activityLog.length === 0 && (
                  <div className={"bz-norecords-available text-muted"}>
                    <span>{"No Activity Log Available"}</span>
                  </div>
                )}
                <>
                  <Card
                    className={
                      "bzo-services-container bz-package-audit-history border-0 border-activitylog"
                    }
                  >
                    <ListView item={MyItemRender} data={activityLog} />
                  </Card>

                  {activityLog.length > 4 && (
                    <div className="d-flex justify-content-center align-items-center bz-services-showMore">
                      <div>
                        {showMore === true ? (
                          <span
                            className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                            onClick={() =>
                              getActivityLog(
                                Math.ceil(activityLog.length / 5),
                                5
                              )
                            }
                            key={"down"}
                          >
                            View More
                            <i className="fa fa-caret-down ml-1"></i>
                          </span>
                        ) : (
                          <span
                            className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                            onClick={() => {
                              setActivityLog([...activityLog.slice(0, 5)]);
                              setShowMore(true);
                            }}
                            key={"up"}
                          >
                            View Less
                            <i className="fa fa-caret-up ml-1"></i>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default withNotification(HardwareConfiguration);

import {
  Card, CardHeader, CardTitle
} from "@progress/kendo-react-layout";
import BuzopsNotification from "generic-components/BuzopsNotification";
import React, { useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import { GetLocalStore } from "utils/storage";
import SessionTable from "./SessionTable";
export interface CancelSessionProps {
  classValue: any;
  handleWindow: any;
  classSessions: any
}
export const ChargeTypeList = [
  {
    text: "Refund",
    id: 2,
  },
  {
    text: "Cancel without Refund",
    id: 3,
  },
];


const CancelSession = (props: CancelSessionProps) => {
  const originalData = props?.classSessions.map((v: any) => ({ ...v, selected: false }));
  const [tableData, settableData] = useState(originalData);
  const [cancellationType, setCancellationType] = useState<any>(null);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [message, setMessage] = useState("");
  const [submit, setSubmit] = useState(false);
  const handleUpdateCancellationType = (e: any) => {
    setCancellationType(e.value);
  };
  const [showCancelled, setshowCancelled] = useState(false);

  const handleShowCancelled = (e: any) => {
    setshowCancelled(e.value);
  };

  useEffect(() => {
    let timer = 1000
    if (notification.error) {
      timer = 3000
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
      if (submit) {
        props.handleWindow();
      }
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false })
  }

  const handleCancelSession = async (data: any) => {

    let selectedList = data.filter(
      (dataItem: any) => dataItem.selected === true
    );
    if (selectedList.length > 0) {
      const req = selectedList.map((item: any) => {
        const obj: any = new Object();
        obj.ClassSessionId = item?.ClassSessionId;
        return obj;
      });
      const listCount = selectedList.length;
      const userSession = GetLocalStore('tenantUserSession')
      const service = new ClassService();
      const reqObj: any = {
        ClubDateTime: new Date(),
        ApplicationType: userSession?.ApplicationType,
        CurrentUserId: userSession?.UserId,
        classSessionUserMappings: req,
        CancellationChargeType: selectedList?.filter((i: any) => i?.BookedSlot > 0)?.length > 0 ? cancellationType?.id : null,
        CanEmailToClient: showCancelled,
      };
      const result = await service.cancelClassSession(reqObj);
      if (result?.ResponseCode === 100) {
        setMessage(`${listCount} Appointments successfully cancelled`);
        setNotification({ ...notification, success: true });
        setSubmit(true);
      } else {
        setMessage(result?.ErrorMessages[0]);
        setNotification({ ...notification, error: true });
      }
    } else {
      setMessage(`No Appointments were selected.`);
      setNotification({ ...notification, error: true });
    }
  };
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{props.classValue?.Name}</CardTitle>
        </CardHeader>
      </Card>
      <SessionTable
        type="cancel"
        title={"Upcoming Appointments"}
        tableData={tableData}
        cancellationType={cancellationType}
        class={props.classValue}
        handleSessionBtn={(data: any) => handleCancelSession(data)}
        showCancelled={showCancelled}
        handleShowCancelled={handleShowCancelled}
        handleUpdateCancellationType={(e: any) => handleUpdateCancellationType(e)}
      />
      <BuzopsNotification notification={notification} message={message} handleNotification={(type: any) => handleNotification(type)} />
    </>
  );
};

export default CancelSession;
function type(type: any) {
  throw new Error("Function not implemented.");
}


import * as React from "react";

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { ImportContext, MigrationFormType } from "./importContext";
import TabData from "./tabData";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { MigrationService } from "services/import/index.service";
import { flatten } from "./fetchImportData.hook";
import { useDownloadFile } from "components/customHooks/useDownloadFile";
import { RequestStatus } from "./subscriptionsImportListView";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";

export enum importTabs {
  CLIENTINFO = "Client Info",
}
const ExcelDataTabs = (props: any) => {
  const [btnloading, setBtnLoading] = React.useState(false);
  const exampleInput = React.useRef<any>();
  const {
    excelData,
    excelUploaded,
    formType,
    setFormType,
    sortOrder,
    setFilters,
    filters,
    tabDataCount,
  } = React.useContext(ImportContext);
  const [selected, setSelected] = React.useState<number>(0);
  React.useEffect(() => {
    if (props.location.state?.migrationData) {
      excelUploaded(props.location.state?.migrationData);
    }
  }, []);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const onSearch = (clearAll = false) => {
    if (clearAll) {
      setFilters((prev: any) => {
        return { ...prev, search: "" };
      });
      return;
    }
    if (exampleInput.current) {
      if (exampleInput.current?.value) {
        setFilters((prev: any) => {
          return { ...prev, search: exampleInput.current?.value };
        });
      } else if (filters?.search) {
        setFilters((prev: any) => {
          return { ...prev, search: "" };
        });
      }
    }
  };

  const exportPDFWithComponent = async () => {
    let migrationStatus: any = [];

    if (filters?.statusFilter?.length > 0) {
      filters?.statusFilter.map((val: any) => migrationStatus.push(val?.id));
    }
    const service = new MigrationService();
    return await service.downloadClientInfo(
      excelData.data.RequestId,
      sortOrder,
      {
        MigrationStatus: flatten(migrationStatus),
        SearchKeyword: filters?.search,
      }
    );
  };

  const onErrorDownloadFile = () => {
    setBtnLoading(false);
  };

  const getFileName = () => {
    return excelData?.data?.FileName;
  };

  const preDownloading = () => setBtnLoading(true);
  const postDownloading = () => setBtnLoading(false);

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: exportPDFWithComponent,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });
  if (!excelData.excelImported) {
    return <></>;
  }

  const getStatusIdForStatus = (filterType: string) => {
    if (filterType === "Draft") {
      return [0];
    } else if (filterType === "Completed") {
      return [3];
    } else if (filterType === "Failed") {
      return [1, 2];
    }
  };
  const externalFilter = (filterType = "all") => {
    const oldStatusFilter: any = [...filters.statusFilter];
    if (filterType === "all") {
      setFilters((prev: any) => {
        return { ...prev, statusFilter: [] };
      });
    } else {
      if (
        oldStatusFilter?.findIndex((val: any) => val.text === filterType) !== -1
      ) {
        setFilters((prev: any) => {
          return {
            ...prev,
            statusFilter: oldStatusFilter?.filter(
              (val: any) => val.text !== filterType
            ),
          };
        });
      } else {
        oldStatusFilter.push({
          text: filterType,
          id: getStatusIdForStatus(filterType),
        });
        setFilters((prev: any) => {
          return { ...prev, statusFilter: oldStatusFilter };
        });
      }
    }
  };

  return (
    <>
      <div className="bz-migration-filter-container">
        <div className="bz-leftSide-filter">
          <div className="badge-filter">
            <BadgeContainer key={`index`}>
              <span
                className="status-links"
                onClick={() => externalFilter("all")}
              >
                <span className="title font-weight-bold">{"Total"}</span>
                <Badge shape={"circle"} themeColor={"info"}>
                  {excelData?.data?.TotalClientCount}
                </Badge>
              </span>
            </BadgeContainer>
            <BadgeContainer key={`index`}>
              <span
                className="status-links"
                onClick={() => externalFilter("Draft")}
              >
                <span className="title font-weight-bold">{"Draft"}</span>
                <Badge shape={"circle"} themeColor={"primary"}>
                  {excelData?.data?.DraftCount}
                </Badge>
              </span>
            </BadgeContainer>
            <BadgeContainer key={`index`}>
              <span
                className="status-links"
                onClick={() => externalFilter("Completed")}
              >
                <span className="title font-weight-bold">{"Completed"}</span>
                <Badge shape={"circle"} themeColor={"success"}>
                  {excelData?.data?.CompletedClientCount}
                </Badge>
              </span>
            </BadgeContainer>
            <BadgeContainer key={`index`}>
              <span
                className="status-links"
                onClick={() => externalFilter("Failed")}
              >
                <span className="title font-weight-bold">{"Failed"}</span>
                <Badge shape={"circle"} themeColor={"error"}>
                  {excelData?.data?.FailedClientCount}
                </Badge>
              </span>
            </BadgeContainer>
            <div className="showAll-filter">
              <Switch
                checked={filters?.showAllRecords}
                onChange={() =>
                  setFilters((prev: any) => {
                    return { ...prev, showAllRecords: !prev.showAllRecords };
                  })
                }
              />
              &nbsp;Show All
            </div>
          </div>
        </div>
        <div className="bz-rightSide-filter">
          <div className="search-filter">
            <div className="input-group">
              <Input
                type={"search"}
                onInput={(e: any) => {
                  console.log(e);
                  if (!e.target.value) {
                    onSearch(true);
                  }
                }}
                id={"search"}
                disabled={false}
                placeholder="Search for Name, Email"
                style={{ width: "70%" }}
                ref={exampleInput}
                className="form-control"

                // onBlur={(e) => setFilters((prev: any) => {
                //   console.log(e);
                //   return {...prev, search: e.target.value}
                // })}
              />
              <div className="input-group-append">
                <div className="input-group-text p-0">
                  <Button
                    className="mx-0"
                    icon="search"
                    onClick={() => onSearch()}
                  ></Button>
                </div>
                {/* <span
                    className="input-group-text"
                    onClick={() => onSearch()}
                    role="button"
                  >
                    
                    <span role="presentation" className="k-icon k-i-search"></span>
                  </span> */}
              </div>
            </div>
            {/* <Hint direction={"end"} style={{ position: "absolute", right: 0 }}>
            {value.length} / {max}
          </Hint> */}
          </div>
          <div className="multiselect-filter">
            <MultiSelect
              data={[
                {
                  text: "Draft",
                  id: [0],
                },
                {
                  text: "Failed",
                  id: [1, 2],
                },
                {
                  text: "Completed",
                  id: [3],
                },
              ]}
              textField="text"
              dataItemKey="text"
              value={filters?.statusFilter}
              onChange={(e) =>
                setFilters((prev: any) => {
                  console.log(e);
                  return { ...prev, statusFilter: e.target.value };
                })
              }
              placeholder="Please select"
            />
          </div>
          <div className="download-btn">
            <span title="Download">
              <a href={url} download={name} className="hidden" ref={ref} />
              <Button
                className="bz-icon-button"
                icon="download"
                onClick={download}
              ></Button>
            </span>
          </div>
        </div>
      </div>

      <TabStrip selected={selected} onSelect={handleSelect}>
        {Object.values(importTabs).map((val, index) => (
          <TabStripTab title={val} key={`tab-${index}`}>
            <TabData {...props} />
          </TabStripTab>
        ))}

        {/* <TabStripTab title="Tab 2 Title">
        <Tab2Data />
      </TabStripTab> */}
      </TabStrip>
      {
        <div className="d-flex justify-content-end px-0 py-2 ">
          <div className="box red mr-2"></div>
          <span>Error</span>
          {/* <div className="box light-blue mr-3 ml-2"></div>
        <span>Edited Field</span> */}
        </div>
      }
      {
        // ((excelData?.data?.Status === 4 && sortOrder && tabDataCount) || excelData?.data?.Status !== 4) &&
        excelData?.data?.Status !== RequestStatus.Completed && (
          <div className="d-flex justify-content-end px-0 py-2">
            <BuzopsButton
              label={"Save as Draft"}
              key={`${formType} save`}
              loading={formType === MigrationFormType.SAVE}
              disabled={formType !== MigrationFormType.NONE || !tabDataCount}
              onClick={() => setFormType(MigrationFormType.SAVE)}
            />
            <BuzopsButton
              label={"Submit Data"}
              key={`${formType} submit`}
              loading={formType === MigrationFormType.SUBMIT}
              disabled={formType !== MigrationFormType.NONE || !tabDataCount}
              onClick={() => setFormType(MigrationFormType.SUBMIT)}
              className="mr-0"
            />
          </div>
        )
      }
    </>
  );
};

export default ExcelDataTabs;

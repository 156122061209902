import { GridCellProps } from "@progress/kendo-react-grid";
import useMigrationDetailHistory from "./migrationDetailView.hook";
import { useHistory } from "react-router-dom";
import GridTableScrollable from "atoms/GridTable/GridScrollable";


const MigrationDetailsView = (props: any) => {
    const {
        subscriptionHistory,
        subscriptionLoading,
        fetchHistory,
    } = useMigrationDetailHistory(props);
    const history = useHistory();
    const uuid = props.match?.params?.uuid;
    const ActionCell = (props: GridCellProps) => {
        
        return <td>
        <span
          className="bz-custom-link bz-cursor-pointer"
          onClick={() =>
            // emailLog.fetchEmail(props.dataItem?.EmailNotificationId)
            // console.log(props)
            history.push(`/app/migrationDetails/${uuid}`, {
              migrationData: {...props?.dataItem, onlyView: true},
            })
          }
          title="View"
        >
          <i className="fa fa-eye"></i>
        </span>
      </td>
      };
    const columns = [
        {
            field: "SubmittedOn",
            title: "Submitted On",
        },
        {
          field: "SubmittedBy",
          title: "Submitted By",
        },
        {
          field: "TotalCount",
          title: "Processed",
        },
        {
          field: "SucceededCount",
          title: "Succeeded",
        },
        {
          field: "FailedCount",
          title: "Failed",
        },
        // {
        //   field: "RequestHistoryId",
        //   title: "Action",
        //   cell: ActionCell,
        // },
      ];
    return (
        <div className="d-flex justify-content-center flex-column">
          <span className="mt-2 ">
            <b>Audit History</b>
          </span>
            <GridTableScrollable
                style={{maxHeight: "250px"}}
                title=""
                total={subscriptionHistory?.total}
                columns={columns}
                data={subscriptionHistory?.data}
                handlePagination={fetchHistory}
                pageSize={10}
                loading={subscriptionLoading}
            />
        </div>
    )
}

export default MigrationDetailsView;
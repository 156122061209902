import { GridExpandChangeEvent } from '@progress/kendo-react-grid';
import React, {useState, useEffect} from 'react';
import { ClassService } from 'services/class/index.service';
import { MigrationService } from 'services/import/index.service';

const useSubscriptionHistory = (props: any) => {
    const [subscriptionLoading, setSubscriptionLoading] = useState(false);
    const [subscriptionHistory, setSubscriptionHistory] = useState({ data: [], total: 0, pageSize: 10, pageNumber: 0 });
    const [deleting, setDeleting] = useState(0);
    const [requestRefresh, setRequestRefresh] = useState(0);
    const migrationService = new MigrationService();
    const service = new ClassService();

    const fetchUserSession = async () => {
        await service.getTenantDetails(
        props.from ? props?.uuid : props?.match?.params?.uuid
        );
    };
    useEffect(() => {
        fetchAllApis();
    }, [])

    const fetchAllApis = async() => {
        setSubscriptionLoading(true);
        if(!props?.match?.params?.oldRequest){
            await fetchUserSession();
        }
        await fetchHistory();
    }
    const fetchHistory = async(pageNumber: number = 0, pageSize: number = 10) => {
        setSubscriptionLoading(true);
        const resp = await migrationService.getMigrationHistory(pageSize, pageNumber);
        setSubscriptionHistory({ data: resp.Item.Result, total: resp.Item.TotalItems, pageNumber, pageSize });
        setSubscriptionLoading(false);
        return true;
    }

    const expandChange = (event: GridExpandChangeEvent) => {
        let newData: any= subscriptionHistory?.data.map((item: any) => {
          if (item.RequestId === event.dataItem.RequestId) {
            item.expanded = !event.dataItem.expanded;
          }
          return item;
        });
        setSubscriptionHistory({
            ...subscriptionHistory,
            data: newData
        })
      };
    const refreshMigrationRequest = async(requestId: number) => {
        setRequestRefresh(requestId);
        const resp = await migrationService.getMigrationHistory(subscriptionHistory.pageSize, subscriptionHistory.pageNumber);
        if(resp.Item.Result){
            const newSubscriptionData : any= subscriptionHistory.data.map((val: any) => {
                if(val.RequestId === requestId){
                    return resp.Item.Result.find((val1: any) => val1.RequestId === requestId)
                }else {
                    return val;
                }
            });
            setSubscriptionHistory({
                ...subscriptionHistory,
                data: newSubscriptionData
            })
        }
        setRequestRefresh(0);
    }

    const removeMigration = async(requestId: number) => {
        setDeleting(requestId);
        try{
            const resp = await migrationService.deleteMigrationHistory(requestId);
            setDeleting(0);
            if(resp){
                props?.handleNotificationMessage("Migration record deleted successfully", "success");
                fetchHistory();
            }
        } catch(err) {
            props?.handleNotificationMessage("Error while deleting Request", "error");
            setDeleting(0);
        }
    }
    return {
        subscriptionHistory,
        subscriptionLoading,
        fetchHistory,
        removeMigration,
        deleting,
        refreshMigrationRequest,
        requestRefresh,
        expandChange
    }
}

export default useSubscriptionHistory;
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useApiClient } from "services/axios-service-utils";
import { TenantService } from "services/tenant/index.service";

const OneonOneLinks = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const {axiosRequest} = useApiClient();

  async function fetchServiceInstance() {
    let req: any = {
      PromotionId: props?.Promotion?.PromotionId,
      IsActive:true
    };
    setLoading(true);
    const servicedet = await axiosRequest.post('Appointments/Inventory',req);
    const result = servicedet.data?.Items
    if (result) {
      setData(result);
    }
    setLoading(false);
  }
  const copyToClipBoard = (text: any) => {
    navigator.clipboard.writeText(text);
    props?.handleNotificationMessage("Copied", "success");

  };

  useEffect(() => {
    if (props?.Promotion?.PromotionId) {
      fetchServiceInstance();
    }
  }, [props?.Promotion]);

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (data?.length === 0 || !props?.Promotion?.PromotionId) {
    return (
      <div
        className={
          "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
        }
      >
        <div className="bz-norecords-available">No Links Available</div>
      </div>
    );
  }

  return (
    <div className="row">
      {data?.map((i: any) => {
        const link = `https://${props?.DomainName}.${process.env.REACT_APP_DOMAIN}.com/promolink/${props?.affiliateDetails?.AffiliateCode}/${props?.Promotion?.ShortCode}/${i?.ShortCode}`;

        return (
          <div className="col-6">
            <div className="d-flex align-items-center mb-2">
              <div className="flex-grow-1">
                <div className="d-flex align-items-center linkbg align-items-center" style={{ gap:'10px'}}>
                  <div className="flex-grow-1" style={{wordBreak:'break-all'}}>
                    {" "}
                    <a href={link} target="_blank">
                    <div style={{display:'flex', justifyContent:'space-between', }} className="align-items-center">
              <div>   <i
                        className="fa fa-link"
                        style={{
                          fontSize: "11px",
                          marginRight: "5px",
                        }}
                      ></i></div>
              <div style={{flexGrow:'1'}}>   {i?.Name}</div>
              </div>
                    
                    
                    </a>
                  </div>
                  {/* <div className="flex-shrink-0" style={{width:'20px'}}>
                    <span className="bz-cursor-pointer">
                      <MdContentCopy
                        onClick={() => copyToClipBoard(link)}
                        title={"Copy"}
                      />
                    </span>
                  </div> */}
                  
                  <button
                   className="actiobtnform" 
                    onClick={() => copyToClipBoard(link)}
                  >
                    {" "}
                    <i
                      style={{ fontSize: "16px", color: "#607d8b" }}
                       className="mdi mdi-content-copy"
                     />Copy{" "}
                  </button>

                  <button
                   className="actiobtnform" 
                   onClick={() => props?.handleSelectLink(link, i?.Name)}
                  >
                    {" "}
                    <i
                      style={{ fontSize: "16px", color: "#607d8b" }}
                       className="mdi mdi-share-variant"
                     />Share{" "}
                  </button>
                  

                </div>
              </div>
              {/* <div className="flex-shrink-0 px-2">
                {" "}
                <a href="#"
                  onClick={() => props?.handleSelectLink(link, i?.Name)}
                  className="btn btn-primary-buzops btn-sm text-white"
                >
                  {" "}
                  <em className="mdi mdi-share-variant-outline"></em>
                </a>
              </div> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OneonOneLinks;

import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import { useState, useEffect } from "react";
import { AccessControlService } from "services/access-control/index.service";
import { CheckInService } from "services/checkin/index.service";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus, getFullImageUrl } from "utils";
import RenderTodayAppointments from "./RenderTodayAppointments";
import { Dialog } from "@progress/kendo-react-dialogs";
import { ListView } from "@progress/kendo-react-listview";
import withNotification from "components/Hoc/withNotification";
import IdleTimeOutHandler from "layout/idleHandler/idleTimeOutHandler";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import { CheckinFrom, CheckinMode } from "./Utils/constants";
import ChargeBackGlobal from "components/ChargeBack/ChargeBackGlobal";
import { FilterTypeForService } from "utils/form-utils";
import BuzopsWindow from "generic-components/BuzopsWindow";
import ComponentForInActiveOrSuspendedTransaction from "./ComponentForInActiveOrSuspendedTransaction";
import useCheckinUser from "./Container/CheckinUser.hook";
import PageExpireCounter from "layout/idleHandler/PageExpireCounter";

const CheckinUserBarcode = (props: any) => {
  const service = new TenantService();
  const simpleAccessService = new SimpleAccessService();
  const checkinService = new CheckInService();
  const [searchAppointments, setSearchAppointments] = useState<any>([]);
  const [apptsLoading, setApptsLoading] = useState<any>(true);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState<any>(true);
  const [checkAll, setCheckAll] = useState<any>(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>(null);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState<Boolean>(false);
  const [failedCheckinsDialog, setFailedCheckinsDialog] = useState(false);
  const [failedCheckins, setFailedCheckins] = useState<any>([]);
  const [restrictUser, setRestrictUser] = useState("");
  const [userDetails, setUserDetails] = useState(props?.userDetails);
  const [transactionState, setTransactionState] = useState({
    visible: false,
    data: {},
  });
  const { handleLocationCheckin } =
    useCheckinUser(props);
  const { securitySystemConfigs } = props;
  useEffect(() => {
    if (props?.userDetails) {
      fetchAllApis();
    }
  }, [props?.userDetails]);

  const userMemberId = props?.userDetails?.UserMemberId;

  useEffect(() => {
    if (Object.keys(securitySystemConfigs).length > 0) {
      getUpcomingAppointments(userMemberId);
    }
  }, [securitySystemConfigs]);

  const fetchAllApis = async () => {
    await getSubscriptionDetails(userMemberId);
  };

  const handleCheck = (dataitem: any) => {
    setCheckAll(false);
    const data = searchAppointments?.map((i: any) => {
      if (i?.ScheduleId === dataitem?.ScheduleId) {
        return { ...i, check: !i?.check };
      } else {
        return { ...i };
      }
    });
    setCheckAll(data.every((val: any) => val.check));
    setSearchAppointments(data);
  };

  const toggleAvailibilityDialog = () => {
    setFailedCheckinsDialog(!failedCheckinsDialog);
  };

  const handleCheckAll = () => {
    const data = searchAppointments?.map((i: any) => {
      if (!checkIfCheckInAllows(i)) {
        return {
          ...i,
          check: false,
        };
      }
      return {
        ...i,
        check: !checkAll,
      };
    });
    setCheckAll(!checkAll);
    setSearchAppointments(data);
  };

  const handleSendEmail = async () => {
    const UserMemberId = props?.userDetails?.UserMemberId;
    const res = await checkinService?.NotifyCheckinsRestricted(UserMemberId);
    if (res?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        "Notification sent to the client successfully",
        "success"
      );
    } else {
      props?.handleNotificationMessage(
        res?.ErrorMessages?.[0] || "Check-In unsuccessfull",
        "error"
      );
    }
  };

  const handleCheckIn = async () => {
    if (
      props?.CheckinMode !== CheckinMode.ByStaff &&
      !CheckUserMemberStatus({ Status: subscriptionDetails?.UserMemberStatus })
    ) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (
      props?.CheckinMode === CheckinMode.ByStaff &&
      !CheckUserMemberStatus({ Status: subscriptionDetails?.UserMemberStatus })
    ) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    setBtnLoading(true);
    const CheckedAppts = searchAppointments?.filter(
      (i: any) => i?.check === true
    );
    if (CheckedAppts?.length > 0) {
      const req: any = [];
      CheckedAppts.forEach((i: any) => {
        const obj = {
          Ocurred: moment().format("YYYY-MM-DDTHH:mm:ss"),
          Type: i?.EntityType,
          CheckedInUserId: props?.userDetails?.UserId,
          CheckinMode: props?.CheckinMode,
          EntityName: i?.ServiceName,
          ScheduledStartTime: i?.FromTime,
          EntityId: i?.EntityId,
          EntityType: i?.EntityType,
          ScheduleId: i?.ScheduleId,
        };
        req.push(obj);
      });
      const payload = {
        CheckinEvents: req,
      };
      const result = await checkinService?.saveEventsCheckins(payload);
      console.log(result);
      if (result?.ResponseCode === 300) {
        props?.handleNotificationMessage(
          "Check-In successfully completed",
          "success"
        );
        getUpcomingAppointments(userMemberId);
      } else {
        if (!result?.Item?.IsValid) {
          setFailedCheckins(
            result?.Item?.CheckinEvents ||
              result?.ErrorMessages || ["Error occured while processing"]
          );
          setFailedCheckinsDialog(true);
        }
      }
    }
    setBtnLoading(false);
  };

  const renderfailedCheckins = (records: any) => {
    let item = records.dataItem;
    if (typeof item === "string") {
      return <div>{item}</div>;
    }
    return (
      <div className="col-12">
        <div className="row p-3 align-middle buz-online-list-view">
          <div className="col-12">
            <div className="buzopsListValidateItem m-0 text-capitalize ">
              {item?.EntityName}{" "}
              <span className="text-muted text-uppercase d-block mb-0">
                <i className="far fa-calendar-alt"></i>
                {item?.ScheduledStartTime}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="buzopsListValidateItem m-0 text-danger">
                {item?.ValidationErrors[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderfailedCheckinsDialog = () => {
    return (
      <Dialog
        className="bz-mybookings-dialog-box bz-custom-content-height bz-checkin-status"
        title={"Check-Ins Status"}
        onClose={toggleAvailibilityDialog}
        width={440}
      >
        <ListView
          data={failedCheckins}
          item={(props: any) => renderfailedCheckins(props)}
          style={{
            width: "100%",
          }}
        />
      </Dialog>
    );
  };
  const getUpcomingAppointments = async (UserMemberId: any) => {
    setApptsLoading(true);
    const req = {
      FromDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      ShowFutureDate: true,
      IsFromCalendar: true,
      CanFilterUpToSpecifiedToTime: true,
      HasSpecificMember: true,
      ToDate: moment().add(1, "day").format("YYYY-MM-DD") + "T23:59:59",
      UserMemberId: UserMemberId,
      Status: 1,
      ServiceInstanceId: null,
    };

    const result = await service.getUpcomingAppointments(req);
    setApptsLoading(false);
    const res = result?.Result?.map((i: any) => {
      if (checkForFutureRecords(i)) {
        return { ...i, futureAppointment: false };
      }
      return { ...i, check: false, futureAppointment: true };
    });
    setSearchAppointments(
      res.filter((val: any) => !val.futureAppointment) || []
    );
  };

  const checkIfUserCanCheckin = (accessCardDetails: any, check = false) => {
    let doCheckin = false;
    if (accessCardDetails.CardNumber) {
      doCheckin = true;
      if (accessCardDetails.IsSuspended) {
        doCheckin = false;
        if (!check) {
          props?.handleNotificationMessage(
            "User's access is temporarily suspended",
            "error"
          );
        }
      }
    } else {
      if (!check) {
        setRestrictUser("Access card is not assigned to the selected user");
        props?.handleNotificationMessage(
          "Access card is not assigned to the selected user",
          "error"
        );
      }
      doCheckin = false;
    }
    return doCheckin;
  };

  const getSubscriptionDetails = async (userMemberId: any) => {
    setSubscriptionsLoading(true);
    const service = new AccessControlService();
    const result = await service.getClientSubscriptionsByMemberId(userMemberId);
    let inactiveItems = [];
    if (result?.Subscriptions?.length > 0) {
      inactiveItems = result?.Subscriptions?.filter(
        (x: any) => x?.Status === 5 || x?.Status === 15 || x?.Status === 16
      );
    }
    const req = {
      UserMemberId: userMemberId,
      PrimaryUserMemberId: props?.userDetails?.MainUserId,
      IncludeSubMember: false,
    };
    let response;
    if (props?.userDetails?.AccessCard) {
      response = [props?.userDetails];
    } else {
      response = await simpleAccessService.getMembersWithAccess(req);
    }
    let doCheckin = true;

    if (!response.length) {
      setRestrictUser("Access card is not assigned to the selected user");
      props?.handleNotificationMessage(
        "Access card is not assigned to the selected user",
        "error"
      );
      doCheckin = false;
    } else {
      doCheckin = checkIfUserCanCheckin(response[0].AccessCard);
      setUserDetails(response[0]);
    }

    if (
      doCheckin &&
      props.from === CheckinFrom.Kiosk &&
      result?.UserMemberStatus !== 4
    ) {
      await handleLocationCheckin();
    }
    setSubscriptionsLoading(false);
    setSubscriptionDetails(result);
    setInactiveSubscriptions(inactiveItems);
  };

  const showMemberType = () => {
    if (userDetails?.MemberType === 0) {
      return "Primary Member";
    } else if (userDetails?.MemberType === 1) {
      return "Sub Member";
    } else if (userDetails?.MemberType === 2) {
      return "Child";
    }
    return "";
  };
  const checkForFutureRecords = (dataItem: any) => {
    let doCheckin = false;
    const entityType = dataItem.EntityType;
    const timeStamp = dataItem.FromTime;
    let minDuration = 240;
    if (entityType === 2) {
      minDuration =
        securitySystemConfigs?.GroupClassCheckinPreference
          ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin;
    } else if (entityType === 3) {
      minDuration =
        securitySystemConfigs?.OneOnOneCheckinPreference
          ?.MaxTimeSpanInMinutesBeforeScheduleForCheckin;
    }
    let diff = moment(moment(timeStamp).format("YYYY-MM-DD HH:mm:ss")).diff(
      moment().format("YYYY-MM-DD HH:mm:ss"),
      "minutes"
    );

    if (diff < minDuration && diff > -30) {
      doCheckin = true;
    } else {
      doCheckin = false;
    }
    return doCheckin;
  };

  const handlePayInactive = (dataItem: any, checkinMode: number) => {
    if (checkinMode === CheckinMode.ByStaff) {
      const item = props?.userDetails;
      const data: any = {
        userMemberID: item?.UserMemberId,
        userMemberStatus: subscriptionDetails?.UserMemberStatus,
        entityId: dataItem?.InstanceId,
        checkinMode: 1,
        filterType: FilterTypeForService[dataItem.ServiceType],
        status: dataItem.Status,
        ...dataItem,
      };
      setTransactionState({
        visible: true,
        data: data,
      });
    }
  };
  const checkIfCheckInAllows = (dataItem: any) => {
    let doCheckin = false;
    if (
      dataItem?.CheckinTimestamp !== null ||
      (!dataItem?.IsPaid && dataItem.PrivateServiceType === 1) ||
      dataItem?.futureAppointment || userDetails?.AccessCard?.IsSuspended
    ) {
      doCheckin = false;
    } else {
      doCheckin = true;
    }

    return doCheckin;
  };

  const onSuccessfullPayment = async () => {
    getSubscriptionDetails(userMemberId);
    getUpcomingAppointments(userMemberId);
  };

  const onWindowClose = async (bool = false) => {
    setTransactionState({
      visible: false,
      data: {},
    });
    if (bool) {
      onSuccessfullPayment();
    }
  };
  const renderTransactionsDialog = () => {
    return (
      <BuzopsWindow
        key={transactionState.visible.toString()}
        reduceWidth={10}
        onWindowClose={() => {
          onWindowClose();
        }}
        title={"Payment Transactions"}
        className={"chargeback-global-window"}
        visibility={transactionState.visible}
      >
        <ComponentForInActiveOrSuspendedTransaction
          {...transactionState.data}
          userDetails={props?.userDetails}
          onWindowClose={(paymentUpdated: boolean) =>
            onWindowClose(paymentUpdated)
          }
        />
      </BuzopsWindow>
    );
  };

  const renderInstructionsOrContactAdmin = () => {
    return (
      <>
        {inactiveSubscriptions.some((val: any) => val.Status === 16) &&
        props?.CheckinMode === CheckinMode.UserFromKiosk ? (
          <>
            <h4 style={{ fontSize: "18px", fontWeight: "600" }}>
              {" "}
              Please Contact Admin to resolve your subscription issues.
            </h4>
          </>
        ) : (
          <>
            <h4 style={{ fontSize: "18px", fontWeight: "600" }}>
              Please resolve the issues for the following subscription to
              check-in
            </h4>
            {!inactiveSubscriptions.every((val: any) => val.Status === 16) && (
              <>
                <div
                  className="btn btn-link"
                  onClick={() => handleSendEmail()}
                >{`Email Instructions`}</div>
                <br />
                <small>
                  {props?.CheckinMode === CheckinMode.UserFromKiosk
                    ? `Will receive an email indicating check-ins are restricted due to pending payment`
                    : `Will send an email to client indicating check-ins are restricted due to pending payment`}
                </small>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const getTitleForInActive = () => {
    if (props?.from === CheckinFrom.Kiosk) {
      return "Please Contact Admin to resolve your subscription issues.";
    }
    return "Click to proceed";
  };

  console.log(props.CheckinMode, searchAppointments);
  return (
    <>
      {props?.CheckinMode === CheckinMode.UserFromKiosk && (
        <>
          <IdleTimeOutHandler
            onLogout={() => {
              window.location.reload();
            }}
            timeOutInterval={
              searchAppointments.length === 0 &&
              inactiveSubscriptions?.length === 0
                ? 7000
                : 45000
            }
            noAppointments={
              searchAppointments.length === 0 &&
              inactiveSubscriptions?.length === 0
            }
          />

          {/* <div>
          <i className="fas fa-barcode"></i> Barcode: 
          <span> {props?.userDetails?.AccessCard?.CardNumber}</span>
        </div> */}

          <br />
        </>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="user-content ">
            {subscriptionsLoading ? (
              <>
                <div
                  className={
                    "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                  }
                >
                  {/* <BuzopsLoader type="list" /> */}
                </div>
              </>
            ) : (
              <>
                {props?.userDetails?.UserMemberId &&
                  (!CheckUserMemberStatus({
                    Status: subscriptionDetails?.UserMemberStatus,
                  }) ||
                    subscriptionDetails?.UserMemberStatus === 8) && (
                    <div
                      className="alert alert-warning alert-dismissible fade show"
                      role="alert"
                    >
                      <span>
                        {subscriptionDetails.UserMemberStatus === 8
                          ? `Action Required on Chargeback/Return transaction. `
                          : `Action Required on Chargeback/Return transaction, no action can be performed until the dispute is resolved. `}

                        {props?.CheckinMode === CheckinMode.ByStaff &&
                        CheckPermissions(
                          PermissionEnum["Chargeback_Return_Manage_User"]
                        ) ? (
                          <>
                            <ChargeBackGlobal
                              clientId={props?.userDetails?.UserMemberId}
                              onSuccessfullPayment={() =>
                                onSuccessfullPayment()
                              }
                              paymentTitle={
                                <a className="btn btn-link text-left mb-1">
                                  <u>Please click here to make a payment.</u>
                                </a>
                              }
                            />
                          </>
                        ) : (
                          <>
                            {`Please contact a staff at the facility to resolve this.`}
                          </>
                        )}
                      </span>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
        <div
          className={`col-md-4 text-center slefcheckinmb ${
            props.CheckinMode === CheckinMode.UserFromKiosk &&
            searchAppointments.length === 0 &&
            "mx-auto"
          }`}
        >
          <div className="user-content pt-4 self-checkbox  self-checkbox-app">
            {subscriptionsLoading ? (
              <>
                <div
                  className={
                    "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                  }
                >
                  <BuzopsLoader type="list" />
                </div>
              </>
            ) : (
              <>
                {searchAppointments.length === 0 &&
                  inactiveSubscriptions.length === 0 &&
                  props.CheckinMode === CheckinMode.UserFromKiosk && (
                    <div className="col-md-4 text-right align-self-end">
                      <span
                        className="close-checkins"
                        onClick={() => props?.onCloseHandler()}
                      >
                        <i className="fas fa-times"></i>
                      </span>
                    </div>
                  )}
                {CheckinFrom.Kiosk === props?.from &&
                  checkIfUserCanCheckin(userDetails?.AccessCard, true) && (
                    <div>
                      <span className="selfcheckin-active">
                        <i className="fa fa-check"></i> Checked In
                      </span>
                    </div>
                  )}
                <div
                  className="user-details checkinuser border-0"
                  style={{ justifyContent: "start", alignSelf: "left" }}
                >
                  <div className={"user-pic text-center mt-3  nb-3 seflcheck "}>
                    <img
                      src={
                        subscriptionDetails?.Photo
                          ? getFullImageUrl(subscriptionDetails?.Photo)
                          : "/images/user.png"
                      }
                      alt="Client"
                      style={{ width: "200px", height: "200px" }}
                      className="rounded-circle"
                    />
                  </div>
                </div>
                <div
                  className={"user-details pl-0 pb-2 "}
                  style={{ position: "relative" }}
                >
                  <div key={1} className={"text-center"}>
                    <h5 className="checkinuser_name selfcheckin-name mt-3 mb-3">
                      {props?.userDetails?.FullName}
                    </h5>

                    {userDetails?.MemberType !== null ? (
                      <p
                        style={{
                          color: "#ffffff",
                          alignItems: "center",
                          marginBottom: "0.25rem",
                          background: "#18addc",
                          padding: "2px 4px",
                          width: "100%",
                          maxWidth: "180px",
                          margin: "auto",
                          borderRadius: "50px",
                        }}
                      >
                        <ClientNameWithMemberType
                          memberType={userDetails?.MemberType}
                          clientName={showMemberType()}
                        />
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="mt-5 text-muted text-center">
                  {searchAppointments.length === 0 &&
                    inactiveSubscriptions.length === 0 &&
                    props.CheckinMode === CheckinMode.UserFromKiosk &&
                    !restrictUser &&
                    !apptsLoading && (
                      <div
                        className={
                          "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                        }
                      >
                        <div className="no-appointment-bz">
                          No Appointments for check-in. <br />
                          You are all set! <br />
                          <span className="btn-warning">
                            {" "}
                            Page will be refreshed automatically in{" "}
                            {<PageExpireCounter timeOutIntervals={5000} />}{" "}
                            seconds.
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
        {props.CheckinMode === CheckinMode.UserFromKiosk &&
        searchAppointments.length === 0 &&
        inactiveSubscriptions?.length === 0 ? (
          <></>
        ) : (
          <div className="col-md-8">
            <div className="user-content pt-4 self-checkbox self-checkbox-app">
              {props?.from === CheckinFrom.Kiosk && (
                <div
                  className="row"
                  style={{
                    borderBottom: "1px solid #d9d9d9",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="col-md-8">
                    {" "}
                    <h4>Self Check-in</h4>
                  </div>

                  <div className="col-md-4 text-right">
                    <span
                      className="close-checkins"
                      onClick={() => props?.onCloseHandler()}
                    >
                      <i className="fas fa-times"></i>
                    </span>
                  </div>
                </div>
              )}

              {inactiveSubscriptions?.length === 0 && !restrictUser ? (
                <>
                  {searchAppointments.length > 0 && (
                    <h6 className="text-left mt-2 bz-fw-6 mb-3">
                      <span className="bz-appointments-selfcheckin">
                        Your Appointments for today
                      </span>
                      <small className="bz-text-muted">
                        Select the appoinments that you want to check-In
                      </small>
                    </h6>
                  )}
                </>
              ) : (
                <></>
              )}
              <div className="today-appointments pt-0">
                {apptsLoading ? (
                  <div
                    className={
                      "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                    }
                  >
                    <BuzopsLoader type="list" />
                  </div>
                ) : (
                  <>
                    {restrictUser && (
                      <div
                        className={
                          "bz-no-upcoming-appoinments text-muted bz-upcomingappointments-card"
                        }
                      >
                        <div className="bz-norecords-available">
                          {restrictUser}
                        </div>
                      </div>
                    )}
                    {inactiveSubscriptions?.length === 0 && !restrictUser ? (
                      <>
                        <RenderTodayAppointments
                          searchAppointments={searchAppointments}
                          handleCheckAll={handleCheckAll}
                          handleCheck={(dataitem: any) => handleCheck(dataitem)}
                          checkAll={checkAll}
                          checkIfCheckInAllows={checkIfCheckInAllows}
                          userDetails={userDetails}
                          getUpcomingAppointments={() =>
                            getUpcomingAppointments(userMemberId)
                          }
                          from={props?.from}
                        />

                        {searchAppointments.length > 0 && (
                          <div className="d-flex justify-content-right pt-3">
                            <BuzopsButton
                              label={"Check-In"}
                              primary
                              onClick={() => handleCheckIn()}
                              loading={btnLoading}
                              disabled={
                                btnLoading ||
                                searchAppointments.findIndex(
                                  (val: any) => val.check
                                ) === -1
                              }
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {inactiveSubscriptions?.length > 0 && !restrictUser && (
                      <div className=" render_resolve">
                        {renderInstructionsOrContactAdmin()}
                        <div className="render_sub_parent pt-2">
                          {inactiveSubscriptions?.map((i: any) => {
                            return (
                              <>
                                <div
                                  title={getTitleForInActive()}
                                  className={`render_resolve_in suspendbuzpos ${
                                    props?.CheckinMode === CheckinMode.ByStaff
                                      ? "cursor-pointer"
                                      : "disable_link"
                                  }`}
                                  onClick={() =>
                                    handlePayInactive(i, props?.CheckinMode)
                                  }
                                >
                                  <p className="i">{i?.Name}</p>
                                  <p>
                                    {" "}
                                    <span className="inactive">
                                      {i?.StatusDescription}
                                    </span>
                                  </p>
                                  <small>{i?.ServiceTypeDescription}</small>
                                  {props?.CheckinMode !==
                                    CheckinMode.UserFromKiosk &&
                                    (i?.Status === 16 ? (
                                      <p className="buttonpaynow">
                                        Click here to Activate
                                      </p>
                                    ) : (
                                      <p className="buttonpaynow">
                                        Pay Now to check-in
                                      </p>
                                    ))}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {failedCheckinsDialog && renderfailedCheckinsDialog()}
      {renderTransactionsDialog()}
    </>
  );
};

export default withNotification(CheckinUserBarcode);

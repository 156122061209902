import * as React from "react";
import {
  Grid,
  GridColumn,
  GridEvent,
} from "@progress/kendo-react-grid";
import BuzopsLoader from "generic-components/BuzopsLoader";

interface PageState {
  skip: number;
  take: number;
}

interface IColumns {
  field: string;
  title: string;
  width?: number;
  cell?: any;
  editor?: any;
  locked?: boolean;
  hide?: boolean;
}

interface IGridTableProps {
  title?: string;
  total?: number;
  columns: IColumns[];
  data: any[];
  handlePagination?: any;
  pageSize?: number;
  className?: string;
  rowRender?: any;
  editField?: string;
  onItemChange?: any;
  loading?: boolean;
  resizable?: boolean;
  style?: any;
}

const GridTableScrollable: React.FunctionComponent<IGridTableProps> = ({
  title,
  total,
  columns,
  data,
  handlePagination,
  pageSize,
  className,
  rowRender,
  editField,
  onItemChange,
  loading,
  resizable,
  style
}) => {
    const [size, setSize] = React.useState<any>(
        20
      );
  const scrollHandler = (event: GridEvent) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + 10 >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const moreData = data.slice(size, size+10);
      if (moreData.length > 0) {
        setSize((val: any) => val+20);
      }
    }
  };
  if(loading){
    return <BuzopsLoader />
  }

  return (
    <div className="payment-transaction-container">
      <h5 className="mb-3 bz_fs_1_5">{title}</h5>
      
      {data.length > 0 ? (
        <>
          <div className={className}>
            <Grid
              data={data.slice(0, size)}
              className="bzc-grid"
              style={style || { maxHeight: "400px" }}
              onScroll={scrollHandler}
              fixedScroll={true}
              rowRender={rowRender}
              editField={editField}
              onItemChange={onItemChange}
              resizable={resizable}
            >
              {columns.map((column, index) => (
                !column.hide &&
                <GridColumn
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  cell={column.cell}
                  key={index}
                  editor={column.editor}
                  locked={column.locked}
                />
              ))}
            </Grid>
          </div>
        </>
      ) : (
        <div className={"bz-no-upcoming-appoinments text-muted"}>No data</div>
      )}
    </div>
  );
};

export default GridTableScrollable;

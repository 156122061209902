import { MdContentCopy } from "react-icons/md";

const LiveStreamSessionExpired = () => {
  const club = window.location.host.split(".")[0];
  const dotnetAPPurl = `${process.env.REACT_APP_DOTNET_APP_URL}/portal/${club}/SignIn`;

  const copyToClipBoard = () => {
    try {
      window.navigator.clipboard.writeText(dotnetAPPurl);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="">
      <div id="bz-notfound">
        <div className="bz-notfound d k-notification k-notification-warning">
          <div className="bz-notfound-404"></div>
          <div>
            <h3>Your login session has timed out.</h3>
            <p>

              In order to view the live stream, page please follow the steps
              below:

            </p>
            <ol style={{
              padding: "0", listStylePosition: "inside"
            }}>
              <li>
                Close your current browser window and login to your BuzOps
                Dashboard.
              </li>
              <br />
              <li>
                For your convenience here is the login Url :
                <span style={{ display: "block", marginTop: "10px", color: "#007bff" }}>
                  {dotnetAPPurl} <span>
                    <MdContentCopy onClick={copyToClipBoard} />
                  </span>
                </span>

              </li>
              <br />
              <li>
                After successful login, please launch the Live Stream page from your Dashboard
              </li>
            </ol>
          </div>




        </div>
      </div>
    </div>
  );
};

export default LiveStreamSessionExpired;

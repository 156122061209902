import React, { useEffect, useState } from "react";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import moment from "moment";
import CustomCalendar, { FilterOptions } from "atoms/CustomCalendar";
import { CurrencyFormat } from "utils";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { MetricsToolTip } from "components/AffiliateProgram/Promotions/Promotions.view";
import TopPromotionLinksByAssociate from "./TopPromotionLinksByAssociate";
import ProspectsDialog from "./ProspectsDialog";
import { ProgressBar } from "@progress/kendo-react-progressbars";

export const SubscriptionsChartContainer = (props: any) => {
  const { dataItem } = props;
  const totalSubscriptions =
    dataItem?.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue?.value,
      0
    ) || 0;
  return (
    <>
      <div className="d-flex justify-content-center ">
        <h6 className="buz-font-600" style={{ width: "100%", fontSize: '14px' }}>
          Total Subscriptions{" "}
        </h6>
        <p className="buz-font-600 mb-0">{totalSubscriptions}</p>
      </div>
      <div className="row">
        {dataItem?.map((i: any) => {
          let progressStatus = "";
          let progressValue = 0;
          switch (i?.text) {
            case "healthy":
              progressStatus = "green";
              progressValue = totalSubscriptions
                ? Math.round((i?.value / totalSubscriptions) * 100)
                : 0;

              break;
            case "failed":
              progressStatus = "orange";
              progressValue = totalSubscriptions
                ? Math.round((i?.value / totalSubscriptions) * 100)
                : 0;
              break;
            case "cancelled":
              progressStatus = "red";
              progressValue = totalSubscriptions
                ? Math.round((i?.value / totalSubscriptions) * 100)
                : 0;
              break;
            default:
              progressStatus = "";
              progressValue = 0;
              break;
          }
          return (
            <div className="bz-summary-list pb-1 col-md-6">
              <div className="bz-summary-details " >
                <div
                  className="bz-summary-info d-flex justify-content-between"
                  style={{ fontSize: "0.75rem" }}
                >
                  <span>{i?.category}</span>
                  <div className="sbz-summary-count">
                    <span>{`${i?.value} (${progressValue}% )`}</span>
                  </div>
                </div>
                <div className="bz-summary-stats">
                  <ProgressBar
                    value={progressValue}
                    min={0}
                    max={100}
                    labelVisible={false}
                    progressStyle={{ background: progressStatus }}
                    style={{ width: "100%", height: "5px" }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const AffiliateOverview = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [fromDateToDate, setFromDateToDate] = useState({
    from: moment().startOf("year").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [promoter, setPromoter] = useState<any>(null);
  const [showProspectDialog, setShowProspectDialog] = useState<any>(false);
  const [topPromoLinks, setTopPromoLinks] = useState<any>([]);
  const service = new AffiliateProgramService();
  const setFromDateToDateFunc = (val: any) => {
    setFromDateToDate(val);
  };

  useEffect(() => {
    if (fromDateToDate?.from && fromDateToDate?.to) {
      fetchPromoterWithKeyMetrics();
    }
  }, [fromDateToDate]);

  const toggleProspectDialog = () => {
    setShowProspectDialog(!showProspectDialog);
  };

  const fetchPromoterWithKeyMetrics = async () => {
    setLoading(true);
    const req = {
      PromoRedeemedFromDate: fromDateToDate?.from,
      PromoRedeemedToDate: fromDateToDate?.to,
      IncludeKeyMetrics: true,
    };
    const res = await service.getAffiliateById(props?.associateId, req);
    setPromoter(res);
    await fetchTopPromotionLinksByAssociateId();
    setLoading(false);
  };

  const fetchTopPromotionLinksByAssociateId = async () => {
    const req = {
      FromDate: fromDateToDate?.from,
      ToDate: fromDateToDate?.to,
      TopN: 5,
      AffiliateId: props?.associateId,
    };
    const res = await service.getTopPromotionLinksByAssociateId(req);
    setTopPromoLinks(res || []);
  };

  return (
    <div className="affiliate-dashboard-overview">
      <div>
        <div className="col-md-12  padding-affiliates p-1">
          <div className="row">
            <div className="col-md-12">
              {" "}
              <h5 className="mb-1 bz_fs_1_5 affiliatemainheading">
                {"Overview"}
              </h5>
              <p className="desaffiliates text-muted">
                {" "}
                Access Your Personalized Affiliate Dashboard: Your Exclusive Hub
                for Performance Metrics, Earnings, and Insights.
              </p>
              <div className="mt-2 mb-0">
                <CustomCalendar
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  setFromDateToDate={setFromDateToDateFunc}
                  selectedDateItem={FilterOptions.ThisYear}
                  onlyPastDates
                />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <BuzopsLoader type="list" />
        ) : (
          <>
            <div className="metric-list-row row mt-2  px-1">
              <div className="col-xl-7 col-lg-6 col-md-12 col-12 padding-affiliates">
                <div className="row">
                  <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                    <div className="card metrics-list-bd-count-card">
                      <div className="metrics-list-bd-count-card-body">
                        <div className="row">
                          <div style={{ width: "75px" }}>
                            <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-badge-circle-light-info m-lg-auto ml-xl-auto">
                              <i className="mdi mdi-finance"></i>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "calc(100% - 75px)",
                              padding: "0 24px",
                            }}
                          >
                            <div className="metrics-card-info metrics-list-card-content">
                              <p>Net Revenue</p>
                              <div className="m-0 metrics-list-count-card-title d-flex align-items-center">
                                <BuzopsTooltip
                                  html={
                                    <a
                                      title="Net Revenue from Purchases"
                                      style={{
                                        borderBottom: "1px dotted #d9d9d9",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {CurrencyFormat(
                                        promoter?.KeyMetrics
                                          ?.NetRevenueFromPurchases || 0
                                      )}
                                    </a>
                                  }
                                  content={
                                    <MetricsToolTip
                                      KeyMetrics={promoter?.KeyMetrics}
                                    />
                                  }
                                  position={"right"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                    <div className="card metrics-list-bd-count-card">
                      <div className="metrics-list-bd-count-card-body">
                        <div className="row">
                          <div style={{ width: "75px" }}>
                            <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-circle-light-primary m-lg-auto ml-xl-auto">
                              <i className="mdi mdi-account-multiple"></i>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "calc(100% - 75px)",
                              padding: "0 24px",
                            }}
                          >
                            <div className="metrics-card-info metrics-list-card-content">
                              <p>Prospects </p>
                              <div className="m-0 metrics-list-count-card-title d-flex align-items-center">
                                {promoter?.KeyMetrics?.LeadsGenerated >
                                  0 ? (
                                  <a
                                    onClick={() => toggleProspectDialog()}
                                    style={{
                                      borderBottom: "1px dotted #d9d9d9",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {promoter?.KeyMetrics?.LeadsGenerated || 0}
                                  </a>
                                ) : (
                                  <span>
                                    {promoter?.KeyMetrics?.LeadsGenerated || 0}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                    <div className="card metrics-list-bd-count-card">
                      <div className="metrics-list-bd-count-card-body">
                        <div className="row">
                          <div style={{ width: "75px" }}>
                            <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-circle-light-success m-lg-auto ml-xl-auto">
                              <i className="mdi  mdi-account-check-outline"></i>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "calc(100% - 75px)",
                              padding: "0 24px",
                            }}
                          >
                            <div className="metrics-card-info metrics-list-card-content">
                              <p>Subscriptions Redeemed</p>
                              <div className="m-0 metrics-list-count-card-title d-flex align-items-center">
                                {promoter?.KeyMetrics?.SubscriptionsRedeemed ||
                                  0}
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm col-sm-12 col-md-6 col-xl-6">
                    <div className="card metrics-list-bd-count-card">
                      <div className="metrics-list-bd-count-card-body">
                        <div className="row">
                          <div style={{ width: "75px" }}>
                            <div className="metrics-list-avatar metrics-list-avatar-lg rounded-circle metrics-list-circle-light-warning m-lg-auto ml-xl-auto">
                              <i className="mdi mdi-account-remove"></i>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "calc(100% - 75px)",
                              padding: "0 24px",
                            }}
                          >
                            <div className="metrics-card-info metrics-list-card-content metrics-list-card-content ">
                              <p>Subscriptions Cancelled</p>
                              <div className="m-0 metrics-list-count-card-title  d-flex align-items-center">
                                {promoter?.KeyMetrics?.SubscriptionsCancelled ||
                                  0}
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-sm col-md-12 col-lg-6 col-xl-5">
                <SubscriptionsChartContainer
                  dataItem={[
                    {
                      text: "healthy",
                      category: "Active Subscriptions",
                      value: promoter?.KeyMetrics?.HealthySubscriptions || 0,
                    },
                    {
                      text: "failed",
                      category: "Failed Payment Subscriptions",
                      value:
                        promoter?.KeyMetrics?.FailedPaymentSubscriptions || 0,
                    },
                    {
                      text: "cancelled",
                      category: "Cancelled Subscriptions",
                      value: promoter?.KeyMetrics?.SubscriptionsCancelled || 0,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="metric-list-row row px-1">
              {/* <div className="col-lg-4 col-xl-3 col-md-12  d-flex align-items-stretch ">
                <div className="summary-card-payout w-100 mb-4">
                  <h5 className="summary-card-heading pb-2">Payouts Summary</h5>
                  <div className="summary-card-body row pl-0">
                    <div className="summary-items col-md-12">
                      <div className="d-flex justify-content-between payoutinfo align-items-center">
                        <div className="w-70 buz-font-500 ">
                          {" "}
                          <span>Pending</span>
                        </div>
                        <div className="w-30">
                          <span
                            className="payout-pending  cursor-pointer"
                            onClick={() =>
                              handlePayoutRedirection(
                                PromoPayoutDisplayStatusEnum["PENDING"]
                              )
                            }
                          >
                            {" "}
                            {CurrencyFormat(
                              promoter?.KeyMetrics?.PendingPayouts || 0
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between payoutinfo  align-items-center">
                        <div className="w-70 buz-font-500">
                          {" "}
                          <span> Paid</span>
                        </div>
                        <div className="w-30">
                          <span
                            className="payout-paid  cursor-pointer"
                            onClick={() =>
                              handlePayoutRedirection(
                                PromoPayoutDisplayStatusEnum["PAID"]
                              )
                            }
                          >
                            {CurrencyFormat(
                              promoter?.KeyMetrics?.PaidPayouts || 0
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between payoutinfo  align-items-center">
                        <div className="w-70 buz-font-500 ">
                          {" "}
                          <span>Overdue</span>
                        </div>
                        <div className="w-30">
                          <span
                            className="payout-overdue  cursor-pointer"
                            onClick={() =>
                              handlePayoutRedirection(
                                PromoPayoutDisplayStatusEnum["OVERDUE"]
                              )
                            }
                          >
                            {" "}
                            {CurrencyFormat(
                              promoter?.KeyMetrics?.OverduePayouts || 0
                            )}
                          </span>
                        </div>
                      </div>
                   
                      <div className="d-flex justify-content-between payoutinfo  align-items-center">
                        <div className="w-70 buz-font-500 ">
                          {" "}
                          <span>Rejected </span>
                        </div>
                        <div className="w-30">
                          <span
                            className="payout-recjected cursor-pointer"
                            onClick={() =>
                              handlePayoutRedirection(
                                PromoPayoutDisplayStatusEnum["REJECTED"]
                              )
                            }
                          >
                            {" "}
                            {CurrencyFormat(
                              promoter?.KeyMetrics?.RejectedPayouts || 0
                            )}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-lg-12  col-xl-12 toppromolinks  col-md-12  d-flex align-items-stretch">
                <div className="summary-card-payout w-100  mb-4 ">
                  <h5 className="affiliatemainheading pb-2">
                    <em className="mdi mdi-bullhorn-variant"></em> Top
                    Subscription Promo Links
                  </h5>
                  <div className="summary-card-body row pl-0">
                    <div className="summary-items col-md-12">
                      <TopPromotionLinksByAssociate
                        topPromoLinks={topPromoLinks}
                        {...props}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showProspectDialog && (
        <ProspectsDialog
          PromotionProspects={promoter?.KeyMetrics?.PromotionProspects}
          toggleProspectDialog={toggleProspectDialog}
          admin={props?.admin}
          affiliatePage={true}
          promotionPage={false}
        />
      )}
    </div>
  );
};

export default AffiliateOverview;

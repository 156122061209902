import { Button } from "@progress/kendo-react-buttons";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox, RadioGroup, Switch } from "@progress/kendo-react-inputs";
import { ListView } from "@progress/kendo-react-listview";
import { Popup } from "@progress/kendo-react-popup";
import BuzopsButton from "generic-components/BuzopsButton";
import React, { useState, useEffect } from "react";
import { checkHasSMSEnable, getFullDate, getTimeFormat } from "utils";
import { CommunicationPreferenceList, CommunicationPreferenceOnlyEmail, timeUnits, Typeradio } from "utils/form-utils";
import { Checkbox } from "@progress/kendo-react-inputs";
import { SelectedLocationType } from "components/class/ClassTypes";
import LocationDD from "components/class/LocationDD";
import { FaCheck } from "react-icons/fa";
import { notificationMessages } from "./UpdateSession";
import { Hint } from "@progress/kendo-react-labels";
import back_icon from "../../Images/back_icon.svg";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";

const EditFieldsSession = (props: any) => {
  const defaultAttributes = {
    "ScheduleMode": 0,
    "ScheduleModeOption": 0,
    "InviteePhoneNumber": "",
    "TraineePhoneNumber": "",
    "OtherAddress": ""
  }
  const [Attributes, setAttributes] = useState<SelectedLocationType>(props?.form?.Attributes || defaultAttributes);
  const handleUpdateLocation = (selectedLocation: SelectedLocationType) => {
    setAttributes(selectedLocation)
    props.handleForm(selectedLocation, 'Attributes')
  }

  useEffect(() => {
    setAttributes(props?.form?.Attributes)
  }, [props?.form?.Attributes])



  const CustomPopup = (props: any) => {
    return (
      <Popup
        {...props}
        anchorAlign={{
          horizontal: "center",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "center",
          vertical: "bottom",
        }}
      />
    );
  };

  const renderUnAvailableList = (records: any) => {
    let item = records.dataItem;

    return (
      <div className="col-12">
        <div className="row py-3 px-sm-2 align-middle bzc-sessions-list-card">
          <div className="col-12">
            <div className="buzopsListValidateItem m-0 text-capitalize ">
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-date-sec">
                    <i className="far fa-calendar-alt"></i>{" "}
                    {getFullDate(item?.StartDateTime)}
                  </div>
                  <div className="bzc-time-sec">
                    <span className="">
                      <i className="far fa-clock"></i>{" "}
                      {getTimeFormat(item?.StartDateTime)} -{" "}
                      {getTimeFormat(item?.EndDateTime)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className="buzopsListValidateItem m-0 mt-2"
              style={{ color: "#ff7a0e" }}
            >
              {item.Description}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderFailedList = (records: any) => {
    let item = records.dataItem;

    return (
      <div className="col-12">
        <div className="row py-3 px-sm-2 align-middle bzc-sessions-list-card">
          <div className="col-12">
            <div className="buzopsListValidateItem m-0 text-capitalize ">
              <div className="bzc-col-1">
                <div>
                  <div className="bzc-date-sec">
                    <i className="far fa-calendar-alt"></i>{" "}
                    {getFullDate(item?.PreviousStartDate)}
                  </div>
                  <div className="bzc-time-sec">
                    <span className="">
                      <i className="far fa-clock"></i>{" "}
                      {getTimeFormat(item?.PreviousStartDate)} -{" "}
                      {getTimeFormat(item?.PreviousEndDate)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div
              className="buzopsListValidateItem m-0 mt-2"
              style={{ color: "#ff7a0e" }}
            >
              {item.ErrorReason}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderUnAvailableListDialog = () => {
    return (
      <Dialog
        className="bz-mybookings-dialog-box bzc-classes-dialog-box bz-custom-content-height"
        title={"Conflicts For Update Sessions"}
        onClose={props.toggleAvailibilityDialog}
        width={440}
        height={400}
      >
        {/* <div className="row">
          <p>Class:{validateObject?.Name}</p>
          <p>Provider:{validateObject?.provider?.FullName}</p>
        </div> */}
        {props.unAvailableList.length === 0 ? (
          <div className={"bz-no-upcoming-appoinments text-muted"}>
            No Conflicts available for this selected Appointments{" "}
          </div>
        ) : (
          <ListView
            data={props.unAvailableList}
            item={(props) => renderUnAvailableList(props)}
            style={{
              width: "100%",
            }}
          />
        )}
        <DialogActionsBar>
          <Button onClick={props.toggleAvailibilityDialog}>{"Cancel"}</Button>
          <BuzopsButton
            loading={props.updateLodaing}
            disabled={props.updateLodaing}
            label={
              props.unAvailableList.length === 0
                ? "Continue"
                : "Continue Anyway"
            }
            onClick={props.handleEditPage}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };
  const renderFailedListDialog = () => {
    return (
      <Dialog
        className="bz-mybookings-dialog-box bzc-classes-dialog-box bz-custom-content-height"
        title={"Failed Sessions to Update"}
        onClose={props.handleCloseFailedDialog}
        width={440}
        height={400}
      >
        <ListView
          data={props.failedList}
          item={(props) => renderFailedList(props)}
          style={{
            width: "100%",
          }}
        />
        <DialogActionsBar>
          <Button onClick={props.handleCloseFailedDialog}>{"Close"}</Button>
        </DialogActionsBar>
      </Dialog>
    );
  };
  const steps = {
    minute: 15,
  };

  const childComponentForCheckbox = (id: string) => {
    const criteria = props.checked[id];
    if (criteria) {
      return (<label className={`bz-cursor-pointer checkmark text-success mb-0`} style={{ width: "18px" }} htmlFor={id}><FaCheck /></label>)
    } else {
      return <label className="checkmark-no" htmlFor={id} style={{ width: "18px" }}></label>
    }
  }
  return (
    <div
      className={
        "edit-session-body bzc-edit-session-body-new bz-edit-appoinments"
      }
    >
      {props.selectedSessions.length === 0 && props.editType === "bulk" ? (
        <Button
          primary={true}
          onClick={() => props.handleSessionTable("choose")}
          className="ml-0"
        >
          Choose Appointments
          <span className={"k-icon k-i-arrow-chevron-right"}></span>
        </Button>
      ) : (
        <>
          {props.editType === "bulk" && (
            <>
              <span
                className="d-inline-block"
                onClick={() => props.handleAppointmentsPage(true)}
                title="Back to Manage Selected Appointments"
              >
                <img src={back_icon} alt="" className="back-icon" />
              </span>
            </>
          )}
          <p className={"selected-data bzc-notice mt-3"}>
            {props.selectedItemText}
          </p>
        </>
      )}
      <p className={"session-header mt-3"}> What do you want to change?</p>
      <div className="bzc-edit-session-options-row-new mb-3 bzc-row">
        <div className="column-2">
          <Checkbox
            name="ClassType"
            value={props.checked.ClassType}
            onChange={(e) => props.handleChange(e, "ClassType", "ClassType")}
            label={"Type"}
            dir="rtl"
            id={"ClassType"}
            children={childComponentForCheckbox("ClassType")}
          />
        </div>
        <div className="column-2">
          <RadioGroup
            name={"Type"}
            data={Typeradio}
            value={props.form.ClassType}
            onChange={(e) => props.handleForm(e.value, "ClassType")}
          />
          <div>
            {props.form.ClassType === 3 && (
              <>
                <Input
                  autoComplete={"off"}
                  id={"NonPaidLabel"}
                  style={{ width: "75%" }}
                  placeholder={"Enrollment Label"}
                  name={"NonPaidLabel"}
                  maxLength={12}
                  onChange={(e) =>
                    props.handleForm(e.target.value, "NonPaidLabel")
                  }
                  value={props.form.NonPaidLabel}
                />
                <Hint>{`Enrollment label for customers (e.g., Free, Non-Paid, etc.)`}</Hint>
              </>
            )}
          </div>
        </div>
        <div className="column-2">
          <Checkbox
            name="startTime"
            value={props.checked.startTime}
            onChange={(e) => props.handleChange(e, "startTime", "startTime")}
            label={"Start Time"}
            dir="rtl"
            id={"startTime"}
            children={childComponentForCheckbox("startTime")}
          />
        </div>
        <div className="column-2">
          <TimePicker
            name="startTime"
            popup={CustomPopup}
            // disabled={!props.checked.startTime}
            placeholder="Start Time"
            formatPlaceholder="short"
            steps={steps}
            value={props.form.startTime}
            onChange={(e) => props.handleForm(e.value, "startTime")}
          />
        </div>
        <div className="column-2">
          <Checkbox
            name="duration"
            value={props.checked.duration}
            onChange={(e) =>
              props.handleChange(e, "duration", "minutes.DurationType")
            }
            label={"Duration"}
            dir="rtl"
            id={"duration"}
            children={childComponentForCheckbox("duration")}
          />
        </div>
        <div className="column-2">
          <div className="d-flex align-items-center">
            <div className="mr-md-3">
              {/* <label className="k-label">Reminder Duration</label> */}
              <div className="input-group bzc-input-group">
                <NumericTextBox
                  name="minutes"
                  min={0}
                  // disabled={!props.checked.duration}
                  placeholder={"Minutes"}
                  max={240}
                  value={props.form.minutes}
                  onChange={(e) => props.handleForm(e.target.value, "minutes")}
                />
                <div className="input-group-append">
                  <DropDownList
                    id={"DurationType"}
                    name={"DurationType"}
                    data={timeUnits}
                    textField="text"
                    dataItemKey="id"
                    onChange={(e) =>
                      props.handleForm(e.target.value, "DurationType")
                    }
                    value={props.form.DurationType}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="column-2">
          <Checkbox
            name="instructor"
            value={props.checked.instructor}
            onChange={(e) => props.handleChange(e, "instructor", "instructor")}
            label={"Provider"}
            dir="rtl"
            id={"instructor"}
            children={childComponentForCheckbox("instructor")}
          />
        </div>
        <div className="column-2">
          <DropDownList
            data={props?.providersList}
            value={props.form.instructor}
            // disabled={!props.checked.instructor}
            textField="FullName"
            dataItemKey="UserId"
            onChange={(e) => props.handleForm(e.value, "instructor")}
            defaultItem={{ FullName: "Select Provider", UserId: -1 }}
          />
        </div>
        <div className="column-2">
          <Checkbox
            name="Location"
            value={props.checked.Location}
            onChange={(e) => props.handleChange(e, "Location", "Attributes")}
            label={"Location"}
            dir="rtl"
            id={"Location"}
            children={childComponentForCheckbox("Location")}
          />
        </div>
        <div className="column-2">
          <LocationDD
            Attributes={Attributes}
            label={false}
            // disabled={!props.checked.Location}
            handleUpdateLocation={(selectedLocation: SelectedLocationType) =>
              handleUpdateLocation(selectedLocation)
            }
          />
        </div>
        <div className="column-2">
          <Checkbox
            name="slots"
            value={props.checked.slots}
            onChange={(e) => props.handleChange(e, "slots", "slots")}
            label={"Maximum Attendees"}
            dir="rtl"
            id={"slots"}
            children={childComponentForCheckbox("slots")}
          />
        </div>
        <div className="column-2">
          <NumericTextBox
            name="slots"
            min={1}
            // disabled={!props.checked.slots}
            max={150}
            placeholder={"Maximum Attendees"}
            value={props.form.slots}
            onChange={(e) => props.handleForm(e.target.value, "slots")}
          />
        </div>
        <div className="column-2">
          <Checkbox
            name="Waitlist"
            value={props.checked.WaitList}
            onChange={(e) => props.handleChange(e, "WaitList", "WaitList")}
            label={"Waitlist Limit"}
            dir="rtl"
            id={"WaitList"}
            children={childComponentForCheckbox("WaitList")}
          />
        </div>
        <div className="column-2">
          <NumericTextBox
            name="Waitlist"
            min={0}
            max={150}
            placeholder={"Waitlist"}
            value={props.form.WaitList}
            onChange={(e) => props.handleForm(e.target.value, "WaitList")}
          />
          <Hint>{`Set max attendees on group waitlist`}</Hint>
        </div>
        <div className="column-2">
          <Checkbox
            name="price"
            value={props.checked.price}
            onChange={(e) => props.handleChange(e, "price", "price")}
            label={"Price"}
            dir="rtl"
            id={"price"}
            children={childComponentForCheckbox("price")}
          />
        </div>
        <div className="column-2">
          <NumericTextBox
            name="price"
            disabled={props.form.ClassType === 3}
            // disabled={!props.checked.price || props.form.ClassType === 3}
            min={0}
            format="c2"
            // key={`props.form.price-${props.form.price}`}
            placeholder={"Price"}
            value={props.form.price}
            onChange={(e) => props.handleForm(e.target.value, "price")}
          />
        </div>
        <div className="column-2">
          <Checkbox
            name="CancellationFee"
            value={props.checked.CancellationFee}
            onChange={(e) =>
              props.handleChange(e, "CancellationFee", "CancellationFee")
            }
            label={"Cancellation fee"}
            dir="rtl"
            id={"CancellationFee"}
            children={childComponentForCheckbox("CancellationFee")}
          />
        </div>
        <div className="column-2">
          <NumericTextBox
            name="CancellationFee"
            min={0}
            format="c2"
            disabled={props.form.ClassType === 3}
            // disabled={!props.checked.CancellationFee || props.form.ClassType === 3}
            placeholder={"Cancellation Fee"}
            value={props.form.CancellationFee}
            onChange={(e) =>
              props.handleForm(e.target.value, "CancellationFee")
            }
          />
        </div>
      </div>
      <h6 className="bzc-edit-session-options-row-header bzc-row bz-fw-6 my-4"></h6>
      <div className="bzc-edit-session-options-row bzc-payout-toggle-form mb-3 bzc-row">
        <div className="column-2 d-flex">
          <Checkbox
            className="d-flex"
            name="AutoCancelWaitListDuration"
            value={props.checked.AutoCancelWaitListDuration}
            onChange={(e) =>
              props.handleChange(
                e,
                "AutoCancelWaitListDuration",
                "CanAutoCancelWaitList.AutoCancelWaitListDuration.AutoCancelWaitListDurationType"
              )
            }
            label={<div className="d-flex" style={{ width: '100%' }}><BuzopsTooltip
              toolTipClass="phone-tooltip"
              position="top"
              html={
                <span
                  className="pl-1 bz-cursor-pointer"
                  title={`Auto cancel waitlisted entries before group starts when toggled on. Or, manually approve/deny entries when toggled off.`}
                >
                  <i
                    style={{
                      fontSize: "16px",
                      verticalAlign: "middle",
                    }}
                    className="fa fa-info-circle"
                  ></i>
                </span>
              }
            /><div className="" >Waitlist Auto-Cancel Setting</div></div>}
            dir="rtl"
            id={"AutoCancelWaitListDuration"}
            children={childComponentForCheckbox("AutoCancelWaitListDuration")}
          />
          <span className="section-notification">
            <span
              title={
                props.form.CanAutoCancelWaitList
                  ? notificationMessages.notificationEnabled
                  : notificationMessages.notificationDisabled
              }
            >
              <Switch
                // disabled={!props.checked.RemainderDuration}
                checked={props.form.CanAutoCancelWaitList}
                name={"CanAutoCancelWaitList"}
                onChange={(e) => {
                  props.handleForm(e.value, "CanAutoCancelWaitList");
                }}
              />
            </span>
          </span>
        </div>

        <div className="column-2">
          <div className="d-flex align-items-center">
            <div className="mr-md-3">
              {/* <label className="k-label">Reminder Duration</label> */}
              <div className="input-group bzc-input-group">
                <NumericTextBox
                  id={"AutoCancelWaitListDuration"}
                  name={"AutoCancelWaitListDuration"}
                  min={props.form.CanAutoCancelWaitList ? 1 : 0}
                  disabled={!props.form.CanAutoCancelWaitList}
                  onChange={(e) =>
                    props.handleForm(
                      e.target.value,
                      "AutoCancelWaitListDuration"
                    )
                  }
                  value={props.form.AutoCancelWaitListDuration}
                />
                <div className="input-group-append">
                  <DropDownList
                    id={"AutoCancelWaitListDurationType"}
                    name={"AutoCancelWaitListDurationType"}
                    data={timeUnits}
                    textField="text"
                    dataItemKey="id"
                    disabled={!props.form.CanAutoCancelWaitList}
                    onChange={(e) =>
                      props.handleForm(
                        e.target.value,
                        "AutoCancelWaitListDurationType"
                      )
                    }
                    value={props.form.AutoCancelWaitListDurationType || null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bzc-edit-session-options-row bzc-payout-toggle-form mb-3 bzc-row">
        <div className="column-2 d-flex">
          <Checkbox
            name="MinimumBookingDuration"
            value={props.checked.MinimumBookingDuration}
            onChange={(e) =>
              props.handleChange(
                e,
                "MinimumBookingDuration",
                "appointmentBookNotification.MinimumBookingDuration.MinimumBookingDurationType"
              )
            }
            label={"An appointment can be booked before"}
            dir="rtl"
            id={"MinimumBookingDuration"}
            children={childComponentForCheckbox("MinimumBookingDuration")}
          />
          <span className="section-notification ">
            <span
              title={
                props.form.appointmentBookNotification
                  ? notificationMessages.notificationEnabled
                  : notificationMessages.notificationDisabled
              }
            >
              <Switch
                //  disabled={!props.checked.MinimumBookingDuration}
                checked={props.form.appointmentBookNotification}
                name={"appointmentBookNotification"}
                onChange={(e) => {
                  props.handleForm(e.value, "appointmentBookNotification");
                }}
              />
            </span>
          </span>
        </div>
        <div className="column-2">
          <div className="d-flex align-items-center">
            <div className="mr-md-3">
              {/* <label className="k-label">Reminder Duration</label> */}
              <div className="input-group bzc-input-group">
                <NumericTextBox
                  id={"MinimumBookingDuration"}
                  name={"MinimumBookingDuration"}
                  min={0}
                  onChange={(e) =>
                    props.handleForm(e.target.value, "MinimumBookingDuration")
                  }
                  // disabled={!props.checked.MinimumBookingDuration || !props.form.appointmentBookNotification}
                  disabled={!props.form.appointmentBookNotification}
                  value={props.form.MinimumBookingDuration}
                />
                <div className="input-group-append">
                  <DropDownList
                    id={"MinimumBookingDurationType"}
                    name={"MinimumBookingDurationType"}
                    data={timeUnits}
                    textField="text"
                    dataItemKey="id"
                    onChange={(e) =>
                      props.handleForm(
                        e.target.value,
                        "MinimumBookingDurationType"
                      )
                    }
                    // disabled={!props.checked.MinimumBookingDuration || !props.form.appointmentBookNotification}
                    disabled={!props.form.appointmentBookNotification}
                    value={props.form.MinimumBookingDurationType || null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bzc-edit-session-options-row bzc-payout-toggle-form mb-3 bzc-row">
        <div className="column-2 d-flex">
          <Checkbox
            name="MinimumCancellationDuration"
            value={props.checked.MinimumCancellationDuration}
            onChange={(e) =>
              props.handleChange(
                e,
                "MinimumCancellationDuration",
                "appointmentCancelNotification.MinimumCancellationDuration.MinimumCancellationDurationType"
              )
            }
            label={"An appointment can be cancelled before"}
            dir="rtl"
            id={"MinimumCancellationDuration"}
            children={childComponentForCheckbox("MinimumCancellationDuration")}
          />
          <span className="section-notification ">
            <span
              title={
                props.form.appointmentCancelNotification
                  ? notificationMessages.notificationEnabled
                  : notificationMessages.notificationDisabled
              }
            >
              <Switch
                // disabled={!props.checked.MinimumCancellationDuration}
                checked={props.form.appointmentCancelNotification}
                name={"appointmentCancelNotification"}
                onChange={(e) => {
                  props.handleForm(e.value, "appointmentCancelNotification");
                }}
              />
            </span>
          </span>
        </div>
        <div className="column-2">
          <div className="d-flex align-items-center">
            <div className="mr-md-3">
              {/* <label className="k-label">Reminder Duration</label> */}
              <div className="input-group bzc-input-group">
                <NumericTextBox
                  id={"MinimumCancellationDuration"}
                  name={"MinimumCancellationDuration"}
                  min={0}
                  onChange={(e) =>
                    props.handleForm(
                      e.target.value,
                      "MinimumCancellationDuration"
                    )
                  }
                  // disabled={!props.checked.MinimumCancellationDuration || !props.form.appointmentCancelNotification}
                  disabled={!props.form.appointmentCancelNotification}
                  value={props.form.MinimumCancellationDuration}
                />
                <div className="input-group-append">
                  <DropDownList
                    id={"MinimumCancellationDurationType"}
                    name={"MinimumCancellationDurationType"}
                    data={timeUnits}
                    textField="text"
                    dataItemKey="id"
                    onChange={(e) =>
                      props.handleForm(
                        e.target.value,
                        "MinimumCancellationDurationType"
                      )
                    }
                    disabled={!props.form.appointmentCancelNotification}
                    // disabled={!props.checked.MinimumCancellationDuration || !props.form.appointmentCancelNotification}
                    value={props.form.MinimumCancellationDurationType || null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h6 className="bzc-edit-session-options-row-header bzc-row bz-fw-6 my-4">
        {"Notifications"}
      </h6>
      <div className="bzc-edit-session-options-row bzc-payout-toggle-form mb-4 bzc-row">
        <div className="column-2 d-flex">
          <Checkbox
            name="CommunicationPreference"
            value={props.checked.CommunicationPreference}
            onChange={(e) =>
              props.handleChange(
                e,
                "CommunicationPreference",
                "CommunicationPreference"
              )
            }
            label={"Communication Preference"}
            dir="rtl"
            id={"CommunicationPreference"}
            children={childComponentForCheckbox("CommunicationPreference")}
          />
        </div>
        <div className="column-2">
          <div className="d-flex align-items-center">
            <div className="mr-md-3">
              {/* <label className="k-label">Reminder Duration</label> */}
              <DropDownList
                id={"CommunicationPreference"}
                name={"CommunicationPreference"}
                data={checkHasSMSEnable() ? CommunicationPreferenceList : CommunicationPreferenceOnlyEmail}
                textField="text"
                dataItemKey="id"
                onChange={(e) =>
                  props.handleForm(e.target.value, "CommunicationPreference")
                }
                value={props.form.CommunicationPreference || null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bzc-edit-session-options-row bzc-payout-toggle-form mb-4 bzc-row">
        <div className="column-2 d-flex">
          <Checkbox
            name="RemainderDuration"
            value={props.checked.RemainderDuration}
            onChange={(e) =>
              props.handleChange(
                e,
                "RemainderDuration",
                "emailNotification.RemainderDuration.RemainderType"
              )
            }
            label={"Reminder to an appointment before"}
            dir="rtl"
            id={"RemainderDuration"}
            children={childComponentForCheckbox("RemainderDuration")}
          />
          <span className="section-notification">
            <span
              title={
                props.form.emailNotification
                  ? notificationMessages.notificationEnabled
                  : notificationMessages.notificationDisabled
              }
            >
              <Switch
                // disabled={!props.checked.RemainderDuration}
                checked={props.form.emailNotification}
                name={"emailNotification"}
                onChange={(e) => {
                  props.handleForm(e.value, "emailNotification");
                }}
              />
            </span>
          </span>
        </div>
        <div className="column-2">
          <div className="d-flex align-items-center">
            <div className="mr-md-3">
              {/* <label className="k-label">Reminder Duration</label> */}
              <div className="input-group bzc-input-group">
                <NumericTextBox
                  id={"RemainderDuration"}
                  name={"RemainderDuration"}
                  min={0}
                  disabled={!props.form.emailNotification}
                  // disabled={!props.checked.RemainderDuration || !props.form.emailNotification}
                  onChange={(e) =>
                    props.handleForm(e.target.value, "RemainderDuration")
                  }
                  value={props.form.RemainderDuration}
                />
                <div className="input-group-append">
                  <DropDownList
                    id={"RemainderType"}
                    name={"RemainderType"}
                    data={timeUnits}
                    textField="text"
                    dataItemKey="id"
                    disabled={!props.form.emailNotification}
                    // disabled={!props.checked.RemainderDuration || !props.form.emailNotification}
                    onChange={(e) =>
                      props.handleForm(e.target.value, "RemainderType")
                    }
                    value={props.form.RemainderType || null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3  bzc-row">
        <hr />
        <div className="d-flex">
          <Checkbox
            style={{ display: "inline-flex" }}
            name="client"
            value={props.checked.client}
            onChange={(e) => props.handleChange(e, "client")}
            label={"Send Update Notifications to Client"}
          />
        </div>
        <div className="d-flex float-right">
          <Button
            onClick={() =>
              props?.editType === "single"
                ? props?.handleWindow()
                : props?.handleAppointmentsPage(true)
            }
            className="ml-0"
          >
            Cancel
          </Button>
          <BuzopsButton
            onClick={() => props.handleContinue()}
            disabled={props.buttonLoading || props.updateLodaing}
            loading={props.buttonLoading || props.updateLodaing}
            label={"Update"}
          />
        </div>
      </div>
      {props.unAvailableListDialog && renderUnAvailableListDialog()}
      {props.failedListDialog && renderFailedListDialog()}
    </div>
  );
};

export default EditFieldsSession;

import React, { useEffect, useState } from "react";
import { ClassService } from "services/class/index.service";
import BuzopsLoader from "generic-components/BuzopsLoader";
import BuzopsButton from "generic-components/BuzopsButton";
import { useDownloadFile } from "components/customHooks/useDownloadFile";

interface AgreementViewProps {
  Id: number;
  Name: string;
  Type: string;
}
const AgreementView = (props: AgreementViewProps) => {
  const [loading, setLoading] = useState(true);
  const [btnloading, setBtnLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [agreementDetails, setAgreementDetails] = useState<any>({});
  const apiService = new ClassService();

  useEffect(() => {
    if (props?.Id) {
      fetchAgreementDetails();
    }
  }, []);

  const exportPDFWithComponent = async () => {

    return  await apiService.getAgreementDetailsForDownload(
      props?.Name,
      props?.Id
    );
  };

  const onErrorDownloadFile = () => {
    setBtnLoading(false);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const getFileName = () => {
    return props?.Name + ".pdf";
  };

  const preDownloading = () => setBtnLoading(true);
  const postDownloading = () => setBtnLoading(false);

  const { ref, url, download, name } = useDownloadFile({
    apiDefinition: exportPDFWithComponent,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });


  const fetchAgreementDetails = async () => {
    setLoading(true);
    const res = await apiService.getAgreementDetails(props.Id);
    setAgreementDetails(res?.Item);
    setLoading(false);
  };
  if (loading) {
    return <BuzopsLoader type={"list"} />;
  }
  if (!agreementDetails?.AgreementContent) return null;
  return (
    <>
      <div className="float-right my-2">
        <a href={url} download={name} className="hidden" ref={ref} />
        <BuzopsButton label={"Download"} onClick={download} loading={btnloading} />
      </div>
      <div
        className="agreement-view"
        id={"agreement-view"}
        dangerouslySetInnerHTML={{
          __html: agreementDetails.AgreementContent,
        }}
      />
    </>
  );
};
export default AgreementView;

import { CommonServiceUtils } from "services/common-service-utils";
import { GetLocalStore, LocalStore, RemoveAllLocalStore } from "utils/storage";
import TimeZoneSetup from "utils/time-zone-setup";

export class AccessControlService extends CommonServiceUtils {

  async getTenantDetails(tenant:string): Promise<any> {
    // eslint-disable-next-line no-new-object
    const tenantDetails: any = new Object();
    const getToken: any = await this.postData(
      `${this.apiUrlCreator(`authentication/issuetoken/${tenant}`)}`
    );
    if (tenant !== GetLocalStore("tenant")) {
      RemoveAllLocalStore()
    } 
    tenantDetails.tokenDetails = getToken;
    if (getToken?.ResponseCode === 100) {
      LocalStore("tenant", tenant);
      LocalStore("token", getToken.Item.Token);
      LocalStore("tenantUserSession", {...getToken?.Item?.UserSession, DomainName: getToken.Item.DomainName, StripePaymentConfiguration: getToken.Item.StripePaymentConfiguration, ChargehubSquarePaymentProfile: getToken.Item.ChargehubSquarePaymentProfile, 
      Configuration: getToken.Item.Configuration});
      LocalStore("CurrentApplicationType", getToken?.Item?.UserSession?.CurrentApplicationType);
      LocalStore("tenantLogo", getToken.Item?.UserSession?.Logo);
      TimeZoneSetup(getToken?.Item?.UserSession?.TenantTime,getToken?.Item.UserSession)
      LocalStore("Configuration", {...getToken.Item.Configuration,IsAffiliateFeatureEnabled:getToken.Item.IsAffiliateFeatureEnabled});
      return getToken;
    }else{
      return getToken;
    }
  }

  async getAccessPointForSite(siteId: number): Promise<any> {
    const req=JSON.stringify({
      siteId:siteId
    });
    const getAccessPointForSite = await this.postData(
      `${this.apiAccessControlBackendCreator(`getAccessPointForSite`)}`,req
    );
    return getAccessPointForSite;
  }
  async getAccessPointInfo(accessPointId: number): Promise<any> {
    const req=JSON.stringify({
      accessPointId:accessPointId
    });
    const getAccessPointInfo = await this.postData(
      `${this.apiAccessControlBackendCreator(`getAccessPointInfo`)}`,req
    );
    return getAccessPointInfo;
  }

  async getScheduleInfo(scheduleId: number): Promise<any> {
    const req=JSON.stringify({
      scheduleId
    })
    const getScheduleInfo: any = await this.postData(
      `${this.apiAccessControlBackendCreator(`retreiveSchedule`)}`,req
    );
    return getScheduleInfo;
  }
  async getHolidaysList(): Promise<any> {
    const token=GetLocalStore('adminAccessToken');
    const req=JSON.stringify({
      token
    })
    const getHolidaysList: any = await this.postData(
      `${this.apiAccessControlBackendCreator(`getHolidays`)}`,req
    );
    return getHolidaysList;
  }
  
  async fetchEventSubscription(): Promise<any> {
    let req={};
    const getEventSubscription: any = await this.postData(
      `${this.apiAccessControlBackendCreator(`eventSubscription`)}`,req
    );
    return getEventSubscription;
  }

  async deleteEventSubscription(req:any): Promise<any> {
    const getEventSubscription: any = await this.postData(
      `${this.apiAccessControlBackendCreator(`deleteEventSubscription`)}`,req
    );
    return getEventSubscription;
  }

  async getAccessEventListing(req:any): Promise<any> {
    const getAccessPointInfo = await this.postData(
      `${this.apiAccessControlBackendCreator(`accessEventListing`)}`,req
    );
    return getAccessPointInfo;
  }
  async getAccessEventListingCount(req:any): Promise<any> {
    const getAccessEventListingCount = await this.postData(
      `${this.apiAccessControlBackendCreator(`getAccessEventListingCount`)}`,req
    );
    return getAccessEventListingCount;
  }
  async unLockDoor(doorId:number): Promise<any> {
    const req=JSON.stringify({
      id:doorId
    });
    const unLockDoor = await this.postData(
      `${this.apiAccessControlBackendCreator(`unLockDoor`)}`,req
    );
    return unLockDoor;
  }

  async updateSchedule(req:any): Promise<any> {
    const updateSchedule: any = await this.postData(
      `${this.apiAccessControlBackendCreator(`updateSchedule`)}`,req
    );
    return updateSchedule;
  }

  async getClientSubscriptions(actorId: number): Promise<any> {
    let response = {};
    const getClientSubscriptions:any = await this.getData(
      `${this.apiUrlCreator(`client/Subscriptions?externalId=${actorId}`)}`
    );
    if (getClientSubscriptions?.ResponseCode === 100) {
      response = getClientSubscriptions.Item;
    }
    return response;
  }
  async getClientSubscriptionsByMemberId(memberId: number): Promise<any> {
    let response = null;
    const getClientSubscriptions:any = await this.getData(
      `${this.apiUrlCreator(`client/SubscriptionsByMemberId?memberId=${memberId}`)}&forCheckinRestrictions=true`
    );
    if (getClientSubscriptions?.ResponseCode === 100) {
      response = getClientSubscriptions.Item;
    }
    return response;
  }
}

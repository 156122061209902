import { RewardTypeList, SecondTierCommissionType } from "utils/form-utils";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { ListView } from "@progress/kendo-react-listview";
import { CurrencyFormat } from "utils";
import useAffiliatePromotions from "./AffiliatePromotions.hook";
import { FaEye } from "react-icons/fa";
import withNotification from "components/Hoc/withNotification";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { MetricsToolTip, OfferTooltip } from "components/AffiliateProgram/Promotions/Promotions.view";
import { Pager } from "@progress/kendo-react-data-tools";

const AffiliatePromotions = (props: any) => {
  const { handleView, rewardTypes, loading, promotions, page,
    handlePageChange,
    totalItems } =
    useAffiliatePromotions(props);
  const { skip, take } = page;

  const NewMyItemRender = (props: any) => {
    let item = props.dataItem;

    return (
      <>
        <div className="">
          <div className="p-3 align-middle buz-online-list-view pe-sm-0 promotions-list-view">
            <div className="section-bg">
              <div className="section-1 align-items-center">
                <h6>{item?.Name}</h6>
                <div className="payout-settings">
                  {/* <div>
                  Payment terms:
                  <span className="buz-font-600">{`Net-${item?.PaymentTermDays} Days`}</span>
                </div> */}
                  {/* <div>
                    <span>
                      <FaEye color="#008db9" onClick={() => handleView(item)} />
                    </span>
                  </div> */}
                  <BuzopsTooltip
                    html={
                      <div title="View">
                        <FaEye color="#008db9" onClick={() => handleView(item)} style={{ cursor: 'pointer' }} />
                      </div>
                    }

                  />
                </div>
              </div>
            </div>
            <div className="section-2">
              <div className="count-items">
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      <BuzopsTooltip
                        html={
                          <a
                            title="Net Revenue from Purchases"
                            style={{
                              borderBottom: "1px dotted #d9d9d9",
                              cursor: "pointer",
                            }}
                          >
                            {CurrencyFormat(
                              item?.KeyMetrics?.NetRevenueFromPurchases || 0
                            )}
                          </a>
                        }
                        content={
                          <MetricsToolTip KeyMetrics={item?.KeyMetrics} />
                        }
                        position={"right"}
                      />
                    </span>
                  </div>
                  <div className="heading-item">Revenue</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      {item?.KeyMetrics?.LeadsGenerated || 0}
                    </span>
                  </div>
                  <div className="heading-item">Prospects</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      {item?.KeyMetrics?.SubscriptionsRedeemed || 0}
                    </span>
                  </div>
                  <div className="heading-item">Subscriptions Redeemed</div>
                </div>
                <div className="child-count-item">
                  <div className="value-item">
                    <span className="">
                      {item?.KeyMetrics?.SubscriptionsCancelled || 0}
                    </span>
                  </div>
                  <div className="heading-item">Subscriptions Cancelled</div>
                </div>
              </div>
              <div className="rewards-section">
                <>
                  {item?.PromotionRewards?.map((reward: any) => {
                    const TypeValue = RewardTypeList?.filter(
                      (i: any) => i?.id === reward?.RewardCreditTypeId
                    )[0]?.text;

                    const RewardTypeValue = rewardTypes?.filter(
                      (i: any) => i?.RewardTypeId === reward?.RewardType
                    )[0]?.RewardTypeName;

                    const commissionSource = SecondTierCommissionType?.filter(
                      (i: any) => i?.id === reward?.CommissionSource
                    )[0]?.text;

                    let descriptionValue = "";
                    if (reward?.RewardCreditTypeId === 1) {
                      descriptionValue =
                        reward?.CommissionType === 1
                          ? `${CurrencyFormat(
                            reward?.RewardAmount
                          )} ${RewardTypeValue}`
                          : `${reward?.RewardAmount}%  ${RewardTypeValue}`;
                    } else if (reward?.RewardCreditTypeId === 2) {
                      descriptionValue = `${CurrencyFormat(
                        reward?.RewardAmount
                      )} ${RewardTypeValue}`;
                    } else if (reward?.RewardCreditTypeId === 3) {
                      descriptionValue = `${reward?.RewardAmount}%  ${commissionSource}`;
                    }
                    return (
                      <>
                        <div className="d-flex justify-content-between mobile">
                          <div className="d-flex mobilesub" style={{ width: '100%' }}>

                            <div className="d-flex mainrewardspayout">
                              <span className="rewardspayout">
                                {TypeValue}
                              </span>
                              <BuzopsTooltip
                                html={
                                  <span className="buz-font-400" style={{
                                    borderBottom: "1px dotted #d9d9d9",
                                    cursor: "pointer",
                                  }} title={`Reward Name :  ${reward?.RewardName}`}>
                                    <span className="rewardsclon">:</span> {descriptionValue}
                                  </span>
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  {item?.PromotionType !==2 && <div className="d-flex justify-content-between mobile">
                    <div className="d-flex mobilesub" style={{ width: '100%' }}>
                      <div className="d-flex mainrewardspayout">
                        <span className="rewardspayout">{"Offer"}</span>
                        <BuzopsTooltip
                          html={
                            <span title="Offer" style={{
                              borderBottom: "1px dotted #d9d9d9",
                              cursor: "pointer",
                            }} className="buz-font-400"> <span className="rewardsclon">:</span>  {item.CouponName}</span>
                          }
                          content={
                            <OfferTooltip data={item?.Offer} />
                          }
                          position={"left"}
                        />
                      </div>
                    </div>
                  </div>}
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="d-flex justify-content-center flex-column manage-affiliate-container-grid payout">
      <div className="bz_fs_1_5 d-flex justify-content-between py-2 px-3">
        <h6 className="bz_fs_1_5 affiliatemainheading mb-0">My Promotions</h6>


      </div>
      <div className="px-3 mb-2"> <p className="desaffiliates text-muted">

        Enhance your campaign's visibility with our 'Promotion' feature, designed to boost engagement and attract a wider audience</p></div>
      <div className="container-fluid">
        {loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            {promotions?.length === 0 ? (
              <>
                <div className="bz-norecords-available">
                  {"No Promotions Available"}
                </div>
              </>
            ) : (
              <div className="mt-2">
                <ListView
                  data={promotions || []}
                  item={NewMyItemRender}
                  style={{
                    width: "100%",
                  }}
                  className="bz-promotions-list-view affliate"
                />
                <Pager
                  className="k-listview-pager-chargeback"
                  skip={skip}
                  responsive={true}
                  // size={'small'}

                  take={take}
                  onPageChange={handlePageChange}
                  total={totalItems}
                  pageSizes={[5, 10, 20, 50]}

                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withNotification(AffiliatePromotions);

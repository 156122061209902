import React from 'react';

const PageExpireCounter = (props: any) => {
    const timeOutIntervals = props.timeOutIntervals;
    const [counter, setCounter] = React.useState(timeOutIntervals/1000);

    // Third Attempts
    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    return (
        <div >
            <div>{counter}</div>
        </div>
    );
}

export default PageExpireCounter;
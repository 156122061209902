import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import useCheckinsReportHook from "../Container/CheckinsReport.hook";
import {
  AutoComplete,
  DropDownList,
  ListItemProps,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";

import CheckinsConstants from "components/CheckIns/contants";
import CustomCalendar from "atoms/CustomCalendar";
import BuzopsLoader from "generic-components/BuzopsLoader";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import React from "react";
import { DATA_ITEM_KEY } from "components/ManageCustomFields/MangeCustomFields.hook";
import { MdFilterAlt, MdOutlineFilterAlt } from "react-icons/md";
import moment from "moment";
import { GetLocalStore } from "utils/storage";


const itemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const itemChildren = `${itemProps?.dataItem?.FullName} ${itemProps?.dataItem?.Email}`;
  return React.cloneElement(li, li.props, itemChildren);
};
const CheckinsReport = (props: any) => {
  const checkinsReport = useCheckinsReportHook(props);  
  return (
    <>
      <div className="bz-checkin-logs site-checkin-livestream">
        <h6 className="mb-1 bz-fw-600">
          <button
            className="btn btn-primary bz-back-btn btn-sm"
            onClick={() => props?.onWindowClose()}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          &nbsp;{"Check-In Logs"}
        </h6>
        <div className="bz-checkins-date-filter bz-logs-filter mt-4 mb-3">
          <div className="bz-row no-gutters w-100">
            <div className="filter-options left col-md-9">
              <DropDownList
                defaultValue={checkinsReport.selectedCheckInTypeOption}
                textField="text"
                dataItemKey="id"
                data={CheckinsConstants.CheckinTypeOptions}
                value={checkinsReport.selectedCheckInTypeOption}
                onChange={(e) =>
                  checkinsReport.setSelectedCheckInTypeOption(e.target.value)
                }
              />
              <AutoComplete
                data={checkinsReport.clientsList}
                style={{ width: "280px" }}
                className="select_clientdrop"
                itemRender={itemRender}
                value={
                  checkinsReport.selectedClient?.type === "search"
                    ? checkinsReport.selectedClient?.value
                    : checkinsReport.selectedClient?.value?.FullName
                }
                onChange={(e: any) => checkinsReport.handleClientSelecton(e)}
                placeholder={"Search Client"}
                loading={checkinsReport.clientSearchLoading}
                listNoDataRender={() => (
                  <span className={"bzo-noData-found"}>
                    {checkinsReport.clientSearchLoading
                      ? "Searching for Client..."
                      : "Client is not available please add new client"}
                  </span>
                )}
              />
              <CustomCalendar
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                key={"no change"}
                setFromDateToDate={checkinsReport.setFromDateToDate}
                selectedDateItem={checkinsReport.dateFilter}
                // setDateFilter={setDateFilter}
                onlyPastDates
              />
            </div>
            <div className="filter-options right col-md-3 justify-content-end">
              <div className="export-btn-container d-flex align-items-center justify-content-end">
                <span
                  onClick={checkinsReport.filterRequired}
                  className="download-icon"
                  title={
                    checkinsReport.disable
                      ? "Hide additional filter"
                      : "Show additional filter"
                  }
                >{ checkinsReport.disable ? (<MdOutlineFilterAlt />) : (<MdFilterAlt />)}
                    
                </span>
                <span
                  onClick={checkinsReport.excelExport}
                  className="download-icon ml-3"
                  title="Download"
                >
                  <i className="fa fa-download"></i>
                </span>
              </div>
            </div>
          </div>
          {checkinsReport.disable ? (
            <>
              <div
                className="filter-level-2 d-flex mt-3"
                style={{ minInlineSize: "-webkit-fill-available" }}
              >
                <div className="bz-row no-gutters align-items-center">
                  <div className="col-auto pr-2">
                    <p className="mb-1 mb-sm-0" style={{ alignSelf: "center" }}>
                      Show check-ins for
                    </p>
                  </div>
                  <div className="col-auto">
                    <MultiSelect
                      style={{ width: "280px" }}
                      data={checkinsReport.planData}
                      textField={"Name"}
                      dataItemKey={"PlanCode"}
                      placeholder="Membership(s)"
                      onChange={checkinsReport.handleMembershipChange}
                      value={checkinsReport.membershipField}
                      tags={
                        checkinsReport.membershipField.length > 0
                          ? [
                              {
                                text: `${checkinsReport.membershipField.length} items selected`,
                                data: [...checkinsReport.membershipField],
                              },
                            ]
                          : []
                      }
                      filterable={true}
                      allowCustom={false}
                      autoClose={false}
                      onFilterChange={(e: any) =>
                        checkinsReport.membershipFilter(e)
                      }
                      className="bz-multiselect-arrow"
                    />
                  </div>
                  {checkinsReport.customOrigFields.length > 0 ? (
                    <>
                      <div className="col-auto px-2">
                        <p
                          className="mb-1 mb-sm-0"
                          style={{ alignSelf: "center" }}
                        >
                          with
                        </p>
                      </div>
                      <div className="col-auto">
                        <MultiSelect
                          style={{ width: "280px" }}
                          data={checkinsReport.customFields}
                          textField={"FieldName"}
                          dataItemKey={DATA_ITEM_KEY}
                          placeholder="Custom Field(s)"
                          onChange={checkinsReport.handleCustomFieldChange}
                          value={checkinsReport.selectedCustomField}
                          tags={
                            checkinsReport.selectedCustomField.length > 0
                              ? [
                                  {
                                    text: `${checkinsReport.selectedCustomField.length} items selected`,
                                    data: [
                                      ...checkinsReport.selectedCustomField,
                                    ],
                                  },
                                ]
                              : []
                          }
                          filterable={true}
                          allowCustom={false}
                          autoClose={false}
                          onFilterChange={(e: any) =>
                            checkinsReport.customFieldFilter(e)
                          }
                          disabled={checkinsReport.membershipField.length === 0}
                          // onFilterChange={(e) => props?.filterCouponChange(e)}
                          // itemRender={itemRenderCoupon}
                          className="bz-multiselect-arrow"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="col-auto pl-2">
                    <button
                      title="Search Check-ins"
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary mx-0"
                      onClick={checkinsReport.searchAdditionalFilters}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        {checkinsReport.loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <>
            <ExcelExport
              data={checkinsReport.dataList?.checkInsData || []}
              ref={checkinsReport._export}
              fileName={`${GetLocalStore("tenantUserSession")?.ClubName?.replace(/[^a-zA-Z ]/g, "")}_CHECK-IN_LOGS_${moment().format('Y-MM-DD HH_mm_ss')}.xlsx`}
            >
              <ExcelExportColumn field="FirstName" title="First Name" />
              <ExcelExportColumn field="LastName" title="Last Name" />
              <ExcelExportColumn field="TypeOfMember" title="Member Type" />
              <ExcelExportColumn field="SiteName" title="Location" />
              <ExcelExportColumn field="Email" title="Email" />
              <ExcelExportColumn field="DateOfBirth" title="Date of Birth"  />
              <ExcelExportColumn field="Phone" title="Phone" />
              <ExcelExportColumn title="Type" field="Type" />
              <ExcelExportColumn field="Ocurred" title="Date Time" />
              {checkinsReport.membershipField.length > 0 && checkinsReport.searchClicked &&
              checkinsReport.dataList?.checkInsData &&
              checkinsReport.dataList?.checkInsData?.length > 0 &&
              Object.keys(checkinsReport.dataList?.checkInsData[0]).includes(
                "MembershipName"
              ) ? (
                <>
                  <ExcelExportColumn
                    field="MembershipName"
                    title="Membership Name"
                  />
                </>
              ) : (
                <></>
              )}
              <ExcelExportColumn title="Check-In By" field="CheckinMode" />
              <ExcelExportColumn title="Details" field="Details" />
              {checkinsReport.selectedCustomField.map((val: any) => {
                if (
                  checkinsReport.dataList?.checkInsData &&
                  checkinsReport.dataList?.checkInsData?.length > 0 &&
                  checkinsReport.searchClicked &&
                  Object.keys(
                    checkinsReport.dataList?.checkInsData[0]
                  ).includes(val.FieldName)
                ) {
                  return (
                    <ExcelExportColumn
                      title={val.FieldName}
                      field={val.FieldName}
                    />
                  );
                }
              })}
            </ExcelExport>
            <Grid
              data={checkinsReport.dataList?.checkInsData || []}
              className={`bzc-grid bz-checkins-grid bz-checkins-date-filter bz-checkin-logs-grid`}
              pageSize={10}
              onPageChange={async (event: GridPageChangeEvent) =>
                await checkinsReport.pageChange(event)
              }
              skip={checkinsReport.page.skip}
              take={checkinsReport.page.take}
              pageable={true}
              total={checkinsReport.dataList?.total}
              ref={checkinsReport._grid}
            >
              
              <Column
                field="FirstName"
                title="First Name"
                width={"125px"}
                cell={(props) => {
                  return (
                    <td>
                      <ClientNameWithMemberType
                        memberType={props?.dataItem?.MemberType}
                        clientName={props?.dataItem?.FirstName}
                      />
                    </td>
                  );
                }}
              />
              <Column field="LastName" title="Last Name" width={"125px"}/>
              {/* <Column field="ClubName" title="Home Club" /> */}
              <Column field="SiteName" title="Location" width={"120px"}/>
              <Column title="Email" field="Email" width={"235px"}/>
              <Column title="Date of Birth" field="DateOfBirth" width={"125px"} cell={(props) => {
                return (
                  <td>

                    {props?.dataItem?.DateOfBirth}
                  </td>
                )
              }} />
              <Column title="Phone" field="Phone" width={"130px"}/>
              <Column title="Type" field="Type" width={"100px"}/>
              <Column field="Ocurred" title="Date Time" width={"190px"}/>
              {checkinsReport.membershipField.length > 0 && checkinsReport.searchClicked &&
              checkinsReport.dataList?.checkInsData &&
              checkinsReport.dataList?.checkInsData?.length > 0 &&
              Object.keys(checkinsReport.dataList?.checkInsData[0]).includes(
                "MembershipName"
              ) ? (
                <Column field="MembershipName" title="Membership Name" width={"200px"}/>
              ) : (
                <></>
              )}

              <Column title="Check-In By" field="CheckinMode" width={"200px"}/>
              <Column
                title="Details"
                field="Details"
                width={"200px"}
                cell={(props) => {
                  return (
                    <td>
                      <div>
                        {props?.dataItem?.EntityName}
                        <br />
                        {props?.dataItem?.ScheduledStartTime}
                      </div>
                    </td>
                  );
                }}
              />
              {checkinsReport.customOrigFields.map((val: any) => {
                if (
                  checkinsReport.dataList?.checkInsData &&
                  checkinsReport.searchClicked &&
                  checkinsReport.dataList?.checkInsData?.length > 0 &&
                  Object.keys(
                    checkinsReport.dataList?.checkInsData[0]
                  ).includes(val.FieldName)
                ) {
                  return <Column title={val.FieldName} field={val.FieldName} width={`${val.FieldName.length*10}px`}/>;
                }
              })}
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default CheckinsReport;

import PurchaseClassList from "components/class/PurchaseClassList";
import withNotification from "components/Hoc/withNotification";
import { useExternalItem } from "./useExternalItem";
const Group = (props: any) => {
  const data = useExternalItem(props, "group");

  if (props?.classShortName && !props?.ShortCodeStatus) {
    let message = "The Group not available to book/enroll";
    return (
      <>
        <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
          <div className="col-12 col-xl-10">
            <h5 className="bzo-primary-heading">{`Group`}</h5>
            <br />{" "}
            <div className={"bzo-services-container border-0 text-muted"}>
              {message}
            </div>
          </div>
        </div>
      </>
    );
  }

  if (
    props?.classShortName &&
    props?.ShortCodeStatus &&
    props?.ShortCodeDescription
  ) {
    let message = "The Appointment not available to book/enroll";
    switch (props?.ShortCodeDescription) {
      case "Expired":
        message = "The appointment has passed/over already";
        break;
      case "Cancelled":
        message = "The  appointment has been cancelled";
        break;
      default:
        break;
    }
    return (
      <>
        <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
          <div className="col-12 col-xl-10">
            <h5 className="bzo-primary-heading">{`Group`}</h5>
            <br />{" "}
            <div className={"bzo-services-container border-0 text-muted"}>
              {message}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
      <div className="col-12 col-xl-10">
        {!props?.classShortName && <h5 className="bzo-primary-heading">{`Group`}</h5>}
        <PurchaseClassList
          page={"online"}
          ShortCodeStatus={props?.ShortCodeStatus}
          classShortName={props?.classShortName}
          classSessionId={props?.classSessionId}
          ShortCodeDescription={props?.ShortCodeDescription}
        />
      </div>
    </div>
  );
};

export default withNotification(Group);

import React, { useEffect, useState } from "react";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllExceptTenant,
} from "utils/storage";
import { useDispatch } from "react-redux";
import { setRedirectionConfig } from "redux-slices/redirectionSlice";
import { setUserConfiguration } from "redux-slices/userSlice";
import { TenantService } from "services/tenant/index.service";
import { useHistory } from "react-router-dom";

export const useExternalItem = (props: any, type: string) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [servicesData, setServicesData] = useState<any>([]);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const unloadFunc = () => {
    dispatch(
      setUserConfiguration({
        loggedIn: false,
        userDetails: null,
      })
    );
    //use the history and the location from the props instead of window
    props.history.replace({
      ...props.location,
      state: undefined,
    });
    const errorMsg = `You have logged out successfully `;
    props?.handleNotificationMessage(errorMsg, "success");
  };

  useEffect(() => {
    let obj: any = { firstPathValue: "external", secondRoute: type };
    dispatch(setRedirectionConfig(obj));
  }, []);
  useEffect(() => {
    if (type === "oneonone") {
      fetchServices();
    }
  }, []);

  async function fetchServices() {
    const service = new TenantService();
    let req = {};
    setIsServiceLoading(true);
    if (props?.shortCodeValue) {
      req = {
        ShortCode: props?.shortCodeValue,
        IncludeHostOnlineOnly: false,
      };
    } else {
      req = {
        IncludeHostOnlineOnly: true,
      };
    }
    req= {...req,IsActive:true}
    const servicedet = await service.getServiceDetails(req);
    const result = servicedet.Result;
    if (result) {
      setServicesData(result);
    }
    setIsServiceLoading(false);
    if (
      result?.length === 1 &&
      result?.[0]?.PrivateServiceType !== 1
    ) {
      history.push(`/external/mybooking`, {
        selectedService: result[0],
        // serviceShortName: props?.shortCodeValue,
      });
    }
  }

  if (props.location?.state?.logout) {
    RemoveAllExceptTenant();
    const hostValues = window.location.host.split(".");
    if (
      hostValues.length === 3 &&
      hostValues[0] !== "www" &&
      hostValues[0] !== "ui"
    ) {
      const tenantName = hostValues[0];
      LocalStore("tenant", tenantName);
    }
  }

  useEffect(() => {
    if (!GetLocalStore("userDetails") && props.location?.state?.logout) {
      unloadFunc();
    }
  }, [GetLocalStore("userDetails")]);

  if (type === "oneonone") {
    return { isServiceLoading, servicesData };
  }
  return {};
};

import { useState, useEffect } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import withNotification from "components/Hoc/withNotification";
import Html5QrcodePlugin from "components/onsiteaccess/Components/Html5QrcodePlugin";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { SimpleAccessService } from "services/simpleAccess/index.service";
import CheckinUser from "./CheckinUser";
import BuzopsButton from "generic-components/BuzopsButton";
import utils from "../../utils/utils";
import { CheckinFrom } from "./Utils/constants";
import { GetLocalStore } from "utils/storage";
import SelfCheckinTabs from "./SelfCheckinTabs";
import { SelfCheckInType } from "utils/constants";
import useSecuritySystemConfigs from "./Container/SecuritysystemConfigs.hook";
import useCheckinUser from "./Container/CheckinUser.hook";

const SelfCheckIn = (props: any) => {
  const simpleAccessService = new SimpleAccessService();
  const [cameraScan, setCameraScan] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [scanResult, setScanResult] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [selectedType, setselectedType] = useState<SelfCheckInType>(
    SelfCheckInType.BARCODE
  );
  const [userDetails, setUserDetails] = useState<any>(null);
  const [enterBarCode, setEnterBarCode] = useState<Boolean>(false);
  const [scannedBarcode, setScannedBarcode] = useState<Boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidBarCode, setIsValidBarCode] = useState(true);
  const [error, setError] = useState("");

  const {
    securityConfig,
    isOpenPathSystem,
    isBarcodeSystem
  } = useSecuritySystemConfigs();
  const {
    checkIfUserCanCheckinForOpenPath,
    checkIfUserCanCheckinForBarcode,
  } = useCheckinUser(props);
  const tenantUserSession = GetLocalStore("tenantUserSession");
  const ClubId = tenantUserSession?.ClubId;
  useEffect(() => {
    if(isOpenPathSystem()){
      setselectedType(SelfCheckInType.EMAIL);
    }
  }, [securityConfig])

  useEffect(() => {
    setScanResult("");
    setEmail("");
  }, []);

  const resetBarcodeText = () => {
    setScanResult("");
    setEnterBarCode(false);
  };
  const handleBarcodeChange = (e: any) => {
    setIsValidBarCode(true);
    setError("");
    if (e.value?.length > 0) {
      setEnterBarCode(true);
    } else {
      setEnterBarCode(false);
    }
    setScanResult(e.value);
  };
  const handleEmailChange = (e: any) => {
    setIsValidEmail(true);
    setEmail(e.value);
    setError("");
  };

  const setUserDetailsIfValid = (data: any) => {
    if(isOpenPathSystem()){
      if(checkIfUserCanCheckinForOpenPath(data.AccessCard, false, true)){
        setUserDetails(data);
        return;
      }
    }else if(isBarcodeSystem()){
      if(checkIfUserCanCheckinForBarcode(data.AccessCard, false, true)){
        setUserDetails(data);
        return;
      }
    }
    setUserDetails(null);
  }
  const handleEnteredBarcode = async (barcodeVal: string, entered = false) => {
    setError("");
    if (utils.hasWhiteSpace(barcodeVal)) {
      setIsValidBarCode(false);
      return;
    }
    if (
      securityConfig.ManualAplhanumCodeCheckinsEnabled === true &&
      !barcodeVal.startsWith(`${ClubId}-`)
    ) {
      barcodeVal = `${ClubId}-` + barcodeVal;
    }
    setLoading(true);
    setUserDetails(null);
    const result = await simpleAccessService?.getSimpleAccessUserLookup(
      barcodeVal,
      0
    );
    setLoading(false);
    if (result?.success) {
      const data = result?.data;
      if (Object.keys(data).length > 0) {
        setUserDetailsIfValid(data);
      } else {
        setUserDetails(null);
        props?.handleNotificationMessage(
          "Access card is not assigned to selected user",
          "error"
        );
        setError("Access card is not assigned to selected user");
      }
      setScannedBarcode(entered);
    } else {
      const errorMsg = result?.msg;
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleEnteredEmail = async (email: string) => {
    setError("");
    if (!utils.checkValidEmail(email)) {
      setIsValidEmail(false);
      return;
    } else {
      setIsValidEmail(true);
    }
    setLoading(true);
    setUserDetails(null);
    let result = null;
    if(isBarcodeSystem()) {
      result = await simpleAccessService?.getSimpleAccessUserLookup(
        email,
        1
      );
    }
    if(isOpenPathSystem()) {
      result = await simpleAccessService?.getHardwareAccessLookup(
        email,
        1
      );
    }
    if (result?.success) {
      const data = result?.data;
      if (Object.keys(data).length > 0) {
        setUserDetailsIfValid(data);
      } else {
        setUserDetails(null);
        props?.handleNotificationMessage(
          "Access card is not assigned to selected user",
          "error"
        );
        setError("Access card is not assigned to selected user");
      }
    } else {
      const errorMsg = result?.msg;
      props?.handleNotificationMessage(errorMsg, "error");
    }
    resetBarcodeText();
    setLoading(false);
  };

  const onScanSuccess = async (decodedText: any, decodedResult: any) => {
    setCameraScan(!cameraScan);
    const cardNumber = decodedResult?.result?.text;
    setScanResult(cardNumber);
    await handleEnteredBarcode(cardNumber, true);
  };
  const onScanFail = (error: any) => {};

  const toggleDialog = () => {
    setCameraScan(!cameraScan);
  };

  const cameraScanner = () => {
    if(isBarcodeSystem()){
      return (
        <Dialog title={"Scanning...."} onClose={toggleDialog}>
          <Html5QrcodePlugin
            SuccessCallback={onScanSuccess}
            FailureCallback={onScanFail}
          />
        </Dialog>
      );
    }
    return <></>;
  };

  const handleToggle = (type: SelfCheckInType) => {
    setselectedType(type);
    setUserDetails(null);
    setScanResult("");
    setEmail("");
    setEnterBarCode(false);
    setIsValidEmail(true);
    setIsValidBarCode(true);
    setError("");
  };

  const submitBarcode = (e: any) => {
    if (e.keyCode === 13) {
      if (e?.target?.value) {
        handleEnteredBarcode(e.target.value);
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && email) {
      handleEnteredEmail(email);
    }
  };

  const onCloseHandler = () => {
    setUserDetails(null);
    setScanResult(null);
    setEmail(null);
    setIsValidEmail(true);
    setIsValidBarCode(true);
    setError("");
  };

  return (
    <>
      <div className="bg-white">
        
        <div className="row">
          <div className="col-md-12 bg-white">
            {!userDetails ? (
              <div className="self-checkbox selfcheckin-height justify-content-center">
                <h4 className="self-checkin-heading">Self Check-In</h4>
                <div className="scan-card-container selfscan_mob">
                  <SelfCheckinTabs selectedType={selectedType} handleToggle={(type: SelfCheckInType) => handleToggle(type)} renderIf={isBarcodeSystem()}/>
                  <div className="self-checkin-input-box">
                    {selectedType === SelfCheckInType.BARCODE ? (
                      <>
                        <div className="">
                          <div className="input-group">
                            <Input
                              key={`access-barcode`}
                              autoFocus={!scanResult || scanResult}
                              style={{ borderRightWidth: "0" }}
                              value={scanResult || ""}
                              onChange={(e) => handleBarcodeChange(e)}
                              onKeyDown={(e) => submitBarcode(e)}
                              className="form-control barcode-text-disabled selfcheck_enter barcode"
                            />
                            <div
                              className="input-group-append"
                            >
                              <span
                                className="input-group-text"
                                style={{ background: "none" }}
                              >
                              </span>
                            </div>
                          </div>
                          <p className="k-form-error">
                            {!isValidBarCode &&
                              "Please enter valid Access code"}
                          </p>
                          <p className="k-form-error">
                            {!isValidBarCode &&
                              "Please enter valid Access code"}
                          </p>
                          {scanResult && (
                            <div className="Scan-action-btns scan-camera d-block">
                              <span>
                                <BuzopsButton
                                  label={"Verify"}
                                  key={loading.toString()}
                                  loading={loading}
                                  disabled={loading || !scanResult}
                                  onClick={() =>
                                    handleEnteredBarcode(scanResult)
                                  }
                                />
                              </span>

                              <BuzopsButton
                                label={"Reset"}
                                key={loading.toString()}
                                disabled={!scanResult}
                                onClick={() => setScanResult(null)}
                                className="resetbtn-checkin"
                              />
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className=" align-items-center">
                          <div>
                            <Input
                              key={`email-barcode`}
                              autoFocus={
                                (!email || email) &&
                                selectedType === SelfCheckInType.EMAIL
                              }
                              style={{ width: "100%" }}
                              value={email || ""}
                              onChange={(e) => handleEmailChange(e)}
                              className="barcode-text-disabled selfcheck_enter barcode"
                              placeholder="Enter your Email"
                              onKeyDown={handleKeyDown}
                            />
                          </div>

                          <p className="k-form-error">
                            {!isValidEmail && "Please enter valid email"}
                          </p>
                          {email && (
                            <div
                              className="Scan-action-btns d-flex"
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <BuzopsButton
                                  onClick={() => handleEnteredEmail(email)}
                                  label={"Verify"}
                                  key={loading.toString()}
                                  loading={loading}
                                  disabled={loading || !email}
                                />
                              </span>
                              <BuzopsButton
                                label={"Reset"}
                                key={loading.toString()}
                                disabled={!email}
                                onClick={() => setEmail(null)}
                                className="resetbtn-checkin"
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {cameraScan && cameraScanner()}
              </div>
            ) : (
              <div>
                {loading ? (
                  <BuzopsLoader type="list" />
                ) : (
                  <>
                    {userDetails && (
                      <CheckinUser
                        userDetails={userDetails}
                        CheckinMode={2}
                        from={CheckinFrom.Kiosk}
                        onCloseHandler={onCloseHandler}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withNotification(SelfCheckIn);

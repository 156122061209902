import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import ExcelExport from "atoms/ExcelExport/ExcelExport";
import moment from "moment";
import React from "react";
import { MdDownload } from "react-icons/md";
import { GetLocalStore } from "utils/storage";
const NameCell = (props: any) => {
  const Name = `${props?.dataItem?.FirstName} ${props?.dataItem?.LastName}`;
  return <td>{Name}</td>;
};
const PhoneCell = (props: any) => {
  return (
    <td>
      {props?.dataItem[props?.field] ? props?.dataItem[props?.field] : "NA"}
    </td>
  );
};

const ProspectsDialog = (props: any) => {
  const { PromotionProspects = [] } = props;
  let ClubName = GetLocalStore("tenantUserSession")?.ClubName;
  if (!props?.admin) {
    ClubName = GetLocalStore("AffiliateDetails")?.UserSession?.ClubName;
  }
  const _exporter = React.createRef<any>();
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current?.save();
    }
  };
  const getExcelData = () => {
    return PromotionProspects.map((row: any) => {
      return {
        ...row,
        Name: `${row?.FirstName} ${row?.LastName}`,
        Phone: row?.Phone ? row?.Phone : "N/A",
      };
    });
  };
  const ProspectsExcelColumn = [
    { field: "Name", title: "Name", visible: true },
    { field: "Email", title: "Email", visible: true },
    { field: "Phone", title: "Phone Number", visible: true },
    { field: "AffiliateName", title: "Affiliate Name", visible: !props?.affiliatePage },
    { field: "PromotionName", title: "Promotion Name", visible: !props?.promotionPage },
  ];
  return (
    <>
      <ExcelExport
        data={getExcelData() || []}
        fileName={`${ClubName} Prospects ${moment().format(
          "MM-DD-YYYY HH:mm:ss"
        )}.xlsx`}
        _exporter={_exporter}
        columns={ProspectsExcelColumn?.filter((i:any) => i?.visible)}
      />
      <Dialog
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingRight: "15px",
              alignItems: "center",
            }}
          >
            {"Prospect Details"}{" "}
            {PromotionProspects?.length > 0 ? (
              <span className="download-icon">
                <MdDownload
                  onClick={excelExport}
                  title="Download"
                  color="#008DB9"
                />
              </span>
            ) : null}
          </div>
        }
        onClose={() => props?.toggleProspectDialog()}
        style={{ zIndex: 9999 }}
        width={"80%"}
      >
        <div className="">
          <Grid
            data={PromotionProspects}
            className="bzc-grid bz-checkins-grid"
            style={{ maxHeight: "400px", overflow: "auto" }}
          >
            <GridColumn title="Name" field="FirstName" cell={NameCell} />
            <GridColumn title="Email" field="Email" />
            <GridColumn title="Phone Number" field="Phone" cell={PhoneCell} />
            {!props?.affiliatePage ? (
              <GridColumn title="Affiliate Name" field="AffiliateName" />
            ) : null}
            {!props?.promotionPage ? (
              <GridColumn title="Promotion Name" field="PromotionName" />
            ) : null}
          </Grid>
        </div>{" "}
      </Dialog>
    </>
  );
};

export default ProspectsDialog;

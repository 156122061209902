import useSubscriptionHistory from "./subscriptionHistory.hook";
import { GridCellProps } from "@progress/kendo-react-grid";
import GridTable from "atoms/GridTable/GridTable";
import { useHistory } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import withNotification from "components/Hoc/withNotification";
import { MdOutlineRefresh } from "react-icons/md";
import MigrationDetailsView from "./migrationDetailView";

export const enum RequestStatus {
    None = 0,
    InProgress = 1,
    Draft = 2,
    Processing = 3,
    Completed = 4,
    PartialErrors = 5
}
export const RequestStatusDescription = new Map<number, string>([
  [RequestStatus.None, 'None'],
  [RequestStatus.InProgress, 'In Progress'],
  [RequestStatus.Draft, 'Draft'],
  [RequestStatus.Processing, 'Processing'],
  [RequestStatus.Completed, 'Completed'],
  [RequestStatus.PartialErrors, 'Partial Errors'],
]);
const SubscriptionsImportListView = (props: any) => {
    const {
        subscriptionHistory,
        subscriptionLoading,
        fetchHistory,
        removeMigration,
        deleting,
        refreshMigrationRequest,
        requestRefresh,
        expandChange
    } = useSubscriptionHistory(props);
    const history = useHistory();
    const uuid = props.match?.params?.uuid;
    const ActionCell = (props: GridCellProps) => {
        if(props?.dataItem?.Status === RequestStatus.Processing){
            return <td>Migration in progress <span title="refresh" className="ml-2 bz-cursor-pointer bz-18" onClick={() => refreshMigrationRequest(props.dataItem.RequestId)}><MdOutlineRefresh className={ requestRefresh === props.dataItem.RequestId ? "fa-spin": ""}/></span></td>
        }
        if(props?.dataItem?.Status === RequestStatus.Completed || props?.dataItem?.Status === RequestStatus.PartialErrors){
          return <td>
            <span
              className="bz-custom-link bz-cursor-pointer mr-2"
              onClick={() =>
                history.push(`/app/migrationDetails/${uuid}`, {
                  migrationData: {...props?.dataItem, onlyView: true},
                })
              }
              title="View"
            >
              <i className="fa fa-eye"></i>
            </span>
          </td>
        }
        return (
          <td>
            <span
              className="bz-custom-link bz-cursor-pointer mr-2"
              onClick={() =>
                history.push(`/app/migrationDetails/${uuid}`, {
                  migrationData: props?.dataItem,
                })
              }
              title="Edit"
            >
              <i className="fa fa-edit"></i>
            </span>
            &nbsp;
            {(deleting && deleting === props?.dataItem?.RequestId) ? (
              <Loader type={"pulsing"} />
            ) : (
              <span
                className="bz-custom-link bz-cursor-pointer text-danger"
                onClick={() => removeMigration(props?.dataItem?.RequestId)}
                title="Delete"
              >
                <i className="fa fa-trash "></i>
              </span>
            )}
          </td>
        );
      };

      const StatusCell = (props: GridCellProps) => {
        return (
          <td>
            {RequestStatusDescription.get(props.dataItem.Status)}
          </td>
        )
      }
      const columns = [
        {
            field: "FileName",
            title: "File Name"
        },
        {
          field: "RequestedOn",
          title: "Imported Date"
        },
        {
            field: "UpdateDateTime",
            title: "Last Processed"
        },
        {
          field: "StatusDescription",
          title: "Status"
        },
        {
          field: "TotalClientCount",
          title: "Total",
          width: 100
        },
        {
          field: "DraftCount",
          title: "Draft",
          width: 100
        },
        {
          field: "CompletedClientCount",
          title: "Completed",
          width: 100
        },
        {
          field: "FailedClientCount",
          title: "Failed",
          width: 100
        },
        {
          field: "RequestId",
          title: "Action",
          cell: ActionCell,
          width: 120
        },
      ];
    return (
        <div className="d-flex justify-content-center flex-column px-4 py-2">
            <div className="pt-2 d-flex justify-content-between align-items-center">
                <h5 className="bz-article-headline">Migration List</h5>
                <span className="k-button k-primary bz-cursor-pointer" onClick={() => history.push(`/app/migrationDetails/${uuid}`)} style={{gridGap:0}}>
                    <i className="fa fa-upload mr-1"></i>
                    Import
                </span>
            </div>
            <GridTable
                title=""
                total={subscriptionHistory?.total}
                columns={columns}
                data={subscriptionHistory?.data}
                handlePagination={fetchHistory}
                pageSize={10}
                loading={subscriptionLoading}
                expandField="expanded"
                onExpandChange={expandChange}
                detail={MigrationDetailsView}
                className="bz-migration-list-table"
            />
        </div>
    )
}

export default withNotification(SubscriptionsImportListView);
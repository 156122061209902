import React, { useRef } from "react";
import Webcam from "react-webcam";
import { Button } from "@progress/kendo-react-buttons";
import { Hint } from "@progress/kendo-react-labels";

const videoConstraints = {
  width: { min: 120, max: 360 },
  height: { min: 120, max: 360 },
  facingMode: "environment",
};

const Camera = (props: any) => {
  const webcamRef = useRef(null);
  const [url, setUrl] = React.useState(null);
  const [hasCamAccess, setHasCamAccess] = React.useState(true);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = (webcamRef.current as any)?.getScreenshot();
    setUrl(imageSrc);
    props.handleCameraCapture(imageSrc)
  }, [webcamRef]);

  const onUserMedia = (e:any) => {
    setHasCamAccess(true)
  };
  const onUserMediaError = (e:any) => {
    setHasCamAccess(false)
  };

  return (
    <>
      <div style={{textAlign:'center'}}>
        <div>
          {hasCamAccess && !url && (<Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            onUserMediaError={onUserMediaError}
          />)}
          {hasCamAccess && url && (
            <div>
              <img src={url} alt="Screenshot" />
            </div>
          )}
          {!hasCamAccess && (
            <>
              <Button onClick={() => props.handleRefreshCamera()}>Refresh</Button>
              <Hint style={{justifyContent:'center'}}>Unable to see camera preview ?</Hint> 
              <Hint style={{justifyContent:'center'}}>Please make sure camera permissions are provided and click refresh button above</Hint> 
            </>            
          )}  
        </div>   
        <br></br>
        {hasCamAccess && (<div>
            <Button disabled={url !== null} onClick={capturePhoto}>Capture</Button>
            <Button disabled={!url} onClick={() => setUrl(null)}>Retake</Button>
          </div>
        )}
      </div>
      <hr/>      
    </>
  );
};

export default Camera;

import React, { useEffect, useState } from "react";
import { hubConnection } from "signalr-no-jquery";
const URL = process.env.REACT_APP_LIVE_CLUB_CHECKIN_BACKEND;
export interface IClubCheckin {
  Ocurred: Date;
  SiteName: string;
  ClubId: number;
  Type: number;
  CheckedInUserId: number;
  CheckedInByStaffUserId?: number | null;
  SourceType: number;
  ServiceName: string;
  ServiceScheduledStartTime?: Date | null;
}
export enum ActionType {
  CheckinAdded = "CheckinAdded",
  Connected = "Connected",
  FailedConnection = "FailedConnection",
  Reconnecting = "Reconnecting"
}
const useClubCheckinv2 = (externalClubId: string) => {
  const timeoutRef = React.useRef<any>(null);
  const [notificationState, setNotificationState] = useState({
    type: "info",
    msg: "",
    action: ""
  });
  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  useEffect(() => {
    if (notificationState.msg) {
      resetNotification();
    }
  }, [notificationState]);
  const connection = hubConnection(`${URL}`, {
    qs: `externalClubId=${externalClubId}`,
    logging: true,
  });
  const checkInActivityHub = connection.createHubProxy("checkinActivityHub");
  const resetNotification = () => {
    timeoutRef.current = setTimeout(() => {
      setNotificationState({
        type: "info",
        msg: "",
        action: ""
      });
    }, 4000);
  };
  // set up event listeners i.e. for incoming "message" event
  const events = (onNewCheckinActivity: (checkin: IClubCheckin) => void) => {
    checkInActivityHub.on(
      "newCheckinActivity",
      function (checkIn: IClubCheckin) {
        console.log(checkIn);
        onNewCheckinActivity(checkIn);
        setNotificationState({
          type: "info",
          msg: "A new check-in has been added.",
          action: ActionType.CheckinAdded
        });
      }
    );
  };

  const startConnection = () => {
    // connect
    connection
      .start()
      .done(function () {
        console.log("Now connected, connection ID=" + connection.id);
        checkInActivityHub.invoke("Subscribe", {
          ExternalClubId: externalClubId,
        });

        setNotificationState({
          type: "success",
          msg: "live stream connected successfully",
          action: ActionType.Connected
        });
      })
      .fail(function () {
        console.log("Could not connect");
        setNotificationState({
          type: "error",
          msg: "failed to connect live stream",
          action: ActionType.FailedConnection
        });
      });
  };
  connection.disconnected(() => {
    // do some initialization once you know the connection has been started
    // For instance, call a method on the server
    setTimeout(function () {
      startConnection();
    }, 5000);
    setNotificationState({
      type: "info",
      msg: "reconnecting livestream",
      action: ActionType.Reconnecting
    });
    console.log("reconnecting livestream");
  });
  return {
    events,
    startConnection,
    notificationState,
  };
};
export default useClubCheckinv2;

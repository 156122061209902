import ExcelDataTabs from "./excelDataTabs";
import ExcelImport from "./excelImport";
import ImportProvider from "./importContext";
import { useHistory } from "react-router-dom";


const SubscriptionsImport = (props: any) => { 
    const history = useHistory();
    return (
        <ImportProvider>
            <div className="d-flex px-4 pt-2 ">
                <button
                    type="button"
                    className="btn btn-primary buz-back-btn btn-sm mb-1"
                    onClick={() => history.push(`/app/migrationHistory/${props?.match?.params?.uuid}/true`)}
                >
                    <i className="fas fa-arrow-left"></i>
                </button>
            </div>
            <div className="d-flex justify-content-center flex-column px-4 py-2">
                {
                    !props.location.state?.migrationData && (
                        <>
                            <div className="align-self-center">
                                <h5 className="bz-article-headline mb-2" >Migration Import</h5>
                            </div>
                            {/* Import component */}
                            <div className="align-self-center bz-import-file-container">
                                <ExcelImport {...props} />
                                
                            </div>
                            <hr />
                        </>
                    )
                }
                {/* Tabs Component to show subscriptions */}
                <ExcelDataTabs {...props}/>
            </div>
        </ImportProvider>
    )
}

export default SubscriptionsImport;
import withNotification from "components/Hoc/withNotification";
import PurchasePackageList from "components/package/PurchasePackageList";
import { useExternalItem } from "./useExternalItem";

const Package = (props: any) => {
    const data = useExternalItem(props, 'package')

    return (
        <div className="row justify-content-center  bz-classes-in-online bz-provider-section">
            <div className="col-12 col-xl-10">
                {!props?.shortCodeValue && <h5 className="bzo-primary-heading">{`Package`}</h5>}
                {props?.shortCodeValue ? <PurchasePackageList page={"online"}  guest={true} packageShortName={props?.shortCodeValue}  ShortCodeStatus={props?.ShortCodeStatus} /> : <PurchasePackageList ShortCodeStatus={props?.ShortCodeStatus} />}
            </div>
        </div>
    );
};

export default withNotification(Package);

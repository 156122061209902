import withNotification from "components/Hoc/withNotification";
import { Dialog } from "@progress/kendo-react-dialogs";
import SimpleConfiguration from "./SimpleConfiguration";
import HardwareConfiguration from "./HardwareConfiguration";
import { securityType } from "./ThirdParty";
import { TfiAngleRight } from "react-icons/tfi";
import { MdContentCopy } from "react-icons/md";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { HardwareSecuritySystemSetupStatusType } from "../Utilities/constants";

import { HardwareProviderType, SecuritySystemType } from "utils/constants";
import useCheckinSystem from "../Container/CheckinSystem.hook";

const CheckinSystem = (props: any) => {
  const checkinSystem = useCheckinSystem(props);
  if (checkinSystem.Loading) {
    return <BuzopsLoader />;
  }
  return (
    <div className="p-4">
      <h6 className="bz-article-headline pb-4">Check-In System Options</h6>
      <div className="row" style={{ marginBottom: "1rem" }}>
        <div className="col-md-12 col-lg-8 col-xl-9">
          <div
            className={`bz-card card bz-checkins-config-cards ${
              checkinSystem.securitySystemConfigs?.SecuritySystemType?.toString() ===
              "1"
                ? "simple_intger_selected"
                : checkinSystem.securitySystemConfigs?.SecuritySystemType &&
                  "disable-card"
            }`}
          >
            <div className="card-body p-3">
              <div className="bz-list-profile">
                <div className="bz-list-profile-detail pr-4">
                  <div className="bz-list-profile-lead">
                    <strong>
                      <span className="ng-binding">
                        Proximity Reader System
                      </span>
                      {checkinSystem.securitySystemConfigs?.SecuritySystemType?.toString() ===
                        "1" && (
                        <span className="">
                          {" "}
                          {checkinSystem.showStatusMessage()}
                        </span>
                      )}
                    </strong>
                  </div>
                  <div className="bz-list-profile-caption">
                    <span>
                      A proximity reader system is a type of access control
                      using RFID technology. It includes a scanner and a
                      proximity card or keyfob, or a smartphone with a unique
                      ID. This is tied to your client's account and a secure
                      door, allowing or disallowing your client access depending
                      on their account status. *You will need additional
                      hardware for this setup.
                    </span>
                    <div className="simple_intger_radio">
                      {checkinSystem.securitySystemConfigs?.SecuritySystemType?.toString() ===
                        "1"}
                    </div>
                  </div>
                  <div className="bz-kiosk-link">
                    {checkinSystem.securitySystemConfigs?.SecuritySystemType ===
                      SecuritySystemType.ProximityReaderSystem &&
                      checkinSystem.securitySystemConfigs
                        ?.HardwareSecuritySystemSetupStatus ===
                        HardwareSecuritySystemSetupStatusType.Enabled &&
                      checkinSystem.securitySystemConfigs
                        ?.HardwareSecuritySystemPreferences?.ProviderType ===
                        HardwareProviderType.Openpath && (
                        <>
                          <span> Kiosk URL : </span>
                          <div>
                            <span>
                              <a
                                href={`https://${checkinSystem.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`}
                                target="_blank"
                              >
                                <i
                                  className="fa fa-link"
                                  style={{
                                    fontSize: "12px",
                                    marginRight: "5px",
                                  }}
                                ></i>
                                {`https://${checkinSystem.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`}
                              </a>
                            </span>
                            &nbsp;
                            <span className="bz-cursor-pointer">
                              <MdContentCopy
                                onClick={checkinSystem.copyToClipBoard}
                                title={
                                  checkinSystem.copiedKioskUrl
                                    ? "Copied"
                                    : "Copy"
                                }
                              />
                            </span>
                          </div>
                        </>
                      )}
                  </div>
                </div>
                {checkinSystem.securitySystemConfigs
                        ?.HardwareSecuritySystemPreferences?.ProviderType ===
                        HardwareProviderType.Passport ? null :   ( <div
                        className="bz-list-profile-detail"
                        onClick={() =>
                          checkinSystem.setSelectedSecType(
                            SecuritySystemType.ProximityReaderSystem
                          )
                        }
                      >
                        <div className="bz-list-profile-lead">
                          <span className="pull-right bz-panel-arrow-icon">
                            <TfiAngleRight />
                          </span>
                        </div>
                    </div>)}
             
            </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8 col-xl-9">
          <div
            className={`bz-card card bz-checkins-config-cards ${
              checkinSystem.securitySystemConfigs?.SecuritySystemType?.toString() ===
              "2"
                ? "simple_intger_selected"
                : checkinSystem.securitySystemConfigs?.SecuritySystemType &&
                  "disable-card"
            }`}
          >
            <div className="card-body p-3">
              <div className="bz-list-profile">
                <div className="bz-list-profile-detail pr-4">
                  <div>
                    <div className="bz-list-profile-lead">
                      <strong>
                        <span className="ng-binding">Scanner System</span>
                        {checkinSystem.securitySystemConfigs?.SecuritySystemType?.toString() ===
                          "2" && (
                          <span className="">
                            {checkinSystem.showStatusMessage()}
                          </span>
                        )}
                      </strong>
                    </div>
                    <div className="bz-list-profile-caption">
                      <span>
                        The scanner system is used to read and interpret
                        Barcodes and QR codes or an input alphanumeric code. It
                        typically consists of a hardware scanner and/or a
                        keypad. This is the simplest and quickest set-up option
                        with little technical knowledge needed.
                      </span>
                    </div>
                  </div>

                  <div>
                    {checkinSystem.securitySystemConfigs?.SecuritySystemType ===
                      SecuritySystemType.ScannerSystem && (
                      <>
                        <span> Kiosk URL : </span>
                        <span>
                          <a
                            href={`https://${checkinSystem.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`}
                            target="_blank"
                          >
                            <i
                              className="fa fa-link"
                              style={{ fontSize: "12px", marginRight: "5px" }}
                            ></i>
                            {`https://${checkinSystem.domain}.${process.env.REACT_APP_DOMAIN}.com/kiosk/login`}
                          </a>
                        </span>
                        &nbsp;
                        <span className="bz-cursor-pointer">
                          <MdContentCopy
                            onClick={checkinSystem.copyToClipBoard}
                            title={
                              checkinSystem.copiedKioskUrl ? "Copied" : "Copy"
                            }
                          />
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div
                  className="bz-list-profile-detail"
                  onClick={() =>
                    checkinSystem.setSelectedSecType(
                      SecuritySystemType.ScannerSystem
                    )
                  }
                >
                  <div className="bz-list-profile-lead">
                    <span className="pull-right bz-panel-arrow-icon">
                      <TfiAngleRight />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      {checkinSystem.selectedSecType && (
        <Dialog
          title={`${
            securityType[checkinSystem.selectedSecType?.toString()]
          } Configuration ${
            checkinSystem.securitySystemConfigs
              ?.HardwareSecuritySystemSetupStatus ===
            HardwareSecuritySystemSetupStatusType.Enabled
              ? "(Configured)"
              : ""
          }`}
          onClose={() => checkinSystem.setSelectedSecType(undefined)}
          height={"98%"}
          width={"95%"}
          style={{ alignItems: "end", paddingRight: "1rem" }}
          className="bz-checkins-config-dialog"
        >
          {checkinSystem.selectedSecType ===
            SecuritySystemType.ProximityReaderSystem && (
            <HardwareConfiguration
              securitySystemConfigs={checkinSystem.securitySystemConfigs}
              handleNotification={props?.handleNotificationMessage}
              setSecuritySystemConfig={checkinSystem.setSecuritySystemConfig}
              isSystemAdmin={checkinSystem.isSystemAdmin}
              setSelectedSecType={checkinSystem.setSelectedSecType}
            />
          )}

          {checkinSystem.selectedSecType ===
            SecuritySystemType.ScannerSystem && (
            <SimpleConfiguration
              securitySystemConfigs={checkinSystem.securitySystemConfigs}
              onSubmit={(saveResponse: any) =>
                checkinSystem.onFormSubmit(saveResponse)
              }
              domain={checkinSystem.domain}
            />
          )}
        </Dialog>
      )}
    </div>
  );
};

export default withNotification(CheckinSystem);

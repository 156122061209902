import useTaxReport from "../Container/TaxReport.hook";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import TaxesGrid from "./TaxesGrid";
import TaxReportFilter from "components/reports/TaxReportFilter";
const FullScreenView = ({ viewData }: any) => {
  return <div className="taxes-fullview">{viewData}</div>;
};
const TaxReport = (props: any) => {
  const {
    uuid,
    dateValues,
    isDateRange,
    state,
    handleFullScreen,
    fullScreen,
    excelData,
    tableData,
    stateGridColumns,
    reportExcelName,
    excelExport,
    exporterRef,
    onColumnsSubmit,
    actualFilterData,
    dataStateChange,
    loading,
    clearAllFilter,
    totalItems,
    sort,
    dataState,
    clearFilterOne,
    isPageable,
    handleChangeSort,
    filterData,
  } = useTaxReport(props);
  return (
    <div>
      <TaxReportFilter
        {...props}
        history={props.history}
        location={props.location}
        dateValues={dateValues}
        defaultValue={
          Object.keys(isDateRange).length === 0 ? state.value.text : isDateRange
        }
      />
      <div className="m-4 taxes-main-container">
        <div className="d-flex justify-content-between sales-close-icon">
          <h6 className="bz-fw-6 p-2">{"Surcharges Report"}</h6>
          <li
            className="k-icon k-i-close cursor-pointer"
            onClick={() => {
              props.history.push(`/app/reports/${uuid}`);
            }}
          ></li>
        </div>
        <div className="taxes-border-header">
          <>
            <div>
              {fullScreen && (
                <FullScreenView
                  viewData={
                    <TaxesGrid
                      toggleFullscreen={() => {
                        handleFullScreen();
                        var style = document.createElement("style");
                        style.innerHTML = `body::-webkit-scrollbar {display: block;}`;
                        document.head.appendChild(style);
                      }}
                      excelExport={excelExport}
                      result={tableData}
                      stateGridColumns={stateGridColumns}
                      onColumnsSubmit={onColumnsSubmit}
                      dataState={dataState}
                      loading={loading}
                      dataStateChange={dataStateChange}
                      total={totalItems}
                      pageable={isPageable}
                      fullScreen={fullScreen}
                      sort={sort}
                      handleChangeSort={handleChangeSort}
                      filterData={filterData}
                      clearFilterOne={clearFilterOne}
                      clearAllFilter={clearAllFilter}
                      actualFilterData={actualFilterData}
                    />
                  }
                />
              )}
              <TaxesGrid
                toggleFullscreen={() => {
                  handleFullScreen();
                  var style = document.createElement("style");
                  style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
                  document.head.appendChild(style);
                }}
                excelExport={excelExport}
                result={tableData}
                dataState={dataState}
                fullScreen={fullScreen}
                loading={loading}
                dataStateChange={dataStateChange}
                stateGridColumns={stateGridColumns}
                onColumnsSubmit={onColumnsSubmit}
                total={totalItems}
                pageable={isPageable}
                sort={sort}
                handleChangeSort={handleChangeSort}
                filterData={filterData}
                clearFilterOne={clearFilterOne}
                clearAllFilter={clearAllFilter}
                actualFilterData={actualFilterData}
              />
            </div>
            <ExcelExport
              data={excelData}
              fileName={reportExcelName()}
              ref={exporterRef}
            >
              {stateGridColumns
                .filter((ele: any) => ele.show)
                .map((el: any, id: any) => {
                  if (el.type === "amount") {
                    return (
                      <ExcelExportColumn
                        key={id}
                        field={el.field}
                        title={el.title}
                        cellOptions={{ format: "number", wrap: true }}
                      />
                    );
                  } else {
                    return (
                      <ExcelExportColumn
                        key={id}
                        field={el.field}
                        title={el.title}
                        cellOptions={{ format: "text", wrap: true }}
                      />
                    );
                  }
                })}
            </ExcelExport>
          </>
        </div>
      </div>
    </div>
  );
};

export default TaxReport;

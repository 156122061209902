import { CommonServiceUtils } from "services/common-service-utils";

export class PackageService extends CommonServiceUtils {
  /**
   * Package will be created with provided data
   * @param data
   *
   */
  
   async addPackage(data?: any): Promise<any> {
    const addPackageDetails:any= await this.postData(this.apiUrlCreator("Package"), data);
    return addPackageDetails;
  }

  async getPackageNamesFilter(req:any): Promise<any> {
    const getPackageNamesFilter: any =  await this.postData(this.apiUrlCreator("Package/Filter"),req);
    return getPackageNamesFilter;
  }

  async getPackage(packageId:number): Promise<any> {
    let response = null;
    const getPackage: any =  await this.getData(this.apiUrlCreator(`Package?packageId=${packageId}`));
    if (getPackage?.ResponseCode === 100) {
      response = {...getPackage?.Item,EditorTicket:getPackage?.EditorTicket}
    }
    return response;
  }

  async updatePackage(data?: any): Promise<any> {
    const updatePackage= await this.postData(this.apiUrlCreator("Package/Put"), data);
    return updatePackage;
  }

  async getAllPackages(req:any): Promise<any> {
    const getPackage: any =  await this.postData(this.apiUrlCreator(`Package/inventory`),req);
    return getPackage;
  }

  
  async buildAgreement(req: any): Promise<any> {
    const createbuildAgreement: any = await this.postData(
      `${this.apiUrlCreator(`package/BuildAgreement`)}`,
      req
    );
    return createbuildAgreement;
  }

  async purchasePackage(req: any): Promise<any> {
    const purchaseDetails: any = await this.postData(
      `${this.apiUrlCreator(`package/purchase`)}`,
      req
    );
    return purchaseDetails;
  }

  async packageInstanceFilter(req:any): Promise<any> {
    const packageInstanceFilter: any =  await this.postData(this.apiUrlCreator(`PackageInstance/Filter`),req);
    return packageInstanceFilter;
  }

  async packageInstanceFilterCount(req:any): Promise<any> {
    const packageInstanceFilterCount: any =  await this.postData(this.apiUrlCreator(`Packageinstance/GetCountByStatus`),req);
    return packageInstanceFilterCount;
  }

  async SearchMemberByStatus(req: any): Promise<any> {
    let response = [];
    const SearchMemberByStatus: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/SearchMemberByStatus`)}`,
      req
    );
    if(SearchMemberByStatus?.ResponseCode===100){
      response=SearchMemberByStatus.Items;
    }
    return response;
  }
  async GetCountByStatus(req: any): Promise<any> {
    let response = [];
    const SearchMemberByStatus: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/GetCountByStatus`)}`,
      req
    );
    if(SearchMemberByStatus?.ResponseCode===100){
      response=SearchMemberByStatus.Items;
    }
    return response;
  }
  async packageInstanceToUse(req: any): Promise<any> {
    let response = [];
    const PackageInstanceToUse: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/touse`)}`,
      req
    );
    if(PackageInstanceToUse?.ResponseCode===100){
      response=PackageInstanceToUse.Items;
    }
    return response;
  }

  async getAuditHistory(req:any): Promise<any> {
    const getAuditHistory: any =  await this.postData(this.apiUrlCreator(`site/Audit`),req);
    return getAuditHistory;
  }
  async getNotes(req:any): Promise<any> {
    const getNotes: any =  await this.postData(this.apiUrlCreator(`Note/Search`),req);
    return getNotes;
  }
  async createNote(req:any): Promise<any> {
    const createNote: any =  await this.postData(this.apiUrlCreator(`Note`),req);
    return createNote;
  }
  async getSalesPerson(): Promise<any> {
    const getSalesPerson: any =  await this.getData(this.apiUrlCreator(`provider/SalesPerson`));
    return getSalesPerson;
  }

  async fetchPackageDetailsForCancellation(req:any): Promise<any> {
    let response:any = null
    const data: any =  await this.postData(this.apiUrlCreator(`Packageinstance/CancellationDetail`),req);
    if (data?.ResponseCode === 100) {
      response = data.Item;
    }
    return response;
  }

  async cancelPackage(req: any): Promise<any> {
    const cancelPackage: any = await this.postData(
      `${this.apiUrlCreator(`packageinstance/Cancel`)}`,
      req
    );
    return cancelPackage;
  }
  async fetchPackageBillingPeriods(req: any): Promise<any> {
    let response=[]
    const getPackageBillingPeriods: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/BillingPeriods`)}`,
      req
    );
    if (getPackageBillingPeriods?.ResponseCode === 100) {
      response = getPackageBillingPeriods.Items;
    }
    return response;
  }
  async UpdateRollOverAppoinments(req: any): Promise<any> {
    const UpdateRollOverAppoinments: any = await this.postData(
      `${this.apiUrlCreator(`Packageinstance/UpdateRolloverSchedules`)}`,
      req
    );
    return UpdateRollOverAppoinments;
  }

  async packageInstanceExcel(req:any): Promise<any> {
    const packageInstanceExcel: any =  await this.postData(this.apiUrlCreator(`PackageInstance/Export`),req,{
      responseType: 'blob'
    });
    return packageInstanceExcel;
  }

}

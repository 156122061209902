import { HardwareProviderType } from "utils/constants";
import { useFormik } from "formik";
import { RadioButton } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import { useState } from "react";

const validate = (values: any) => {
  let errors: any = {};
  if (!values.hardwareProviderType) {
    errors["hardwareProviderType"] = "Required";
  }
  return errors;
};

const HardwareProviderTypeForm = (props: any) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      hardwareProviderType: null,
    },
    validate,
    onSubmit: (values) => {
      props.handleSubmit(values, setBtnLoading);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mt-2">
        <div className="custom-radio custom-control-inline">
          <RadioButton
            name="hardwareProviderType"
            value={HardwareProviderType.Brivo}
            checked={
              formik.values.hardwareProviderType === HardwareProviderType.Brivo
            }
            onChange={(e) =>
              formik.setFieldValue("hardwareProviderType", e.value)
            }
            label="Brivo"
          />
        </div>
        <div className="custom-radio custom-control-inline">
          <RadioButton
            name="hardwareProviderType"
            value={HardwareProviderType.Openpath}
            checked={
              formik.values.hardwareProviderType ===
              HardwareProviderType.Openpath
            }
            onChange={(e) =>
              formik.setFieldValue("hardwareProviderType", e.value)
            }
            label="Openpath"
          />
        </div>
        {formik.errors.hardwareProviderType ? (
          <div>{formik.errors.hardwareProviderType}</div>
        ) : null}
      </div>
      <BuzopsButton
        label={"Submit"}
        className="k-button k-primary m-0 my-3"
        type="submit"
        style={{ marginRight: "10px", padding: "10px 20px" }}
        loading={btnLoading}
        disabled={btnLoading}
      />
    </form>
  );
};

export default HardwareProviderTypeForm;

import * as React from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';

export const MemberTypeDropdown = (props: GridCellProps) => {
    
    const memberTypeData = [
        { text: 'Primary', value: "0" },
        { text: 'Family Member', value: "1" },
    ];

    const handleChange = (e: DropDownListChangeEvent) => {
        if(props.onChange){
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.value
            });
        }
    }

    const { dataItem } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    return (
      <td>
        {dataItem.inEdit ? (
          <DropDownList
            className='grid-editors'
            onChange={handleChange}
            value={memberTypeData.find(c => c.value === dataValue.toString())}
            data={memberTypeData}
            textField="text"
                />
            ) : (
                memberTypeData.find(c => c.value === dataValue.toString())?.text
                )}
      </td>
    );
}
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import React from "react";

const ManagePayoutPaymentTerms = (props: any) => {
  const {handleCloseDialog,PaymentTerms,selectedPaymentTermObj,handleChangePaymentTermObj,btnLoading,handleUpdatePaymentTermObj} = props?.PromotionOverview;
  return (
    <>
      <Dialog
        title={"Update Payment Term & Payout Min.Value "}
        onClose={() => handleCloseDialog()}
        style={{ zIndex: 9999 }}
        width={"45%"}
        height={"350px"}
      >
        <div className="bz-mt-2rem bz-mb-2_5rem rewards-form">
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Current payout terms
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <DropDownList
                data={PaymentTerms}
                textField="Description"
                className={`form-control col-md-10`}
                dataItemKey="PaymentTermId"
                name="PromotionPaymentTerms"
                style={{ width: "100%" }}
                value={
                  PaymentTerms?.filter(
                    (i: any) =>
                      i?.PaymentTermId ===
                      selectedPaymentTermObj?.PaymentTermId
                  )?.[0] || {
                    PaymentTermId: 0,
                    Description: "Please Select Payment Term",
                  }
                }
                onChange={(e) => {
                  handleChangePaymentTermObj(
                    e.value.PaymentTermId,
                    "PaymentTermId"
                  );
                }}
              />
            </div>
          </div>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              Minimum payout For Bank(ACH)
            </div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">
              <NumericTextBox
                id={"Minimum Payout"}
                name={"Minimum Payout"}
                width={"100%"}
                className={`form-control col-md-10`}
                placeholder="Enter Minimum Payout"
                onChange={(e) => {
                  handleChangePaymentTermObj(e.value, "PayoutMinValue");
                }}
                value={selectedPaymentTermObj?.PayoutMinValue}
              />
            </div>
          </div>
          <div className="bz-content-text-row bz-notify-row mt-4 pt-2 mble-mt-0">
            <div className="bz-content-title text-right"></div>
            <span className="bz-colon text-center"></span>
            <div className="bz-content-body">
              <Button
                className="ml-0"
                disabled={btnLoading}
                onClick={() => handleCloseDialog()}
              >
                Cancel
              </Button>
              <BuzopsButton
                disabled={btnLoading}
                type={"submit"}
                loading={btnLoading}
                label={"Update"}
                onClick={() => handleUpdatePaymentTermObj()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ManagePayoutPaymentTerms;

import React, { useEffect, useState } from "react";
import moment from "moment";
import { IdleTimeOutModal } from "./idleTimeoutModal";

const IdleTimeOutHandler = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [isLogout, setLogout] = useState(false);
  let timer: any = undefined;
  let defaultTimeout = 45000;
  const events = ["click", "load", "keydown"];

  useEffect(() => {
    if(props?.noAppointments && showModal) {
      removeEvents();
      clearTimeout(timer);
      window.location.reload();
    }else {

    }
  }, [showModal, props?.noAppointments]);

  const eventHandler = (eventType: any) => {
    console.log(eventType);
    if (!isLogout) {
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        props?.onActive?.();
        startTimer();
      }
    }
  };

  useEffect(() => {
    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, [props.timeOutInterval]);

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(
      () => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(
          moment().diff(moment(lastInteractionTime)),
          "milliseconds"
        );
        let timeOutInterval = props.timeOutInterval
          ? props.timeOutInterval
          : defaultTimeout;
        if (isLogout) {
          clearTimeout(timer);
        } else {
          if (diff < timeOutInterval) {
            startTimer();
            props?.onActive?.();
          } else {
            props?.onIdle?.();
            setShowModal(true);
          }
        }
      },
      props.timeOutInterval ? props.timeOutInterval : defaultTimeout
    );
  };
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  const handleContinueSession = () => {
    setShowModal(false);
    setLogout(false);
  };
  const handleLogout = () => {
    removeEvents();
    clearTimeout(timer);
    setLogout(true);
    props.onLogout();
    setShowModal(false);
  };

  return (
    <div>
      {
        props?.noAppointments ? <></> : <IdleTimeOutModal
        showModal={showModal}
        handleContinue={handleContinueSession}
        handleLogout={handleLogout}
        timeOutInterval={props.timeOutInterval || defaultTimeout}
      />
      }
      {/* <PageExpireCounter timeOutIntervals={props.timeOutInterval || defaultTimeout} /> */}
      
    </div>
  );
};

export default IdleTimeOutHandler;

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import { MdContentCopy } from "react-icons/md";
import SharableLinksDialog from "./SharableLinkDialog";
import { PromotionSubscriptionTypes } from "utils/form-utils";
import { GetLocalStore } from "utils/storage";
import { CurrencyFormat } from "utils";
const AmountCell = (props: any) => {
  return <td>{CurrencyFormat(props?.dataItem?.NetRevenueFromPurchases)}</td>
}
const TopPromotionLinksByAssociate = (props: any) => {
  const { topPromoLinks } = props;
  const copyToClipBoard = (text: any) => {
    navigator.clipboard.writeText(text);
  };
  const [selectedLink, setSelectedLink] = React.useState({
    url: "",
    name: "",
  });
  const [showDialog, setShowDialog] = React.useState(false);
  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const handleSelectLink = (link: string, name: string) => {
    setSelectedLink({
      url: link,
      name,
    });
    setShowDialog(true);
  };
  let DomainName = GetLocalStore("tenantUserSession")?.DomainName;
  if (!props?.uuid) {
    DomainName = GetLocalStore("AffiliateDetails")?.DomainName;
  }
  const promoLinkCell = (props: any) => {
    const SubscriptionType = PromotionSubscriptionTypes?.find((i: any) => i?.id === props.dataItem?.EntityType)?.text
    const link = `https://${DomainName}.${process.env.REACT_APP_DOMAIN}.com/promolink/${props?.dataItem?.AffiliateShortCode}/${props?.dataItem?.PromotionShortCode}/${props?.dataItem?.SubscriptionShortCode}`;

    return (
      <td>
        <div className="d-flex justify-content-start align-items-center">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center linkbg">
              <div className="flex-grow-1">
                {" "}
                <a href={link} target="_blank">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div> <i
                      className="fa fa-link"
                      style={{
                        fontSize: "11px",
                        marginRight: "5px",
                      }}
                    ></i></div>
                    <div style={{ flexGrow: '1' }}>                  {`${SubscriptionType} - ${props?.dataItem?.SubscriptionName}`}
                    </div>
                  </div>
                </a>
              </div>
              <div className="flex-shrink-0">
                <span className="bz-cursor-pointer">
                  <MdContentCopy
                    onClick={() => copyToClipBoard(link)}
                    title={"Copy"}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 px-2">
            {" "}
            <a
              href="#"
              onClick={() =>
                handleSelectLink(link, props?.dataItem?.SubscriptionName)
              }
              className="btn btn-primary-buzops btn-sm text-white"
            >
              {" "}
              <em className="mdi mdi-share-variant-outline"></em>
            </a>
          </div>
        </div>
      </td>
    );
  };


  return (
    <>
      {" "}
      <div className="manage-affiliate-container">
        <Grid
          data={topPromoLinks}
          className="bzc-grid"
        >
          <GridColumn title="Promotion Name" field="PromotionName" width={300} />
          <GridColumn title="Net Revenue" field="NetRevenueFromPurchases" cell={AmountCell} width={150} />
          {/* <GridColumn title="Subscription Type" field="EntityType" cell={SubscriptionTypeCell} /> */}
          <GridColumn
            title="Subscription Promo Links"
            field="SubscriptionShortCode"
            cell={promoLinkCell}
            width={450}

          />
        </Grid>
      </div>{" "}
      {showDialog && (
        <SharableLinksDialog
          {...props}
          copyToClipBoard={copyToClipBoard}
          selectedLink={selectedLink}
          toggleDialog={toggleDialog}
        />
      )}
    </>
  );
};

export default TopPromotionLinksByAssociate;

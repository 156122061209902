import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
  Card,
  CardTitle,
  CardBody,
  CardHeader,
} from "@progress/kendo-react-layout";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { getCompleteDate, getTimeFormat, getWeekdayFormat } from "utils";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { TenantService } from "services/tenant/index.service";
import moment from "moment";
import { GetLocalStore, LocalStore } from "utils/storage";
import { ChargeTypeList } from "./CancelSession";
import { Switch } from "@progress/kendo-react-inputs";

export interface EditSessionProps {
  title: string;
  type: string;
  tableData: any;
  class: any;
  cancellationType?: any;
  handleSessionBtn: any;
  handleUpdateCancellationType?: any;
  handleShowCancelled?:any;
  showCancelled?:any;
}

const weekdayFilter = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const SessionTable = (props: EditSessionProps) => {
  const [originalData, setOriginalData] = useState(props.tableData);
  const [filterData, setFilterData] = useState(props.tableData);
  const [provider, setProvider] = useState([])

  const date = new Date();
  date.setMonth(date.getMonth() + 12);
  let initial_date = new Date(date);
  if(props?.class?.EndDate){
    initial_date=new Date(props?.class?.EndDate);
  }
  let defaultInitialFilterValues = {
    weekDay: "All Week Days",
    instructor: "All Providers",
    fromDate: new Date(),
    toDate: initial_date,
    time: "All Start Times",
  };
  let initialFilterValues = defaultInitialFilterValues;
  if(GetLocalStore('sessionFilter')){
    initialFilterValues = JSON.parse(JSON.stringify(GetLocalStore('sessionFilter')));
    initialFilterValues.fromDate = new Date(initialFilterValues.fromDate);
    initialFilterValues.toDate = new Date(initialFilterValues.toDate);
  }

  const startTimedropDown = props.tableData.map((o: any) =>
    getTimeFormat(o.ClassSessionStartDate)
  );
  const uniqueStartTime = Array.from(new Set(startTimedropDown));
  const [filterContent, setFilterContent] = useState(initialFilterValues);
  const [visible, setvisible] = React.useState(false);
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const [message, setMessage] = useState("");

  
  useEffect(() => {
    fetchProviders()
  }, []);

  const fetchProviders= async ()=>{
      const service= new TenantService()
      const res=await service.getTrainers();
      const data=res?.map((item:any)=>item?.FullName);
      setProvider(data || [])
      // setProvidersList(res)
  }


  useEffect(() => {
    let timer=1000
    if(notification.error){
      timer=3000
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);
  useEffect(() => {
    filterAll();
  }, [filterContent]);

  const toggleDialog = () => {
    setvisible(!visible);
  };
  const handleRemove = (val: boolean) => {
    if (val) {
      const length=originalData?.filter((i:any)=>i?.selected && i?.BookedSlot > 0)?.length
      if(length > 0 && !props?.cancellationType){
        setMessage("Please select cancellation type which are applicable to enrolled clients");
        setNotification({ ...notification, error: true });
        return
      }else{
        props.handleSessionBtn(originalData);
      }
    }
    toggleDialog();
  };
  let count = filterData.filter((dataItem: any) => dataItem.selected === true)
    .length;

  const customAttendanceCell = (props: any) => {
    const bookedSlot = props?.dataItem?.BookedSlot;
    return <td>{bookedSlot} of {props?.dataItem?.Slot}</td>;
  };
  const customTimeCell = (props: any) => {
    const time = `${getTimeFormat(props.dataItem.ClassSessionStartDate)} - ${getTimeFormat(
      props.dataItem.ClassSessionEndTime
    )}`;
    return <td>{time}</td>;
  };
  const customDateCell = (props: any) => {
    const date = getCompleteDate(props.dataItem.ClassSessionStartDate);
    return <td className={"capitalize "}>{date}</td>;
  };

  const cancelSession = () => {
    if (count === 0) {
      props.handleSessionBtn(originalData);
    } else {
      setvisible(true);
    }
  };

  const selectionChange = (event: any) => {
    const filterDup = JSON.parse(JSON.stringify(filterData));
    const originalDup = JSON.parse(JSON.stringify(originalData));
    const show =
        moment().format() > moment(event.dataItem?.ClassSessionStartDate).format()
          ? false
          : true;
    if(!show){
      setMessage("Past Appointments cannot be selected.");
      setNotification({ ...notification, error: true });
      return;
    }
    const original = originalDup.map((item: any) => {
      if (item.ClassSessionId === event.dataItem.ClassSessionId) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });

    const filter = filterDup.map((item: any) => {
      if (item.ClassSessionId === event.dataItem.ClassSessionId) {
        item.selected = !event.dataItem.selected;
      }
      return item;
    });
    setFilterData(filter);
    setOriginalData(original);
  };

  const headerSelectionChange = (event: any) => {
    const checked = event.syntheticEvent.target.checked;
    const originalDup = JSON.parse(JSON.stringify(filterData));
    const original = originalDup.map((item: any) => {
      const show = moment().format() > moment(item?.ClassSessionStartDate).format()
        ? false
        : true;
        if(show){
          item.selected = checked;
        }else{
          item.selected = false;
        }
      return item;
    });
    let result = originalData.map((item:any) => {
      let item2 = original.find((i2:any) => i2.ClassSessionId === item.ClassSessionId);
      return item2 ? { ...item, ...item2 } : item;
    });
    setFilterData(original);
    setOriginalData(result);
  };

  const editSession = () => {
    if (count === 0) {
      setMessage("No Appointments were selected.");
      setNotification({ ...notification, error: true });
    } else {
      LocalStore("sessionFilter", filterContent);
      props.handleSessionBtn(originalData);
    }
  };
  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const handleFilter = (val: any, name: string) => {
    setFilterContent({ ...filterContent, [name]: val });
  };

  const filterWeekDay = (item: any) => {
    if (
      filterContent.weekDay === undefined ||
      filterContent.weekDay === 'All Week Days' ||
      filterContent.weekDay === "" ||
      getWeekdayFormat(item.ClassSessionStartDate).toLowerCase() === filterContent.weekDay.toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterInstructor = (item: any) => {
    if (
      filterContent.instructor === undefined ||
      filterContent.instructor === 'All Providers' ||
      filterContent.instructor === "" ||
      item.TrainerName
        .toLowerCase()
        .includes(filterContent.instructor.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterTime = (item: any) => {
    if (
      filterContent.time === undefined ||
      filterContent.time === 'All Start Times' ||
      filterContent.time === "" ||
      getTimeFormat(item.ClassSessionStartDate).toLowerCase() ===
        filterContent.time.toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterDates = (item: any) => {
    if(moment(moment(item.ClassSessionStartDate).format('YYYY-MM-DD')).isBetween(moment(filterContent.fromDate).subtract(1, "days").format('YYYY-MM-DD'), moment(filterContent.toDate).add(1, "days").format('YYYY-MM-DD'))){
      return true;
    }else{
      return false;
    }
  };

  const ClearFilter = () => {
    setFilterData(originalData);
    setFilterContent(defaultInitialFilterValues);
  };

  const filterAll = () => {
    const results = originalData.filter((item: any) => {
      if (
        filterWeekDay(item) &&
        filterInstructor(item) &&
        filterTime(item) &&
        filterDates(item)
      ) {
        return true;
      } else {
        return false;
      }
    });
    setFilterData(results);
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="bz-fs-1rem">
            {props.title}&nbsp;({filterData.length})
          </CardTitle>
        </CardHeader>
        <CardBody className={"session-table"}>
          <div className="row filter-row">
            <div className="">
              <div className="row">
                <div className="col-sm-4 col-lg-2 mb-2 mb-lg-0">
                  <DropDownList
                    data={weekdayFilter}
                    id={"weekDay"}
                    name={"weekDay"}
                    style={{width:'100%'}}
                    onChange={(e) => handleFilter(e.value, "weekDay")}
                    value={filterContent.weekDay}
                    defaultItem={'All Week Days'}
                  />
                </div>
                <div className="col-sm-4 col-lg-2 mb-2 mb-lg-0">
                  <DropDownList
                    data={provider}
                    id={"instructor"}
                    style={{width:'100%'}}
                    name={"instructor"}
                    onChange={(e) => handleFilter(e.value, "instructor")}
                    value={filterContent.instructor}
                    defaultItem={'All Providers'}
                  />
                </div>
                <div className="col-sm-4 col-lg-2 mb-2 mb-lg-0">
                  <DropDownList
                    data={uniqueStartTime}
                    id={"time"}
                    name={"time"}
                    style={{width:'100%'}}
                    onChange={(e) => handleFilter(e.value, "time")}
                    value={filterContent.time}
                    defaultItem={'All Start Times'}
                  />
                </div>
                <div className="col-sm-4 col-lg-2 mb-2 mb-lg-0"> <DatePicker
                    name="fromDate"
                    id="fromDate"
                    width={'100%'}
                    value={filterContent.fromDate}
                    onChange={(e) => handleFilter(e.target.value, "fromDate")}
                  /></div>
                <div className="col-sm-4 col-lg-2 mb-2 mb-lg-0"><DatePicker
                    name="toDate"
                    width={'100%'}

                    id="toDate"
                    value={filterContent.toDate}
                    onChange={(e) => handleFilter(e.target.value, "toDate")}
                  /></div>
                <div className="col-sm-2 d-flex justify-content-end justify-content-md-start"><Button onClick={() => ClearFilter()} className="mr-0">Clear Filter</Button></div>
              </div>
            </div>
            {/* <div className="col-4">
              <div className="row">
                <div className="col-6">
                 
                </div>
                <div className="col-6">
                  
                </div>
              </div>
            </div>
            <div className="col-1">
              <Button onClick={() => ClearFilter()}>ClearFilter</Button>
            </div> */}
          </div>
          <Grid
            data={filterData.sort((a: any, b: any) => new Date(a.ClassSessionStartDate).setHours(0, 0, 0, 0) - new Date(b.ClassSessionStartDate).setHours(0, 0, 0, 0) )}
            selectedField="selected"
            onSelectionChange={selectionChange}
            onHeaderSelectionChange={headerSelectionChange}
            className="bzc-grid bz-selectSession edit-event-tbl pb-2"
          >
            <Column
              field="selected"
              width="50px"
              headerSelectionValue={
                filterData.findIndex(
                  (dataItem: any) => dataItem.selected === false && moment().format() <= moment(dataItem?.ClassSessionStartDate).format()
                ) === -1
              }
            />
            <Column field="ClassSessionStartDate" title="Date" cell={customDateCell} />
            <Column title="Time" cell={customTimeCell} />
            <Column field="TrainerName" title="Provider" />
            {/* <Column
              field="BookedSlot"
              title="Slots"
              cell={customAttendanceCell}
            /> */}
            <Column field="Cost" title="Price ($)" />
            <Column field="BookedSlot" title="Booked Slots" />
          </Grid>
          <div className="row">
            {props.type === "edit" && (
              <div className="edit-events-btn">
                <span className="buz-font-600">{count} Appointments Selected</span>
              <Button primary onClick={() => editSession()}>
                continue
              </Button>
              </div>
            )}
            {props.type === "cancel" && (
              <div className="select-sessions-button">
                <Button primary onClick={() => cancelSession()}>
                  {`Cancel ${count} ${count === 1 ? "Appointment" : "Appointments"}`}
                </Button>
              </div>
            )}
          </div>
          {visible && (
            <Dialog onClose={() => toggleDialog()}>
              <p className={""} style={{width:'450px', wordWrap:'break-word'}}>
                {`Are you sure you want to cancel ${count} ${count === 1 ? "Appointment" : "Appointments"}? Please note that any registered clients will be automatically cancelled.`}
              </p>
              {originalData?.filter((i:any)=>i?.selected && i?.BookedSlot > 0)?.length > 0 && 
              <div>
                <div className="bzc-provider-name mb-3 mb-md-0">
                  <span className="k-label">Cancellation Type</span>
                  <div className="bzc-time-sec">
                    <DropDownList
                      id={`cancellationType`}
                      name={`cancellationType`}
                      data={ChargeTypeList}
                      textField="text"
                      style={{width:'100%'}}
                      dataItemKey="id"
                      value={props?.cancellationType || {id:0,text:"Please select Cancellation Type"}}
                      onChange={(e) => props?.handleUpdateCancellationType(e)}
                    />
                  </div>
                </div>
              </div>}
              <DialogActionsBar>
                <Button onClick={() => handleRemove(false)}>
                  {"No,keep it."}
                </Button>
                <Button primary={true} onClick={() => handleRemove(true)}>
                  Yes
                </Button>
              </DialogActionsBar>
              {originalData?.filter((i:any)=>i?.selected && i?.BookedSlot > 0)?.length > 0 ? 
              <p className={"dialogText pt-3"}>
              <><Switch
              value={props?.showCancelled}
              onChange={(e) => props?.handleShowCancelled(e)}
            
            /> <span>Enrolled clients will be notified via email</span></>
              </p>: null}
            </Dialog>
          )}
          <BuzopsNotification
            notification={notification}
            message={message}
            handleNotification={(type: any) => handleNotification(type)}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default SessionTable;

import {GridPageChangeEvent} from "@progress/kendo-react-grid";
import React, { useEffect } from "react";
import { AffiliateProgramService } from "services/affiliateprogram/index.service";
import { CurrencyFormat } from "utils";
import { PromotionSubscriptionTypes } from "utils/form-utils";
import moment from "moment";
import useState from "react-usestateref";
const bulkInitialItems = [
  {
    text: "Mark as Paid Today",
    key: "MarkPaidToday",
    visible: true,
  },
  {
    text: "Edit Payout",
    key: "edit",
    visible: true,
  },
];
const initialDataState: any = { skip: 0, take: 10 };
const useAffiliateProgramPayouts = (props: any) => {
  const initialBulkPayout = {
    Status: null,
    Notes: "",
    PaidDateTime: new Date(),
  };
  const initialSinglePayout = {
    Status: null,
    Notes: "",
    PaidDateTime: new Date(),
  };
  const initialRejectItem = {
    dataItem: null,
    dialog: false,
    isParent: false,
  };
  const [page, setPage, pageRef] = useState<any>(initialDataState);
  const [dataState, setDataState] = React.useState<any>([]);
  const [excelData, setExcelData] = React.useState<any>([]);
  const [PayoutTotals, setPayoutTotals] = React.useState<any>(null);
  const [totalItems, setTotalItems] = React.useState<number>(0);
  const [selectedPayout, setSelectedPayout] = React.useState<any>(null);
  const [notesLoading, setNotesLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectAll, setSelectAll] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [singlePayoutLoading, setSinglePayoutLoading] = useState(false);
  const [dropDownBtnLoading, setDropDownBtnLoading] = useState({
    id: 0,
    loading: false,
  });
  const [bulkPayout, setBulkPayout] = useState<any>(initialBulkPayout);
  const [singlePayout, setSinglePayout] = useState<any>(initialSinglePayout);
  const [payoutHistory, setPayoutHistory] = useState<any>([]);
  const [historyLoading, setHistoryLoading] = useState<any>(false);
  const [fetchLinkedPayoutsLoading, setFetchLinkedPayoutsLoading] = useState<any>(true);
  const [rejectConfirmDialog, setRejectConfirmDialog] = useState<any>(false);
  const [selectedRejectItem, setSelectedRejectedItem] = useState<any>(initialRejectItem);
  const [showBulkAction, setShowBulkAction] = useState<any>(
    props?.admin ? true : false
  );
  const [showDialog, setShowDialog] = useState<any>({
    Notes: false,
    SinglePayout: false,
    BulkPayout: false,
    History: false,
    LinkedPayouts:false
  });
  const [linkedPayouts, setLinkedPayouts] = React.useState<any>({
    isLinkedDebits: false,
    parentItem:null,
    linkedPayouts:[]
  });
  const [showDebitRecords, setShowDebitRecords] = React.useState<any>({
    show: false,
    dataItem:null,
  });
  const [promoters, setPromoters] = useState<any>([]);
  const [filteredPromoters, setFilteredPromoters] = useState<any>([]);
  const [selectedStatus, setSelectedStatus, selectedStatusRef] = useState<any>(
    props?.payoutFilter? [parseInt(props?.payoutFilter)] :[-1]
  );
  const [selectedPromoter, setSelectedPromoter, selectedPromoterRef] =
    useState<any>({
      type: "search",
      value: "",
    });
  const [fromDateToDate, setFromDateToDate, fromDateToDateRef] = useState({
    from: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
    to: moment().endOf("month").endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
  });
  const [BulkActionItems, setBulkActionItems] = useState<any>(bulkInitialItems);

  const setFromDateToDateFunc = (val: any) => {
    setFromDateToDate(val);
    setPage(initialDataState)
    getPromotionPayouts();
  };

  const service = new AffiliateProgramService();

  useEffect(() => {
    getPromotionPayouts();
    fetchPromoters();
  }, []);

  const fetchLinkedPayoutsList=async(PromotionPayoutIds:any,Payout:any,isLinkedDebits:boolean,HighlightPayoutId=0)=>{
    const req={
      PromotionPayoutIds
    }
    setFetchLinkedPayoutsLoading(true)
    const data=await service.getPromotionPayoutFetchList(req)
    setFetchLinkedPayoutsLoading(false)
    setLinkedPayouts({
      isLinkedDebits: isLinkedDebits,
      parentItem:Payout,
      childItems:data,
      hightlight:HighlightPayoutId
    })
  }

  const handleLinkedPayouts=async(Payout:any,isLinkedDebits:boolean,HighlightPayoutId=0)=>{
    setShowDialog({
      ...showDialog,LinkedPayouts:true
    })
    let PromotionPayoutIds=[]
    if(isLinkedDebits){
      PromotionPayoutIds=Payout?.LinkedDebitPayouts?.map((i:any)=>i?.PayoutId)
    }else{
      PromotionPayoutIds=Payout?.LinkedCreditPayouts?.map((i:any)=>i?.PayoutId)
    }
    await fetchLinkedPayoutsList(PromotionPayoutIds,Payout,isLinkedDebits,HighlightPayoutId)
  }

  const handleConfirmReject=(dataItem: any,dialogVal:boolean,isParent=false)=>{
    setSelectedRejectedItem({
      dataItem: dataItem,
      dialog: dialogVal,
      isParent: isParent,
    })
    setRejectConfirmDialog(!rejectConfirmDialog)
  }
  const handleCloseConfirmReject=()=>{
    setSelectedRejectedItem(initialRejectItem)
    setRejectConfirmDialog(!rejectConfirmDialog)
  }

  const handleViewDebits=async(dataItem:any)=>{
    setShowDebitRecords({
      loading:true,
      dataItem:null,
      records:[]
    })
    const updatedData= dataState?.map((i:any)=>{
      if(i.PromotionPayoutId ===dataItem.PromotionPayoutId){
        return {...i,expanded: !dataItem?.expanded}
      }else{
        return {...i,expanded:false}

      }
    })
    setDataState(updatedData)
    const PromotionPayoutIds=dataItem?.LinkedDebitPayouts?.map((i:any)=>i?.PayoutId)
    const req={
      PromotionPayoutIds
    }
    const data=await service.getPromotionPayoutFetchList(req)
    setShowDebitRecords({
      loading:false,
      dataItem:dataItem,
      records:data
    })
  }

  const handlePromoterSelecton = (e: any) => {
    if (e.syntheticEvent?.key === "Enter") {
      if (promoters?.length > 0) {
        setSelectedPromoter({
          ...selectedPromoter,
          type: "select",
          value: promoters[0],
        });
      }
      return true;
    }
    setSelectedPromoter({
      ...selectedPromoter,
      type: "search",
      value: e.value,
    });
    if (e.syntheticEvent?.type === "change" && e.value.length >= 3) {
      autoCompletePromoters(e.value);
    }else if(e.syntheticEvent?.type === "change" && !e.value){
      setSelectedPromoter({
        ...selectedPromoter,
        type: "select",
        value: e.value,
      });
    }
    if (e.syntheticEvent?.type === "click") {
      setSelectedPromoter({
        ...selectedPromoter,
        type: "select",
        value: e.value,
      });
    }
    if (selectedPromoterRef.current?.type === "select") {
      setPage(initialDataState)
      getPromotionPayouts();
    }
  };

  const autoCompletePromoters = (str: string) => {
    const searchData = promoters.filter((item: any) =>
      item.FullName.toLowerCase().includes(str.toLowerCase())
    );
    setFilteredPromoters(searchData);
  };

  const fetchPromoters = async () => {
    const req = {
      IncludeInActive: true,
      PageNumber: 0, 
      PageSize: 32767
    };
    const res = await service.getPromoters(req);
    setPromoters(res?.Items || []);
    setLoading(false);
  };

  const handleFilterStatus = (e: any) => {
    let data = e.target.value.map((item: any) => item.id);
    if(data?.length > 1 && data?.includes(-1)){
      if(data[data.length - 1] ===-1){
        setSelectedStatus([-1]);
      }else if(data[0] ===-1){
        data=data?.filter((i:any)=>i !== -1)
        setSelectedStatus(data)
      }else{
        setSelectedStatus(data)
      }
    }else{
      setSelectedStatus(data);
    }
    setPage(initialDataState)
    getPromotionPayouts();
  };

  const getPromotionPayouts = async () => {
    let req: any = {
      PageSize: pageRef?.current?.take,
      PageNumber: pageRef?.current.skip / pageRef?.current.take,
    };
    if (props?.AffiliateUserId) {
      req = {
        ...req,
        AffiliateUserId: props?.AffiliateUserId,
      };
    }
    if (selectedPromoterRef.current?.type === "select") {
      req = {
        ...req,
        AffiliateUserId: selectedPromoterRef.current?.value?.UserId,
      };
    }
    if (selectedStatusRef.current?.length > 0 && !selectedStatusRef.current?.includes(-1)) {
      req = {
        ...req,
        DisplayStatuses: selectedStatusRef.current,
      };
    }
    if (fromDateToDateRef.current?.from && fromDateToDateRef.current?.to) {
      req = {
        ...req,
        FromDate: fromDateToDateRef.current?.from,
        ToDate: fromDateToDateRef.current?.to,
      };
    }

    setLoading(true);
    const res = await service.getPromotionPayouts(req);
    setLoading(false);
    const records = res?.PromotionPayouts || [];
    const response =
      records?.map((dataItem: any) =>
        Object.assign({ selected: false,expanded:false }, dataItem)
      ) || [];
    setSelectAll(false);
    setDataState(response);
    setTotalItems(res?.TotalItems);
    setPayoutTotals(res?.PayoutTotals);
    await getPromotionPayoutsExcelData(req);
  };
  const getPromotionPayoutsExcelData=async(req:any)=>{
    const payload = req
    payload.PageNumber=0;
    payload.PageSize=32767;
    const res = await service.getPromotionPayouts(payload);
    setExcelData(res?.PromotionPayouts || [])
  }
  const fetchPayoutHistory = async (payoutId: number) => {
    setHistoryLoading(true);
    const res = await service.fetchPayoutHistory(payoutId);
    setHistoryLoading(false);
    setPayoutHistory(res);
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const take = event.page.take;
    setPage({
      ...event.page,
      take,
    });
    getPromotionPayouts();
  };

  const handleShowBulkAction = () => {
    if (showBulkAction) {
      handleCancelBulkUpdate();
    }
    setShowBulkAction(!showBulkAction);
  };

  const getExcelData = () => {
    return excelData?.map((row: any) => {
      const subscriptionType = PromotionSubscriptionTypes?.filter(
        (j: any) => j?.id === row?.EntityType
      )[0]?.text;

      let subscriptionDetails = `${CurrencyFormat(row?.PurchasePrice)} - ${
        row?.SubscriptionName
      }`;

      return {
        ...row,
        SubscriptionType: subscriptionType,
        SubscriptionDetails: subscriptionDetails,
        Debit: row?.IsDebit ? "Debit" : "Credit",
        CreateDateTime: row?.CreateDateTime
          ? moment(row?.CreateDateTime).format("L")
          : "NA",
        PayoutAmount: CurrencyFormat(row?.RewardAmount),
      };
    }) || []
  };


  const toggleDialog = (name: any) => {
    setShowDialog({
      ...showDialog,
      [name]: !showDialog?.[name],
    });
  };

  const handleChangeSinglePayout = (value: any, name: string) => {
    setSinglePayout({
      ...singlePayout,
      [name]: value,
    });
  };
  const SubmitNotes = async (dataItem: any) => {
    const req = [
      {
        PromotionPayoutId: dataItem?.PromotionPayoutId,
        Notes: singlePayout?.Notes,
        Status: dataItem?.Status,
      },
    ];
    setNotesLoading(true);
    const res = await service.UpdatePayoutNotes(req);
    setNotesLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(`Notes updated successfully`, "success");
      setSinglePayout(initialSinglePayout);
      toggleDialog("Notes");
      await getPromotionPayouts();
    } else {
      const errorMsg = res?.ErrorMessages[0] || "Error in updating Notes";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleEditSinglePayout = (dataItem: any) => {
    setSelectedPayout(dataItem);
    setSinglePayout({
      ...singlePayout,
      Notes: dataItem?.Notes,
      Status: dataItem?.Status,
    });
    toggleDialog("SinglePayout");
  };
  const handleViewPayoutHistory = async (dataItem: any) => {
    setSelectedPayout(dataItem);
    toggleDialog("History");
    await fetchPayoutHistory(dataItem?.PromotionPayoutId);
  };
  const handleNotesDialog = (dataItem: any) => {
    setSelectedPayout(dataItem);
    setSinglePayout({ ...singlePayout, Notes: dataItem?.Notes });
    toggleDialog("Notes");
  };
  const handleBulkPayoutDialog = () => {
    toggleDialog("BulkPayout");
  };

  const handleBulkPayout = (value: any, name: string) => {
    setBulkPayout({
      ...bulkPayout,
      [name]: value,
    });
  };
  const handleBulkMarkPaidToday = async () => {
    const filterData = dataState?.filter((i: any) => i?.selected === true);
    const newRecords = filterData?.map((i: any) => {
      return {
        PromotionPayoutId: i?.PromotionPayoutId,
        Status: 1,
        PaidDateTime: moment().format("L"),
      };
    });
    setBtnLoading(true);
    const res = await service.UpdatePayoutStatus(newRecords);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Payout status updated successfully`,
        "success"
      );
      setSelectAll(false);
      await getPromotionPayouts();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in updating payout status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleBulkRejectPayout = async () => {
    const filterData = dataState?.filter((i: any) => i?.selected === true);
    const newRecords = filterData?.map((i: any) => {
      return {
        PromotionPayoutId: i?.PromotionPayoutId,
        Status: 2,
      };
    });
    setBtnLoading(true);
    const res = await service.UpdatePayoutStatus(newRecords);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Payout status updated successfully`,
        "success"
      );
      setSelectAll(false);
      await getPromotionPayouts();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in updating payout status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleBulkUpdate = async () => {
    const filterData = dataState?.filter((i: any) => i?.selected === true);
    const newRecords = filterData?.map((i: any) => {
      return {
        PromotionPayoutId: i?.PromotionPayoutId,
        Status: bulkPayout?.Status?.id,
        Notes: bulkPayout?.Notes,
        PaidDateTime:
          bulkPayout?.Status?.id === 1
            ? moment(bulkPayout?.PaidDateTime).format("L")
            : undefined,
      };
    });
    setBtnLoading(true);
    const res = await service.UpdatePayoutStatus(newRecords);
    setBtnLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Payout status updated successfully`,
        "success"
      );
      setBulkPayout(initialBulkPayout);
      setSelectAll(false);
      toggleDialog("BulkPayout");
      await getPromotionPayouts();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in updating payout status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleSingleUpdate = async (dataItem: any) => {
    const req = [
      {
        PromotionPayoutId: dataItem?.PromotionPayoutId,
        Notes: singlePayout?.Notes,
        Status: singlePayout?.Status,
        PaidDateTime:
          singlePayout?.Status === 1
            ? moment(singlePayout?.PaidDateTime).format("L")
            : undefined,
      },
    ];
    setSinglePayoutLoading(true);
    const res = await service.UpdatePayoutStatus(req);
    setSinglePayoutLoading(false);
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Payout status updated successfully`,
        "success"
      );
      setSinglePayout(initialSinglePayout);
      toggleDialog("SinglePayout");
      await getPromotionPayouts();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in updating payout status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleMarkPaidToday = async (dataItem: any) => {
    const req = [
      {
        PromotionPayoutId: dataItem?.PromotionPayoutId,
        Notes: dataItem?.Notes,
        Status: 1,
        PaidDateTime: moment().format("L"),
      },
    ];
    setDropDownBtnLoading({
      id: dataItem?.PromotionPayoutId,
      loading: true,
    });
    const res = await service.UpdatePayoutStatus(req);
    setDropDownBtnLoading({
      id: 0,
      loading: false,
    });
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Payout status updated successfully`,
        "success"
      );
      await getPromotionPayouts();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in updating payout status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleRejectPayout = async (rejectContent:any) => {
    const {dataItem,dialog,isParent}=rejectContent
    const req = [
      {
        PromotionPayoutId: dataItem?.PromotionPayoutId,
        Notes: dataItem?.Notes,
        Status: 2,
      },
    ];
    setDropDownBtnLoading({
      // id: dataItem?.PromotionPayoutId,
      id:0,
      loading: true,
    });
    const res = await service.UpdatePayoutStatus(req);
    setDropDownBtnLoading({
      id: 0,
      loading: false,
    });
    if (res.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Payout status updated successfully`,
        "success"
      );
      if(dialog){
        // toggleDialog("LinkedPayouts")
        if(linkedPayouts?.childItems?.length <=1 || isParent){
          toggleDialog("LinkedPayouts")
        }else{
          let  PromotionPayoutIds=linkedPayouts?.childItems?.map((i:any)=>i?.PromotionPayoutId)
          PromotionPayoutIds=PromotionPayoutIds?.filter((i:any)=>i !== dataItem?.PromotionPayoutId)
          await fetchLinkedPayoutsList(PromotionPayoutIds,linkedPayouts?.parentItem,linkedPayouts?.isLinkedDebits)
        }
      }
      handleCloseConfirmReject()
      await getPromotionPayouts();
    } else {
      const errorMsg =
        res?.ErrorMessages[0] || "Error in updating payout status";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };
  const handleCancelBulkUpdate = () => {
    setBulkPayout(initialBulkPayout);
    setShowDialog({ ...showDialog, BulkPayout: false });
    setSelectAll(false);
    const data = dataState?.map((i: any) => {
      return { ...i, selected: false };
    });
    setDataState(data);
  };

  const handleCheckboxChange = (event: any, dataItem: any) => {
    const updatedData = [...dataState];
    const index = updatedData.findIndex(
      (item) => item.PromotionPayoutId === dataItem.PromotionPayoutId
    );
    updatedData[index].selected = !updatedData[index].selected;
    setDataState(updatedData);
    const check = updatedData.some((i: any) => i?.selected === false);
    handleBulkDropdownOptions(updatedData?.filter((i: any) => i?.selected));
    setSelectAll(!check);
  };
  const handleBulkDropdownOptions = (selectedData: any) => {
    const checkPaid = selectedData.some((i: any) => i?.DisplayStatus === 1);
    const checkIsRejected = selectedData.some(
      (i: any) => i?.DisplayStatus === 2
    );
    const checkIsDebit = selectedData.every((i: any) => i?.IsDebit);
    const checkIsCredit = selectedData.every((i: any) => !i?.IsDebit);
    // const checkPaid=selectedData.some((i: any) => i?.DisplayStatus === 1)
    const data = BulkActionItems?.map((i: any) => {
      if (i?.key === "MarkPaidToday") {
        return { ...i, visible: !checkPaid };
      } else if (i?.key === "reject") {
        let text = "Reject / Waive";
        if (checkIsCredit) {
          text = "Reject";
        } else if (checkIsDebit) {
          text = "Waive";
        }
        return { ...i, visible: !checkIsRejected, text: text };
      }
      return { ...i };
    });
    setBulkActionItems(data);
  };

  const handleSelectAll = () => {
    const updatedData = [...dataState];
    updatedData.forEach((item) => (item.selected = !selectAll));
    setDataState(updatedData);
    setSelectAll(!selectAll);
    if (selectAll) {
      setBulkPayout(initialBulkPayout);
    }
  };
  return {
    toggleDialog,
    singlePayout,
    handleChangeSinglePayout,
    notesLoading,
    singlePayoutLoading,
    SubmitNotes,
    selectedPayout,
    handleSingleUpdate,
    historyLoading,
    payoutHistory,
    handleNotesDialog,
    handleEditSinglePayout,
    handleViewPayoutHistory,
    loading,
    dataState,
    pageRef,
    totalItems,
    pageChange,
    selectAll,
    handleSelectAll,
    bulkPayout,
    handleBulkPayout,
    handleCancelBulkUpdate,
    btnLoading,
    handleCheckboxChange,
    handleBulkUpdate,
    showDialog,
    showBulkAction,
    handleShowBulkAction,
    getExcelData,
    handleBulkPayoutDialog,
    handlePromoterSelecton,
    selectedPromoter,
    filteredPromoters,
    selectedStatus,
    handleFilterStatus,
    setFromDateToDateFunc,
    PayoutTotals,
    handleMarkPaidToday,
    dropDownBtnLoading,
    handleRejectPayout,
    BulkActionItems,
    handleBulkMarkPaidToday,
    handleBulkRejectPayout,
    handleLinkedPayouts,
    linkedPayouts,
    fetchLinkedPayoutsLoading,
    handleConfirmReject,
    handleCloseConfirmReject,
    rejectConfirmDialog,
    selectedRejectItem,
    showDebitRecords,
    handleViewDebits
  };
};

export default useAffiliateProgramPayouts;

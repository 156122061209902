import React from "react";
import {
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import moment from "moment";

const DateCell = (props: any) => {
  return <td>{props?.dataItem[props?.field] ? moment(props?.dataItem[props?.field]).format("L"):`N/A`}</td>;
};
const PercentageCell = (props: any) => {
  return <td>{`${props?.dataItem[props?.field]}%`}</td>;
};
const TaxHistory = (props: any) => {
  const {taxHistory,tax}=props

  return (
    <>
      <h6 className="bz-article-headline mb-3">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => props?.handleClose()}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;
        {`History (${tax?.TaxName})`}
      </h6>
      <Grid
          // dataItemKey="TaxId"
          data={taxHistory}
          className="bzc-grid bz-surcharge-history"
        >
          <Column
            field="TaxName"
            title="Surcharge Name"
          />
          <Column field="Percentage" title="Percentage" cell={PercentageCell} />
          <Column field="UpdateDateTime" title="Updated Time" />
          <Column field="StartDate" title="Effective Start Date" cell={DateCell} />
          <Column field="EndDate" title="Effective End Date" cell={DateCell} />
        </Grid>
    </>
  );
};

export default TaxHistory;

export enum MemberType {
  PrimaryMember = 0,
  SubMember = 1,
}

export enum OpenPathCredentialStatusType {
  Suspended = "Suspended",
  Pending = "Pending",
  Active = "Active",
  NoAccess = "No Access",
}

export enum OpenpathCrendentialType {
  Mobile = 1,
  CardWeigand = 2,
  CardMIFARE = 4,
  CardDESFire = 5,
}

export const OpenpathCrendentialOptions = [
  { id: OpenpathCrendentialType.Mobile, text: "Mobile" },
  {
    id: OpenpathCrendentialType.CardMIFARE,
    text: "Card-MIFARE",
  },
  {
    id: OpenpathCrendentialType.CardDESFire,
    text: "Card-DESFire",
  },
  {
    id: OpenpathCrendentialType.CardWeigand,
    text: "Card-Wiegand",
  },
];

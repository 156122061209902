import BuzopsLoader from "generic-components/BuzopsLoader";
import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { CurrencyFormat } from "utils";
import moment from "moment";
import { CustomColumnMenu } from "components/reports/Generic/customColumnMenu";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";

const dataCell = (props: any, column: any) => {
  if (column.type === "amount") {
    return <td>{CurrencyFormat(props.dataItem[props.field])}</td>;
  } else if (column.type === "date") {
    return <td>{moment(props.dataItem[props.field]).format("YYYY-MM-DD")}</td>;
  } else if (column.type === "dateMonth") {
    return <td>{moment(props.dataItem[props.field]).format("YYYY-MM")}</td>;
  } else if (column.type === "dateTime") {
    return (
      <td>{moment(props.dataItem[props.field]).format("YYYY-MM-DD HH:MM")}</td>
    );
  }
  return <td>{props.dataItem[props.field]}</td>;
};

const TaxesGrid = ({
  excelExport,
  result,
  stateGridColumns,
  dataState,
  dataStateChange,
  onColumnsSubmit,
  toggleFullscreen,
  loading,
  total,
  pageable,
  sort,
  handleChangeSort,
  filterData,
  clearFilterOne,
  clearAllFilter,
  actualFilterData,
  fullScreen
}: any) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          {filterData.map((el: any, id: any, nextEle: any) => (
            <>
              <h2
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",

                  borderRadius: "25px",
                  float: "left",
                  paddingTop: "7px",
                  color: "#626469",
                }}
              >
                {el.DisplayName}{" "}
                <span style={{ fontSize: "15px", fontWeight: "lighter" }}>
                  {(el.Operator === "eq" && "=") ||
                    (el.Operator === "neq" && "<>") ||
                    (el.Operator === "gte" && ">=") ||
                    (el.Operator === "gt" && ">") ||
                    (el.Operator === "lte" && "<=") ||
                    (el.Operator === "lt" && "<") ||
                    (el.Operator === "doesnotcontain" && "Does NOT Contains") ||
                    (el.Operator === "startswith" && "Starts With") ||
                    (el.Operator === "contains" && "Contains") ||
                    (el.Operator === "endswith" && "Ends With") ||
                    el.Operator}{" "}
                </span>
                {el.Value}{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#888888",
                    paddingRight: "0.6rem",
                    paddingLeft: "0.5rem",
                  }}
                  onClick={() => clearFilterOne(el, id)}
                >
                  X{" "}
                </span>
              </h2>
              {filterData.length > 1 && filterData.length !== id + 1 ? (
                <span style={{ margin: "6px" }}>
                  {" "}
                  {el.DisplayName === nextEle[id + 1].DisplayName
                    ? nextEle[id + 1].Logic
                    : "and"}
                </span>
              ) : null}
            </>
          ))}

          {filterData.length > 0 ? (
            <div style={{ display: "inline-block" }}>
              <button
                style={{
                  backgroundColor: "#008db9",
                  borderRadius: "20px",
                  width: "90px",
                  color: "#fff",
                  border: "none",
                  fontSize: "15px",
                  padding: "5px",
                }}
                onClick={clearAllFilter}
              >
                Clear all
              </button>
            </div>
          ) : null}
        </div>

        <div className="pb-4 taxes-header-search">
          <div className="taxes-download-header">
            <button
              title="Download"
              className="mr-3 taxes-download-button"
              onClick={excelExport}
            >
              <li className="k-icon k-i-download"></li>
            </button>
          </div>
          <div className="taxes-fullscreen-header">
          <BuzopsTooltip
          position={"bottom"}
              html={
                <button
            title={fullScreen? "Exit Fullscreen":"Fullscreen"}
            className="taxes-fullscreen-button"
            onClick={toggleFullscreen}
            >
            <li className="k-icon k-i-full-screen"></li>
            </button>
              }
            />
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <BuzopsLoader type="list" />
        </>
      ) : (
        <>
          <div className={"tax-report-grid"}>
            <Grid
              data={orderBy(result, sort)}
              onDataStateChange={dataStateChange}
              sortable={true}
              sort={sort}
              onSortChange={handleChangeSort}
              pageSize={dataState.take}
              resizable={true}
              total={total}
              skip={dataState.skip}
              pageable={pageable}
            >
              {stateGridColumns.map((column: any, idx: any) => {
                if (column.show) {
                  if (column.field == "TransactionDate") {
                    return (
                      <Column
                        width={column.width}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        cell={(props) => dataCell(props, column)}
                      />
                    );
                  } else {
                    return (
                      <Column
                        width={column.width}
                        key={idx}
                        field={column.field}
                        title={column.title}
                        filter={column.filter}
                        cell={(props) => dataCell(props, column)}
                        columnMenu={(props) => {
                          var modifiedFilters =
                            actualFilterData?.filters?.length > 0
                              ? actualFilterData
                              : undefined;

                          props = { ...props, filter: modifiedFilters };

                          return (
                            <CustomColumnMenu
                              {...props}
                              columns={stateGridColumns}
                              onColumnsSubmitClientWise={onColumnsSubmit}
                            />
                          );
                        }}
                      />
                    );
                  }
                }
              })}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default TaxesGrid;

import React, { useEffect,useState,useRef } from "react";
import {
  GetLocalStore,
  LocalStore,
  RemoveAllExceptTenant,
} from "utils/storage";
import PurchaseClassList from "components/class/PurchaseClassList";
import withNotification from "components/Hoc/withNotification";
import { useParams } from "react-router-dom";
import PurchasePackageList from "components/package/PurchasePackageList";
import { useDispatch } from "react-redux";
import { setRedirectionConfig } from "redux-slices/redirectionSlice";
import { setUserConfiguration } from "redux-slices/userSlice";
import PurchasePlanList from "components/plans/PurchasePlanList";
import Service from "components/service/Service";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { TenantService } from "services/tenant/index.service";
import { CapitalizeStr } from "utils";

const ExternalHome = (props: any) => {
  const { type } = useParams<any>();
  const dispatch = useDispatch();
  const targetRef = useRef<any>();

  const [tenantDetails, setTenantDetails] = useState<any>(GetLocalStore('tenantDetails'));
  const [isLoading, setIsLoading] = useState(false);
  const [servicesData, setServicesData] = useState<any>([]);
  const [isServiceLoading, setIsServiceLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const unloadFunc = () => {
    dispatch(setUserConfiguration({
      loggedIn:false,
      userDetails:null
    }))
    //use the history and the location from the props instead of window
    props.history.replace({
      ...props.location,
      state: undefined,
    });
    const errorMsg = `You have logged out successfully `;
    props?.handleNotificationMessage(errorMsg, "success");
  };

  useEffect(() => {
    let obj: any = { firstPathValue: "external" };
    if (type) {
      obj = { ...obj, secondRoute: type };
    }
    dispatch(setRedirectionConfig(obj));
  }, []);

  if (props.location?.state?.logout) {
    RemoveAllExceptTenant();
    const hostValues = window.location.host.split(".");
    if (
      hostValues.length === 3 &&
      hostValues[0] !== "www" &&
      hostValues[0] !== "ui"
    ) {
      const tenantName = hostValues[0];
      LocalStore("tenant", tenantName);
    }
  }

  useEffect(() => {
    if (!GetLocalStore("userDetails") && props.location?.state?.logout) {
      unloadFunc();
    }
  }, [GetLocalStore("userDetails")]);
  useEffect(() => {
    fetchTenants();
  }, [GetLocalStore("tenant")]);

  async function fetchTenants() {
    // const service = new TenantService();
    setTenantDetails(GetLocalStore('tenantDetails'));
    if (props?.show === "service" || !props?.show) {
      fetchServices();
    }
  }

  async function fetchServices() {
    const service = new TenantService();
    let req = {};
    setIsServiceLoading(true);
    if (props?.shortCodeValue) {
      req = { ShortCode: props?.shortCodeValue, 
        IncludeHostOnlineOnly:false
      };
    }else{
      req = { 
        IncludeHostOnlineOnly:true
      };
    }
    req= {...req,IsActive:true}
    const servicedet = await service.getServiceDetails(req);
    const result = servicedet.Result;
    if (result) {
      setServicesData(result);
    }
    setIsServiceLoading(false);
  }
  const handleTabSelect = (tabValue: number) => {
    setSelectedTab(tabValue);
  };

  const tabs = [
    {
      title: "One-on-One",
      visible: tenantDetails?.IsIndividualServicesTabVisible,
      id: 1,
    },
    {
      title: "Group",
      visible: tenantDetails?.IsGroupServicesTabVisible,
      id: 2,
    },
    {
      title: "Memberships",
      visible: tenantDetails?.IsMemberplanTabVisible,
      id: 3,
    },
    {
      title: "Packages",
      visible: tenantDetails?.IsPackageTabVisible,
      id: 4,
    },
  ];

  return (
    <div className={`row justify-content-center bz-classes-in-online bz-tabstrip bz-noaboutus-page`}>
    <div className="col-12 col-xl-10">
      {!props?.show && (
        <TabStrip
          selected={selectedTab}
          onSelect={(e) => handleTabSelect(e.selected)}
        >
          {tabs
            .filter((i) => i?.visible === true)
            .map((item, index) => {
              return (
                <TabStripTab
                  title={`${CapitalizeStr(item.title)}`}
                  key={index}
                >
                  {item.id === 1 && (
                    <Service
                      servicesData={servicesData}
                      isServiceLoading={isServiceLoading}
                      // serviceShortName={props?.shortCodeValue}
                    />
                  )}
                  {item.id === 2 && <PurchaseClassList page={"online"} />}
                  {item.id === 3 && <PurchasePlanList />}
                  {item.id === 4 && <PurchasePackageList page={"online"} />}
                </TabStripTab>
              );
            })}
        </TabStrip>
      )}
      {props?.show === "service" && (
        <Service
          servicesData={servicesData}
          isServiceLoading={isServiceLoading}
          serviceShortName={props?.shortCodeValue}
        />
      )}
      {props?.show === "class" && (
        <PurchaseClassList
          page={"online"}
          guest={true}
          classShortName={props?.shortCodeValue}
        />
      )}
      {props?.show === "plan" && (
        <PurchasePlanList
          page={"online"}
          guest={true}
          planShortName={props?.shortCodeValue}
        />
      )}
    </div>
  </div>
  );
};

export default withNotification(ExternalHome);

import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import BuzopsButton from "generic-components/BuzopsButton";
import { Button } from "@progress/kendo-react-buttons";
import Coupons from "../Coupons";
import { filterBy } from "@progress/kendo-data-query";
import withNotification from "components/Hoc/withNotification";
import { CheckUserMemberStatus, CurrencyFormat, CustomToFixed, checkHasTaxEnable } from "utils";
import { useDispatch } from "react-redux";
import { SetNavigateToAPageOnButtonClick } from "redux-slices/genericSlice";
import AddonPayments from "./AddonPayments";
import AuditHistory from "../AuditHistory";
import CheckPermissions from "utils/permissions";
import { PermissionEnum } from "utils/PermissionEnum";
import Agreements from "../Agreements";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";

const AddonOverView = (props: any) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<any>(true);
  const [couponLoading, setCouponLoading] = useState<any>(false);
  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const [couponValue, setCouponValue] = useState<any>(null);
  const [CouponsToAdd, setCouponsToAdd] = useState<any>([]);
  const [couponsList, setCouponsList] = useState<any>([]);
  const [couponRemoveVisible, setCouponRemoveVisible] = useState<any>(false);
  const [couponAddVisible, setCouponAddVisible] = useState<any>(false);
  const [removeCouponValue, setRemoveCouponValue] = useState<any>(null);
  const [dialogLoading, setDialogLoading] = useState<any>(false);
  const [PredictedTaxes, setPredictedTaxes] = useState<any>([]);
  const [PredictedTotalTaxAmount, setPredictedTotalTaxAmount] = useState<number>(0);
  const [userData, setUserData] = useState<any>(props?.userData);

  const service = new TenantService();

  const [selectedAddon, setSelectedAddon] = useState<any>(null);

  useEffect(() => {
    let memberId = props?.userData?.UserMemberId;
    if (memberId) {
      fetchAllApis(memberId);
    }
  }, []);

  const fetchAllApis=async(memberId:any)=>{
    const taxes=await fetchTaxes() 
    fetchUserDetails(memberId);
    fetchAddonSummary(taxes);
  }

  const CheckAddonCancelPermission = (SubscriptionStatus: any) => {
    return CheckPermissions(PermissionEnum["ApproveCancelRequest"]) &&
      SubscriptionStatus !== "Completed" && SubscriptionStatus !== "Cancelled"
  }
  const CheckAddonFreezePermission = (SubscriptionStatus: any) => {
    return CheckPermissions(PermissionEnum["ApproveFreezeRequest"]) &&
      SubscriptionStatus !== "Completed" && SubscriptionStatus !== "Cancelled"
  }


  const fetchUserDetails = async (userMemberId: any) => {
    setUserData(props?.userData);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService= new ManageTaxesService()
    const res = await taxService.getTaxes(req);
    setPredictedTaxes(res);
    return res
  };

  const fetchAddonSummary = async (taxes=PredictedTaxes) => {
    const req = {
      EntityId: props?.selectedAddonId,
      PageSize: 32767,
      PageNumber: 0,
      UserMemberId: props?.userData?.UserMemberId,
    };
    setLoading(true);
    const res = await service?.getAddOnSummary(req);
    setLoading(false);
    setSelectedAddon(res);
    setCouponValue(null);
    setShowCoupon(false);
    setCouponsToAdd(res?.CouponsToAdd);
    setCouponsList(res?.Coupons);
    handleTaxCalculation(res?.RecurringAmount-res?.CouponAmount,taxes)

  };

  const handleTaxCalculation=(purchaseAmt:number,TaxItems=PredictedTaxes)=>{
    const TaxesArray=TaxItems.map((i:any)=>{
      const amount=purchaseAmt*i?.Percentage/100;
      const calcAmt=CustomToFixed(amount,2)
        return {
          "TaxId": i?.TaxId,
          "TaxName": i?.TaxName,
          "Percentage": i?.Percentage,
          "Amount": calcAmt
        }
    })
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
          return acc + currentValue?.Amount
    }, 0);
      setPredictedTaxes(TaxesArray)
      setPredictedTotalTaxAmount(taxAmt)
  }

  const toggleAddCouponDialog = () => {
    if (!couponValue) {
      const errorMsg = "Please select coupon";
      props?.handleNotificationMessage(errorMsg, "error");
      return;
    }
    if (!props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage('', "error", true, 'client');
      return
    } else if (props?.staffPage && !CheckUserMemberStatus(userData)) {
      props?.handleNotificationMessage('', "error", false, 'staff');
      return
    }
    setCouponAddVisible(!couponAddVisible);
  };

  const handleAddCouponToPlan = async (val: boolean) => {
    if (val) {
      await AddCouponToPlan();
    }
    toggleAddCouponDialog();
  };
  const toggleRemoveCouponDialog = () => {
    setCouponRemoveVisible(!couponRemoveVisible);
  };

  const handleRemoveCouponItem = (dataItem: any) => {
    setRemoveCouponValue(dataItem);
    toggleRemoveCouponDialog();
  };
  const handleCoupon = (val: any) => {
    setCouponValue(val);
  };

  const CouponfilterChange = (e: any) => {
    const filteredData = CouponFilterData(e.filter);
    setCouponsToAdd(filteredData);
  };

  const CouponFilterData = (filter: any) => {
    const data = selectedAddon?.CouponsToAdd.slice();
    return filterBy(data, filter);
  };

  const handleRemoveCouponFromPlan = async (val: boolean) => {
    if (val) {
      await handleRemoveCoupon();
    }
    toggleRemoveCouponDialog();
  };

  const handleRemoveCoupon = async () => {
    const req = removeCouponValue;
    setDialogLoading(true);
    const result = await service?.DeActivateMemberCoupon(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchAddonSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Removing Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const AddCouponToPlan = async () => {
    const req = { ...couponValue, EntityType: 3 };
    setDialogLoading(true);
    const result = await service?.AddCouponToExistingPlan(req);
    setDialogLoading(false);
    if (result?.ResponseCode === 100) {
      const successMsg = result?.SuccessMessage;
      props?.handleNotificationMessage(successMsg, "success");
      await fetchAddonSummary();
    } else {
      const errorMsg = result?.ErrorMessages?.[0] || "Error In Adding Coupon";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const renderRecurrenceDetails = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (selectedAddon === null) {
      return "No Recurrence Details Found";
    }
    const { RecurringAmount, NextRecurrence,Frequency,CouponAmount,CanCollectTaxes } = selectedAddon;
    return (
      <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Recurring Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {" "}
            {NextRecurrence !=='N/A' ? moment(NextRecurrence).format("L") : Frequency ===1 ?"NA - One Time": "NA"}
          </div>
        </div>
        <div className="bz-content-text-row align-items-start">
          <div className="bz-content-title text-right">
          <div className="d-flex flex-column justify-content-end">
              <span>Recurring Amount {checkHasTaxEnable() && CanCollectTaxes && NextRecurrence !=='N/A' && Frequency !==1 && <span>*</span>}</span>
              {checkHasTaxEnable() && CanCollectTaxes  && NextRecurrence !=='N/A' && Frequency !==1 &&  <small style={{fontSize:'10px',color:'#777'}}><Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentPackageService
                      title={tooltipProps.title}
                      data={PredictedTaxes}
                      TotalTaxAmount={PredictedTotalTaxAmount}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"(Surcharges)"}
                </a>
              </Tooltip></small>}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(Number(RecurringAmount)-Number(CouponAmount))}
          {CanCollectTaxes && NextRecurrence !=='N/A' && Frequency !==1 && <small className="pl-1">{`(Exclusion of Surcharges)`}</small>}
          </div>
        </div>
      </div>
    );
  };
  const renderPlanDetails = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (selectedAddon === null) {
      return "No Membership Details Found";
    }
    const { PlanName, MembershipPlanId } = selectedAddon;
    return (
      <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Membership Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{PlanName}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Membership ID</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{`MP-${MembershipPlanId}`}</div>
        </div>
      </div>
    );
  };
  const renderSubscriptionDetails = () => {
    if (loading) {
      return <BuzopsLoader type={"list"} />;
    }
    if (selectedAddon === null) {
      return "No Subscriptions Data Found";
    }
    const {
      Charge,
      PurchasedDate,
      StartDateFormatted,
      EndDate,
      SalesPersonName,
      FrequencyDescription,
      Frequency,
      RepeatFrequency,
      PackageDescription,
      ServiceName,
      CanCollectTaxes 
    } = selectedAddon;
    return (
      <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{ServiceName}</div>
        </div>
        <div className="bz-content-text-row align-items-start">
          <div className="bz-content-title text-right">
            <div className="d-flex flex-column justify-content-end">
              <span>Charge {CanCollectTaxes && <span>*</span>}</span>
              {CanCollectTaxes && <small style={{fontSize:'10px',color:'#777'}}>(Exclusion of Surcharges)</small>}
            </div>
          </div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{`${CurrencyFormat(Charge)}`}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Purchase Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {moment(PurchasedDate).format("L")}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Start Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {StartDateFormatted ? moment(StartDateFormatted).format("L") : `NA`}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">End Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {EndDate ? moment(EndDate).format("L") : `NA`}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Repeat Every</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{`${Frequency === 1 ? "NA" : RepeatFrequency} - ${FrequencyDescription}`}</div>
        </div>

        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Description</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{PackageDescription}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Signed up by</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{SalesPersonName}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="dFlexBtn bz-planAddon">
        <h5 className="mb-3 bz_fs_1_5"><button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => dispatch(SetNavigateToAPageOnButtonClick({ id: 136, parent: false }))}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;Add-On Overview</h5>
        {props?.staffPage && (<span className="float-right">
          <div className="bz-schedule-btn-containet">
          {(CheckAddonCancelPermission(selectedAddon?.Status) && (<>
            <Button
              className={"bz-schedule-btn"}
              primary={true}
              onClick={() => {
                dispatch(SetNavigateToAPageOnButtonClick({ id: 28, parent: false }))
              }}
              disabled={!CheckAddonCancelPermission(selectedAddon?.Status)}
            >
              Cancel
            </Button>
            </>))}
            &nbsp;
            {CheckAddonFreezePermission(selectedAddon?.Status) && (<>
              <Button
                className={"bz-schedule-btn"}
                primary={true}
                onClick={() => {
                  dispatch(SetNavigateToAPageOnButtonClick({ id: 30, parent: false }))
                }}
                disabled={!CheckAddonFreezePermission(selectedAddon?.Status)}
              >
                {selectedAddon?.Status !== "Frozen" ? "Freeze" : "Unfreeze"}
              </Button>
            </>)}
          </div>
        </span>)}
      </div>
      <hr />
      <div className="bz-subsection-row bz-plan-overview bz-addon-overview">
        {/* <h5 className="mb-3 bz_fs_1_5">Add-On Overview</h5> */}
        {(loading) ? (
          <BuzopsLoader type={"content"} />
        ) : (<>
          <div className="left-section">
            <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
            {renderPlanDetails()}
            <hr />
            <h5 className="mb-3 pt-3 bz_fs_1_5">Subscription Info  <span
                              className={`badge badge-status-${selectedAddon?.Status}`}
                            >
                              {selectedAddon?.Status}
                            </span></h5>
            {renderSubscriptionDetails()}
            <hr />
            <h5 className="mb-3 pt-3 bz_fs_1_5">Recurrence Details</h5>
            {renderRecurrenceDetails()}
            {props?.staffPage ? <>
            <hr />
              <div className="bz-mt-2rem mb-3 applied-coupons">
              <h5 className="my-3 bz_fs_1_5">Applied Coupons</h5>
              <Coupons
                loading={couponLoading}
                showCoupon={showCoupon}
                setShowCoupon={setShowCoupon}
                CouponsToAdd={CouponsToAdd}
                status={selectedAddon?.Status}
                handleCoupon={handleCoupon}
                couponValue={couponValue}
                CouponfilterChange={CouponfilterChange}
                couponsList={couponsList}
                toggleAddCouponDialog={toggleAddCouponDialog}
                handleRemoveCouponItem={handleRemoveCouponItem}
              />
            </div>
            </>
            :null}
            
          </div>
          {couponAddVisible && (
            <Dialog onClose={() => toggleAddCouponDialog()}>
              <p className={"dialogContainer"}>
                Are you sure you want to apply the selected coupon?
              </p>
              <p className={"dialogContainer"}>
                Note: It will override the existing same type coupon.
              </p>
              <DialogActionsBar>
                <Button onClick={() => handleAddCouponToPlan(false)}>
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  label="Ok"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleAddCouponToPlan(true)}
                />
              </DialogActionsBar>
            </Dialog>
          )}
          {couponRemoveVisible && (
            <Dialog onClose={() => toggleRemoveCouponDialog()}>
              <p className={"dialogContainer"}>Are you sure to Deactivate ?</p>
              <DialogActionsBar>
                <Button onClick={() => handleRemoveCouponFromPlan(false)}>
                  {"Cancel"}
                </Button>
                <BuzopsButton
                  label="Ok"
                  loading={dialogLoading}
                  disabled={dialogLoading}
                  onClick={() => handleRemoveCouponFromPlan(true)}
                />
              </DialogActionsBar>
            </Dialog>
          )}
            <div>
              <hr />
              <div className="bz-mt-2rem bz-mb-2_5rem">
                <h5 className="my-3 pb-2 bz_fs_1_5">Payment Transactions</h5>
                <AddonPayments
                  {...props}
                  selectedAddonId={props?.selectedAddonId}
                  userData={userData}
                  staffPage={props?.staffPage}
                  className="addon"
                />
              </div>
              <hr />
              <div className="bz-mt-2rem bz-mb-2_5rem">
                <h5 className="my-3 bz_fs_1_5">Audit History</h5>
                <AuditHistory
                  {...props}
                  EntityId={props?.selectedAddonId}
                  UserMemberId={userData?.UserMemberId}
                  Type={16}
                  heading={true}
                  userData={userData}
                  staffPage={props?.staffPage}
                />
              </div>
              <hr />
              <div className="bz-mt-2rem bz-mb-2_5rem">
                {/* <h5 className="my-3 bz_fs_1_5">Agreements</h5> */}
                <Agreements 
                  {...props}
                  userData={userData}
                  staffPage={props?.staffPage}
                  selectedItemId={props?.selectedAddonId}
                  type={'addon'}
                  key={"child"}
                />
              </div>
            </div>
            
        </>)}
      </div>
    </>)
}

export default withNotification(AddonOverView);

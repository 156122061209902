import React, { useEffect, useState } from "react";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from "@progress/kendo-react-layout";
import { GetLocalStore } from "utils/storage";
import { useHistory, withRouter } from "react-router-dom";
import { getFullImageUrl } from "utils";
import { CheckInService } from "services/checkin/index.service";
import { useSelector } from "react-redux";

const CheckInsHeader = () => {
  const history = useHistory();
  const { loggedInStaffDetails, isLoggedIn } = useSelector(
    (state: any) => state?.staffCheckIn
  );
  const [logo, setLogo] = useState(
    "https://uatchargehub.buzops.com/Assets/Images/logo.png"
  );
  const [logoName, setLogoName] = useState(GetLocalStore("tenant"));
  const [tenantDetails, setTenantDetails] = useState<any>(null);

  useEffect(() => {
    document.title = GetLocalStore("tenant")
      ? GetLocalStore("tenant") + " - Check-Ins"
      : "Check-Ins";
    fetchTenants();
  }, [GetLocalStore("tenant")]);

  useEffect(() => {
    if (tenantDetails) {
      if (tenantDetails?.Logo && tenantDetails?.Logo !== "" && tenantDetails?.Logo !== "/Assets/Images/logo.png") {
        setLogo(tenantDetails?.Logo);
      }
      setLogoName(tenantDetails?.ClubName);
    }
  }, [tenantDetails]);

  useEffect(() => {
    if (
      GetLocalStore("userDetails") &&
      GetLocalStore("userDetails")?.UserSession?.CurrentApplicationType === 2
    ) {
      handleLogout();
    }
  }, [GetLocalStore("userDetails")]);

  const handleLogout = () => {
    history.push({
      pathname: `/kiosk/login`,
      state: {
        logout: true,
      },
    });
  };

  async function fetchTenants() {
    const service = new CheckInService();
    const result = await service.getTenantDetails(GetLocalStore("tenant"));
    setTenantDetails(result?.Item?.UserSession);
  }
  return (
    <React.Fragment>
      <AppBar className="bz-navBar sticky-top px-3 self-checkin-nav">
        <AppBarSection>
          <div className="row">
            <div className="col-md-6">
              <span className="title buz-font-600 bz-cursor-pointer logo-container pr-3">
                <img src={getFullImageUrl(logo)} alt="Logo" />
              </span>{" "}
            </div>
            <div className="col-md-6 text-right">
              {" "}
              <h6 className="bz-tenet-name bz-cursor-pointer position-relative mb-0 pb-0 pl-0">
                {logoName}
                <small className="ba-tag-name">Check-Ins</small>
              </h6>{" "}
            </div>
          </div>
        </AppBarSection>
        <AppBarSpacer />
        <AppBarSection className="bz-user-login-sec">
        </AppBarSection>
      </AppBar>
    </React.Fragment>
  );
};

export default withRouter(CheckInsHeader);

import withNotification from "components/Hoc/withNotification";
import useStaffOnsiteAccessHook from "../Container/StaffOnsiteAccess.hook";
import MemberCredential from "./MemberCredential";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { HardwareProviderType } from "utils/constants";
import PassportMemberCredential from "components/onsiteaccess/Components/PassportMemberCredential";

const StaffOnsiteAccess = (props: any) => {
  const staffOnsiteAccess = useStaffOnsiteAccessHook(props);
  return (
    <>
      <>
        {staffOnsiteAccess.Loading ? (
          <BuzopsLoader type={"list"} />
        ) : (
          <div
            className=""
            style={{
              width: "100%",
              opacity: staffOnsiteAccess.processing ? 0.5 : 1,
            }}
          >
            <Card className="" style={{ width: "100%" }}>
              <CardHeader className="p-0">
                <CardTitle className="bz-card-title">
                  {"Onsite Access"}
                </CardTitle>
              </CardHeader>
              <CardBody className="p-0">
                <>
                  {staffOnsiteAccess.processing && (
                    <Loader
                      type="infinite-spinner"
                      className="laoder-full-screen"
                    />
                  )}
                  {staffOnsiteAccess?.securityConfig?.HardwareSecuritySystemPreferences?.ProviderType === HardwareProviderType.Openpath ? <MemberCredential
                    {...staffOnsiteAccess.memberAccess}
                    ConfiguredGroups={staffOnsiteAccess.ConfiguredGroups}
                    handleNotification={props.handleNotificationMessage}
                    setProcessing={staffOnsiteAccess.setProcessing}
                    checkMemberHardwareAccess={
                      staffOnsiteAccess.checkMemberHardwareAccess
                    }
                  />:null}
                  {staffOnsiteAccess?.securityConfig?.HardwareSecuritySystemPreferences?.ProviderType === HardwareProviderType.Passport ? <PassportMemberCredential
                    {...staffOnsiteAccess.passportMemberAccess}
                    handleNotification={props.handleNotificationMessage}
                    setProcessing={staffOnsiteAccess.setProcessing}
                    checkMemberPassportAccess={
                      staffOnsiteAccess.checkMemberPassportAccess
                    }
                    StaffPage={true}
                  />:null}
                  
                </>
              </CardBody>
            </Card>
          </div>
        )}
      </>
    </>
  );
};

export default withNotification(StaffOnsiteAccess);
